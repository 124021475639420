import { useDispatch, useSelector } from 'react-redux';
import styles from './css/CanvasColorPicker.module.css';
import { RootState } from '../../../../store';
import { updateLineColor } from '../../../../features/session/blackboardReducer';

export const CanvasColorPicker = () => {
    const dispatch = useDispatch();
    const { lineColor } = useSelector((state: RootState) => state.blackboard);
    const color = ['#FFB700', '#EF5400', '#F00707', '#E417E4', '#7600F4', '#0400F6', '#00B4F6', '#00B545', '#000000'];

    const changeLineColor = (color: string) => {
        dispatch(updateLineColor(color));
    };

    const ColorRender = () => {
        return (
            <>
                {color.map((item, index) => (
                    <button
                        onClick={() => changeLineColor(item)}
                        className={`${styles.color_button} ${lineColor === item ? styles.active : ''}`}
                    >
                        <div className={styles.color_icon} style={{ backgroundColor: item }}></div>
                    </button>
                ))}
            </>
        );
    };

    return (
        <div className={styles.canvas_colorpicker}>
            <div className={styles.options_container}>
                <ColorRender />
            </div>
        </div>
    );
};

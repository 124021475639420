import { SpanText } from '../../../../../component/PublicComponent';
import Postit from '../../../../../assets/face/session2/session2_postit.png';
import styles from '../css/TenthSession.module.css';
import { useState } from 'react';

import { ReactComponent as Icon_pencil } from '../../../../../assets/face/session8/icon_pencil.svg';
import { ReactComponent as Icon_session10_bubble } from '../../../../../assets/face/session10/icon_session10_bubble.svg';

import { ReactComponent as Icon_gold_medal } from '../../../../../assets/face/session10/icon_gold_medal.svg';
import { ReactComponent as Icon_silver_medal } from '../../../../../assets/face/session10/icon_silver_medal.svg';
import { ReactComponent as Icon_bronze_medal } from '../../../../../assets/face/session10/icon_bronze_medal.svg';
import Img1 from '../../../../../assets/face/session10/session10_plan.png';
import Img2 from '../../../../../assets/face/session1/session1_brain.png';
import non_title_brain from '../../../../../assets/face/session10/session10_nontitle_brain.png';
import { ThoughtFeelInput } from './FirstSessionComponent';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import { updateBrain } from '../../../../../features/faceSessionReducer';
import { getMemberInfo } from '../../../../../utils/GetMemberInfo';

export const PreventionRecurrence = () => {
    const memberInfo = getMemberInfo();
    const readOnly = memberInfo?.memberRole !== 'user' && true;
    return (
        <div className={styles.prevention_recurrence}>
            <SpanText className="text_32_NotoSansKR_Bold" color="#41465A" text="Booster Session" />
            <div className={styles.middle_title}>
                <SpanText
                    className="text_22_NotoSansKR_Bold"
                    color="#626466"
                    text="1. 증상이 다시 나타날 수 있음을 인정합니다."
                />
                <SpanText
                    className="text_22_NotoSansKR_Bold"
                    color="#626466"
                    text="2. 회복 시 도움이 된 방법은 앞으로도 도움이 됩니다."
                />
                <SpanText
                    className="text_22_NotoSansKR_Bold"
                    color="#626466"
                    text="3. 바뀐 생각의 패턴을 계속 연습해 봅시다."
                />
            </div>
            <div className={styles.content_container}>
                <div
                    style={{
                        position: 'relative',
                        display: 'flex',
                        alignItems: 'center',
                        gap: '0.5rem',
                    }}
                >
                    <Icon_pencil className={styles.icon_pencil} />
                    <SpanText className="text_22_NotoSansKR_Bold" color="#626466" text="재발방지 일기" />
                </div>
                <div className={styles.experiences_content_container}>
                    <div className={styles.experiences_content}>
                        <div className={styles.postit_container}>
                            <img alt="postit" src={Postit} className={styles.postit} />
                            <div className={`${styles.postit_title} text_22_NotoSansKR_Bold`}>감정의 신체적 반응</div>
                        </div>
                        <textarea className={styles.experiences_input} readOnly={readOnly} />
                    </div>
                    <div className={styles.experiences_content}>
                        <div className={styles.postit_container}>
                            <img alt="postit" src={Postit} className={styles.postit} />
                            <div className={`${styles.postit_title} text_22_NotoSansKR_Bold`}>감정의 행동적 반응</div>
                        </div>
                        <textarea className={styles.experiences_input} readOnly={readOnly} />
                    </div>
                    <div className={styles.experiences_content}>
                        <div className={styles.postit_container}>
                            <img alt="postit" src={Postit} className={styles.postit} />
                            <div className={`${styles.postit_title} text_22_NotoSansKR_Bold`}>감정의 인지적 반응</div>
                        </div>
                        <textarea className={styles.experiences_input} readOnly={readOnly} />
                    </div>
                </div>
                <Icon_session10_bubble className={styles.icon_session10_bubble} />
            </div>
        </div>
    );
};

export const CognitiveErrorStatistics = () => {
    const [cognitiveErrorList, setCognitiveErrorList] = useState([
        {
            medal: 'gold',
            title: '흑백논리의 오류',
            count: 10,
        },
        {
            medal: 'silver',
            title: '흑백논리의 오류',
            count: 8,
        },
        {
            medal: 'bronze',
            title: '흑백논리의 오류',
            count: 3,
        },
    ]);

    const Medal: any = ({ medal }: { medal: string }) => {
        switch (medal) {
            case 'gold':
                return <Icon_gold_medal width={'5.75rem'} height={'5.375rem'} />;
            case 'silver':
                return <Icon_silver_medal width={'5.75rem'} height={'5.375rem'} />;
            case 'bronze':
                return <Icon_bronze_medal width={'5.75rem'} height={'5.375rem'} />;
        }
    };

    return (
        <div className={styles.cognitive_error_statistics}>
            <span
                className="text_32_NotoSansKR_Bold"
                style={{
                    color: '#41465A',
                }}
            >
                상위{' '}
                <span
                    className="text_32_Inter_Bold"
                    style={{
                        color: '#41465A',
                    }}
                >
                    3
                </span>
                개의 선택된 인지오류
            </span>
            <div className={styles.content_container}>
                {cognitiveErrorList.map((item, index) => (
                    <div key={index.toString()} className={styles.statistics_content}>
                        <Medal medal={item.medal} />
                        <div className={styles.statistics_input}>
                            <SpanText className="text_24_NotoSansKR_Bold" color="#020202" text={item.title} />
                            <span
                                className="text_22_NotoSansKR_Medium"
                                style={{
                                    color: '#9D9FA2',
                                }}
                            >
                                <span
                                    className="text_22_Inter_Medium"
                                    style={{
                                        color: '#9D9FA2',
                                    }}
                                >
                                    {item.count}
                                </span>
                                회
                            </span>
                        </div>
                    </div>
                ))}
            </div>
            <div className={styles.img_plan_container}>
                <img alt="img1" src={Img1} className={styles.img} />
            </div>
        </div>
    );
};

export const ThoughtFeelInputAnswer = () => {
    const dispatch = useDispatch();
    const { inputData } = useSelector((state: RootState) => state.faceSession);
    const onChageBrain = (index: number, patient: string, therapist: string) => {
        dispatch(updateBrain({ index, patient, therapist }));
    };
    return (
        <div
            style={{
                display: 'flex',
                position: 'absolute',
                width: '100%',
                height: '100%',
            }}
        >
            {inputData.brain.map((item, index) => (
                <div
                key={index.toString()}
                    className={`${styles.thought_feel_input_container} ${
                        styles[`thought_feel_input_container${index}`] || ''
                    }`}
                >
                    <textarea
                        className={styles.thought_feel_input}
                        value={item.patient}
                        maxLength={30}
                        onChange={(e) => onChageBrain(index, e.target.value, '')}
                    />
                    <textarea
                        className={`${styles.thought_feel_input} ${styles.therapist}`}
                        value={item.patient}
                        maxLength={30}
                        onChange={(e) => onChageBrain(index, e.target.value, '')}
                    />
                </div>
            ))}
        </div>
    );
};

export const ThoughtFeelAnswer = () => {
    return (
        <div className={styles.thought_feel}>
            <div className={styles.thought_feel_container}>
                <img alt="brain" src={Img2} className={styles.img} />
                <ThoughtFeelInput />
            </div>
        </div>
    );
};

export const ThoughtFeel = () => {
    return (
        <div className={styles.thought_feel_non_title}>
            <div className={styles.thought_feel_container}>
                <img alt="brain" src={non_title_brain} className={styles.img} />
                <ThoughtFeelInput />
            </div>
        </div>
    );
};

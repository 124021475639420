import React, { useEffect, useState } from 'react';

import styles from './css/Authority.module.css';
import authorityModalStyles from './component/css/AuthorityComponent.module.css';
import { ReactComponent as Icon_arrowleft } from '../../../../assets/public/icon_arrowleft.svg';
import { ReactComponent as Icon_arrowright } from '../../../../assets/member/signup/icon_arrowright.svg';

import { useNavigate } from 'react-router-dom';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { EmployeeList, getEmployeeList } from './ts/AuthorityApi';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { OfficialRender, SearchContainer, TitleRender } from './component/AuthorityComponent';
import { resetModal, updateCheckAry, updatePageNum } from '../../../../features/authorityReducer';

export const Authority = () => {
    const dispatch = useDispatch();
    const { companyId, pageNum, organVisible, roleVisible, employeeSettingVisible } = useSelector(
        (state: RootState) => state.authority
    );

    const { data, error, isLoading, refetch } = useQuery<EmployeeList>({
        queryKey: ['employeeList', { companyId, pageNum }],
        queryFn: () => getEmployeeList(companyId, pageNum),
        placeholderData: keepPreviousData,
    });

    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (
                (organVisible.visible && !event.target.closest(`.${authorityModalStyles.dropdown_container}`)) ||
                (employeeSettingVisible.visible &&
                    !event.target.closest(`.${authorityModalStyles.non_button_dropdown_container}`)) ||
                (roleVisible.visible && !event.target.closest(`.${authorityModalStyles.non_button_dropdown_container}`))
            ) {
                dispatch(resetModal());
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, [organVisible, employeeSettingVisible, roleVisible]);

    // 로딩 중일 때
    if (isLoading) {
        return <div>Loading...</div>;
    }

    // 오류 발생 시
    if (error) {
        return <div>Error: {error.message}</div>;
    }

    // data가 존재하지 않거나 비어있을 경우
    if (!data || !data?.members) {
        return <div>No employees found.</div>;
    }

    const nextPage = () => {
        if (data?.members.totalPages! - 1 < pageNum) {
            dispatch(updatePageNum(pageNum + 1));
        }
    };

    const prevPage = () => {
        if (pageNum > 0) {
            dispatch(updatePageNum(pageNum - 1));
        }
    };

    const selectOrgan = async () => {
        // await axiosInstance.
    };

    return (
        <div className={styles.main_container}>
            <div className="text_32_NotoSansKR_Bold">권한</div>
            <TitleRender list={data} />
            <div className={styles.content_container}>
                <SearchContainer />
                <OfficialRender list={data} refetch={refetch} />
            </div>
            <div className={styles.page_container}>
                <button onClick={prevPage} className={styles.button}>
                    <Icon_arrowleft
                        className={`${styles.icon_arrow} ${pageNum > 0 ? styles.activate : styles.deactivate}`}
                    />
                </button>
                <span
                    className="text_18_Inter_Medium"
                    style={{
                        color: '#626466',
                    }}
                >
                    Page{pageNum + 1}
                </span>
                <button onClick={nextPage} className={styles.button}>
                    <Icon_arrowright
                        className={`${styles.icon_arrow} ${
                            data?.members.totalPages! - 1 === pageNum ? styles.deactivate : styles.activate
                        }`}
                    />
                </button>
            </div>
        </div>
    );
};

import styles from '../../css/SecondSession.module.css';
import '../../../css/PublicSessionStyle.css';

import Img1 from '../../../../../../assets/face/session2/session2_1.png';
import Img2 from '../../../../../../assets/face/session2/session2_2.png';

import { useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import { ExperiencesEmotion } from '../../component/SecondSessionComponent';
import { SituationEmotion, SubmitWorkbook } from '../../component/PublicSessionComponent';
import { getMemberInfo } from '../../../../../../utils/GetMemberInfo';
import { useLocation } from 'react-router-dom';
import { updateSessionPage } from '../../../../../../features/session/sessionReducer';
import { useDispatch } from 'react-redux';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { getSessionData } from '../ts/TherapistSessionApi';

export const TherapistSecondSession = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const currentSession = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);

    const queryParams = new URLSearchParams(location.search);
    const encryptedData = queryParams.get('session');
    const { data, isLoading, error, refetch } = useQuery({
        queryKey: ['getSessionData', encryptedData],
        queryFn: () => getSessionData(encryptedData!),
        placeholderData: keepPreviousData,
    });

    const memberInfo = getMemberInfo();
    const readOnly = memberInfo?.memberRole !== 'user' && true;
    const playerRef = useRef<ReactPlayer | null>(null);
    const playerPopUpRef = useRef<ReactPlayer | null>(null);

    const [physicalSymptomsList, setPhysicalSymptomsList] = useState([
        {
            style: styles.brain,
        },
        {
            style: styles.breath,
        },
        {
            style: styles.muscle_tension,
        },
        {
            style: styles.intestine,
        },
        {
            style: styles.peripheral_blood_vessels,
        },
        {
            style: styles.salivary_glands,
        },
        {
            style: styles.heart,
        },
        {
            style: styles.camouflage,
        },
        {
            style: styles.sweat_glands,
        },
    ]);

    const [playing, setPlaying] = useState(false);
    const [volume, setVolume] = useState(0.8); // 초기 볼륨
    const [playbackTime, setPlaybackTime] = useState(0);
    const [popUpVisible, setPopUpVisible] = useState<boolean>(true);

    const targetRef: any = useRef(null);
    const [showPopup, setShowPopup] = useState(false);
    const lastScrollY = useRef(0); // useRef로 상태를 관리

    useEffect(() => {
        window.scrollTo(0, 0);
        if (data) {
            dispatch(updateSessionPage(data));
        }
    }, [data]);

    const handlePlay = () => {
        setPlaying(!playing); // 재생 중
    };

    // 동영상의 재생 시간 업데이트
    const handleProgress = (progress: { playedSeconds: number }) => {
        if (!showPopup) {
            setPlaybackTime(progress.playedSeconds);
        }
    };
    return (
        <div className={'therapist_main_container'}>
            <div ref={targetRef}>
                <ReactPlayer
                    ref={playerRef}
                    url="https://exqibabapqbf17923502.cdn.ntruss.com/ydTest/CBT10.mp4"
                    height="auto" // 비디오 높이
                    width="100%"
                    playing={playing}
                    onPlay={handlePlay}
                    onPause={handlePlay}
                    volume={volume}
                    onProgress={handleProgress}
                    pip={false}
                    controls // 기본 컨트롤 비활성화
                    stopOnUnmount={true}
                />
            </div>
            <div className={styles.image_input_container}>
                <img alt="1" src={Img1} className={styles.img1} />
                {physicalSymptomsList.map((item, index) => (
                    <div
                        key={index.toString()}
                        className={`${styles.physical_symptoms_input_container} ${item.style} text_18_NotoSansKR_Regular`}
                    >
                        <input className={styles.physical_symptoms_input} readOnly={readOnly} />
                        <span className="text_18_NotoSansKR_Regular">점</span>
                    </div>
                ))}
            </div>
            <img alt="2" src={Img2} className={styles.img} />
            <SituationEmotion currentSession={currentSession} />

            <ExperiencesEmotion />
        </div>
    );
};

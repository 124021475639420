import styles from './css/AssignedDetail.module.css';
import { AssigendProfile } from './component/AssigendProfile';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { TherapistMemberDetail, getTherapistMemberDetail } from './ts/AssignedDetailApi';
import { format, getDay, parseISO } from 'date-fns';
import { ko } from 'date-fns/locale';

interface MenuButtonProps {
    $isSelectedMenu: string;
    $menu: string;
}

const MenuButton = styled.button<MenuButtonProps>`
    display: inline-block;
    height: 3.5rem;
    align-items: flex-start;
    ${(props) =>
        props.$isSelectedMenu === props.$menu
            ? 'color: #3A5074; border-bottom: 4px solid #3a5074;'
            : 'color: #9D9FA2; padding-bottom: 0.25rem;'}
`;

const SessionMenuButton = styled.button<{ $isSelectedMenu: string; $menu: string }>`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 1.5rem;
    height: 3rem;
    border-radius: 10px;
    ${(props) =>
        props.$isSelectedMenu === props.$menu
            ? 'color: #fff; background: #3a5074;'
            : 'color: #9D9FA2; border: 1px solid  #E3E5EA;background: #FFF;'}
`;

const ProgressMenuButton = styled.button<MenuButtonProps>`
    display: flex;
    box-sizing: border-box;
    width: 6.5625rem;
    height: 2.625rem;
    justify-content: center;
    align-items: center;
    border-radius: 0.5rem;
    ${(props) =>
        props.$isSelectedMenu === props.$menu
            ? 'color: #3A5074; background: #fff; box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.15);'
            : 'color: #9D9FA2;'}
`;

export const AssignedDetail = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const encryptedData = queryParams.get('data');

    const { data, isLoading, error, refetch } = useQuery<TherapistMemberDetail>({
        queryKey: ['TherapistMemberDetail', { memberId: encryptedData }],
        queryFn: () => getTherapistMemberDetail(encryptedData!),
        placeholderData: keepPreviousData,
        enabled: encryptedData !== null,
    });

    const memberInfo = {
        memberId: data?.memberId,
        memberName: data?.memberName,
        regDate: data?.regDate,
        therapistList: data?.therapistList,
        branch: data?.branch,
    };

    const [menuList, setMenuList] = useState<string[]>(['세션관리', '문서관리']);
    const progressStatusList = [
        { id: -1, title: '전체' },
        { id: 0, title: '세션완료' },
        { id: 1, title: '예약취소' },
        { id: 2, title: '노쇼' },
    ];

    const [isSelectedMenu, setIsSelectedMenu] = useState<string>('세션관리');
    const [isSessionSelectedMenu, setIsSessionSelectedMenu] = useState<string>(data?.workBookNameList[0]!);
    const [isProgressSelectedMenu, setIsProgressSelectedMenu] = useState<{ id: number; title: string }>({
        id: -1,
        title: '전체',
    });

    useEffect(() => {
        if (data) {
            setIsSessionSelectedMenu(data.workBookNameList[0]);
        }
    }, [data]);

    if (isLoading) {
        return <div>sdf</div>;
    }

    // 날짜,시간 출력형식 변환
    const formatDate = (dateString: string) => {
        const date = parseISO(dateString); // ISO 형식의 문자열을 Date 객체로 변환

        // 요일 배열
        const weekdays = ['일요일', '월요일', '화요일', '수요일', '목요일', '금요일', '토요일'];

        // 날짜 형식 설정
        const formattedDate = format(date, 'yyyy-MM-dd', { locale: ko });
        const dayOfWeek = weekdays[getDay(date)]; // 요일 가져오기
        const timePart = format(date, 'aa hh:mm', { locale: ko }); // 오전/오후와 시간 형식

        return `${formattedDate} ${dayOfWeek} ${timePart}`;
    };

    const statusColor = (type: number) => {
        switch (type) {
            case 0:
                return { title: '예약완료', color: '#3A5074', bgColor: '#E9F0FD' };
            case 1:
                return { title: '예약취소', color: '#FF5833', bgColor: '#FFEDE9' };
            case 2:
                return { title: '노쇼', color: '#626466', bgColor: '#EEF0F3' };
            case 3:
                return { title: '세션완료', color: '#39D71D', bgColor: '#E4FFDF' };
        }
    };

    const MenuRender = () => {
        return (
            <>
                {menuList.map((item, index) => (
                    <MenuButton
                        key={index.toString()}
                        $isSelectedMenu={isSelectedMenu}
                        $menu={item}
                        onClick={() => {
                            setIsSelectedMenu(item);
                        }}
                        className={`${styles.menu_title} text_18_NotoSansKR_Bold`}
                    >
                        {item}
                    </MenuButton>
                ))}
            </>
        );
    };

    const CurrentSessionMenuRender = () => {
        return (
            <div className={styles.menu_container}>
                <div className={styles.session_menu_container}>
                    {data?.workBookNameList.map((item, index) => (
                        <SessionMenuButton
                            key={index.toString()}
                            $isSelectedMenu={isSessionSelectedMenu}
                            $menu={item}
                            onClick={() => {
                                setIsSessionSelectedMenu(item);
                            }}
                            className={`${styles.session_menu} text_18_NotoSansKR_Bold`}
                        >
                            {item}
                        </SessionMenuButton>
                    ))}
                </div>
                <div className={styles.progress_status_container}>
                    {progressStatusList.map((item, index) => (
                        <ProgressMenuButton
                            key={index.toString()}
                            className={
                                item === isProgressSelectedMenu
                                    ? 'text_16_NotoSansKR_Bold'
                                    : 'text_16_NotoSansKR_Medium'
                            }
                            $isSelectedMenu={isProgressSelectedMenu.title}
                            onClick={() => setIsProgressSelectedMenu(item)}
                            $menu={item.title}
                        >
                            {item.title}
                        </ProgressMenuButton>
                    ))}
                </div>
            </div>
        );
    };

    const SessionContent = () => {
        const sessionFilter = data?.workBookList
            .filter((item) => item.workBookName === isSessionSelectedMenu)
            .flatMap((item) => item.sessionAll);

        return (
            <ul>
                <div className={styles.session_content_menu}>
                    <div
                        style={{
                            display: 'flex',
                            gap: '8.75rem',
                        }}
                    >
                        <div className={`${styles.session_title} text_16_NotoSansKR_Medium`}>진행세션</div>
                        <div className={`${styles.session_title} text_16_NotoSansKR_Medium`}>예약일</div>
                    </div>
                    <div
                        style={{
                            width: '25.375rem',
                        }}
                    >
                        <div className={`${styles.session_title} text_16_NotoSansKR_Medium`}>상태</div>
                    </div>
                </div>
                {(isProgressSelectedMenu.id === -1
                    ? sessionFilter!
                    : sessionFilter!.filter((item) => item.sessionStatus === isProgressSelectedMenu.id)
                ).map((item, index) => (
                    <li
                        key={index.toString()}
                        className={`${styles.sessino_list_container} ${
                            index === sessionFilter!.length - 1 ? '' : styles.border_bottom
                        }`}
                    >
                        <div
                            style={{
                                display: 'flex',
                                gap: '8.75rem',
                                alignItems: 'center',
                            }}
                        >
                            <div className={`${styles.current_session} text_16_NotoSansKR_Medium`}>
                                세션{item.sessionNo}
                            </div>
                            <div>{formatDate(item.regDate)}</div>
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                width: '25.375rem',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            }}
                        >
                            <div
                                className={`${styles.status} text_16_NotoSansKR_Medium`}
                                style={{
                                    color: statusColor(item.sessionStatus)?.color,
                                    backgroundColor: statusColor(item.sessionStatus)?.bgColor,
                                }}
                            >
                                {statusColor(item.sessionStatus)?.title}
                            </div>
                            <button className={`${styles.reserve_info_button} text_16_NotoSansKR_Bold`}>
                                예약 상세정보
                            </button>
                        </div>
                    </li>
                ))}
            </ul>
        );
    };

    return (
        <div className={styles.main_container}>
            {!isLoading && <AssigendProfile pageData={data} />}
            <div>
                <div className={styles.menu_title_container}>
                    <MenuRender />
                </div>
                {isSelectedMenu === '세션관리' ? (
                    <div className={styles.patient_session_container}>
                        <CurrentSessionMenuRender />
                        <SessionContent />
                    </div>
                ) : (
                    <div className={`${styles.preparing_service} text_18_NotoSansKR_Medium`}>
                        <span>
                            보다 나은 서비스 제공을 위해{' '}
                            <span
                                style={{
                                    color: '#3A5074',
                                }}
                            >
                                페이지 준비중
                            </span>
                            에 있습니다.
                        </span>
                    </div>
                )}
            </div>
        </div>
    );
};

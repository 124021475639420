import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import styles from './css/Login.module.scss';
import { MemberTypeMenu } from './component/MemberTypeMenu';

import { ReactComponent as Icon_kakao } from '../../../assets/member/login/icon_kakao.svg';
import { ReactComponent as Icon_checkbox } from '../../../assets/member/icon_checkbox.svg';
import { ReactComponent as Icon_checkedbox } from '../../../assets/member/icon_checkedbox.svg';
import logoPng from '../../../assets/member/login/logo.png';
import { ModalContext } from '../../../context/ModalContext';
import { LoginInput } from './component/LoginInput';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { Illustration } from '../component/Illustration';
import { useNavigate } from 'react-router-dom';
import { MainButton } from '../component/MainButton';
import { SelectOrganizationLogin } from './component/SelectOrganizationLogin';
import axiosInstance from '../../../utils/AxiosInstanceJava';
import { kakaoLogin, normalLoginFn } from './types/LoginFn';
import { updateLoginMemberAccount } from '../../../features/loginReducer';
import { updateMemberType } from '../../../features/memberTypeMenuReducer';

export const Login = () => {
    const { oneButtonOpenModal } = useContext(ModalContext);
    const dispatch = useDispatch();
    const login = useSelector((state: RootState) => state.login);
    const memberType = useSelector((state: RootState) => state.memberType);

    const navigate = useNavigate();

    const [saveIdChecked, setSaveIdChecked] = useState<boolean>(false);

    useEffect(() => {
        getSavedAccount();
    }, []);

    const getSavedAccount = () => {
        const data = localStorage.getItem('saveMemberAccount');
        if (data) {
            const account = JSON.parse(data);
            setSaveIdChecked(true);
            dispatch(updateLoginMemberAccount(account.memberAccount));
        }
    };

    const loginRule = () => {
        let message = '';
        const accountRegex = /^(?=.*[a-z])(?=.*\d)[a-z\d]{4,}$/;
        const passwordRegex =
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>])[a-zA-Z\d!@#$%^&*(),.?":{}|<>]{4,}$/;
        if (login.memberAccount === '') {
            message = '아이디를 입력해 주세요.';
        } else if (login.memberPassword === '') {
            message = '비밀번호를 입력해 주세요.';
        } else if (!passwordRegex.test(login.memberPassword) || !accountRegex.test(login.memberAccount)) {
            message = '아이디 또는 비밀번호가 잘못되었습니다.';
        } else {
            if (memberType.type === 0) {
            } else {
                if (login.memberOrgan === '') {
                    message = '로그인하실 소속기관을 선택해 주세요.';
                }
            }
        }
        if (message !== '') {
            return oneButtonOpenModal(() => {}, '', message, '확인');
        }

        normalLoginFn(
            memberType.type === 0 ? 'user' : 'employee',
            login,
            saveIdChecked,
            axiosInstance,
            navigate,
            'normal',
            oneButtonOpenModal
        );
    };

    return (
        <div className={styles.main_container}>
            <div className={styles.view_container}>
                <div className={styles.content_container}>
                    <img className={styles.logo} src={logoPng} />
                    <div>
                        <div className={styles.login_title}>
                            <span className="text_40_Inter_Bold">YD iCBT </span>
                            <span className="text_40_NotoSansKR_Bold">로그인</span>
                        </div>
                        <div className={`${styles.signup_title} text_18_NotoSansKR_Regular`}>
                            <span
                                style={{
                                    color: '#626466',
                                }}
                            >
                                아직 계정이 없나요?
                            </span>
                            <button className="text_18_NotoSansKR_Regular" onClick={() => navigate('/public/signup')}>
                                <span
                                    style={{
                                        color: '#3A5074',
                                        textDecorationLine: 'underline',
                                    }}
                                >
                                    회원가입 하기
                                </span>
                            </button>
                        </div>
                    </div>
                    <MemberTypeMenu menuList={['일반회원', '직원']} selectedIndex={0} reset={() => {}} />
                    <button onClick={kakaoLogin} className={styles.kakao_login_button}>
                        <Icon_kakao className={styles.icon_kakao_logo} />
                        <span className="text_18_NotoSansKR_Bold">카카오로 시작하기</span>
                    </button>
                    <div className={styles.and_line_container}>
                        <div className={styles.and_line} />
                        <span
                            className="text_18_NotoSansKR_Regular"
                            style={{
                                color: '#9D9FA2',
                            }}
                        >
                            또는
                        </span>
                        <div className={styles.and_line} />
                    </div>
                    <SelectOrganizationLogin />
                    <LoginInput loginRule={loginRule} />
                    <div className={styles.account_assistant_container}>
                        <div className={styles.save_id}>
                            <button onClick={() => setSaveIdChecked(!saveIdChecked)}>
                                {saveIdChecked ? (
                                    <Icon_checkedbox width={'1.5rem'} height={'1.5rem'} />
                                ) : (
                                    <Icon_checkbox width={'1.5rem'} height={'1.5rem'} />
                                )}
                            </button>
                            <span className="text_18_NotoSansKR_Medium" style={{ color: '#020202' }}>
                                아이디 저장
                            </span>
                        </div>

                        <div className={styles.find_account}>
                            <button
                                onClick={() => {
                                    navigate(`/public/findAccount?selectedIndex=${0}`);
                                    dispatch(updateMemberType(0));
                                }}
                            >
                                <span
                                    className="text_18_NotoSansKR_Regular"
                                    style={{
                                        color: '#3A5074',
                                    }}
                                >
                                    아이디 찾기
                                </span>
                            </button>
                            <div className={styles.length_line} />
                            <button
                                onClick={() => {
                                    navigate(`/public/findAccount?selectedIndex=${1}`);
                                    dispatch(updateMemberType(1));
                                }}
                            >
                                <span
                                    className="text_18_NotoSansKR_Regular"
                                    style={{
                                        color: '#3A5074',
                                    }}
                                >
                                    비밀번호 찾기
                                </span>
                            </button>
                        </div>
                    </div>
                    <MainButton title={'로그인'} onClick={loginRule} />
                </div>
            </div>
            <Illustration />
        </div>
    );
};

import { SquareCheckBox } from '../../../../../component/PublicComponent';
import { ReactComponent as Icon_search } from '../../../../../assets/therapist/icon_search.svg';
import { ReactComponent as Icon_dot_menu } from '../../../../../assets/public/icon_dot_menu.svg';
import { ReactComponent as Icon_arrowbottom } from '../../../../../assets/member/icon_arrowbottom.svg';
import styles from './css/AuthorityComponent.module.css';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import {
    updateCheckAry,
    updateEmployeeSettingVisible,
    updateIsSelectedOfficial,
    updateBranchVisible,
    updateRoleVisible,
    updateSelectOrgan,
} from '../../../../../features/authorityReducer';
import moment from 'moment';
import { EmployeeList } from '../ts/AuthorityApi';
import axiosInstance from '../../../../../utils/AxiosInstanceJava';
import { getMemberInfo } from '../../../../../utils/GetMemberInfo';

interface OrganList {
    index: number;
    companyId: number;
    branchName: string;
}

interface EmployeeData {
    memberId: number;
    memberName: string;
    memberRole: string;
    branches: {
        companyId: number;
        branchName: string;
    }[];
    modDate: string;
}

export const TitleRender = ({ list }: { list: EmployeeList }) => {
    const dispatch = useDispatch();
    const { isSelectedTitle } = useSelector((state: RootState) => state.authority);

    return (
        <div className={styles.middle_title_container}>
            {list.branchesCategories.map((item, index) => (
                <button
                    key={index.toString()}
                    onClick={() =>
                        dispatch(updateSelectOrgan({ companyId: item.companyId, branchName: item.branchName }))
                    }
                    className={`${styles.middle_title} ${
                        isSelectedTitle === item.branchName ? styles.activate : styles.deactivate
                    } ${isSelectedTitle === item.branchName ? 'text_18_NotoSansKR_Bold' : 'text_18_NotoSansKR_Medium'}`}
                >
                    {item.branchName}
                    <span>({item.count})</span>
                </button>
            ))}
        </div>
    );
};

export const SearchContainer = () => {
    const dispatch = useDispatch();
    const { officialList, isSelectedOfficial } = useSelector((state: RootState) => state.authority);

    return (
        <div className={styles.search_container}>
            <div className={styles.input_container}>
                <input className={styles.input} placeholder="검색어 입력" />
                <div className={styles.icon_search}>
                    <Icon_search />
                </div>
            </div>
            <div className={styles.filter_button_container}>
                {officialList.map((item, index) => (
                    <button
                        key={index.toString()}
                        onClick={() => dispatch(updateIsSelectedOfficial(item.type))}
                        className={`${styles.filter_button} ${
                            isSelectedOfficial === item.type ? styles.activate : styles.deactivate
                        } ${
                            isSelectedOfficial === item.type ? 'text_16_NotoSansKR_Bold' : 'text_16_NotoSansKR_Medium'
                        } `}
                    >
                        {item.title}
                    </button>
                ))}
            </div>
        </div>
    );
};

export const EmployeeSettingRender = ({ memberData }: { memberData: EmployeeData }) => {
    const { employeeSettingMenuList } = useSelector((state: RootState) => state.authority);
    const memberInfo = getMemberInfo();
    const deleteMember = async () => {
        await axiosInstance.delete(`/api/${memberInfo?.memberRole}/${memberData.memberId}/v1`).then((response) => {});
    };

    return (
        <div className={styles.non_button_dropdown_container}>
            <div className={styles.employee_button_conatiner}>
                {employeeSettingMenuList.map((item, index) => (
                    <button key={index.toString()} className={styles.employee_button} onClick={deleteMember}>
                        <span className="text_16_NotoSansKR_Medium">{item}</span>
                    </button>
                ))}
            </div>
        </div>
    );
};

export const OfficialRender = ({ list, refetch }: { list: EmployeeList; refetch: Function }) => {
    const dispatch = useDispatch();
    const { organVisible, chartTitleList, roleVisible, employeeSettingVisible, isSelectedOfficial } = useSelector(
        (state: RootState) => state.authority
    );

    const officialMemberListFilter = () => {
        let filterList = list.members.content;
        // if (isSelectedTitle.branchName !== '모든 기관') {
        //     filterList = officialMemberList.filter((item) => item.organ === isSelectedTitle.branchName);
        // }
        if (isSelectedOfficial !== 'all') {
            filterList = list.members.content.filter(
                (item: { memberRole: string }) => item.memberRole === isSelectedOfficial
            );
        }
        return filterList;
    };

    const changeMemberRole = (role: string) => {
        switch (role) {
            case 'therapist':
                return '치료자';
            case 'manager':
                return '매니저';
            case 'administrator':
                return '관리자';
            case 'representative':
                return '시스템 관리자';
            default:
                return '';
        }
    };

    // 시스템 관리자를 제외하고 드롭박스
    const RoleRender = ({ role, index }: { role: string; index: number }) => {
        if (role === 'representative') {
            return <span className="text_16_NotoSansKR_Medium">{changeMemberRole(role)}</span>;
        } else {
            return (
                <button
                    onClick={() => dispatch(updateRoleVisible({ index: index, visible: !roleVisible.visible }))}
                    className={styles.dropdown_button}
                >
                    <span className="text_16_NotoSansKR_Medium">{changeMemberRole(role)}</span>
                    <Icon_arrowbottom />
                </button>
            );
        }
    };

    const OrganRender = ({
        role,
        organ,
        index,
    }: {
        role: string;
        organ: { companyId: number; branchName: string }[];
        index: number;
    }) => {
        if (role === 'representative') {
            return null;
        } else {
            return (
                <button
                    onClick={() => {
                        dispatch(updateBranchVisible({ index: index, visible: !organVisible.visible }));
                        dispatch(updateCheckAry(organ));
                    }}
                    className={styles.dropdown_button}
                >
                    <span className="text_16_NotoSansKR_Medium">{organ.map((item) => item.branchName).join(', ')}</span>
                    <Icon_arrowbottom />
                </button>
            );
        }
    };

    return (
        <>
            <div className={styles.chart_title_container}>
                {chartTitleList.map((item, index) => (
                    <div key={index.toString()} className={`${styles.chart_title} text_16_NotoSansKR_Medium`}>
                        {item}
                    </div>
                ))}
            </div>
            {officialMemberListFilter().map((item, index) => (
                <div
                    key={index.toString()}
                    className={`${styles.chart_content_container} ${
                        index === list.members.content.length - 1 ? null : styles.border_bottom
                    }`}
                >
                    <div className={styles.item}>
                        <span className="text_16_NotoSansKR_Bold">{item.memberName}</span>
                    </div>
                    <div className={styles.item}>
                        <RoleRender role={item.memberRole} index={index} />
                        {roleVisible.index === index && roleVisible.visible && (
                            <RoleDropDownRender memberData={item} refetch={refetch} />
                        )}
                    </div>
                    <div className={styles.item}>
                        <OrganRender role={item.memberRole} organ={item.branches} index={index} />
                        {organVisible.index === index && organVisible.visible && (
                            <OrganDropDownRender
                                memberData={item}
                                branchList={list.branchesCategories}
                                refetch={refetch}
                            />
                        )}
                    </div>
                    <div className={styles.item}>
                        <span className="text_16_Inter_Medium">{moment(item.modDate).format('YYYY-MM-DD')}</span>
                    </div>
                    <div className={styles.icon_dot_menu}>
                        <button
                            onClick={() =>
                                dispatch(
                                    updateEmployeeSettingVisible({
                                        index: index,
                                        visible: !employeeSettingVisible.visible,
                                    })
                                )
                            }
                        >
                            <Icon_dot_menu />
                        </button>
                        {employeeSettingVisible.index === index && employeeSettingVisible.visible && (
                            <EmployeeSettingRender memberData={item} />
                        )}
                    </div>
                </div>
            ))}
        </>
    );
};

export const RoleDropDownRender = ({ memberData, refetch }: { memberData: EmployeeData; refetch: Function }) => {
    const dispatch = useDispatch();
    const { roleVisible } = useSelector((state: RootState) => state.authority);
    const roleList: { role: string; type: string }[] = [
        { role: '치료자', type: 'therapist' },
        { role: '매니저', type: 'manager' },
        { role: '관리자', type: 'administrator' },
    ];
    const memberInfo = getMemberInfo();

    const changeMemberRole = async (role: string) => {
        await axiosInstance
            .post(`/api/${memberInfo?.memberRole}/change/role/v1`, {
                memberId: memberData.memberId,
                role: role,
            })
            .then((response) => {
                if (response.data.result) {
                    dispatch(updateRoleVisible({ index: -1, visible: !roleVisible.visible }));
                    refetch();
                }
            });
    };

    return (
        <div className={styles.non_button_dropdown_container}>
            <div className={styles.role_button_conatiner}>
                {roleList.map((item, index) => (
                    <button
                        key={index.toString()}
                        className={styles.employee_button}
                        onClick={() => changeMemberRole(item.type)}
                    >
                        <span className="text_16_NotoSansKR_Medium">{item.role}</span>
                    </button>
                ))}
            </div>
        </div>
    );
};

export const OrganDropDownRender = ({
    memberData,
    refetch,
    branchList,
}: {
    memberData: EmployeeData;
    refetch: Function;
    branchList: { companyId: number; branchName: string; count: number }[];
}) => {
    const dispatch = useDispatch();
    const { checkAry, organVisible } = useSelector((state: RootState) => state.authority);

    const dupliValue = (item: OrganList) => {
        const index = checkAry.findIndex((existingItem) => existingItem.branchName === item.branchName);

        if (index !== -1) {
            // 중복이 있으면 해당 값을 제거
            return checkAry.filter((_, i) => i !== index);
        } else {
            // 중복이 없으면 추가
            return [...checkAry, item];
        }
    };

    const checkStorage = (item: OrganList) => {
        dispatch(updateCheckAry(dupliValue(item)));
    };

    const memberInfo = getMemberInfo();

    const changeMemberOrgan = async () => {
        const changeBranchId = checkAry.map((item) => item.companyId);
        await axiosInstance
            .post(`/api/${memberInfo?.memberRole}/changed/branches/v1`, {
                memberId: memberData.memberId,
                afterBranchIds: changeBranchId,
            })
            .then((response) => {
                if (response.data.result) {
                    dispatch(updateBranchVisible({ index: -1, visible: !organVisible.visible }));
                    refetch();
                }
            });
    };

    return (
        <div className={styles.dropdown_container}>
            <div className={styles.dropdown_item_container}>
                {branchList
                    .filter((item) => item.companyId !== 0)
                    .map((item, index) => (
                        <button
                            key={index.toString()}
                            className={styles.dropdown_item_button}
                            onClick={() =>
                                checkStorage({ companyId: item.companyId, branchName: item.branchName, index: index })
                            }
                        >
                            <SquareCheckBox
                                bool={checkAry.some((value) => value.branchName === item.branchName)}
                                size="1.5rem"
                            />
                            <span className="text_16_NotoSansKR_Medium">{item.branchName}</span>
                        </button>
                    ))}
            </div>
            <div className={styles.dropdown_button_container}>
                <button
                    onClick={() =>
                        dispatch(updateBranchVisible({ index: organVisible.index, visible: !organVisible.visible }))
                    }
                    className={styles.dropdown_cancel_button}
                >
                    <span className="text_16_NotoSansKR_Bold">취소</span>
                </button>
                <button onClick={changeMemberOrgan} className={styles.dropdown_select_button}>
                    <span
                        className="text_16_NotoSansKR_Bold"
                        style={{
                            color: '#FFF',
                        }}
                    >
                        선택완료
                    </span>
                </button>
            </div>
        </div>
    );
};

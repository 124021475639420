import React, { useEffect, useState } from 'react';

import styles from './css/MainProgram.module.css';
import { BasicHeader } from '../../../component/HeaderComponent';
import { ReactComponent as Icon_check } from '../../../assets/member/signup/icon_check.svg';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../../../utils/AxiosInstanceJava';

interface ProgramList {
    workBookProductId: number;
    discountPrice: number;
    price: number;
    productDescription: string;
    productName: string;
    workBookList: {
        workBookId: number;
        type: string;
        workBookName: string;
    }[];
}

interface ProgramItemButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    $isSelected: number; // 추가적인 prop 정의
    $index: number;
}

const ProgramItemButton = styled.button<ProgramItemButtonProps>`
    display: flex;
    position: relative;
    align-items: center;
    box-sizing: border-box;
    padding: 1.75rem 2.25rem;
    gap: 1.75rem;
    border-radius: 0.93rem;
    outline: ${(props) => (props.$isSelected === props.$index ? '0.1875rem solid #3A5074' : '1px solid #e3e5ea')};
    background: ${(props) => (props.$isSelected === props.$index ? '#F6F9FF' : '#FFF')};
`;

export const ProgramSelect = () => {
    const navigate = useNavigate();

    const [programList, setProgramList] = useState<ProgramList[]>([]);
    const [isSelected, setIsSelected] = useState<number>(-1);

    const getProgramList = async () => {
        await axiosInstance.get('/api/workBook/sale/list/v1').then((response) => {
            if (response.data.result) {
                setProgramList(response.data.productList);
            }
        });
    };

    useEffect(() => {
        getProgramList();
    }, []);

    const ProgramListRender = () => {
        return (
            <div className={styles.program_container}>
                {programList.map((item: ProgramList, index: number) => (
                    <ProgramItemButton
                        key={index.toString()}
                        $isSelected={isSelected}
                        $index={index}
                        className={styles.program_button}
                        onClick={() => setIsSelected(index)}
                    >
                        <>
                            <Icon_check
                                className={styles.icon_check}
                                color={isSelected === index ? '#3a5074' : '#d2d5d9'}
                            />
                            <div className={styles.title}>
                                <span
                                    className="text_22_NotoSansKR_Medium"
                                    style={{
                                        color: isSelected === index ? '#3a5074' : '#626466',
                                    }}
                                >
                                    {item.productName}
                                </span>
                                <span
                                    className="text_18_NotoSansKR_Regular"
                                    style={{
                                        color: isSelected === index ? '#3a5074' : '#9D9FA2',
                                    }}
                                >
                                    진행회기: {item.productDescription}
                                </span>
                            </div>
                        </>

                        <div className={styles.price}>
                            {item.discountPrice && (
                                <span
                                    className="text_18_Inter_Medium"
                                    style={{
                                        color: '#D2D5D9',
                                        textDecoration: 'line-through',
                                    }}
                                >
                                    {item.price / 10000}
                                    <span
                                        className="text_18_NotoSansKR_Medium"
                                        style={{
                                            color: '#D2D5D9',
                                        }}
                                    >
                                        만원
                                    </span>
                                </span>
                            )}
                            <span className="text_24_Inter_Bold">
                                {item.discountPrice ? item.discountPrice / 10000 : item.price / 10000}
                                <span className="text_24_NotoSansKR_Bold">만원</span>
                            </span>
                        </div>
                    </ProgramItemButton>
                ))}
            </div>
        );
    };

    return (
        <div>
            <BasicHeader />
            <div className={styles.main_container}>
                <div className={`${styles.main_title} text_32_NotoSansKR_Bold`}>
                    참여하고 싶으신 프로그램을 선택해 주세요.
                </div>
                {programList.length > 0 && <ProgramListRender />}
                <button
                    onClick={
                        () =>
                            (window.location.href = `/yd/patient/program/payment?selected=${programList[isSelected].workBookProductId}`)

                        // navigate(`/yd/patient/program/payment?selected=${programList[isSelected].workBookProductId}`)
                    }
                    className={`${styles.program_select_button} text_18_NotoSansKR_Bold`}
                >
                    선택완료
                </button>
            </div>
        </div>
    );
};

import { D } from '@tanstack/react-query-devtools/build/legacy/ReactQueryDevtools-Cn7cKi7o';
import axiosInstance from '../../../../../utils/AxiosInstanceJava';
import { getMemberInfo } from '../../../../../utils/GetMemberInfo';
import { decryptData } from '../../../../../utils/croptojs';

export interface SessionMapApi {
    workBooks: [
        {
            registerId: number;
            workBookName: string;
            workBookType: string;
            addPay: boolean;
            sessionMap: {
                totalSessionCnt: number;
                finishCnt: number;
                feedbackCnt: number;
                registerSessionIds: number[];
            };
            assignmentMap: {
                totalAssignmentCnt: number;
                finishCnt: number;
                registerAssignmentIds: number[];
            };
        }
    ];
    message: string;
    result: boolean;
}

// 워크북 세션 데이터 가져오기
export const getSessionData = async (sessionData: string) => {
    const sessionId = decryptData(sessionData);
    return await axiosInstance.get(`/api/workBook/field/${sessionId}/v1`).then((response) => response.data);
};

// 세션 메뉴 가져오기
export const getSessionMenu = async (programData: string) => {
    const programId = decryptData(programData);
    return await axiosInstance.get(`/api/workBook/cbtStatus/${34}/v1`).then((response) => response.data);
};

// 회원 워크북 저장

// 치료자 피드백 저장
export const saveSessionFeedback = async (fieldId: number, field: any) => {
    const memberInfo = getMemberInfo();
    const requestData = {
        sessionFieldId: fieldId,
        fields: field,
    };
    console.log(JSON.stringify(requestData));
    return await axiosInstance
        .put(`/api/${memberInfo?.memberRole}/field/v1`, requestData)
        .then((response) => response.data);
};

// 회원 지도
export const getSessionMap = async (): Promise<SessionMapApi> => {
    return await axiosInstance.get(`/api/workBook/map/v1`).then((response) => response.data);
};

export const deleteSessionFeedback = async (fieldId: number, index: number) => {
    const memberInfo = getMemberInfo();
    return await axiosInstance
        .delete(`/api/${memberInfo?.memberRole}/field/${fieldId}/${index}/v1`)
        .then((response) => response.data);
};

import axiosInstance from '../../../../../utils/AxiosInstanceJava';
import { getMemberInfo } from '../../../../../utils/GetMemberInfo';
import { decryptData } from '../../../../../utils/croptojs';

export interface TherapistMemberDetail {
    memberName: string;
    memberId: number;
    regDate: string;
    workBookNameList: string[];
    therapistList: string[];
    branch: [
        {
            companyId: number;
            branchName: string;
        }
    ];
    workBookList: [
        {
            registerId: number;
            workBookName: string;
            sessionAll: [
                {
                    registerSessionId: number;
                    sessionNo: number;
                    videoUrl: string;
                    sessionStatus: number;
                    regDate: string;
                    modDate: string;
                    finished: boolean;
                }
            ];
            sessionFinish: [
                {
                    registerSessionId: number;
                    sessionNo: number;
                    videoUrl: string;
                    sessionStatus: number;
                    regDate: string;
                    modDate: string;
                    finished: boolean;
                }
            ];
            sessionReservationCancel: [
                {
                    registerSessionId: number;
                    sessionNo: number;
                    videoUrl: string;
                    sessionStatus: number;
                    regDate: string;
                    modDate: string;
                    finished: boolean;
                }
            ];
            sessionNoShow: [
                {
                    registerSessionId: number;
                    sessionNo: number;
                    videoUrl: string;
                    sessionStatus: number;
                    regDate: string;
                    modDate: string;
                    finished: boolean;
                }
            ];
            message: string;
            result: boolean;
        }
    ];
    message: string;
    result: boolean;
}

export const getTherapistMemberDetail = async (memberId: string) => {
    const memberInfo = getMemberInfo();
    console.log(memberInfo);
    if (memberInfo) {
        return await axiosInstance
            .post(`/api/${memberInfo.memberRole}/register/detail/${decryptData(memberId)}/v1`)
            .then((response) => response.data);
    }
};

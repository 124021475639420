export const CalendarTest = () => {
    return <div></div>;
};
// import React, { useState } from 'react';
// import 'react-calendar/dist/Calendar.css'; // 기본 스타일을 가져옵니다.
// import './CalendarTest.css';
// import { useDispatch, useSelector } from 'react-redux';
// import { RootState } from '../store';
// import { addEvent, removeEvent, updateEvent } from '../features/reservationReducer';

// interface Schedule {
//     date: string;
//     time: string;
//     description: string;
// }

// export const CalendarTest = () => {
//     const dispatch = useDispatch();
//     const events = useSelector((state: RootState) => state.events.events);

//     const [currentDate, setCurrentDate] = useState(new Date());
//     const [view, setView] = useState('month'); // 'month' 또는 'week' 뷰 상태 관리
//     const weekdays = ['일', '월', '화', '수', '목', '금', '토'];

//     const today = new Date();
//     const isToday = (day: Date) => day && day.toDateString() === today.toDateString();

//     const daysInMonth = (date: Date) => {
//         const year = date.getFullYear();
//         const month = date.getMonth();
//         return new Date(year, month + 1, 0).getDate(); // 해당 월의 마지막 날
//     };
//     const firstDayOfMonth = (date: Date) => {
//         return new Date(date.getFullYear(), date.getMonth(), 1).getDay(); // 해당 월의 첫 번째 날의 요일
//     };

//     const generateCalendar = () => {
//         const days: any = [];
//         const totalDays = daysInMonth(currentDate);
//         const firstDay = firstDayOfMonth(currentDate);

//         // 첫 번째 날 이전의 빈 공간 추가
//         for (let i = 0; i < firstDay; i++) {
//             days.push(null); // 빈 공간
//         }

//         for (let i = 1; i <= totalDays; i++) {
//             days.push(new Date(currentDate.getFullYear(), currentDate.getMonth(), i));
//         }
//         return days;
//     };

//     const generateWeek = () => {
//         const days = [];
//         const startOfWeek = new Date(currentDate);
//         startOfWeek.setDate(currentDate.getDate() - currentDate.getDay()); // 주의 시작일(일요일)

//         for (let i = 0; i < 7; i++) {
//             days.push(new Date(startOfWeek.getFullYear(), startOfWeek.getMonth(), startOfWeek.getDate() + i));
//         }
//         return days;
//     };

//     const handleAddEvent = (day: Date, time: string) => {
//         console.log(day);
//         const title = prompt('이벤트 제목을 입력하세요:');
//         if (view === 'month') {
//             const time = prompt('시간을 입력하세요 (예: 14:00');

//             if (title && time) {
//                 dispatch(
//                     addEvent({
//                         id: Date.now(),
//                         title,
//                         date: day.toDateString(),
//                         time,
//                         color: String('#' + Math.floor(Math.random() * 16777215).toString(16)),
//                     })
//                 );
//             }
//         } else {
//             if (title) {
//                 dispatch(
//                     addEvent({
//                         id: Date.now(),
//                         title,
//                         date: day.toDateString(),
//                         time,
//                         color: String('#' + Math.floor(Math.random() * 16777215).toString(16)),
//                     })
//                 );
//             }
//         }
//     };

//     const handleUpdateEvent = (day: Date, id: number) => {
//         const title = prompt('수정할 제목을 입력하세요:');
//         const time = prompt('수정할 시간을 입력하세요 (예: 14:00');
//         if (title && time) {
//             const index = events.findIndex((item) => item.id === id);
//             dispatch(
//                 updateEvent({
//                     id: events[index].id,
//                     title,
//                     date: day.toDateString(),
//                     time,
//                     color: events[index].color,
//                 })
//             );
//         }
//     };

//     const handleDeleteEvent = (title: string, id: number) => {
//         if (window.confirm(`"${title}" 이벤트를 삭제하시겠습니까?`)) {
//             dispatch(removeEvent({ id: id }));
//         }
//     };

//     const calendarDays = view === 'month' ? generateCalendar() : generateWeek();

//     const resetMonth = () => {
//         setCurrentDate(new Date());
//     };

//     const goToPreviousMonth = () => {
//         setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() - 1));
//     };

//     const goToNextMonth = () => {
//         setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1));
//     };

//     const goToPreviousWeek = () => {
//         setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - 7));
//     };

//     const goToNextWeek = () => {
//         setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + 7));
//     };
//     const [selectedDate, setSelectedDate] = useState<Date>(new Date());
//     const [schedules, setSchedules] = useState<Schedule[]>([]);

//     // 주간 뷰로 스케줄 표시
//     const renderWeeklySchedule = () => {
//         const weekStart = new Date(selectedDate);
//         weekStart.setDate(weekStart.getDate() - weekStart.getDay()); // 주의 시작일 (일요일)

//         // 시간대 설정
//         const timeSlots = [
//             '하루종일',
//             ...Array.from({ length: 23 }, (_, index) => {
//                 const hour = index + 1;
//                 return hour + ':00';
//             }),
//         ];

//         return (
//             <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
//                 <div
//                     style={{
//                         display: 'grid',
//                         height: '20px',
//                         gridTemplateColumns: 'repeat(7, 1fr)',
//                         border: '1px solid #ccc',
//                         marginTop: '10px',
//                         alignItems: 'center',
//                         paddingLeft: '120px',
//                     }}
//                 >
//                     {weekdays.map((day, index) => (
//                         <div
//                             key={index}
//                             style={{
//                                 height: '100%',
//                                 display: 'flex',
//                                 alignItems: 'center',
//                                 justifyContent: 'center',
//                             }}
//                         >
//                             {day} {calendarDays[index].getDate()}
//                         </div>
//                     ))}
//                 </div>
//                 {/* 시간 표시 */}
//                 <div
//                     style={{
//                         display: 'flex',
//                     }}
//                 >
//                     <div style={{ width: '100px', borderRight: '1px solid #ccc' }}>
//                         {timeSlots.map((time, index) => (
//                             <div
//                                 key={index}
//                                 style={{
//                                     height: '62px',
//                                     display: 'flex',
//                                     alignItems: index === 0 ? 'center' : 'flex-end',
//                                     borderBottom: index === 0 ? '1px solid #ccc' : '',
//                                     paddingLeft: '20px',
//                                 }}
//                             >
//                                 <strong>{time}</strong>
//                             </div>
//                         ))}
//                     </div>

//                     {/* 주간 스케줄 */}
//                     <div style={{ display: 'flex', flexGrow: 1 }}>
//                         {calendarDays.map((date: any, index: number) => (
//                             <div key={index} style={{ flex: 1 }}>
//                                 <div style={{ display: 'flex', flexDirection: 'column' }}>
//                                     {timeSlots.map((timeSlot, timeIndex) => {
//                                         const timeKey = timeSlot; // 현재 시간 슬롯
//                                         const scheduleExists = events.some(
//                                             (schedule) =>
//                                                 new Date(schedule.date).toDateString() === date.toDateString() &&
//                                                 timeKey.includes(schedule.time)
//                                         );

//                                         return (
//                                             <div
//                                                 key={timeIndex.toString()}
//                                                 style={{
//                                                     height: '60px',
//                                                     border: '1px solid #eee',
//                                                     backgroundColor: scheduleExists ? '#add8e6' : '#f9f9f9', // 스케줄이 있을 경우 색상 변경
//                                                     display: 'flex',
//                                                     alignItems: 'center',
//                                                     flexDirection: 'column',
//                                                     justifyContent: 'center',
//                                                 }}
//                                                 onClick={() => handleAddEvent(date, timeSlot)}
//                                             >
//                                                 {events
//                                                     .filter(
//                                                         (schedule) =>
//                                                             timeKey.includes(schedule.time) &&
//                                                             new Date(schedule.date).toDateString() ===
//                                                                 date.toDateString()
//                                                     )
//                                                     .map((event, index) => (
//                                                         <div
//                                                             key={index.toString()}
//                                                             style={{
//                                                                 display: 'flex',
//                                                                 width: '100%',
//                                                                 height: '20px',
//                                                                 alignItems: 'center',
//                                                                 backgroundColor: event.color,
//                                                             }}
//                                                         >
//                                                             <span>{event.title}</span>
//                                                         </div>
//                                                     ))}
//                                             </div>
//                                         );
//                                     })}
//                                 </div>
//                             </div>
//                         ))}
//                     </div>
//                 </div>
//             </div>
//         );
//     };

//     return (
//         <div className="calendar">
//             <div className="calendar-header">
//                 <div className="navigation">
//                     <button onClick={resetMonth}>오늘</button>
//                     {view === 'month' ? (
//                         <>
//                             <button onClick={goToPreviousMonth}>이전 월</button>
//                             <button onClick={goToNextMonth}>다음 월</button>
//                         </>
//                     ) : (
//                         <>
//                             <button onClick={goToPreviousWeek}>이전 주</button>
//                             <button onClick={goToNextWeek}>다음 주</button>
//                         </>
//                     )}
//                 </div>
//                 <div className="year">
//                     {currentDate.getFullYear() + '년'} {currentDate.toLocaleString('ko', { month: 'long' })}
//                 </div>
//                 <button
//                     onClick={() => setView(view === 'month' ? 'week' : 'month')}
//                     style={{
//                         position: 'absolute',
//                         right: '20px',
//                     }}
//                 >
//                     {view === 'month' ? '주간 보기' : '월간 보기'}
//                 </button>
//             </div>
//             {view === 'month' ? (
//                 <>
//                     <div className="weekdays">
//                         {weekdays.map((day, index) => (
//                             <div key={index} className="weekday">
//                                 {day}
//                             </div>
//                         ))}
//                     </div>
//                     <div className="days">
//                         {calendarDays.map((day: any, index: number) => (
//                             <div key={index} className="day" onClick={() => handleAddEvent(day, '')}>
//                                 <div className={`${isToday(day) ? 'today-child' : 'day-child'}`}>
//                                     {day ? day.getDate() : ''}
//                                 </div>
//                                 {day &&
//                                     events
//                                         .filter((event) => event.date === day.toDateString())
//                                         .map((event, idx) => (
//                                             <div
//                                                 key={idx}
//                                                 className="event"
//                                                 style={{
//                                                     backgroundColor: event.color,
//                                                 }}
//                                             >
//                                                 {event.title}
//                                                 <button
//                                                     onClick={(e) => {
//                                                         e.stopPropagation();
//                                                         handleUpdateEvent(day, event.id);
//                                                     }}
//                                                     className="delete-button"
//                                                 >
//                                                     수정
//                                                 </button>
//                                                 <button
//                                                     onClick={(e) => {
//                                                         e.stopPropagation();
//                                                         handleDeleteEvent(event.title, event.id);
//                                                     }}
//                                                     className="delete-button"
//                                                 >
//                                                     삭제
//                                                 </button>
//                                             </div>
//                                         ))}
//                             </div>
//                         ))}
//                     </div>
//                 </>
//             ) : (
//                 renderWeeklySchedule()
//             )}
//         </div>
//     );
// };

import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface Login {
    memberAccount: string;
    kakaoEmail: string;
    memberPassword: string;
    memberOrgan: string;
}

const initialState: Login = {
    memberAccount: '',
    kakaoEmail: '',
    memberPassword: '',
    memberOrgan: '',
};

export const eventsSlice = createSlice({
    name: 'login',
    initialState,
    reducers: {
        updateLoginMemberAccount: (state, action: PayloadAction<string>) => {
            state.memberAccount = action.payload; // 새로운 이벤트 추가
        },
        updateLoginMemberPassword: (state, action: PayloadAction<string>) => {
            state.memberPassword = action.payload; // 새로운 이벤트 추가
        },
        updateMemberOrgan: (state, action: PayloadAction<string>) => {
            state.memberOrgan = action.payload;
        },
    },
});

export const { updateLoginMemberAccount, updateLoginMemberPassword, updateMemberOrgan } = eventsSlice.actions;
export default eventsSlice.reducer;

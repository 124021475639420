import { useEffect, useRef, useState } from 'react';
import styles from '../../css/SixthSession.module.css';
import '../../../css/PublicSessionStyle.css';

import ReactPlayer from 'react-player';
import {
    AuthmaticThinkingGraph,
    BottomFiveSeven,
    CognitiveError,
    SituationEmotion,
    SocraticQuestionAnswer,
    SocraticQuestionAnswerStep,
    SubmitWorkbook,
    ThoughtSiuation,
} from '../../component/PublicSessionComponent';

import Img1 from '../../../../../../assets/face/session6/session6_automatic_accident.png';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { getSessionData } from '../ts/TherapistSessionApi';
import { updateSessionPage } from '../../../../../../features/session/sessionReducer';

export const TherapistSixthSession = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const currentSession = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);

    const queryParams = new URLSearchParams(location.search);
    const encryptedData = queryParams.get('session');
    const { data, isLoading, error, refetch } = useQuery({
        queryKey: ['getFirstSessionData', encryptedData],
        queryFn: () => getSessionData(encryptedData!),
        placeholderData: keepPreviousData,
    });

    const playerRef = useRef<ReactPlayer | null>(null);
    const [playing, setPlaying] = useState(false);
    const [volume, setVolume] = useState(0.8); // 초기 볼륨
    const [playbackTime, setPlaybackTime] = useState(0);
    const [popUpVisible, setPopUpVisible] = useState<boolean>(true);

    const targetRef: any = useRef(null);
    const [showPopup, setShowPopup] = useState(false);
    const lastScrollY = useRef(0);

    useEffect(() => {
        window.scrollTo(0, 0);
        if (data) {
            dispatch(updateSessionPage(data));
        }
    }, [data]);

    const handlePlay = () => {
        setPlaying(!playing); // 재생 중
    };

    // 동영상의 재생 시간 업데이트
    const handleProgress = (progress: { playedSeconds: number }) => {
        if (!showPopup) {
            setPlaybackTime(progress.playedSeconds);
        }
    };

    return (
        <div className={'therapist_main_container'}>
            <div
                ref={targetRef}
                style={{
                    marginBottom: '2.25rem',
                }}
            >
                <ReactPlayer
                    ref={playerRef}
                    url="https://exqibabapqbf17923502.cdn.ntruss.com/ydTest/CBT10.mp4"
                    height="auto" // 비디오 높이
                    width="100%"
                    playing={playing}
                    onPlay={handlePlay}
                    onPause={handlePlay}
                    volume={volume}
                    onProgress={handleProgress}
                    pip={false}
                    controls // 기본 컨트롤 비활성화
                    stopOnUnmount={true}
                />
            </div>
            <SituationEmotion currentSession={currentSession} />

            <ThoughtSiuation />
            <BottomFiveSeven />
            <CognitiveError />
            <SocraticQuestionAnswerStep />
            <AuthmaticThinkingGraph />
        </div>
    );
};

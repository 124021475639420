import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface MemberType {
    type: number;
}

const initialState: MemberType = {
    type: 0,
};

export const eventsSlice = createSlice({
    name: 'memberType',
    initialState,
    reducers: {
        updateMemberType: (state, action: PayloadAction<number>) => {
            state.type = action.payload; // 새로운 이벤트 추가
        },
    },
});

export const { updateMemberType } = eventsSlice.actions;
export default eventsSlice.reducer;

import styles from '../../css/FourthSession.module.css';
import '../../../css/PublicSessionStyle.css';

import Img1 from '../../../../../../assets/face/session4/session4_1.png';
import Img2 from '../../../../../../assets/face/session4/session4_2.png';
import Img_quiz_1 from '../../../../../../assets/face/session4/session4_quiz_1.png';
import Img_quiz_2 from '../../../../../../assets/face/session4/session4_quiz_2.png';
import Img_quiz_3 from '../../../../../../assets/face/session4/session4_quiz_3.png';
import Img_quiz_4 from '../../../../../../assets/face/session4/session4_quiz_4.png';

import { useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import {
    BottomFiveSeven,
    CircleCheckBox,
    CognitiveError,
    Quiz,
    SituationEmotion,
    SubmitWorkbook,
    ThoughtSiuation,
} from '../../component/PublicSessionComponent';
import { useLocation } from 'react-router-dom';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { getSessionData } from '../ts/TherapistSessionApi';
import { useDispatch } from 'react-redux';
import { updateSessionPage } from '../../../../../../features/session/sessionReducer';

export const TherapistFourthSession = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const currentSession = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);

    const queryParams = new URLSearchParams(location.search);
    const encryptedData = queryParams.get('session');
    const { data, isLoading, error, refetch } = useQuery({
        queryKey: ['getSessionData', encryptedData],
        queryFn: () => getSessionData(encryptedData!),
        placeholderData: keepPreviousData,
    });

    const playerRef = useRef<ReactPlayer | null>(null);
    const [playing, setPlaying] = useState(false);
    const [volume, setVolume] = useState(0.8); // 초기 볼륨
    const [playbackTime, setPlaybackTime] = useState(0);
    const [popUpVisible, setPopUpVisible] = useState<boolean>(true);

    const targetRef: any = useRef(null);
    const [showPopup, setShowPopup] = useState(false);
    const lastScrollY = useRef(0); // useRef로 상태를 관리

    const [quizList, setQuizList] = useState([
        {
            url: Img_quiz_1,
            answerList: [
                {
                    title: '독심술',
                    select: false,
                },
                {
                    title: '정진적 여과',
                    select: false,
                },
                {
                    title: '명명하기',
                    select: false,
                },
                {
                    title: '당위진술',
                    select: false,
                },
            ],
        },
        {
            url: Img_quiz_2,
            answerList: [
                {
                    title: '독심술',
                    select: false,
                },
                {
                    title: '정진적 여과',
                    select: false,
                },
                {
                    title: '명명하기',
                    select: false,
                },
                {
                    title: '당위진술',
                    select: false,
                },
            ],
        },
        {
            url: Img_quiz_3,
            answerList: [
                {
                    title: '독심술',
                    select: false,
                },
                {
                    title: '정진적 여과',
                    select: false,
                },
                {
                    title: '명명하기',
                    select: false,
                },
                {
                    title: '당위진술',
                    select: false,
                },
            ],
        },
        {
            url: Img_quiz_4,
            answerList: [
                {
                    title: '독심술',
                    select: false,
                },
                {
                    title: '정진적 여과',
                    select: false,
                },
                {
                    title: '명명하기',
                    select: false,
                },
                {
                    title: '당위진술',
                    select: false,
                },
            ],
        },
    ]);

    useEffect(() => {
        window.scrollTo(0, 0);
        if (data) {
            dispatch(updateSessionPage(data));
        }
    }, [data]);

    // 로딩 중일 때
    if (isLoading) {
        return <div>Loading...</div>;
    }

    // 오류 발생 시
    if (error) {
        return <div>Error: {error.message}</div>;
    }

    // data가 존재하지 않거나 비어있을 경우
    if (!data) {
        return <div>No employees found.</div>;
    }

    const handlePlay = () => {
        setPlaying(!playing); // 재생 중
    };

    // 동영상의 재생 시간 업데이트
    const handleProgress = (progress: { playedSeconds: number }) => {
        if (!showPopup) {
            setPlaybackTime(progress.playedSeconds);
        }
    };

    const selectQuizAnswer = (url: string, title: string) => {
        const updatedData = quizList.map((item) => {
            if (item.url === url) {
                return {
                    ...item,
                    answerList: item.answerList.map((value) => {
                        if (value.title === title) {
                            return { ...value, select: !value.select }; // hobby 업데이트
                        }
                        return value;
                    }),
                };
            }
            return item;
        });
        setQuizList(updatedData);
    };

    return (
        <div className={'therapist_main_container'}>
            <div ref={targetRef}>
                <ReactPlayer
                    ref={playerRef}
                    url="https://exqibabapqbf17923502.cdn.ntruss.com/ydTest/CBT10.mp4"
                    height="auto" // 비디오 높이
                    width="100%"
                    playing={playing}
                    onPlay={handlePlay}
                    onPause={handlePlay}
                    volume={volume}
                    onProgress={handleProgress}
                    pip={false}
                    controls // 기본 컨트롤 비활성화
                    stopOnUnmount={true}
                />
            </div>
            <img alt="img1" src={Img1} className={styles.img1} />
            <Quiz />
            <SituationEmotion currentSession={currentSession} />

            <ThoughtSiuation />
            <BottomFiveSeven />
            <CognitiveError />
        </div>
    );
};

import { useState } from 'react';
import styles from './css/SettingComponent.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { updateSettingMenu, updateSettingVisible } from '../features/headerReducer';
import { RootState } from '../store';
import { ReactComponent as Icon_setting_check } from '../assets/public/icon_setting_check.svg';
import { ReactComponent as Icon_profile } from '../assets/public/icon_profile.svg';
import { ReactComponent as Icon_notification_setting } from '../assets/public/icon_notification_setting.svg';
import { ReactComponent as Icon_logout } from '../assets/public/icon_logout.svg';
import { useLocation, useNavigate } from 'react-router-dom';

export const Setting = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const { settingVisible, settingMenu } = useSelector((state: RootState) => state.header);
    const [menuList, setMenuList] = useState<{ title: string; path: string; icon: any }[]>([
        { title: '프로필 수정', path: 'memberInfo', icon: Icon_profile },
        { title: '알림 설정', path: 'notificationSetting', icon: Icon_notification_setting },
        { title: '로그아웃', path: 'memberInfo', icon: Icon_logout },
    ]);
    const exists = menuList.some((item) => location.pathname.includes(item.path));

    const settingNavigate = (title: string) => {
        const queryParams = new URLSearchParams(location.search);

        switch (title) {
            case '프로필 수정':
                navigate(`memberInfo?${queryParams.toString()}`);
                dispatch(updateSettingVisible(!settingVisible));
                break;
            case '알림 설정':
                navigate(`notificationSetting?${queryParams.toString()}`);
                dispatch(updateSettingVisible(!settingVisible));
                break;
            case '로그아웃':
                // navigate('logout');
                localStorage.removeItem('userToken');
                window.location.href = '/public/login';
                dispatch(updateSettingVisible(!settingVisible));
                break;
        }
    };

    const SettingMenu = () => {
        return (
            <>
                {menuList.map((item, index) => (
                    <button
                        key={index.toString()}
                        onClick={() => {
                            dispatch(updateSettingMenu(item.title));
                            settingNavigate(item.title);
                        }}
                        className={`${styles.button} ${
                            exists && settingMenu === item.title ? styles.activate : styles.decativate
                        }`}
                    >
                        <div className={styles.button_content}>
                            <item.icon
                                width={'1.5rem'}
                                height={'1.5rem'}
                                className={`${
                                    exists && settingMenu === item.title ? styles.icon_activate : styles.icon_deactivate
                                }`}
                            />
                            <div
                                className={`${
                                    exists && settingMenu === item.title
                                        ? 'text_16_NotoSansKR_Bold'
                                        : 'text_16_NotoSansKR_Medium'
                                }`}
                            >
                                {item.title}
                            </div>
                        </div>
                        {exists && settingMenu === item.title && <Icon_setting_check />}
                    </button>
                ))}
            </>
        );
    };

    return (
        <div className={styles.modal_main_container}>
            <SettingMenu />
        </div>
    );
};

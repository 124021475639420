import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { updateEmailAddress, updateEmailAddressVisible } from '../../../features/signupReducer';
import { RootState } from '../../../store';

const Dropdown = styled.div`
    display: flex;
    position: absolute;
    flex-direction: column;
    box-sizing: border-box;
    top: 5rem;
    left: 0;
    z-index: 10;
    width: 100%;
    padding: 1.25rem 1.5rem;
    gap: 1.5rem;
    border-radius: 10px;
    border: 1px solid var(--For-fonts-5, #e3e5ea);
    background: #fff;
    box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
`;

const DropdownButton = styled.button`
    display: flex;
    align-items: center;
`;

export const EmailDropdown = ({ updateAddress }: { updateAddress: Function }) => {
    const dispatch = useDispatch();
    const userInfo = useSelector((state: RootState) => state.signup);

    const emailList = [
        '선택해주세요',
        'naver.com',
        'hanmail.net',
        'daum.net',
        'gmail.com',
        'nate.com',
        'hotmail.com',
        'outlook.com',
        'icloud.com',
        '직접입력',
    ];

    return (
        <Dropdown>
            {emailList.map((item, index) => (
                <DropdownButton
                    key={index.toString()}
                    onClick={(e) => {
                        if (index !== 0) {
                            e.stopPropagation();
                            updateAddress(item);
                        }
                    }}
                    className="text_16_NotoSansKR_Medium"
                    style={{
                        color: index === 0 ? '#D2D5D9' : '#020202',
                    }}
                >
                    {item}
                </DropdownButton>
            ))}
        </Dropdown>
    );
};

import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface Signup {
    identityInfo: [userName: string, userPhoneNumber: string, userBirth: number, userGender: number];
    memberEmail: string;
    emailAddress: string;
    emailAddressVisible: boolean;
    memberAccount: string;
    memberPassword: string;
    userRecheckPw: string;
    organList: { companyId: number; branchName: string }[];
    selectedOrgan: { companyId: number; branchName: string }[];
    snsType: string;
}

const initialState: Signup = {
    identityInfo: ['', '', 0, -1], // 수정된 부분
    memberEmail: '',
    emailAddress: '선택해 주세요.',
    emailAddressVisible: false,
    memberAccount: '',
    memberPassword: '',
    userRecheckPw: '',
    organList: [],
    selectedOrgan: [],
    snsType: 'normal',
};

export const eventsSlice = createSlice({
    name: 'signup',
    initialState,
    reducers: {
        updateUserIdentityInfo: (
            state,
            action: PayloadAction<[userName: string, userPhoneNumber: string, userBirth: number, userGender: number]>
        ) => {
            state.identityInfo = action.payload;
        },
        updatememberEmail: (state, action: PayloadAction<string>) => {
            state.memberEmail = action.payload; // 새로운 이벤트 추가
        },
        updateEmailAddress: (state, action: PayloadAction<string>) => {
            state.emailAddress = '@' + action.payload;
        },
        updateEmailAddressVisible: (state, action: PayloadAction<boolean>) => {
            state.emailAddressVisible = action.payload;
        },
        updateMemberAccount: (state, action: PayloadAction<string>) => {
            state.memberAccount = action.payload; // 새로운 이벤트 추가
        },
        updateMemberPassword: (state, action: PayloadAction<string>) => {
            state.memberPassword = action.payload; // 새로운 이벤트 추가
        },
        updateUserRecheckPw: (state, action: PayloadAction<string>) => {
            state.userRecheckPw = action.payload; // 새로운 이벤트 추가
        },
        updateOrganList: (state, action: PayloadAction<{ companyId: number; branchName: string }[]>) => {
            state.organList.push(...action.payload);
        },
        updateSelectedOrgan: (state, action: PayloadAction<{ companyId: number; branchName: string }[]>) => {
            state.selectedOrgan.push(...action.payload);
        },
        updateSnsType: (state, action: PayloadAction<string>) => {
            state.snsType = action.payload;
        },
    },
});

export const {
    updateUserIdentityInfo,
    updatememberEmail,
    updateEmailAddress,
    updateEmailAddressVisible,
    updateMemberAccount,
    updateMemberPassword,
    updateUserRecheckPw,
    updateOrganList,
    updateSelectedOrgan,
    updateSnsType,
} = eventsSlice.actions;
export default eventsSlice.reducer;

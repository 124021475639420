import { Route, Routes, useLocation } from 'react-router-dom';
import styles from './css/TherapistCBT.module.css';

import { SessionMap } from '../../SessionMap';
import { MemberInfo } from '../../../../public/MemberInfo';
import { NotificationSetting } from '../../../../public/NotificationSetting';
import { TherapistCBTMenu } from './component/TherapistCBTComponent';
import { TherapistFirstSession } from './therapistNonFaceSession/TherapistFirstSession';
import { TherapistSecondSession } from './therapistNonFaceSession/TherapistSecondSession';
import { TherapistThirdSession } from './therapistNonFaceSession/TherapistThirdSession';
import { TherapistFourthSession } from './therapistNonFaceSession/TherapistFourthSession';
import { TherapistFifthSession } from './therapistNonFaceSession/TherapistFifthSession';
import { TherapistSixthSession } from './therapistNonFaceSession/TherapistSixthSession';
import { TherapistSeventhSession } from './therapistNonFaceSession/TherapistSeventhSession';
import { TherapistEighthSession } from './therapistNonFaceSession/TherapistEighthSession';
import { decryptData } from '../../../../../utils/croptojs';
import { AssignedFirstSession } from '../sessionAssigned/assigned/AssignedFirstSession';
import { AssignedSecondSession } from '../sessionAssigned/assigned/AssignedSecondSession';
import { AssignedThirdSession } from '../sessionAssigned/assigned/AssignedThirdSession';
import { AssignedFourthSession } from '../sessionAssigned/assigned/AssignedFourthSession';
import { AssignedFifthSession } from '../sessionAssigned/assigned/AssignedFifthSession';
import { AssignedSixthSession } from '../sessionAssigned/assigned/AssignedSixthSession';
import { AssignedSeventhSession } from '../sessionAssigned/assigned/AssignedSeventhSession';
import { AssignedEighthSession } from '../sessionAssigned/assigned/AssignedEighthSession';

export const TherapistNonFaceCBT = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const encryptedData = queryParams.get('page');

    const SessionPage = () => {
        const session = Number(encryptedData!);
        switch (session) {
            case 1:
                return <TherapistFirstSession />;
            case 2:
                return <TherapistSecondSession />;
            case 3:
                return <TherapistThirdSession />;
            case 4:
                return <TherapistFourthSession />;
            case 5:
                return <TherapistFifthSession />;
            case 6:
                return <TherapistSixthSession />;
            case 7:
                return <TherapistSeventhSession />;
            case 8:
                return <TherapistEighthSession />;
        }
    };

    const AssignedSessionPage = () => {
        const session = Number(encryptedData!);
        switch (session) {
            case 1:
                return <AssignedFirstSession />;
            case 2:
                return <AssignedSecondSession />;
            case 3:
                return <AssignedThirdSession />;
            case 4:
                return <AssignedFourthSession />;
            case 5:
                return <AssignedFifthSession />;
            case 6:
                return <AssignedSixthSession />;
            case 7:
                return <AssignedSeventhSession />;
            case 8:
                return <AssignedEighthSession />;
        }
    };

    return (
        <div className={styles.main_container}>
            <TherapistCBTMenu />
            <div className={`${styles.content_container}`}>
                <Routes>
                    <Route path="firstSession" element={<TherapistFirstSession />} />
                    <Route path="secondSession" element={<TherapistSecondSession />} />
                    <Route path="thirdSession" element={<TherapistThirdSession />} />
                    <Route path="fourthSession" element={<TherapistFourthSession />} />
                    <Route path="fifthSession" element={<TherapistFifthSession />} />
                    <Route path="sixthSession" element={<TherapistSixthSession />} />
                    <Route path="seventhSession" element={<TherapistSeventhSession />} />
                    <Route path="eighthSession" element={<TherapistEighthSession />} />
                    <Route path="sessionMap" element={<SessionMap />} />
                    <Route path="memberInfo" element={<MemberInfo />} />
                    <Route path="notificationSetting" element={<NotificationSetting />} />
                </Routes>
            </div>
        </div>
    );
};

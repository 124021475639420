import styled from 'styled-components';
import styles from './css/SignupInput.module.css';
import { useEffect, useState } from 'react';
import { EmailDropdown } from '../../component/EmailDropdown';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import {
    updatememberEmail,
    updateMemberAccount,
    updateMemberPassword,
    updateUserRecheckPw,
    updateOrganList,
    updateEmailAddressVisible,
    updateEmailAddress,
} from '../../../../features/signupReducer';
import { SelectOrganizationSignup } from './SelectOrganizationSignup';
import axiosInstance from '../../../../utils/AxiosInstanceJava';
import { ReactComponent as Icon_arrowbotton } from '../../../../assets/member/icon_arrowbottom.svg';

interface InputProps {
    $index: number;
    $isFocused: number;
}

const InputPossible = styled.input<InputProps>`
    box-sizing: border-box;
    width: 100%;
    height: 4rem;
    padding: 1.18rem 1.25rem;
    border-radius: 10px;
    outline: ${(props) => (props.$isFocused === props.$index ? '0.1875rem solid #3A5074' : '1px solid #e3e5ea')};
    background: #fff;
`;

const InputNotPossible = styled.input`
    box-sizing: border-box;
    width: 100%;
    height: 4rem;
    padding: 1.18rem 1.25rem;
    border-radius: 10px;
    border: 1px solid #e3e5ea;
    background: #f6f7f9;
`;

const InputPhoneNumber = styled.input`
    flex: 1;
    width: 9.75rem;
    box-sizing: border-box;
    height: 4rem;
    padding: 1.18rem 1.25rem;
    border-radius: 10px;
    border: 1px solid #e3e5ea;
    background: #f6f7f9;
`;

const InputGuardianPhoneNumber = styled.input<InputProps>`
    flex: 1;
    width: 9.75rem;
    box-sizing: border-box;
    height: 4rem;
    padding: 1.18rem 1.25rem;
    border-radius: 10px;
    outline: ${(props) => (props.$isFocused === props.$index ? '0.1875rem solid #3A5074' : '1px solid #e3e5ea')};
    background: #fff;
`;

const InputEmail = styled.input<InputProps>`
    flex: 1;
    height: 4rem;
    box-sizing: border-box;
    padding: 1.18rem 1.25rem;
    border-radius: 10px;
    outline: ${(props) => (props.$isFocused === props.$index ? '0.1875rem solid #3A5074' : '1px solid #e3e5ea')};
    background: #fff;
`;

const EmailDropdownButton = styled.button<InputProps>`
    flex: 1;
    height: 4rem;
    position: relative;
    box-sizing: border-box;
    text-align: justify;
    padding: 1.18rem 1.25rem;
    border-radius: 10px;
    outline: ${(props) => (props.$isFocused === props.$index ? '0.1875rem solid #3A5074' : '1px solid #e3e5ea')};
    background: #fff;
`;

const Essential = () => {
    return (
        <span
            className="text_18_Inter_bold"
            style={{
                color: '#FF5833',
            }}
        >
            *
        </span>
    );
};

export const SignupInput = () => {
    const dispatch = useDispatch();

    const [isFocused, setIsFocused] = useState(0);
    const [emailVisible, setEmailVisible] = useState<boolean>(false);

    const userInfo = useSelector((state: RootState) => state.signup);

    const phoneNumber = userInfo.identityInfo ? userInfo.identityInfo[1].split('-') : '';
    const params = new URLSearchParams(window.location.search);
    const code = params.get('code'); // 카카오에서 받은 인증 코드

    useEffect(() => {
        getOrganList();
    }, []);

    const getOrganList = async () => {
        await axiosInstance.get('/api/common/branch/list/v1').then((response) => {
            if (response.data.result) {
                dispatch(updateOrganList(response.data.branchList));
            }
        });
    };

    const onChange = (type: string, text: string) => {
        switch (type) {
            case 'email':
                dispatch(updatememberEmail(text));
                break;
            case 'id':
                dispatch(updateMemberAccount(text));
                break;
            case 'pw':
                dispatch(updateMemberPassword(text));
                break;
            case 'recheckPw':
                dispatch(updateUserRecheckPw(text));
                break;
        }
    };

    const inputFocused = (index: number) => {
        setIsFocused(index);
    };

    const resetFocuse = () => {
        setIsFocused(0);
    };

    const EmailAddressSelect = () => {
        if (!userInfo.emailAddress.includes('직접')) {
            return (
                <EmailDropdownButton
                    $isFocused={userInfo.emailAddressVisible ? 2 : -1}
                    $index={2}
                    onClick={() => dispatch(updateEmailAddressVisible(!userInfo.emailAddressVisible))}
                    className="text_18_NotoSansKR_Regular"
                    style={{
                        color: userInfo.emailAddress.includes('선택') ? '#9D9FA2' : '#020202',
                    }}
                >
                    {userInfo.emailAddress.replace('@', '')}
                    <Icon_arrowbotton className={styles.icon_arrowbottm} />
                    <div>
                        {userInfo.emailAddressVisible && (
                            <EmailDropdown
                                updateAddress={(item: string) => {
                                    dispatch(updateEmailAddress(item));
                                    dispatch(updateEmailAddressVisible(!userInfo.emailAddressVisible));
                                }}
                            />
                        )}
                    </div>
                </EmailDropdownButton>
            );
        } else {
            return (
                <InputEmail
                    $isFocused={isFocused}
                    $index={2}
                    onFocus={() => inputFocused(2)}
                    onBlur={resetFocuse}
                    className="text_18_NotoSansKR_Regular"
                    placeholder="입력해 주세요."
                />
            );
        }
    };

    return (
        <div className={styles.input_container}>
            <div className={styles.input_content_container}>
                <span className="text_18_NotoSansKR_Bold" style={{ color: '#626466' }}>
                    이름
                </span>
                <InputNotPossible className="text_18_Inter_Regular" readOnly defaultValue={userInfo.identityInfo[0]} />
            </div>
            <div className={styles.input_multi_container}>
                <div className={styles.input_content_container}>
                    <span className="text_18_NotoSansKR_Bold" style={{ color: '#626466' }}>
                        휴대폰 번호
                    </span>
                    <div className={styles.input_phone_number}>
                        <InputPhoneNumber className="text_18_Inter_Regular" readOnly defaultValue={phoneNumber[0]} />
                        <span>-</span>
                        <InputPhoneNumber className="text_18_Inter_Regular" readOnly defaultValue={phoneNumber[1]} />
                        <span>-</span>
                        <InputPhoneNumber className="text_18_Inter_Regular" readOnly defaultValue={phoneNumber[2]} />
                    </div>
                </div>
            </div>
            <div className={styles.input_content_container}>
                <span className="text_18_NotoSansKR_Bold" style={{ color: '#626466' }}>
                    이메일 주소
                    <Essential />
                </span>
                <div className={styles.input_phone_number}>
                    <InputEmail
                        $isFocused={isFocused}
                        $index={1}
                        onChange={(e) => onChange('email', e.target.value)}
                        onFocus={() => inputFocused(1)}
                        onBlur={resetFocuse}
                        value={userInfo.memberEmail}
                        className="text_18_NotoSansKR_Regular"
                        placeholder="이메일 입력"
                    />
                    <span>@</span>
                    <EmailAddressSelect />
                </div>
            </div>
            <div
                className={styles.input_multi_container}
                style={{
                    gap: '0.75rem',
                }}
            >
                <div className={styles.input_content_container}>
                    <span className="text_18_NotoSansKR_Bold" style={{ color: '#626466' }}>
                        생년월일
                    </span>
                    <InputNotPossible
                        className="text_18_Inter_Regular"
                        readOnly
                        defaultValue={userInfo.identityInfo[2] !== 0 ? userInfo.identityInfo[2] : ''}
                    />
                </div>
                <div className={styles.input_content_container}>
                    <span className="text_18_NotoSansKR_Bold" style={{ color: '#626466' }}>
                        성별
                    </span>
                    <InputNotPossible
                        className="text_18_NotoSansKR_Regular"
                        readOnly
                        defaultValue={
                            userInfo.identityInfo[3] !== -1 ? (userInfo.identityInfo[3] === 0 ? '남' : '여') : ''
                        }
                    />
                </div>
            </div>
            <SelectOrganizationSignup />
            {!code && (
                <>
                    <div className={styles.input_content_container}>
                        <span className="text_18_NotoSansKR_Bold" style={{ color: '#626466' }}>
                            아이디
                            <Essential />
                        </span>
                        <InputPossible
                            $isFocused={isFocused}
                            $index={3}
                            onChange={(e) => onChange('id', e.target.value)}
                            onFocus={() => inputFocused(3)}
                            onBlur={resetFocuse}
                            className="text_18_NotoSansKR_Regular"
                            placeholder="아이디 입력"
                        />
                    </div>
                    <form className={styles.password_form}>
                        <div className={styles.input_content_container}>
                            <span className="text_18_NotoSansKR_Bold" style={{ color: '#626466' }}>
                                비밀번호
                                <Essential />
                            </span>
                            <InputPossible
                                $isFocused={isFocused}
                                $index={4}
                                onChange={(e) => onChange('pw', e.target.value)}
                                onFocus={() => inputFocused(4)}
                                onBlur={resetFocuse}
                                type="password"
                                autoComplete="off"
                                className="text_18_NotoSansKR_Regular"
                                placeholder="띄어쓰기 미포함 최소 4자리 이상 입력"
                            />
                        </div>
                        <div className={styles.input_content_container}>
                            <span className="text_18_NotoSansKR_Bold" style={{ color: '#626466' }}>
                                비밀번호 확인
                                <Essential />
                            </span>
                            <InputPossible
                                $isFocused={isFocused}
                                $index={5}
                                onChange={(e) => onChange('recheckPw', e.target.value)}
                                onFocus={() => inputFocused(5)}
                                onBlur={resetFocuse}
                                type="password"
                                autoComplete="off"
                                className="text_18_NotoSansKR_Regular"
                                placeholder="비밀번호 재입력"
                            />
                        </div>
                        <div className={styles.input_content_container}>
                            <span className="text_18_NotoSansKR_Bold" style={{ color: '#626466' }}>
                                보호자 휴대폰 번호 <Essential />
                            </span>
                            <div className={styles.input_phone_number}>
                                <InputGuardianPhoneNumber
                                    className="text_18_Inter_Regular"
                                    defaultValue={phoneNumber[0]}
                                    placeholder="010"
                                    onFocus={() => inputFocused(6)}
                                    onBlur={resetFocuse}
                                    $isFocused={isFocused}
                                    $index={6}
                                />
                                <span>-</span>
                                <InputGuardianPhoneNumber
                                    className="text_18_Inter_Regular"
                                    defaultValue={phoneNumber[1]}
                                    placeholder="1234"
                                    onFocus={() => inputFocused(7)}
                                    onBlur={resetFocuse}
                                    $isFocused={isFocused}
                                    $index={7}
                                />
                                <span>-</span>
                                <InputGuardianPhoneNumber
                                    className="text_18_Inter_Regular"
                                    defaultValue={phoneNumber[2]}
                                    placeholder="5678"
                                    onFocus={() => inputFocused(8)}
                                    onBlur={resetFocuse}
                                    $isFocused={isFocused}
                                    $index={8}
                                />
                            </div>
                        </div>
                    </form>
                </>
            )}
        </div>
    );
};

import { useEffect, useState } from 'react';
import { useInactivity } from '../context/InactivityContext';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { KakaoLogin } from '../pages/member/login/KakaoLogin';
import { Login } from '../pages/member/login/Login';
import { FindAccount } from '../pages/member/login/FindAccount';
import { Signup } from '../pages/member/signup/Signup';
import { PrivacyPolicyConsent } from '../pages/policy/PrivacyPolicyConsent';
import { SensitivenInfoConsent } from '../pages/policy/SensitivenInfoConsent';
import { UniqueIdentifierConsent } from '../pages/policy/UniqueIdentifierConsent';
import { Official } from '../pages/home/official/Official';
import { ProgramSelect } from '../pages/payment/program/ProgramSelect';
import { ProgramPayment } from '../pages/payment/ProgramPayment';
import { PaymentReceipt } from '../pages/payment/PaymentReceipt';
import { PerformancePolicy } from '../pages/policy/PerformancePolicy';
import { FaceMain } from '../pages/home/cbt/session/faceToFace/FaceMain';
import { NonFaceMain } from '../pages/home/cbt/session/nonFace/NonFaceMain';
import { CalendarTest } from '../pages/CalendarTest';
import { BarChart } from '../pages/BarChart';
import { PieChart } from '../pages/PieChart';
import { YoutubePlayer } from '../pages/YoutubePlayer';
import { Canvas } from '../pages/Canvas';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store';
import { resetModal } from '../features/headerReducer';

import settingStyles from './../component/css/SettingComponent.module.css';
import notificationStyles from './../component/css/NotificationComponent.module.css';
import organStyles from './../component/css/SelectOrganComponent.module.css';
import { encryptData } from '../utils/croptojs';
import PaymentResult from '../pages/payment/PaymentResult';
import PaymentRequest from '../pages/payment/PaymentRequest';

export const MainRoutes = () => {
    const currentUrl = window.location.pathname;
    const token = localStorage.getItem('userToken');
    const dispatch = useDispatch();
    const { notificationVisible, settingVisible, organVisible } = useSelector((state: RootState) => state.header);

    useEffect(() => {
        // 로그인하지 않았고 특정 키로 시작하지 않는 URL을 감시
        if (!token && currentUrl.startsWith('/yd')) {
            window.location.replace('/public/login');
        }
    }, [currentUrl, token]);

    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (
                (notificationVisible && !event.target.closest(`.${notificationStyles.modal_main_container}`)) ||
                (settingVisible && !event.target.closest(`.${settingStyles.modal_main_container}`)) ||
                (organVisible && !event.target.closest(`.${organStyles.modal_main_container}`))
            ) {
                dispatch(resetModal());
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, [notificationVisible, settingVisible, organVisible]);

    return (
        <Routes>
            <Route path="/" element={<Navigate to="/public/login" />} />
            <Route path="/public/auth/kakaologin" element={<KakaoLogin />} />
            <Route path="/public/login" element={<Login />} />
            <Route path="/public/findAccount" element={<FindAccount />} />
            <Route path="/public/signup" element={<Signup />} />
            <Route path="/public/privacyPolicy" element={<PrivacyPolicyConsent />} />
            <Route path="/public/sensitivenInfo" element={<SensitivenInfoConsent />} />
            <Route path="/public/uniqueIdentifier" element={<UniqueIdentifierConsent />} />

            {/* 관리자 or 매니저 View */}
            {/* 토큰 o */}
            <Route path="/yd/official" element={<Navigate to="/yd/official/assignedMember" />} />
            <Route path="/yd/official/*" element={<Official />} />
            {/* 치료자 View */}
            {/* <Routes>
        <Route path="/yd/therapist" element={<Navigate to="/yd/therapist/assignedMember" />} />
        <Route path="/yd/therapist/*" element={<TherapistMain />} />
    </Routes> */}
            {/* <Routes>
        <Route path="/yd/administrator" element={<Navigate to="/yd/therapist/assignedMember" />} />
        <Route path="/yd/administrator/*" element={<TherapistMain />} />
    </Routes> */}
            {/* 환자 View */}
            <Route path="/yd/patient/program/select" element={<ProgramSelect />} />
            <Route path="/yd/patient/program/payment" element={<ProgramPayment />} />
            <Route path="/yd/patient/payment/receipt" element={<PaymentReceipt />} />
            <Route path="/yd/policy/performance" element={<PerformancePolicy />} />
            {/* 대면 */}
            <Route
                path="/yd/patient/face"
                element={<Navigate to={`/yd/patient/face/session?page=${encodeURIComponent(encryptData(1))}`} />}
            />
            <Route path="/yd/patient/face/*" element={<FaceMain />} />
            {/* 비대면 */}
            <Route
                path="/yd/patient/nonFace"
                element={<Navigate to={`/yd/patient/nonface/session?page=${encodeURIComponent(encryptData(1))}`} />}
            />
            <Route path="/yd/patient/nonFace/*" element={<NonFaceMain />} />
            <Route path="/calendar" element={<CalendarTest />} />
            <Route path="/barchart" element={<BarChart />} />
            <Route path="/piechart" element={<PieChart />} />
            <Route path="/youtube" element={<YoutubePlayer />} />
            <Route path="/canvas" element={<Canvas />} />
            {/* <Route path="/paymentRequest" element={<PaymentRequest />} /> */}
            <Route path="/public/paymentReceipt" element={<PaymentReceipt />} />
        </Routes>
    );
};

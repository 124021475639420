import styles from './css/HeaderComponent.module.css';

import { ReactComponent as Icon_header_logo } from '../assets/icon_header_logo.svg';
import { ReactComponent as Icon_fold } from '../assets/leftNavigation/icon_fold.svg';
import { ReactComponent as Icon_see_all } from '../assets/leftNavigation/icon_see_all.svg';
import { ReactComponent as Icon_arrowbottom } from '../assets/member/icon_arrowbottom.svg';
import { ReactComponent as Icon_map } from '../assets/public/icon_map.svg';
import { ReactComponent as Icon_notification } from '../assets/public/icon_notification.svg';
import { ReactComponent as Icon_organ } from '../assets/public/icon_organ.svg';
import { ReactComponent as Icon_blackboard } from '../assets/therapist/icon_blackboard.svg';
import { useDispatch, useSelector } from 'react-redux';
import { navVisible } from '../features/leftNavReducer';
import { RootState } from '../store';
import { getMemberInfo } from '../utils/GetMemberInfo';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Notification } from './NotificationComponent';
import { updateNotificationVisible, updateOrganVisible, updateSettingVisible } from '../features/headerReducer';
import { Setting } from './SettingComponent';
import { SpanText } from './PublicComponent';
import { SelectOrgan } from './SelectOrganComponent';
import { updateBlackboardVisible, updateColorPickerVisible } from '../features/session/blackboardReducer';

export const BasicHeader = () => {
    const dispatch = useDispatch();
    const visible = useSelector((state: RootState) => state.navVisible);

    const FoldVisible = () => {
        if (visible.navVisible) {
            return (
                <>
                    <Icon_header_logo className={styles.icon_header_logo} />
                    <button onClick={() => dispatch(navVisible(!visible.navVisible))}>
                        <Icon_fold className={styles.icon_menu} />
                    </button>
                </>
            );
        } else {
            return (
                <>
                    <button onClick={() => dispatch(navVisible(!visible.navVisible))}>
                        <Icon_see_all className={styles.icon_menu} />
                    </button>
                </>
            );
        }
    };

    return (
        <div className={styles.header_container}>
            <div
                className={`${styles.header_logo_container} ${
                    visible.navVisible ? styles.activate : styles.deactivate
                }`}
            >
                <FoldVisible />
            </div>
            <div className={styles.menu}></div>
        </div>
    );
};

// 관리자 + 매니저
export const ManagerAdministratorHeader = ({ title }: { title: string }) => {
    const dispatch = useDispatch();
    const visible = useSelector((state: RootState) => state.navVisible);
    const { notificationVisible, settingVisible, organVisible } = useSelector((state: RootState) => state.header);
    const memberInfo = getMemberInfo();

    const FoldVisible = () => {
        if (visible.navVisible) {
            return (
                <>
                    <Icon_header_logo className={styles.icon_header_logo} />
                    <button onClick={() => dispatch(navVisible(!visible.navVisible))}>
                        <Icon_fold className={styles.icon_menu} />
                    </button>
                </>
            );
        } else {
            return (
                <>
                    <button onClick={() => dispatch(navVisible(!visible.navVisible))}>
                        <Icon_see_all className={styles.icon_menu} />
                    </button>
                </>
            );
        }
    };

    return (
        <div className={styles.header_container}>
            <div
                className={`${styles.header_logo_container} ${
                    visible.navVisible ? styles.activate : styles.deactivate
                }`}
            >
                <FoldVisible />
            </div>
            {memberInfo && (
                <div className={styles.header_content_container}>
                    <div className={styles.header_content}>
                        <SpanText className="text_28_NotoSansKR_Bold" color="#020202" text={title} />
                        <div className={styles.menu}>
                            <button onClick={() => dispatch(updateNotificationVisible(!notificationVisible))}>
                                <Icon_notification className={styles.icon_menu} />
                            </button>
                            <button
                                onClick={() => dispatch(updateOrganVisible(!organVisible))}
                                className={`${styles.organ_button} text_16_NotoSansKR_Medium`}
                            >
                                <Icon_organ className={styles.icon_menu} />
                                {memberInfo?.loginBranch.branchName}
                                <Icon_arrowbottom className={styles.icon_arrowbottom} />
                            </button>
                            <button
                                onClick={() => dispatch(updateSettingVisible(!settingVisible))}
                                className={`${styles.haler_profile_button} text_16_NotoSansKR_Medium`}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        width: '2rem',
                                        height: '2rem',
                                        borderRadius: '100px',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        marginRight: '0.5rem',
                                        color: '#E8F0F3',
                                        backgroundColor: '#FEEFE4',
                                    }}
                                    className="text_12_NotoSansKR_Bold"
                                >
                                    이
                                </div>
                                {memberInfo?.memberName}
                                <Icon_arrowbottom className={styles.icon_arrowbottom} />
                            </button>
                        </div>
                    </div>
                </div>
            )}
            {notificationVisible && <Notification />}
            {settingVisible && <Setting />}
            {organVisible && <SelectOrgan />}
        </div>
    );
};

// 치료자
export const TherapistHeader = ({ title }: { title: string }) => {
    const dispatch = useDispatch();
    const visible = useSelector((state: RootState) => state.navVisible);
    const { notificationVisible, settingVisible, organVisible } = useSelector((state: RootState) => state.header);
    const { blackboardVisible, colorPickerVisible } = useSelector((state: RootState) => state.blackboard);
    const memberInfo = getMemberInfo();

    const blackboardActive = () => {
        dispatch(updateColorPickerVisible(false));
        dispatch(updateBlackboardVisible(!blackboardVisible));
    };

    const FoldVisible = () => {
        if (visible.navVisible) {
            return (
                <>
                    <Icon_header_logo className={styles.icon_header_logo} />
                    <button onClick={() => dispatch(navVisible(!visible.navVisible))}>
                        <Icon_fold className={styles.icon_menu} />
                    </button>
                </>
            );
        } else {
            return (
                <>
                    <button onClick={() => dispatch(navVisible(!visible.navVisible))}>
                        <Icon_see_all className={styles.icon_menu} />
                    </button>
                </>
            );
        }
    };

    const BlackBoard = () => {
        if (blackboardVisible) {
            return (
                <>
                    <span
                        className="text_16_NotoSansKR_Medium"
                        style={{
                            color: '#FFF',
                        }}
                    >
                        전자칠판 종료
                    </span>
                </>
            );
        } else {
            return (
                <>
                    <Icon_blackboard className={styles.icon_blackboard} />
                    <span
                        className="text_16_NotoSansKR_Medium"
                        style={{
                            color: '#FFF',
                        }}
                    >
                        전자칠판
                    </span>
                </>
            );
        }
    };

    return (
        <div className={styles.header_container}>
            <div
                className={`${styles.header_logo_container} ${
                    visible.navVisible ? styles.activate : styles.deactivate
                }`}
            >
                <FoldVisible />
            </div>
            {memberInfo && (
                <div className={styles.header_content_container}>
                    <div className={styles.header_content}>
                        <SpanText className="text_28_NotoSansKR_Bold" color="#020202" text={title} />
                        <div className={styles.menu}>
                            <button onClick={blackboardActive} className={styles.blackboard_button}>
                                <BlackBoard />
                            </button>
                            <button onClick={() => dispatch(updateNotificationVisible(!notificationVisible))}>
                                <Icon_notification className={styles.icon_menu} />
                            </button>
                            <button
                                onClick={() => dispatch(updateOrganVisible(!organVisible))}
                                className={`${styles.organ_button} text_16_NotoSansKR_Medium`}
                            >
                                <Icon_organ className={styles.icon_menu} />
                                {memberInfo.loginBranch.branchName}
                                <Icon_arrowbottom className={styles.icon_arrowbottom} />
                            </button>
                            <button
                                onClick={() => dispatch(updateSettingVisible(!settingVisible))}
                                className={`${styles.haler_profile_button} text_16_NotoSansKR_Medium`}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        width: '2rem',
                                        height: '2rem',
                                        borderRadius: '100px',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        marginRight: '0.5rem',
                                        color: '#E8F0F3',
                                        backgroundColor: '#FEEFE4',
                                    }}
                                    className="text_12_NotoSansKR_Bold"
                                >
                                    이
                                </div>
                                {memberInfo?.memberName}
                                <Icon_arrowbottom className={styles.icon_arrowbottom} />
                            </button>
                        </div>
                    </div>
                </div>
            )}
            {notificationVisible && <Notification />}
            {settingVisible && <Setting />}
            {organVisible && <SelectOrgan />}
        </div>
    );
};

// 환자
export const PatientHeader = ({ title }: { title: string }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const visible = useSelector((state: RootState) => state.navVisible);
    const { notificationVisible, settingVisible } = useSelector((state: RootState) => state.header);
    const memberInfo = getMemberInfo();
    const queryParams = new URLSearchParams(location.search);

    const moveSessionMap = () => {
        navigate(`sessionMap?${queryParams.toString()}`);
    };

    const FoldVisible = () => {
        if (visible.navVisible) {
            return (
                <>
                    <Icon_header_logo className={styles.icon_header_logo} />
                    <button onClick={() => dispatch(navVisible(!visible.navVisible))}>
                        <Icon_fold className={styles.icon_menu} />
                    </button>
                </>
            );
        } else {
            return (
                <>
                    <button onClick={() => dispatch(navVisible(!visible.navVisible))}>
                        <Icon_see_all className={styles.icon_menu} />
                    </button>
                </>
            );
        }
    };

    return (
        <div className={styles.header_container}>
            <div
                className={`${styles.header_logo_container} ${
                    visible.navVisible ? styles.activate : styles.deactivate
                }`}
            >
                <FoldVisible />
            </div>
            <div className={styles.header_content_container}>
                <div className={styles.header_content}>
                    <SpanText className="text_28_NotoSansKR_Bold" color="#020202" text={title} />
                    <div className={styles.menu}>
                        <button onClick={moveSessionMap}>
                            <Icon_map className={styles.icon_menu} />
                        </button>
                        <button onClick={() => dispatch(updateNotificationVisible(!notificationVisible))}>
                            <Icon_notification className={styles.icon_menu} />
                        </button>
                        <button
                            onClick={() => navigate('/yd/patient/program/select')}
                            className={styles.program_payment_button}
                        >
                            <div className={`${styles.progran_payment_content} text_16_NotoSansKR_Medium`}>
                                <span
                                    style={{
                                        background: 'linear-gradient(90deg, #3A5074 0%, #4D717F 100%)',
                                        WebkitBackgroundClip: 'text',
                                        WebkitTextFillColor: 'transparent',
                                    }}
                                >
                                    프로그램 결제
                                </span>
                            </div>
                        </button>
                        <button
                            onClick={() => dispatch(updateSettingVisible(!settingVisible))}
                            className={`${styles.patient_profile_button} text_16_NotoSansKR_Medium`}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    width: '2rem',
                                    height: '2rem',
                                    borderRadius: '100px',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    marginRight: '0.5rem',
                                    color: '#F07F34',
                                    backgroundColor: '#FEEFE4',
                                }}
                                className="text_12_NotoSansKR_Bold"
                            >
                                {memberInfo?.memberName[0]}
                            </div>
                            <div className={styles.name_content}>
                                <span
                                    className="text_12_NotoSansKR_Medium"
                                    style={{
                                        textAlign: 'left',
                                    }}
                                >
                                    {memberInfo?.memberName}님
                                </span>
                                <span className="text_12_NotoSansKR_Medium" style={{ color: '#9D9FA2' }}>
                                    변화기간:{' '}
                                    <span
                                        className="text_12_Inter_Medium"
                                        style={{
                                            color: '#9D9FA2',
                                        }}
                                    >
                                        D+999
                                    </span>
                                    일
                                </span>
                            </div>
                            <Icon_arrowbottom className={styles.icon_arrowbottom} />
                        </button>
                    </div>
                </div>
            </div>
            {notificationVisible && <Notification />}
            {settingVisible && <Setting />}
        </div>
    );
};

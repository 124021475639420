import { Route, Routes } from 'react-router-dom';

import styles from './css/ManagerMain.module.css';

import { ManagerAdministratorHeader } from '../../../../component/HeaderComponent';
import { ManagerLeftMenu } from '../../../../component/MenuComponent';
import { AssignedDetail } from '../assignedDetail/AssignedDetail';
import { MemberInfo } from '../../../public/MemberInfo';
import { NotificationSetting } from '../../../public/NotificationSetting';
import { ManagerAssignedMember } from '../assignedMember/ManagerAssignedMember';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { ReservationStatus } from '../reservationStatus/ReservationStatus';

export const ManagerMain = () => {
    const visible = useSelector((state: RootState) => state.navVisible);

    return (
        <div className={styles.main_container}>
            <ManagerAdministratorHeader title={''} />
            <ManagerLeftMenu />
            <div className={`${styles.content_container} ${visible.navVisible ? styles.activate : styles.deactivate}`}>
                <Routes>
                    <Route path="assignedMember" element={<ManagerAssignedMember />} />
                    <Route path="assignedMember/detail/*" element={<AssignedDetail />} />
                    <Route path="reservationStatus" element={<ReservationStatus />} />
                    <Route path="memberInfo" element={<MemberInfo />} />
                    <Route path="notificationSetting" element={<NotificationSetting />} />
                </Routes>
            </div>
        </div>
    );
};

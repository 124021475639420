import styles from '../../css/FifthSession.module.css';
import '../../../css/PublicSessionStyle.css';

import { SocraticQuestionAnswer, SubmitWorkbook } from '../../component/PublicSessionComponent';
import { useLocation } from 'react-router-dom';

export const TherapistFifthSession = () => {
    const location = useLocation();
    const currentSession = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);
    return (
        <div className={'therapist_main_container'}>
            <SocraticQuestionAnswer />
        </div>
    );
};

import { TherapistMain } from './therapist/TherapistMain';
import { Administrator } from './administrator/Administrator';
import { ManagerMain } from './manager/ManagerMain';
import { useEffect, useState } from 'react';
import { getMemberInfo } from '../../../utils/GetMemberInfo';
import { Representative } from './representative/Representative';

export const Official = () => {
    const [memberInfo, setMemberInfo] = useState<any>(null);
    const getInfo = async () => {
        const info = getMemberInfo();
        if (info) {
            setMemberInfo(info);
        }
    };

    useEffect(() => {
        getInfo();
    }, []);

    return (
        memberInfo && (
            <div>
                {memberInfo.memberRole === 'therapist' && <TherapistMain />}
                {memberInfo.memberRole === 'manager' && <ManagerMain />}
                {memberInfo.memberRole === 'administrator' && <Administrator />}
                {memberInfo.memberRole === 'representative' && <Representative />}
            </div>
        )
    );
};

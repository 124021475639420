import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { getMemberInfo } from '../utils/GetMemberInfo';

const memberInfo = getMemberInfo();
interface Header {
    notificationVisible: boolean;
    settingVisible: boolean;
    settingMenu: string;
    organVisible: boolean;
    selectOrgan: string | undefined;
}

const initialState: Header = {
    notificationVisible: false,
    settingVisible: false,
    settingMenu: '',
    organVisible: false,
    selectOrgan: '',
};

export const headerReducer = createSlice({
    name: 'header',
    initialState,
    reducers: {
        updateNotificationVisible: (state, action: PayloadAction<boolean>) => {
            state.notificationVisible = action.payload;
        },
        updateSettingVisible: (state, action: PayloadAction<boolean>) => {
            state.settingVisible = action.payload;
        },
        updateSettingMenu: (state, action: PayloadAction<string>) => {
            state.settingMenu = action.payload;
        },
        updateOrganVisible: (state, action: PayloadAction<boolean>) => {
            state.organVisible = action.payload;
        },
        updateSelectOrgan: (state, action: PayloadAction<string>) => {
            state.selectOrgan = action.payload;
        },
        resetModal: () => initialState,
    },
});

export const {
    updateNotificationVisible,
    updateSettingVisible,
    updateSettingMenu,
    updateOrganVisible,
    updateSelectOrgan,
    resetModal,
} = headerReducer.actions;
export default headerReducer.reducer;

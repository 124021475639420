import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface Profile {
    memberName: string;
    memberAccount: string;
    memberPhoneNumber: string;
    guardianPhoneNumber: string;
    memberBirth: string;
    memberGender: string;
    memberEmail: string;
    memberEmailAddress: string;
    memberNewPassword: string;
    memberNewRecheckPassword: string;
    memberNickName: string;
    naverId: string;
    marketing: boolean;
    type: string;
    emailVisible: boolean;
}

const initialState: Profile = {
    memberName: '',
    memberAccount: '',
    memberPhoneNumber: '',
    guardianPhoneNumber: '',
    memberBirth: '',
    memberGender: '',
    memberEmail: '',
    memberEmailAddress: '',
    memberNewPassword: '',
    memberNewRecheckPassword: '',
    memberNickName: '',
    naverId: '',
    marketing: false,
    type: '',
    emailVisible: false,
};

export const editProfileSlice = createSlice({
    name: 'editProfile',
    initialState,
    reducers: {
        updateMemberEmail: (state, action: PayloadAction<string>) => {
            state.memberEmail = action.payload;
        },
        updateEmailAddress: (state, action: PayloadAction<string>) => {
            state.memberEmailAddress = action.payload;
        },
        updateMemberNewPassword: (state, action: PayloadAction<string>) => {
            state.memberNewPassword = action.payload;
        },
        updateMemberNewRecheckPassword: (state, action: PayloadAction<string>) => {
            state.memberNewRecheckPassword = action.payload;
        },
        updateMemberNickName: (state, action: PayloadAction<string>) => {
            state.memberNickName = action.payload;
        },
        updateMemberNaverId: (state, action: PayloadAction<string>) => {
            state.naverId = action.payload;
        },
        updateMemberMarketing: (state, action: PayloadAction<boolean>) => {
            state.marketing = action.payload;
        },
        updateEmailVisible: (state, action: PayloadAction<boolean>) => {
            state.emailVisible = action.payload;
        },
    },
});

export const {
    updateMemberEmail,
    updateEmailAddress,
    updateMemberNewPassword,
    updateMemberNewRecheckPassword,
    updateMemberNickName,
    updateMemberNaverId,
    updateMemberMarketing,
    updateEmailVisible,
} = editProfileSlice.actions;
export default editProfileSlice.reducer;

import React, { useCallback, useEffect, useRef, useState } from 'react';
import styles from '../../css/FirstSession.module.css';
import '../../../css/PublicSessionStyle.css';

import Img_brain from '../../../../../../assets/face/session1/session1_brain.png';

import { DesiredSymptomsAdvantageGoal, ThoughtFeelInput } from '../../component/FirstSessionComponent';
import { useLocation } from 'react-router-dom';

export const TherapistFirstSession = () => {
    const location = useLocation();
    const currentSession = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);
    return (
        <div className={'therapist_main_container'}>
            <div className={styles.thought_feel}>
                <div className={styles.thought_feel_container}>
                    <div>
                        <img alt="brain" src={Img_brain} className={styles.img} />
                    </div>
                    <ThoughtFeelInput />
                </div>
            </div>
            <DesiredSymptomsAdvantageGoal />
        </div>
    );
};

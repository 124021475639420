import styles from '../../css/SecondSession.module.css';
import '../../../css/PublicSessionStyle.css';

import Img1 from '../../../../../../assets/face/session2/session2_1.png';
import Img2 from '../../../../../../assets/face/session2/session2_2.png';

import { useRef, useState } from 'react';

import { ExperiencesEmotion } from '../../component/SecondSessionComponent';
import { SituationEmotion, SubmitWorkbook } from '../../component/PublicSessionComponent';
import { getMemberInfo } from '../../../../../../utils/GetMemberInfo';
import { useLocation } from 'react-router-dom';

export const TherapistSecondSession = () => {
    const location = useLocation();
    const currentSession = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);
    const memberInfo = getMemberInfo();
    const readOnly = memberInfo?.memberRole !== 'user' && true;
    const [physicalSymptomsList, setPhysicalSymptomsList] = useState([
        {
            style: styles.brain,
        },
        {
            style: styles.breath,
        },
        {
            style: styles.muscle_tension,
        },
        {
            style: styles.intestine,
        },
        {
            style: styles.peripheral_blood_vessels,
        },
        {
            style: styles.salivary_glands,
        },
        {
            style: styles.heart,
        },
        {
            style: styles.camouflage,
        },
        {
            style: styles.sweat_glands,
        },
    ]);

    return (
        <div className={'therapist_main_container'}>
            <div className={styles.image_input_container}>
                <img alt="1" src={Img1} className={styles.img1} />
                {physicalSymptomsList.map((item, index) => (
                    <div
                        className={`${styles.physical_symptoms_input_container} ${item.style} text_18_NotoSansKR_Regular`}
                    >
                        <input className={styles.physical_symptoms_input} readOnly={readOnly} />
                        <span className="text_18_NotoSansKR_Regular">점</span>
                    </div>
                ))}
            </div>
            <img alt="2" src={Img2} className={styles.img} />
            <SituationEmotion currentSession={currentSession} />

            <ExperiencesEmotion />
        </div>
    );
};

import React, { createContext, useContext, useEffect, useState } from 'react';

interface InactivityContextType {
    isLoggedIn: boolean;
    setIsLoggedIn: React.Dispatch<React.SetStateAction<boolean>>;
}

const InactivityContext = createContext<InactivityContextType | undefined>(undefined);

export const useInactivity = () => {
    const context = useContext(InactivityContext);
    if (!context) {
        throw new Error('useInactivity must be used within an InactivityProvider');
    }
    return context;
};

export const InactivityProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [isLoggedIn, setIsLoggedIn] = useState(true);
    const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);
    const currentUrl = window.location.pathname;
    const search = window.location.search;

    const logout = () => {
        setIsLoggedIn(false);
        localStorage.removeItem('userToken');
        localStorage.setItem('lastVisitedPage', currentUrl + search);
        window.location.replace('/public/login');
    };

    const resetTimer = () => {
        if (timeoutId) {
            clearTimeout(timeoutId);
        }
        // const id = setTimeout(logout, 3000); // 30분 후 로그아웃
        const id = setTimeout(logout, 30 * 60 * 1000); // 30분 후 로그아웃
        setTimeoutId(id);
    };

    useEffect(() => {
        const handleUserActivity = () => {
            resetTimer();
        };

        window.addEventListener('mousemove', handleUserActivity);
        window.addEventListener('keypress', handleUserActivity);
        window.addEventListener('click', handleUserActivity);
        window.addEventListener('scroll', handleUserActivity);
        window.addEventListener('touchstart', handleUserActivity);
        window.addEventListener('touchend', handleUserActivity);
        window.addEventListener('keydown', handleUserActivity);
        window.addEventListener('keyup', handleUserActivity);
        window.addEventListener('wheel', handleUserActivity);

        document.removeEventListener('visibilitychange', handleUserActivity);

        return () => {
            window.removeEventListener('mousemove', handleUserActivity);
            window.removeEventListener('keypress', handleUserActivity);
            window.removeEventListener('click', handleUserActivity);
            window.removeEventListener('scroll', handleUserActivity);
            window.removeEventListener('touchstart', handleUserActivity);
            window.removeEventListener('touchend', handleUserActivity);
            window.removeEventListener('keydown', handleUserActivity);
            window.removeEventListener('keyup', handleUserActivity);
            window.removeEventListener('wheel', handleUserActivity);

            document.removeEventListener('visibilitychange', handleUserActivity);
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [timeoutId]);

    useEffect(() => {
        resetTimer();
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, []);

    return <InactivityContext.Provider value={{ isLoggedIn, setIsLoggedIn }}>{children}</InactivityContext.Provider>;
};

export const EssentialMark = () => {
    return (
        <span
            className="text_18_Inter_bold"
            style={{
                color: '#FF5833',
            }}
        >
            *
        </span>
    );
};

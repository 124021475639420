import { EmailDropdown } from '../pages/member/component/EmailDropdown';
import styles from './css/MemberInfoComponent.module.css';
import { ReactComponent as Icon_arrowbottom } from '../assets/member/icon_arrowbottom.svg';
import { ReactComponent as Icon_arrowright } from '../assets/member/signup/icon_arrowright.svg';

import { EssentialMark } from '../pages/member/component/EssentialMark';
import { SquareCheckBox, Toggle } from './PublicComponent';
import styled from 'styled-components';
import { getMemberInfo } from '../utils/GetMemberInfo';
import { useContext, useState } from 'react';
import { ModalContext } from '../context/ModalContext';
import axiosInstance from '../utils/AxiosInstanceJava';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store';
import {
    updateEmailAddress,
    updateEmailVisible,
    updateMemberNaverId,
    updateMemberNewPassword,
    updateMemberNewRecheckPassword,
    updateMemberNickName,
} from '../features/editProfileReducer';

const Title = styled.span`
    color: #626466;
`;

export const BasicInfo = ({ editMemberInfo }: { editMemberInfo: Function }) => {
    const dispatch = useDispatch();
    const { emailVisible, memberEmailAddress } = useSelector((state: RootState) => state.editProfile);
    const memberInfo = getMemberInfo();

    return (
        <div className={styles.basic_info_container}>
            <div className="text_22_NotoSansKR_Bold">기본정보</div>
            <div
                style={{
                    display: 'flex',
                    width: '6.875rem',
                    height: '6.875rem',
                    borderRadius: '100px',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginRight: '0.5rem',
                    color: '#F07F34',
                    backgroundColor: '#FEEFE4',
                }}
                className="text_40_NotoSansKR_Bold"
            >
                {memberInfo?.memberName[0]}
            </div>
            <div className={styles.basic_info}>
                <div className={styles.info_container}>
                    <div className={styles.input_container}>
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                            }}
                        >
                            <Title className="text_18_NotoSansKR_Bold">이름</Title>
                            <button
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '0.25rem',
                                }}
                            >
                                <span
                                    className="text_18_NotoSansKR_Bold"
                                    style={{
                                        color: '#3a5074',
                                    }}
                                >
                                    본인인증
                                </span>
                                <Icon_arrowright className={styles.icon_arrowright} />
                            </button>
                        </div>
                        <input
                            type="text"
                            className={`${`${styles.read_only_input} text_18_NotoSansKR_Regular`} text_18_NotoSansKR_Regular`}
                            defaultValue={memberInfo?.memberName}
                            readOnly
                        />
                        <span
                            className="text_18_NotoSansKR_Regular"
                            style={{
                                color: '#FF5833',
                            }}
                        >
                            ※ 이름, 휴대폰 번호, 성별 변경이 필요하신 경우 휴대폰 본인인증을 완료하시면 자동으로 일괄
                            변경됩니다.
                        </span>
                    </div>
                    <div className={styles.input_container}>
                        <Title className="text_18_NotoSansKR_Bold">
                            이메일 주소
                            <EssentialMark />
                        </Title>
                        <div className={styles.multi_input_container}>
                            <input
                                className={`${styles.input} text_18_Inter_Regular`}
                                value={memberInfo?.memberEmail.split('@')[0]}
                                onChange={() => {}}
                            />
                            <span>@</span>
                            <button
                                onClick={() => dispatch(updateEmailVisible(!emailVisible))}
                                className={styles.email_dropdown}
                            >
                                <span className="text_18_Inter_Regular">{memberEmailAddress}</span>
                                <Icon_arrowbottom className={styles.icon_arrowbottm} />
                                {emailVisible && (
                                    <EmailDropdown
                                        updateAddress={(item: string) => {
                                            console.log(item);
                                            dispatch(updateEmailAddress(item));
                                            dispatch(updateEmailVisible(!emailVisible));
                                        }}
                                    />
                                )}
                            </button>
                        </div>
                    </div>
                    <div className={styles.input_container}>
                        <Title className="text_18_NotoSansKR_Bold">생년월일</Title>
                        <input
                            type="text"
                            className={`${styles.read_only_input} text_18_NotoSansKR_Regular`}
                            defaultValue={'2024/01/01'}
                            readOnly
                        />
                    </div>
                    <div className={styles.input_container}>
                        <Title className="text_18_NotoSansKR_Bold">
                            보호자 휴대폰 번호
                            <EssentialMark />
                        </Title>
                        <div className={styles.multi_input_container}>
                            <input
                                type="text"
                                className={`${styles.input} text_18_NotoSansKR_Regular`}
                                value={memberInfo?.memberPhone.split('-')[0]}
                            />
                            <span>-</span>
                            <input
                                type="text"
                                className={`${styles.input} text_18_NotoSansKR_Regular`}
                                value={memberInfo?.memberPhone.split('-')[1]}
                            />
                            <span>-</span>
                            <input
                                type="text"
                                className={`${styles.input} text_18_NotoSansKR_Regular`}
                                value={memberInfo?.memberPhone.split('-')[2]}
                            />
                        </div>
                    </div>
                </div>
                <div className={styles.info_container}>
                    <div className={`${styles.input_container} ${styles.margin_bottom}`}>
                        <Title className="text_18_NotoSansKR_Bold">아이디</Title>
                        <input
                            type="text"
                            className={`${styles.read_only_input} text_18_NotoSansKR_Regular`}
                            defaultValue={memberInfo?.memberAccount}
                            readOnly
                        />
                    </div>
                    <div className={styles.input_container}>
                        <Title className="text_18_NotoSansKR_Bold">휴대폰 번호</Title>
                        <div className={styles.multi_input_container}>
                            <input
                                type="text"
                                className={`${styles.read_only_input} text_18_NotoSansKR_Regular`}
                                defaultValue={memberInfo?.memberPhone.split('-')[0]}
                                readOnly
                            />
                            <span>-</span>
                            <input
                                type="text"
                                className={`${styles.read_only_input} text_18_NotoSansKR_Regular`}
                                defaultValue={memberInfo?.memberPhone.split('-')[1]}
                                readOnly
                            />
                            <span>-</span>
                            <input
                                type="text"
                                className={`${styles.read_only_input} text_18_NotoSansKR_Regular`}
                                defaultValue={memberInfo?.memberPhone.split('-')[2]}
                                readOnly
                            />
                        </div>
                    </div>
                    <div className={styles.input_container}>
                        <Title className="text_18_NotoSansKR_Bold">성별</Title>
                        <input
                            type="text"
                            className={`${styles.read_only_input} text_18_NotoSansKR_Regular`}
                            defaultValue={'남자'}
                            readOnly
                        />
                    </div>
                </div>
            </div>
            <button
                className={`${styles.save_info_button} text_18_NotoSansKR_Bold`}
                onClick={() => editMemberInfo('basic')}
            >
                기본정보 저장
            </button>
        </div>
    );
};

export const ChangePassword = ({ editMemberInfo }: { editMemberInfo: Function }) => {
    const dispatch = useDispatch();
    const { oneButtonOpenModal } = useContext(ModalContext);
    const { memberNewPassword, memberNewRecheckPassword } = useSelector((state: RootState) => state.editProfile);

    const changePasswordRole = () => {
        const passwordRegex =
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>])[a-zA-Z\d!@#$%^&*(),.?":{}|<>]{4,}$/;
        if (memberNewPassword === '') {
            return oneButtonOpenModal(() => {}, '', '새로운 비밀번호를 입력해 주세요.', '확인');
        } else if (memberNewRecheckPassword === '') {
            return oneButtonOpenModal(() => {}, '', '비밀번호를 재입력해 주세요.', '확인');
        } else if (!passwordRegex.test(memberNewPassword)) {
            return oneButtonOpenModal(
                () => {},
                '',
                '올바르지 않은 비밀번호 형식입니다. (띄어쓰기 미포함 최소 4자리 이상 입력 가능)',
                '확인'
            );
        } else if (memberNewPassword !== memberNewRecheckPassword) {
            return oneButtonOpenModal(() => {}, '', '비밀번호가 일치하지 않습니다.', '확인');
        }
        editMemberInfo('password');
    };

    const onChangeText = (text: string, type: string) => {
        switch (type) {
            case 'newPassword':
                return dispatch(updateMemberNewPassword(text));
            case 'newRecheckPassword':
                return dispatch(updateMemberNewRecheckPassword(text));
        }
    };

    return (
        <div className={styles.basic_info_container}>
            <div className="text_22_NotoSansKR_Bold">비밀번호 변경</div>
            <div className={styles.basic_info}>
                <div className={styles.info_container}>
                    <div className={styles.input_container}>
                        <Title className="text_18_NotoSansKR_Bold">
                            새로운 비밀번호
                            <EssentialMark />
                        </Title>
                        <input
                            type="password"
                            className={`${`${styles.input} text_18_NotoSansKR_Regular`} text_18_NotoSansKR_Regular`}
                            value={memberNewPassword}
                            onChange={(e) => onChangeText(e.target.value, 'newPassword')}
                        />
                    </div>
                </div>
                <div className={styles.info_container}>
                    <div className={styles.input_container}>
                        <Title className="text_18_NotoSansKR_Bold">
                            새로운 비밀번호 확인 <EssentialMark />
                        </Title>
                        <input
                            type="password"
                            className={`${styles.input} text_18_NotoSansKR_Regular`}
                            value={memberNewRecheckPassword}
                            onChange={(e) => onChangeText(e.target.value, 'newRecheckPassword')}
                        />
                    </div>
                </div>
            </div>
            <button className={`${styles.save_info_button} text_18_NotoSansKR_Bold`} onClick={changePasswordRole}>
                비밀번호 저장
            </button>
        </div>
    );
};

export const AdditionalInfo = ({ editMemberInfo }: { editMemberInfo: Function }) => {
    const dispatch = useDispatch();
    const { memberNickName, naverId } = useSelector((state: RootState) => state.editProfile);

    const changeMemberNickName = (text: string) => {
        dispatch(updateMemberNickName(text));
    };

    const changeMemberNaverId = (text: string) => {
        dispatch(updateMemberNaverId(text));
    };

    return (
        <div className={styles.basic_info_container}>
            <div className="text_22_NotoSansKR_Bold">부가정보 입력</div>
            <div className={styles.basic_info}>
                <div className={styles.info_container}>
                    <div className={styles.input_container}>
                        <Title className="text_18_NotoSansKR_Bold">
                            닉네임
                            <span
                                className="text_18_NotoSansKR_Regular"
                                style={{
                                    color: '#9D9FA2',
                                }}
                            >
                                (선택)
                            </span>
                        </Title>
                        <input
                            className={`${`${styles.input} text_18_NotoSansKR_Regular`} text_18_NotoSansKR_Regular`}
                            value={memberNickName}
                            onChange={(e) => {
                                changeMemberNickName(e.target.value);
                            }}
                        />
                    </div>
                </div>
                <div className={styles.info_container}>
                    <div className={styles.input_container}>
                        <Title className="text_18_NotoSansKR_Bold">
                            네이버 카페 아이디
                            <span
                                className="text_18_NotoSansKR_Regular"
                                style={{
                                    color: '#9D9FA2',
                                }}
                            >
                                (선택)
                            </span>
                        </Title>
                        <input
                            className={`${styles.input} text_18_NotoSansKR_Regular`}
                            value={naverId}
                            onChange={(e) => {
                                changeMemberNaverId(e.target.value);
                            }}
                        />
                    </div>
                </div>
            </div>
            <button
                className={`${styles.save_info_button} text_18_NotoSansKR_Bold`}
                onClick={() => editMemberInfo('additional')}
            >
                부가정보 저장
            </button>
        </div>
    );
};

export const EventPolicy = ({ editMemberInfo }: { editMemberInfo: Function }) => {
    const [isChecked, setIsChecked] = useState<{ index: number }[]>([]);

    const toggleKeyValue = (index: number) => {
        setIsChecked((prev) => {
            const exists = prev.find((item) => item.index === index);
            return exists
                ? prev.filter((item) => item.index !== index) // 존재하면 제거
                : [...prev, { index: index }]; // 존재하지 않으면 추가
        });
    };

    return (
        <div className={styles.event_policy_container}>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                }}
            >
                <div className="text_22_NotoSansKR_Bold">
                    이벤트 정보 수신
                    <EssentialMark />
                </div>
                <Toggle fn={editMemberInfo} />
            </div>
            <div className={styles.event_content_container}>
                <div className={styles.event_content}>
                    <button onClick={() => toggleKeyValue(0)} className={styles.event_item}>
                        <SquareCheckBox bool={isChecked.some((item) => item.index === 0)} size={'1.5rem'} />
                        <span className="text_18_NotoSansKR_Medium">
                            광고성 마케팅 수신/마케팅 활용 동의
                            <span
                                className="text_18_NotoSansKR_Regular"
                                style={{
                                    color: '#9D9FA2',
                                }}
                            >
                                (선택)
                            </span>
                        </span>
                    </button>
                    <div className={styles.event_detail_button}>
                        <span
                            className="text_18_NotoSansKR_Bold"
                            style={{
                                color: '#3A5074',
                            }}
                        >
                            내용보기
                        </span>
                        <Icon_arrowright className={styles.icon_arrowright} />
                    </div>
                </div>
                <button onClick={() => toggleKeyValue(1)} className={styles.event_content}>
                    <div className={styles.event_item}>
                        <SquareCheckBox bool={isChecked.some((item) => item.index === 1)} size={'1.5rem'} />
                        <span className="text_18_NotoSansKR_Medium">SMS</span>
                    </div>
                </button>
            </div>
        </div>
    );
};

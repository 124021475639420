import React, { useRef, useState } from 'react';
import YouTube from 'react-youtube';
import ReactPlayer from 'react-player';

export const YoutubePlayer = () => {
    const playerRef = useRef<ReactPlayer | null>(null);
    const [playing, setPlaying] = useState(false);
    const [volume, setVolume] = useState(0.8); // 초기 볼륨
    const [played, setPlayed] = useState(0); // 재생된 시간 비율
    const [duration, setDuration] = useState(0); // 총 길이

    const handlePlayPause = () => {
        setPlaying(!playing);
    };

    const handleVolumeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setVolume(parseFloat(e.target.value));
    };

    const handleProgress = (state: { played: number }) => {
        setPlayed(state.played);
    };

    const handleDuration = (duration: number) => {
        setDuration(duration);
    };

    const handleSeekChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newPlayed = parseFloat(e.target.value);
        setPlayed(newPlayed);
        if (playerRef.current) {
            playerRef.current.seekTo(newPlayed);
        }
    };
    const [value, setValue] = useState('');

    const handleChange = (event: any) => {
        setValue(event.target.value);
        // 높이 자동 조정
        event.target.style.height = 'auto'; // 초기화
        event.target.style.height = `${event.target.scrollHeight}px`; // 내용에 맞게 조정
    };

    return (
        <div className="main-container">
            <div className="safe-area">
                <textarea
                    value={value}
                    onChange={handleChange}
                    style={{
                        width: '20%',
                        height: 'auto',
                        resize: 'none', // 사용자가 수동으로 크기 조정하지 못하게
                        overflow: 'hidden', // 스크롤바 숨김
                        padding: '10px',
                        boxSizing: 'border-box', // 패딩 포함
                    }}
                />
                <YouTube
                    videoId="DYpsW7dTSGw"
                    opts={{
                        width: '560',
                        height: '315',
                        playerVars: {
                            autoplay: 1, //자동재생 O
                            rel: 0, //관련 동영상 표시하지 않음
                            modestbranding: 1, // 컨트롤 바에 youtube 로고를 표시하지 않음
                        },
                    }}
                />
                <ReactPlayer
                    url="https://exqibabapqbf17923502.cdn.ntruss.com/ydTest/CBT10.mp4"
                    width="40%" // 비디오 너비
                    height="auto" // 비디오 높이
                    playing={playing}
                    volume={volume}
                    onProgress={handleProgress}
                    onDuration={handleDuration}
                    controls={true} // 기본 컨트롤 비활성화
                />
                {/* <div className="custom-controls">
                    <button onClick={handlePlayPause}>{playing ? '일시 정지' : '재생'}</button>
                    <input type="range" min={0} max={1} step="0.01" value={played} onChange={handleSeekChange} />
                    <span>
                        {(duration * played).toFixed(2)} / {duration.toFixed(2)} 초
                    </span>
                    <input type="range" min={0} max={1} step="0.01" value={volume} onChange={handleVolumeChange} />
                    <span>볼륨: {(volume * 100).toFixed(0)}%</span>
                </div> */}
            </div>
        </div>
    );
};

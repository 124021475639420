import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface Schedule {
    calendarId: number;
    memberInfo: { memberId: number; memberName: string };
    therapistNames: string[][];
    registerSessionIds: number[];
    workBookNames: string[];
    sessionProgresses: number[];
    memo: string;
    reservationDate: string;
    startTime: string;
    endTime: string;
    originStartTime: string;
    originEndTime: string;
    timePickerModalState: string;
    reservationStatus: number;

    timePickerVisible: boolean;
    programPickerVisible: boolean;
    patientPickerVisible: boolean;
    scheduleMoreVisible: { day: string; bool: boolean };
}

const initialState: Schedule = {
    calendarId: 0,
    memberInfo: { memberId: 0, memberName: '' },
    therapistNames: [[]],
    registerSessionIds: [],
    workBookNames: [],
    sessionProgresses: [],
    memo: '',
    reservationDate: '',
    startTime: '',
    endTime: '',
    originStartTime: '',
    originEndTime: '',
    timePickerModalState: '',
    reservationStatus: -1,

    timePickerVisible: false,
    programPickerVisible: false,
    patientPickerVisible: false,
    scheduleMoreVisible: { day: '', bool: false },
};

export const scheduleModalSlice = createSlice({
    name: 'header',
    initialState,
    reducers: {
        updateTimePickerVisible: (state, action: PayloadAction<{ visible: boolean; type: string }>) => {
            // if (state.timePickerModalState === '') {
            //     state.timePickerModalState = 'left';
            // } else if (state.timePickerModalState === 'left') {
            //     state.timePickerModalState = 'right';
            // } else {
            //     state.timePickerModalState = 'left';
            // }
            state.timePickerModalState = action.payload.type;
            state.timePickerVisible = action.payload.visible;
        },
        updateProgramPickerVisible: (state, action: PayloadAction<boolean>) => {
            state.programPickerVisible = action.payload;
        },
        updatePatientPickerVisible: (state, action: PayloadAction<boolean>) => {
            state.patientPickerVisible = action.payload;
        },
        updateScheduleMoreVisible: (state, action: PayloadAction<{ day: string; bool: boolean }>) => {
            state.scheduleMoreVisible = action.payload;
        },
        updateReservationDate: (state, action: PayloadAction<string>) => {
            state.reservationDate = action.payload;
        },
        updateReserveTime: (
            state,
            action: PayloadAction<{
                startTime: string;
                originStartTime: string;
                endTime: string;
                originEndTime: string;
            }>
        ) => {
            const { startTime, originStartTime, endTime, originEndTime } = action.payload;
            if (startTime === '') {
                state.endTime = endTime;
                state.originEndTime = originEndTime;
            } else if (endTime === '') {
                state.startTime = startTime;
                state.originStartTime = originStartTime;
            } else {
                state.endTime = endTime;
                state.originEndTime = originEndTime;
                state.startTime = startTime;
                state.originStartTime = originStartTime;
            }
            state.timePickerVisible = false;
        },
        updateSelectProgarm: (
            state,
            action: PayloadAction<{
                registerSessionIds: number[];
                workBookNames: string[];
                therapistNames: string[][];
                sessionProgresses: number[];
                calendarId: number;
            }>
        ) => {
            const { registerSessionIds, workBookNames, therapistNames, sessionProgresses, calendarId } = action.payload;

            state.registerSessionIds = registerSessionIds;
            state.workBookNames = workBookNames;
            state.therapistNames = therapistNames;
            state.sessionProgresses = sessionProgresses;
            state.calendarId = calendarId;
        },
        updateSelectPatient: (state, action: PayloadAction<{ memberId: number; memberName: string }>) => {
            const { memberId, memberName } = action.payload;
            state.memberInfo.memberId = memberId;
            state.memberInfo.memberName = memberName;
            state.patientPickerVisible = false;
        },
        updateMemo: (state, action: PayloadAction<string>) => {
            state.memo = action.payload;
        },
        updateReservationStatus: (state, action: PayloadAction<number>) => {
            state.reservationStatus = action.payload;
        },
        resetScheduleData: () => initialState,
    },
});

export const {
    updateTimePickerVisible,
    updateReservationDate,
    updateReserveTime,
    updateProgramPickerVisible,
    updateSelectProgarm,
    updatePatientPickerVisible,
    updateSelectPatient,
    updateScheduleMoreVisible,
    updateMemo,
    updateReservationStatus,
    resetScheduleData,
} = scheduleModalSlice.actions;
export default scheduleModalSlice.reducer;

import styles from './css/policy.module.css';

export const SensitivenInfoConsent = () => {
    const Title = ({ text }: { text: string }) => {
        return <span className="text_22_NotoSansKR_Bold">{text}</span>;
    };

    const Content = ({ text }: { text: string }) => {
        return <span className="text_16_NotoSansKR_Medium">{text}</span>;
    };

    return (
        <div className={styles.main_container}>
            <div className={styles.content_container}>
                <span className="text_32_NotoSansKR_Bold">민감정보 처리 동의서</span>
                <div className={styles.content}>
                    <Content
                        text="
                    와이디 퍼포먼스(이하 '회사'라고 합니다)는 개인정보보호법 등 관련 법령상의 개인정보보호 규정을 준수하며 회원의 개인정보보호에 최선을 다하고 있습니다. 회사는 개인정보보호법 제23조에 근거하여, 다음과 같이 민감정보 처리에 관한 동의를 받고자 합니다."
                    />
                    {'\n'}
                    <Content text="다음의 내용을 자세히 읽어보신 후 동의 여부를 결정하여 주십시오." />
                </div>
                <div className={styles.content}>
                    <Title text="1. 처리하는 민감정보" />
                    <Content text="1. 검사 및 진단 결과 등 개인상담정보에 관한 자료" />
                </div>
                <div className={styles.content}>
                    <Title text="2. 민감정보의 처리목적" />
                    <Content text="1. 개인상담정보 확인 목적" />
                </div>
                <div className={styles.content}>
                    <Title text="3. 민감정보의 보유기간" />
                    <Content text="1. 민감정보 처리 목적을 달성한 날짜" />
                </div>
                <div className={styles.content}>
                    <Title text="4. 동의 거부 시 불이익 안내" />
                    <Content text="1. 회원께서는 민감정보의 처리에 대한 동의를 거부할 권리가 있습니다. 다만,동의를 거부할 경우 서비스 이용제한등의 불이익이 있을 수 있습니다." />
                </div>
                <div
                    className={styles.content}
                    style={{
                        gap: 0,
                    }}
                >
                    <Content text="※ 민감정보 처리의 위탁" />
                    <ul>
                        <Content text="1. 민감정보 처리를 위탁 받는 자 : (주) 제이엔제이테크 " />
                        <ol>
                            <Content text="a. 민감정보 처리 위탁 목적: 서버 및 프로그램 운영 및 개발" />
                        </ol>
                        <ol>
                            <Content text="b. 민감정보 처리 위탁 기간: 상담 기간 중 보유,종료 시 삭제" />
                        </ol>
                    </ul>
                </div>
                <div className={styles.content}>
                    <Content
                        text="본인은 본 동의서의 내용을 모두 이해하였으며, 민감정보의 처리에 관한 회원 권리 안내문에 관하여 자세한 설명을 듣고 내용에 동의합니다. 
 그 외 사항은 회사의 개인정보처리방침에 따릅니다."
                    />
                </div>
                <div className={styles.content}>
                    <span className="text_16_NotoSansKR_Bold">2024년 10월 03일</span>
                </div>
            </div>
        </div>
    );
};

import styles from '../css/FirstSession.module.css';
import '../../session/css/Feedback.css';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import Img_goal from '../../../../../assets/face/session1/session1_goal.png';
import PopUpImg from '../../../../../assets/face/session1/session1_test.png';
import { ReactComponent as Icon_x } from '../../../../../assets/public/icon_x.svg';

import ReactPlayer from 'react-player';
import { getMemberInfo } from '../../../../../utils/GetMemberInfo';
import {
    changeFeedbackState,
    updateBrain,
    updateDesiredSymptomChange,
    updateMyStrengths,
    updatePsychologicalBarriers,
    updateSessionField,
    updateTherapyGoals,
} from '../../../../../features/session/sessionReducer';
import { WriteFeedbackTextarea } from './FeedbackComponent';
import { saveSessionFeedback } from '../ts/SessionApi';
import { MouseEventHandler } from 'react';

interface CurrentSession {
    currentSession: string;
}

/* 대면 환자 뇌 인풋박스 */
export const ThoughtFeelInput = () => {
    const dispatch = useDispatch();
    const { fieldIds, fields } = useSelector((state: RootState) => state.session);
    const memberInfo = getMemberInfo();
    const readOnly = memberInfo?.memberRole !== 'user' && true;
    const onChageBrain = (index: number, patient: string) => {
        dispatch(
            updateBrain({
                index: index,
                patient: patient,
                feedback: fields.brain[index].feedback,
                visible: fields.brain[index].visible,
                feedbackCheck: fields.brain[index].feedbackCheck,
            })
        );
    };
    return (
        <div className={styles.patient_brain_container}>
            {fields.brain.map((item, index) => (
                <div
                    key={index.toString()}
                    className={`${styles.thought_feel_input_container} ${
                        styles[`thought_feel_input_container${index}`] || ''
                    }`}
                >
                    <textarea
                        className={`${styles.thought_feel_input} text_18_NotoSansKR_Regular`}
                        value={item.patient}
                        maxLength={30}
                        onChange={(e) => onChageBrain(index, e.target.value)}
                        readOnly={readOnly}
                    />
                </div>
            ))}
        </div>
    );
};

/* 대면 환자 뇌 인풋박스 */
export const TherapistThoughtFeelInput = ({ currentSession }: { currentSession: string }) => {
    const memberInfo = getMemberInfo();
    const readOnly = memberInfo?.memberRole !== 'user' && true;
    const dispatch = useDispatch();
    const { fieldIds, fields } = useSelector((state: RootState) => state.session);

    const onChangeSessionText = (index: number, text: string) => {
        switch (currentSession) {
            case 'firstSession':
                break;
            case 'tenthSession':
                break;
        }
    };

    return (
        <div className={styles.therapist_brain_container}>
            {fields.brain.map((item, index) => (
                <div
                    key={index.toString()}
                    className={`${styles.thought_feel_input_container} ${
                        styles[`thought_feel_input_container${index}`] || ''
                    }`}
                >
                    <textarea
                        className={`${styles.thought_feel_input} text_18_NotoSansKR_Regular`}
                        value={item.patient}
                        maxLength={30}
                        onChange={(e) => onChangeSessionText(index, e.target.value)}
                        readOnly={readOnly}
                    />
                </div>
            ))}
        </div>
    );
};

export const DesiredSymptomsAndReasons = ({ currentSession }: CurrentSession) => {
    const dispatch = useDispatch();
    const { fieldIds, fields } = useSelector((state: RootState) => state.session);
    const memberInfo = getMemberInfo();
    const readOnly = memberInfo?.memberRole !== 'user' && true;

    const openFeedback = (index: number, type: string) => {
        if (memberInfo?.memberRole === 'therapist') {
            switch (type) {
                case 'desiredSymptomChange':
                    const desired = fields.desiredSymptomChange[0];
                    dispatch(
                        updateDesiredSymptomChange({
                            patient: desired.feedback,
                            feedback: desired.feedback,
                            visible: !desired.visible,
                            feedbackCheck: desired.feedbackCheck,
                        })
                    );
                    break;

                case 'psychologicalBarriers':
                    const psychological = fields.psychologicalBarriers[index];
                    dispatch(
                        updatePsychologicalBarriers({
                            index: index,
                            patient: psychological.patient,
                            feedback: psychological.feedback,
                            visible: !psychological.visible,
                            feedbackCheck: psychological.feedbackCheck,
                        })
                    );
                    break;
            }
        }
    };

    const onChangeDesiredSymptomChange = (text: string, type: string) => {
        const result = fields.desiredSymptomChange[0];
        switch (memberInfo?.memberRole) {
            case 'therapist':
                dispatch(
                    updateDesiredSymptomChange({
                        patient: result.patient,
                        feedback: text,
                        visible: result.visible,
                        feedbackCheck: result.feedbackCheck,
                    })
                );
                break;
            default:
                dispatch(
                    updateDesiredSymptomChange({
                        patient: text,
                        feedback: result.feedback,
                        visible: result.visible,
                        feedbackCheck: result.feedbackCheck,
                    })
                );
                break;
        }
    };

    const onChangePsychologicalBarriers = (index: number, text: string) => {
        const result = fields.psychologicalBarriers[index];
        switch (memberInfo?.memberRole) {
            case 'therapist':
                dispatch(
                    updatePsychologicalBarriers({
                        index: index,
                        patient: result.patient,
                        feedback: text,
                        visible: result.visible,
                        feedbackCheck: result.feedbackCheck,
                    })
                );
                break;
            default:
                dispatch(
                    updatePsychologicalBarriers({
                        index: index,
                        patient: text,
                        feedback: result.feedback,
                        visible: result.visible,
                        feedbackCheck: result.feedbackCheck,
                    })
                );
                break;
        }
    };

    const saveFeedback = (fieldId: number, field: any, key: string, index: number) => {
        if (field[index].feedbackCheck) {
            dispatch(changeFeedbackState({ index: index, key: key, value: field }));
        } else {
            const extractDataByKey = () => {
                return {
                    [key]: field,
                };
            };
            saveSessionFeedback(fieldId, extractDataByKey()).then((response) => {
                if (response.result) {
                    dispatch(updateSessionField({ index: index, key: key, value: field }));
                }
            });
        }
    };

    const cancel = (index: number, type: string) => {
        if (memberInfo?.memberRole === 'therapist') {
            openFeedback(index, type);
        }
    };

    return (
        <div className={styles.symptom_barrier}>
            <div className={styles.desired_symptom_container}>
                <div
                    className="text_22_NotoSansKR_Bold"
                    style={{
                        color: '#626466',
                    }}
                >
                    1. 인지행동치료 후에 가장 변화하고 싶은 증상과 그 이유를 적어주세요.
                </div>
                <textarea
                    onDoubleClick={() => openFeedback(0, 'desiredSymptomChange')}
                    className={`${styles.desired_symptom_input} text_18_NotoSansKR_Regular`}
                    value={fields.desiredSymptomChange[0].patient}
                    onChange={(e) => onChangeDesiredSymptomChange(e.target.value, 'patient')}
                    readOnly={readOnly}
                />
                {fields.desiredSymptomChange[0].visible && readOnly && (
                    <WriteFeedbackTextarea
                        onChange={(text: string) => onChangeDesiredSymptomChange(text, 'therapist')}
                        save={() => saveFeedback(15, fields.desiredSymptomChange, 'desiredSymptomChange', 0)}
                        cancel={() => cancel(0, 'desiredSymptomChange')}
                        height="19.06rem"
                        edit={fields.desiredSymptomChange[0].feedbackCheck}
                        value={fields.desiredSymptomChange[0].feedback}
                    />
                )}
            </div>
            <div className={styles.desired_symptom_container}>
                <div
                    className="text_22_NotoSansKR_Bold"
                    style={{
                        color: '#626466',
                    }}
                >
                    2. 치료에 방해되는 심리적 장애물이 있다면?
                </div>
                <div
                    style={{
                        padding: '0 8.12rem',
                    }}
                >
                    <img alt="goal" src={Img_goal} className={styles.img} />
                </div>
                <div className={styles.barrier_input_container}>
                    {fields.psychologicalBarriers.map((item, index) => (
                        <div key={index.toString()} className={styles.barrier_input_box}>
                            <textarea
                                key={index.toString()}
                                onDoubleClick={() => openFeedback(index, 'psychologicalBarriers')}
                                className={`${styles.barrier_input} text_18_NotoSansKR_Regular`}
                                value={item.patient}
                                onChange={(e) => onChangePsychologicalBarriers(index, e.target.value)}
                                readOnly={readOnly}
                            />
                            {item.visible && readOnly && (
                                <WriteFeedbackTextarea
                                    onChange={(text: string) => onChangePsychologicalBarriers(index, text)}
                                    save={() =>
                                        saveFeedback(15, fields.psychologicalBarriers, 'psychologicalBarriers', index)
                                    }
                                    cancel={() => cancel(index, 'psychologicalBarriers')}
                                    height="19.06rem"
                                    edit={item.feedbackCheck}
                                    value={item.feedback}
                                />
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export const AdvantagesAndGoals = () => {
    const dispatch = useDispatch();
    const { fieldIds, fields } = useSelector((state: RootState) => state.session);
    const memberInfo = getMemberInfo();
    const readOnly = memberInfo?.memberRole !== 'user' && true;

    const openFeedback = (index: number, type: string) => {
        if (memberInfo?.memberRole === 'therapist') {
            switch (type) {
                case 'myStrengths':
                    const strengths = fields.myStrengths[0];
                    dispatch(
                        updateMyStrengths({
                            patient: strengths.feedback,
                            feedback: strengths.feedback,
                            visible: !strengths.visible,
                            feedbackCheck: strengths.feedbackCheck,
                        })
                    );
                    break;

                case 'therapyGoals':
                    const goals = fields.therapyGoals[index];
                    dispatch(
                        updateTherapyGoals({
                            index: index,
                            patient: goals.patient,
                            feedback: goals.feedback,
                            visible: !goals.visible,
                            feedbackCheck: goals.feedbackCheck,
                        })
                    );
                    break;
            }
        }
    };

    const onChangeMyStrengths = (text: string, type: string) => {
        const result = fields.myStrengths[0];
        switch (memberInfo?.memberRole) {
            case 'therapist':
                dispatch(
                    updateMyStrengths({
                        patient: result.patient,
                        feedback: text,
                        visible: result.visible,
                        feedbackCheck: result.feedbackCheck,
                    })
                );
                break;
            default:
                dispatch(
                    updateMyStrengths({
                        patient: text,
                        feedback: result.feedback,
                        visible: result.visible,
                        feedbackCheck: result.feedbackCheck,
                    })
                );
                break;
        }
    };

    const onChangeTherapyGoals = (index: number, text: string) => {
        const result = fields.therapyGoals[index];

        switch (memberInfo?.memberRole) {
            case 'therapist':
                dispatch(
                    updateTherapyGoals({
                        index: index,
                        patient: result.patient,
                        feedback: text,
                        visible: result.visible,
                        feedbackCheck: result.feedbackCheck,
                    })
                );
                break;
            default:
                dispatch(
                    updateTherapyGoals({
                        index: index,
                        patient: text,
                        feedback: result.feedback,
                        visible: result.visible,
                        feedbackCheck: result.feedbackCheck,
                    })
                );
                break;
        }
    };

    const saveFeedback = (fieldId: number, field: any, key: string, index: number) => {
        if (field[index].feedbackCheck) {
            dispatch(changeFeedbackState({ index: index, key: key, value: field }));
        } else {
            const extractDataByKey = () => {
                return {
                    [key]: field,
                };
            };
            saveSessionFeedback(fieldId, extractDataByKey()).then((response) => {
                if (response.result) {
                    dispatch(updateSessionField({ index: index, key: key, value: field }));
                }
            });
        }
    };

    const cancel = (index: number, type: string) => {
        if (memberInfo?.memberRole === 'therapist') {
            openFeedback(index, type);
        }
    };

    const Step = ({ step }: { step: number }) => {
        return (
            <div
                className="text_24_NotoSansKR_Medium"
                style={{
                    color: '#626466',
                }}
            >
                <span
                    className="text_24_Inter_Medium"
                    style={{
                        color: '#626466',
                    }}
                >
                    {step}
                </span>
                단계
            </div>
        );
    };

    return (
        <div className={styles.advantage_goal}>
            <div className={styles.desired_symptom_container}>
                <div
                    className="text_22_NotoSansKR_Bold"
                    style={{
                        color: '#626466',
                    }}
                >
                    3. 나의 장점은?
                </div>
                <textarea
                    className={`${styles.advantage_input} text_18_NotoSansKR_Regular`}
                    value={fields.myStrengths[0].patient}
                    onDoubleClick={() => openFeedback(0, 'myStrengths')}
                    onChange={(e) => onChangeMyStrengths(e.target.value, '')}
                    readOnly={readOnly}
                />
                {fields.myStrengths[0].visible && readOnly && (
                    <WriteFeedbackTextarea
                        onChange={(text: string) => onChangeMyStrengths(text, 'therapist')}
                        save={() => saveFeedback(fieldIds.myStrengths, fields.myStrengths, 'myStrengths', 0)}
                        cancel={() => cancel(0, 'myStrengths')}
                        height="19.06rem"
                        edit={fields.myStrengths[0].feedbackCheck}
                        value={fields.myStrengths[0].feedback}
                    />
                )}
            </div>
            <div className={styles.desired_symptom_container}>
                <div
                    className="text_22_NotoSansKR_Bold"
                    style={{
                        color: '#626466',
                    }}
                >
                    4. 치료를 위한 단계별 목표 정하기
                </div>
                <div className={styles.advantage_goal_content}>
                    {fields.therapyGoals.map((item, index) => (
                        <div
                            key={index.toString()}
                            className={`${styles.step_container} ${
                                index === 0 ? styles.padding_one : index === 1 ? styles.padding_two : ''
                            }`}
                        >
                            <Step step={index + 1} />
                            <div
                                className={`${
                                    index === 0 ? styles.step_one : index === 1 ? styles.step_two : styles.step_three
                                }`}
                            ></div>
                            <textarea
                                className={`${styles.goal_input} text_18_NotoSansKR_Regular`}
                                value={item.patient}
                                onDoubleClick={() => openFeedback(index, 'therapyGoals')}
                                onChange={(e) => onChangeTherapyGoals(index, e.target.value)}
                                readOnly={readOnly}
                            />
                            {item.visible && readOnly && (
                                <WriteFeedbackTextarea
                                    onChange={(text: string) => onChangeTherapyGoals(index, text)}
                                    save={() => saveFeedback(fieldIds.therapyGoals, item, 'therapyGoals', index)}
                                    cancel={() => cancel(index, 'therapyGoals')}
                                    height="19.06rem"
                                    edit={item.feedbackCheck}
                                    value={item.feedback}
                                />
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export const VideoPopup = ({
    showPopup,
    playerPopUpRef,
    currentTime,
    updatePopUpVisible,
    popupStyle,
    onMouseDown,
    onResizeMouseDown,
}: {
    showPopup: boolean;
    playerPopUpRef: any;
    onProgress: any;
    currentTime: number;
    updatePopUpVisible: Function;
    popupStyle: { top: number; right: number; width: number; height: number };
    onMouseDown: MouseEventHandler<HTMLDivElement>;
    onResizeMouseDown: MouseEventHandler<HTMLDivElement>;
}) => {
    let test = false;
    return (
        <div
            style={{
                display: 'flex',
                position: 'fixed',
                top: `${popupStyle.top}rem`,
                right: `${popupStyle.right}rem`,
                width: `${popupStyle.width}rem`,
                height: `${popupStyle.height}rem`,
                zIndex: 1000,
                cursor: 'move',
            }}
            onMouseDown={onMouseDown}
        >
            <div className={styles.video_container}>
                <ReactPlayer
                    ref={playerPopUpRef}
                    url={'https://exqibabapqbf17923502.cdn.ntruss.com/ydTest/CBT10.mp4'} // 동일한 동영상 URL
                    height="100%" // 비디오 높이
                    width="auto"
                    playing={showPopup}
                    // onProgress={onProgress}
                    progressInterval={100} // 100ms마다 업데이트
                    controls={true}
                    playbackRate={1} // 재생 속도 설정
                    style={{
                        borderRadius: '1.25rem',
                    }}
                    onReady={(player) => {
                        if (!test) {
                            player.seekTo(currentTime); // 원래 비디오의 재생 시간으로 설정
                        }
                        test = true;
                    }}
                />
                <button
                    className={styles.close_button}
                    onClick={() => {
                        updatePopUpVisible(false);
                    }}
                >
                    <Icon_x className={styles.icon_x} />
                </button>
            </div>
            <div className={styles.video_resize_box} onMouseDown={onResizeMouseDown} />
        </div>
    );
};

/* 비대면 */
export const DesiredSymptomsAdvantageGoal = () => {
    const memberInfo = getMemberInfo();
    const readOnly = memberInfo?.memberRole !== 'user' && true;
    return (
        <div className={styles.symptom_barrier}>
            <div className={styles.desired_symptom_container}>
                <div
                    className="text_22_NotoSansKR_Bold"
                    style={{
                        color: '#626466',
                    }}
                >
                    1. 인지행동치료 후에 가장 변화하고 싶은 증상과 그 이유를 적어주세요.
                </div>
                <textarea
                    className={`${styles.desired_symptom_input} text_18_NotoSansKR_Regular`}
                    readOnly={readOnly}
                />
            </div>
            <div className={styles.desired_symptom_container}>
                <div
                    className="text_22_NotoSansKR_Bold"
                    style={{
                        color: '#626466',
                    }}
                >
                    2. 변화를 막는 심리적 장애물이 있다면?
                </div>
                <img alt="goal" src={Img_goal} className={styles.img} />
                <textarea
                    className={`${styles.desired_symptom_input} text_18_NotoSansKR_Regular`}
                    readOnly={readOnly}
                />
            </div>
            <div className={styles.desired_symptom_container}>
                <div
                    className="text_22_NotoSansKR_Bold"
                    style={{
                        color: '#626466',
                    }}
                >
                    3. 나의 장점은?
                </div>
                <textarea className={`${styles.desired_symptom_input} text_18_NotoSansKR_Regular`} readOnly />
            </div>
            <div className={styles.desired_symptom_container}>
                <div
                    className="text_22_NotoSansKR_Bold"
                    style={{
                        color: '#626466',
                    }}
                >
                    4. 구체적인 나의 목표는?
                </div>
                <textarea
                    className={`${styles.desired_symptom_input} text_18_NotoSansKR_Regular`}
                    readOnly={readOnly}
                />
            </div>
        </div>
    );
};

import { MouseEventHandler, useEffect, useState } from 'react';
import styles from '../css/PublicSession.module.css';
import { ReactComponent as Icon_circle_3 } from '../../../../../assets/face/session3/icon_circle_3.svg';
import { ReactComponent as Icon_circle_4 } from '../../../../../assets/face/session3/icon_circle_4.svg';
import { ReactComponent as Icon_plus } from '../../../../../assets/face/session3/icon_plus.svg';
import { ReactComponent as Icon_same } from '../../../../../assets/face/session3/icon_same.svg';

import { ReactComponent as Icon_emotion_bubble } from '../../../../../assets/face/session2/icon_emotion_bubble.svg';
import { ReactComponent as Icon_automatic_accident_bubble } from '../../../../../assets/face/session3/icon_automatic_accident_bubble.svg';
import { ReactComponent as Icon_human } from '../../../../../assets/face/session3/icon_human.svg';
import { ReactComponent as Icon_image } from '../../../../../assets/face/session3/icon_image.svg';
import { ReactComponent as Icon_pencil } from '../../../../../assets/face/session8/icon_pencil.svg';
import { ReactComponent as Icon_change_automatic_accident_bubble } from '../../../../../assets/face/session8/icon_change_automatic_accident_bubble.svg';

import { ReactComponent as Icon_horror } from '../../../../../assets/face/session2/icon_horror.svg';
import { ReactComponent as Icon_anger } from '../../../../../assets/face/session2/icon_anger.svg';
import { ReactComponent as Icon_anxiety } from '../../../../../assets/face/session2/icon_anxiety.svg';
import { ReactComponent as Icon_disappointment } from '../../../../../assets/face/session2/icon_disappointment.svg';
import { ReactComponent as Icon_frustration } from '../../../../../assets/face/session2/icon_frustration.svg';
import { ReactComponent as Icon_depression } from '../../../../../assets/face/session2/icon_depression.svg';
import { ReactComponent as Icon_irritability } from '../../../../../assets/face/session2/icon_irritability.svg';
import { ReactComponent as Icon_etc } from '../../../../../assets/face/session2/icon_etc.svg';

import { ReactComponent as Icon_circle_checkbox } from '../../../../../assets/face/session4/icon_circle_checkbox.svg';
import { ReactComponent as Icon_circle_checkedBox } from '../../../../../assets/payment/icon_circle_checkedBox.svg';

import Realict_results from '../../../../../assets/face/session8/session8_realict_results.png';
import Letter from '../../../../../assets/face/session8/session8_letter.png';
import Postit from '../../../../../assets/face/session5/session5_postit.png';
import AutomaticGraph from '../../../../../assets/face/session6/session6_automatic_accident.png';
import Img_quiz_1 from '../../../../../assets/face/session4/session4_quiz_1.png';
import Img_quiz_2 from '../../../../../assets/face/session4/session4_quiz_2.png';
import Img_quiz_3 from '../../../../../assets/face/session4/session4_quiz_3.png';
import Img_quiz_4 from '../../../../../assets/face/session4/session4_quiz_4.png';

import { SpanText } from '../../../../../component/PublicComponent';
import { getMemberInfo } from '../../../../../utils/GetMemberInfo';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import { WriteFeedbackTextarea } from './FeedbackComponent';
import {
    changeFeedbackState,
    deleteFeedback,
    updateAdvantagesOfTrustingAutoThought,
    updateAuthmaticThinkingGraph,
    updateBestOutcome,
    updateCognitiveError,
    updateEmotionIcon,
    updateEmotionScore,
    updateEmotionsAfterChange,
    updateEvidenceCorrectThinking,
    updateEvidenceIncorrectThinking,
    updateLessonsLearned,
    updateLetter,
    updateQuiz,
    updateRationalThinkingAutoCheck,
    updateRealisticOutcome,
    updateScoreAfterChange,
    updateSessionField,
    updateSituation,
    updateTrustInThoughts,
    updateWorstOutcome,
    uppdateSocraticAutoThinking,
} from '../../../../../features/session/sessionReducer';
import {
    updateImage,
    updateSelfPerception,
    updateSituationThoughts,
    updateThought,
    updateWorstCaseScenario,
} from '../../../../../features/session/sessionReducer';
import { useLocation } from 'react-router-dom';
import { deleteSessionFeedback, saveSessionFeedback } from '../ts/SessionApi';

interface CurrentSession {
    currentSession: string;
}

/* 자동사고 기록지 */
/*  session2, session3, session4, session5, session6, session7, session8, session9   */
export const SituationEmotion = ({ currentSession }: CurrentSession) => {
    const dispatch = useDispatch();
    const { fieldIds, fields } = useSelector((state: RootState) => state.session);
    const memberInfo = getMemberInfo();
    const readOnly = memberInfo?.memberRole !== 'user' && true;

    const emotionLis = [
        { Icon: Icon_horror, name: '공포' },
        { Icon: Icon_anger, name: '분노' },
        { Icon: Icon_anxiety, name: '불안' },
        { Icon: Icon_disappointment, name: '실망' },
        { Icon: Icon_frustration, name: '좌절' },
        { Icon: Icon_depression, name: '우울' },
        { Icon: Icon_irritability, name: '짜증' },
        { Icon: Icon_etc, name: '기타' },
    ];

    const emotionRatio = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

    const openFeedback = (index: number) => {
        if (memberInfo?.memberRole === 'therapist') {
            const situ = fields.situation[index];
            dispatch(
                updateSituation({
                    index: index,
                    patient: situ.patient,
                    feedback: situ.feedback,
                    visible: !situ.visible,
                    feedbackCheck: situ.feedbackCheck,
                })
            );
        }
    };

    const onChangeSituation = (index: number, text: string) => {
        const result = fields.situation[index];
        switch (memberInfo?.memberRole) {
            case 'therapist':
                dispatch(
                    updateSituation({
                        index: index,
                        patient: result.patient,
                        feedback: text,
                        visible: result.visible,
                        feedbackCheck: result.feedbackCheck,
                    })
                );
                break;
            default:
                dispatch(
                    updateSituation({
                        index: index,
                        patient: text,
                        feedback: result.feedback,
                        visible: result.visible,
                        feedbackCheck: result.feedbackCheck,
                    })
                );
                break;
        }
    };

    const onChangeEmotionIcon = (index: number, text: string) => {
        const result = fields.emotionIcon[0];

        dispatch(
            updateEmotionIcon({
                patient: text,
                feedback: result.feedback,
                visible: result.visible,
                feedbackCheck: result.feedbackCheck,
            })
        );
    };

    const onChangeEmotionScore = (index: number, text: string) => {
        const result = fields.emotionScore[0];

        dispatch(
            updateEmotionScore({
                patient: text,
                feedback: result.feedback,
                visible: result.visible,
                feedbackCheck: result.feedbackCheck,
            })
        );
    };

    const saveFeedback = (fieldId: number, field: any, key: string, index: number) => {
        if (field[index].feedback !== '') {
            if (field[index].feedbackCheck) {
                dispatch(changeFeedbackState({ index: index, key: key, value: field }));
            } else {
                const extractDataByKey = () => {
                    return {
                        [key]: field,
                    };
                };
                saveSessionFeedback(fieldId, extractDataByKey()).then((response) => {
                    if (response.result) {
                        dispatch(updateSessionField({ index: index, key: key, value: field }));
                    }
                });
            }
        }
    };

    const cancel = (fieldId: number, field: any, key: string, index: number) => {
        if (memberInfo?.memberRole === 'therapist') {
            if (field[index].feedbackCheck) {
                deleteSessionFeedback(fieldId, index).then((response) => {
                    if (response.resulst) {
                        dispatch(deleteFeedback({ index: index, key: key, value: field }));
                    }
                });
            }
            openFeedback(index);
        }
    };

    return (
        <div className={styles.situation_emotion_container}>
            <div
                className="text_32_NotoSansKR_Bold"
                style={{
                    color: '#41465A',
                }}
            >
                자동사고 기록지
            </div>
            <div className={styles.situation_container}>
                <Icon_emotion_bubble className={styles.icon_emotion_bubble} />
                <div className={`${styles.situation_emotion} text_28_NotoSansKR_Bold`}>상황</div>
                <div className={styles.situation_content_container}>
                    <div className={styles.situation_input_contaienr}>
                        <div className={`${styles.situation_content} text_22_NotoSansKR_Bold`}>언제:</div>
                        <div className={styles.situation_input_box}>
                            <input
                                className={`${styles.situation_input} text_18_NotoSansKR_Regular`}
                                readOnly={readOnly}
                                onChange={(e) => onChangeSituation(0, e.target.value)}
                                onDoubleClick={() => openFeedback(0)}
                                value={fields.situation[0].patient}
                            />
                            {fields.situation[0].visible && readOnly && (
                                <WriteFeedbackTextarea
                                    onChange={(text: string) => onChangeSituation(0, text)}
                                    save={() => saveFeedback(fieldIds.situation, fields.situation, 'situation', 0)}
                                    cancel={() => cancel(fieldIds.situation, fields.situation, 'situation', 0)}
                                    height="19.06rem"
                                    edit={fields.situation[0].feedbackCheck}
                                    value={fields.situation[0].feedback}
                                />
                            )}
                        </div>
                    </div>
                    <div className={styles.situation_input_contaienr}>
                        <div className={`${styles.situation_content} text_22_NotoSansKR_Bold`}>어디서:</div>
                        <div className={styles.situation_input_box}>
                            <input
                                className={`${styles.situation_input} text_18_NotoSansKR_Regular`}
                                readOnly={readOnly}
                                onChange={(e) => onChangeSituation(1, e.target.value)}
                                onDoubleClick={() => openFeedback(1)}
                                value={fields.situation[1].patient}
                            />
                            {fields.situation[1].visible && readOnly && (
                                <WriteFeedbackTextarea
                                    onChange={(text: string) => onChangeSituation(1, text)}
                                    save={() => saveFeedback(fieldIds.situation, fields.situation, 'situation', 1)}
                                    cancel={() => cancel(fieldIds.situation, fields.situation, 'situation', 1)}
                                    height="19.06rem"
                                    edit={fields.situation[1].feedbackCheck}
                                    value={fields.situation[1].feedback}
                                />
                            )}
                        </div>
                    </div>
                    <div className={styles.situation_input_contaienr}>
                        <div className={`${styles.situation_content} text_22_NotoSansKR_Bold`}>무엇을 어떻게:</div>
                        <div className={styles.situation_input_box}>
                            <input
                                className={`${styles.situation_input} text_18_NotoSansKR_Regular`}
                                readOnly={readOnly}
                                onChange={(e) => onChangeSituation(2, e.target.value)}
                                onDoubleClick={() => openFeedback(2)}
                                value={fields.situation[2].patient}
                            />
                            {fields.situation[2].visible && readOnly && (
                                <WriteFeedbackTextarea
                                    onChange={(text: string) => onChangeSituation(2, text)}
                                    save={() => saveFeedback(fieldIds.situation, fields.situation, 'situation', 2)}
                                    cancel={() => cancel(fieldIds.situation, fields.situation, 'situation', 2)}
                                    height="19.06rem"
                                    edit={fields.situation[2].feedbackCheck}
                                    value={fields.situation[2].feedback}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.emotion_container}>
                <div className={`${styles.situation_emotion} text_28_NotoSansKR_Bold`}>감정</div>
                <div className={styles.emotion_content_container}>
                    <div className={styles.title_score_container}>
                        <span
                            className="text_22_NotoSansKR_Bold"
                            style={{
                                color: '#626466',
                            }}
                        >
                            가장 강렬했던 감정을 고르고 점수를 기록해 주세요.{' '}
                            <span className="text_22_Inter_Bold">(1~10점)</span>
                        </span>
                    </div>
                    <div className={styles.emotion_icon_container_box}>
                        {emotionLis.map((item, index) => (
                            <button
                                key={item.name.toString()}
                                onClick={() => {
                                    if (!readOnly) {
                                        if (fields.emotionIcon[0].patient === item.name) {
                                            onChangeEmotionIcon(0, '');
                                        } else {
                                            onChangeEmotionIcon(0, item.name);
                                        }
                                    }
                                }}
                                className={`${styles.emotion_icon_container} ${
                                    fields.emotionIcon[0].patient === item.name ? styles.activate : styles.deactivate
                                }`}
                            >
                                <div className={styles.icon_container}>
                                    <item.Icon
                                        style={{
                                            width: '100%', // 아이콘을 부모의 너비에 맞게
                                            height: 'auto', // 비율 유지
                                        }}
                                    />
                                </div>
                                <span
                                    className="text_22_NotoSansKR_Medium"
                                    style={{
                                        color: '#626466',
                                    }}
                                >
                                    {item.name}
                                </span>
                            </button>
                        ))}
                    </div>
                </div>
            </div>
            <div className={styles.select_score_radio_container}>
                <div
                    className="text_22_NotoSansKR_Bold"
                    style={{
                        color: '#626466',
                    }}
                >
                    선택한 감정의 정도를{' '}
                    <span
                        className="text_22_Inter_Bold"
                        style={{
                            color: '#626466',
                        }}
                    >
                        1~10
                    </span>
                    점 사이로 측정해 주세요.
                </div>
                <div className={styles.radio_item_container}>
                    {emotionRatio.map((item, index) => (
                        <button
                            key={item.toString()}
                            className={styles.radio_item}
                            onClick={() => onChangeEmotionScore(index, String(item))}
                        >
                            <CircleCheckBox bool={fields.emotionScore[0].patient === String(item)} />
                            <div
                                className="text_24_Inter_Medium"
                                style={{
                                    color: '#9D9FA2',
                                }}
                            >
                                {item}
                            </div>
                        </button>
                    ))}
                </div>
            </div>
        </div>
    );
};

/* 어떤 생각이 떠올랐나요? */
/*  session3, session4, session5, session6, session7, session8, session9   */
export const ThoughtSiuation = () => {
    const dispatch = useDispatch();
    const { fieldIds, fields } = useSelector((state: RootState) => state.session);
    const memberInfo = getMemberInfo();
    const readOnly = memberInfo?.memberRole !== 'user' && true;

    const openFeedback = (index: number, type: string) => {
        if (memberInfo?.memberRole === 'therapist') {
            switch (type) {
                case 'thought':
                    const thoughtData = fields.thought[0];
                    dispatch(
                        updateThought({
                            patient: thoughtData.patient,
                            feedback: thoughtData.feedback,
                            visible: !thoughtData.visible,
                            feedbackCheck: thoughtData.feedbackCheck,
                        })
                    );
                    break;
                case 'worstCaseScenario':
                    const worstCase = fields.worstCaseScenario[0];
                    dispatch(
                        updateWorstCaseScenario({
                            patient: worstCase.patient,
                            feedback: worstCase.feedback,
                            visible: !worstCase.visible,
                            feedbackCheck: worstCase.feedbackCheck,
                        })
                    );
                    break;
            }
        }
    };

    const onChangeThought = (index: number, text: string) => {
        const result = fields.thought[0];
        switch (memberInfo?.memberRole) {
            case 'therapist':
                dispatch(
                    updateThought({
                        patient: result.patient,
                        feedback: text,
                        visible: result.visible,
                        feedbackCheck: result.feedbackCheck,
                    })
                );
                break;
            default:
                dispatch(
                    updateThought({
                        patient: text,
                        feedback: result.feedback,
                        visible: result.visible,
                        feedbackCheck: result.feedbackCheck,
                    })
                );
                break;
        }
    };

    const onChangeWorstCaseScenario = (index: number, text: string) => {
        const result = fields.worstCaseScenario[0];
        switch (memberInfo?.memberRole) {
            case 'therapist':
                dispatch(
                    updateWorstCaseScenario({
                        patient: result.patient,
                        feedback: text,
                        visible: result.visible,
                        feedbackCheck: result.feedbackCheck,
                    })
                );
                break;
            default:
                dispatch(
                    updateWorstCaseScenario({
                        patient: text,
                        feedback: result.feedback,
                        visible: result.visible,
                        feedbackCheck: result.feedbackCheck,
                    })
                );
                break;
        }
    };

    const saveFeedback = (fieldId: number, field: any, key: string, index: number) => {
        if (field[index].feedbackCheck) {
            dispatch(changeFeedbackState({ index: index, key: key, value: field }));
        } else {
            const extractDataByKey = () => {
                return {
                    [key]: field,
                };
            };
            saveSessionFeedback(fieldId, extractDataByKey()).then((response) => {
                if (response.result) {
                    dispatch(updateSessionField({ index: index, key: key, value: field }));
                }
            });
        }
    };

    const cancel = (index: number, type: string) => {
        if (memberInfo?.memberRole === 'therapist') {
            openFeedback(index, type);
        }
    };

    return (
        <div className={styles.thought_situation}>
            <div className={styles.thought_situation_container}>
                <div
                    className="text_22_NotoSansKR_Bold"
                    style={{
                        color: '#626466',
                    }}
                >
                    <span
                        className="text_22_Inter_Bold"
                        style={{
                            color: '#626466',
                        }}
                    >
                        3.{' '}
                    </span>
                    어떤 생각이 떠올랐나요?(상황에 대한 생각)
                </div>
                <textarea
                    className={`${styles.thought_situation_input} text_18_NotoSansKR_Regular`}
                    readOnly={readOnly}
                    onDoubleClick={() => openFeedback(0, 'thought')}
                    onChange={(e) => onChangeThought(0, e.target.value)}
                    value={fields.thought[0].patient}
                />
                {fields.thought[0].visible && readOnly && (
                    <WriteFeedbackTextarea
                        onChange={(text: string) => onChangeThought(0, text)}
                        save={() => saveFeedback(fieldIds.thought, fields.thought, 'thought', 0)}
                        cancel={() => cancel(0, 'thought')}
                        height="19.06rem"
                        edit={fields.thought[0].feedbackCheck}
                        value={fields.thought[0].feedback}
                    />
                )}
            </div>
            <div className={styles.thought_situation_container}>
                <div
                    className="text_22_NotoSansKR_Bold"
                    style={{
                        color: '#626466',
                    }}
                >
                    <span
                        className="text_22_Inter_Bold"
                        style={{
                            color: '#626466',
                        }}
                    >
                        4.{' '}
                    </span>
                    최악의 경우, 어떤 일이 일어날 것이라는 생각이 들었나요? (생각으로 인한 결과)
                </div>
                <textarea
                    className={`${styles.thought_situation_input} text_18_NotoSansKR_Regular`}
                    readOnly={readOnly}
                    onDoubleClick={() => openFeedback(0, 'worstCaseScenario')}
                    onChange={(e) => onChangeWorstCaseScenario(0, e.target.value)}
                    value={fields.worstCaseScenario[0].patient}
                />
                {fields.worstCaseScenario[0].visible && readOnly && (
                    <WriteFeedbackTextarea
                        onChange={(text: string) => onChangeWorstCaseScenario(0, text)}
                        save={() =>
                            saveFeedback(fieldIds.worstCaseScenario, fields.worstCaseScenario, 'worstCaseScenario', 0)
                        }
                        cancel={() => cancel(0, 'worstCaseScenario')}
                        height="19.06rem"
                        edit={fields.worstCaseScenario[0].feedbackCheck}
                        value={fields.worstCaseScenario[0].feedback}
                    />
                )}
            </div>
        </div>
    );
};

/* 떠오른 이미지, 상황에 대한 생각 */
/*  session3, session4, session5, session6, session7, session8, session9   */
export const BottomFiveSeven = () => {
    const dispatch = useDispatch();
    const { fieldIds, fields } = useSelector((state: RootState) => state.session);
    const memberInfo = getMemberInfo();
    const readOnly = memberInfo?.memberRole !== 'user' && true;

    const openFeedback = (index: number, type: string) => {
        if (memberInfo?.memberRole === 'therapist') {
            switch (type) {
                case 'image':
                    dispatch(
                        updateImage({
                            patient: fields.image[0].patient,
                            feedback: fields.image[0].feedback,
                            visible: !fields.image[0].visible,
                            feedbackCheck: fields.image[0].feedbackCheck,
                        })
                    );
                    break;
                case 'selfPerception':
                    dispatch(
                        updateSelfPerception({
                            patient: fields.selfPerception[0].patient,
                            feedback: fields.selfPerception[0].feedback,
                            visible: !fields.selfPerception[0].visible,
                            feedbackCheck: fields.image[0].feedbackCheck,
                        })
                    );
                    break;
                case 'situationThoughts':
                    dispatch(
                        updateSituationThoughts({
                            patient: fields.situationThoughts[0].patient,
                            feedback: fields.situationThoughts[0].feedback,
                            visible: !fields.situationThoughts[0].visible,
                            feedbackCheck: fields.image[0].feedbackCheck,
                        })
                    );
                    break;
            }
        }
    };

    const onChangeImage = (index: number, text: string) => {
        const result = fields.image[0];
        switch (memberInfo?.memberRole) {
            case 'therapist':
                dispatch(
                    updateImage({
                        patient: result.patient,
                        feedback: text,
                        visible: result.visible,
                        feedbackCheck: fields.image[0].feedbackCheck,
                    })
                );
                break;
            default:
                dispatch(
                    updateImage({
                        patient: text,
                        feedback: result.feedback,
                        visible: result.visible,
                        feedbackCheck: fields.image[0].feedbackCheck,
                    })
                );
                break;
        }
    };

    const onChangeSelfPerception = (index: number, text: string) => {
        const result = fields.selfPerception[0];
        switch (memberInfo?.memberRole) {
            case 'therapist':
                dispatch(
                    updateSelfPerception({
                        patient: result.patient,
                        feedback: text,
                        visible: result.visible,
                        feedbackCheck: fields.image[0].feedbackCheck,
                    })
                );
                break;
            default:
                dispatch(
                    updateSelfPerception({
                        patient: text,
                        feedback: result.feedback,
                        visible: result.visible,
                        feedbackCheck: fields.image[0].feedbackCheck,
                    })
                );
                break;
        }
    };

    const onChangeSituationThoughts = (index: number, text: string) => {
        const result = fields.situationThoughts[0];
        switch (memberInfo?.memberRole) {
            case 'therapist':
                dispatch(
                    updateSituationThoughts({
                        patient: result.patient,
                        feedback: text,
                        visible: result.visible,
                        feedbackCheck: fields.image[0].feedbackCheck,
                    })
                );
                break;
            default:
                dispatch(
                    updateSituationThoughts({
                        patient: text,
                        feedback: result.feedback,
                        visible: result.visible,
                        feedbackCheck: fields.image[0].feedbackCheck,
                    })
                );
                break;
        }
    };

    const onChangeTrustlnThoughts = (index: number, text: string) => {
        const result = fields.trustInThoughts[0];
        switch (memberInfo?.memberRole) {
            case 'therapist':
                dispatch(
                    updateTrustInThoughts({
                        patient: result.patient,
                        feedback: text,
                        visible: result.visible,
                        feedbackCheck: fields.image[0].feedbackCheck,
                    })
                );
                break;
            default:
                dispatch(
                    updateTrustInThoughts({
                        patient: text,
                        feedback: result.feedback,
                        visible: result.visible,
                        feedbackCheck: fields.image[0].feedbackCheck,
                    })
                );
                break;
        }
    };

    const saveFeedback = (fieldId: number, field: any, key: string, index: number) => {
        if (field[index].feedbackCheck) {
            dispatch(changeFeedbackState({ index: index, key: key, value: field }));
        } else {
            const extractDataByKey = () => {
                return {
                    [key]: field,
                };
            };
            saveSessionFeedback(fieldId, extractDataByKey()).then((response) => {
                if (response.result) {
                    dispatch(updateSessionField({ index: index, key: key, value: field }));
                }
            });
        }
    };

    const cancel = (index: number, type: string) => {
        if (memberInfo?.memberRole === 'therapist') {
            openFeedback(index, type);
        }
    };

    return (
        <div className={styles.bottom_five_seven_container}>
            <div className={styles.img_to_mind}>
                <div className={styles.thought_situation_container}>
                    <div
                        className="text_22_NotoSansKR_Bold"
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            color: '#626466',
                        }}
                    >
                        <span
                            className="text_22_Inter_Bold"
                            style={{
                                color: '#626466',
                            }}
                        >
                            5.{' '}
                        </span>
                        그때 떠오른 이미지는?
                        <Icon_image className={styles.icon_image} />
                    </div>
                    <textarea
                        className={`${styles.thought_situation_input} text_18_NotoSansKR_Regular`}
                        readOnly={readOnly}
                        onDoubleClick={() => openFeedback(0, 'image')}
                        onChange={(e) => onChangeImage(0, e.target.value)}
                        value={fields.image[0].patient}
                    />
                    {fields.image[0].visible && readOnly && (
                        <WriteFeedbackTextarea
                            onChange={(text: string) => onChangeImage(0, text)}
                            save={() => saveFeedback(fieldIds.image, fields.image, 'image', 0)}
                            cancel={() => cancel(0, 'image')}
                            height="19.06rem"
                            edit={fields.image[0].feedbackCheck}
                            value={fields.image[0].feedback}
                        />
                    )}
                </div>
                <div className={styles.thought_situation_container}>
                    <div
                        className="text_22_NotoSansKR_Bold"
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            color: '#626466',
                        }}
                    >
                        <span
                            className="text_22_Inter_Bold"
                            style={{
                                color: '#626466',
                            }}
                        >
                            6.{' '}
                        </span>
                        내가 어떤 사람으로 보여질까?
                        <Icon_human className={styles.icon_human} />
                    </div>
                    <textarea
                        className={`${styles.thought_situation_input} text_18_NotoSansKR_Regular`}
                        readOnly={readOnly}
                        onDoubleClick={() => openFeedback(0, 'selfPerception')}
                        onChange={(e) => onChangeSelfPerception(0, e.target.value)}
                        value={fields.selfPerception[0].patient}
                    />
                    {fields.selfPerception[0].visible && readOnly && (
                        <WriteFeedbackTextarea
                            onChange={(text: string) => onChangeSelfPerception(0, text)}
                            save={() =>
                                saveFeedback(fieldIds.selfPerception, fields.selfPerception, 'selfPerception', 0)
                            }
                            cancel={() => cancel(0, 'selfPerception')}
                            height="19.06rem"
                            edit={fields.selfPerception[0].feedbackCheck}
                            value={fields.selfPerception[0].feedback}
                        />
                    )}
                </div>
            </div>
            <div
                style={{
                    position: 'relative',
                    display: 'flex',
                    alignItems: 'flex-start',
                    marginTop: '9.25rem',
                    gap: '3.25rem',
                }}
            >
                <div className={styles.circle_question_container}>
                    <div className={styles.circle_question}>
                        <Icon_circle_3 />
                        <div className={`${styles.circle_title} text_24_NotoSansKR_Bold`}>상황에 대한 생각</div>
                    </div>
                    <Icon_plus />
                    <div className={styles.circle_question}>
                        <Icon_circle_4 />
                        <div className={`${styles.circle_title} text_24_NotoSansKR_Bold`}>생각으로 인한 결과</div>
                    </div>
                </div>
                <div className={styles.icon_same_container}>
                    <Icon_same />
                </div>
                <div className={styles.circle_input_container}>
                    <textarea
                        className={`${styles.circle_input} text_18_NotoSansKR_Regular`}
                        readOnly={readOnly}
                        onDoubleClick={() => openFeedback(0, 'situationThoughts')}
                        onChange={(e) => onChangeSituationThoughts(0, e.target.value)}
                        value={fields.situationThoughts[0].patient}
                    />
                    {fields.situationThoughts[0].visible && readOnly && (
                        <WriteFeedbackTextarea
                            onChange={(text: string) => onChangeSituationThoughts(0, text)}
                            save={() =>
                                saveFeedback(
                                    fieldIds.situationThoughts,
                                    fields.situationThoughts,
                                    'situationThoughts',
                                    0
                                )
                            }
                            cancel={() => cancel(0, 'situationThoughts')}
                            height="19.06rem"
                            edit={fields.situationThoughts[0].feedbackCheck}
                            value={fields.situationThoughts[0].feedback}
                        />
                    )}
                </div>
                <Icon_automatic_accident_bubble className={styles.icon_automatic_accident_bubble} />
            </div>
            <div className={styles.degree_trust}>
                <div
                    className="text_22_NotoSansKR_Bold"
                    style={{
                        color: '#626466',
                    }}
                >
                    <span
                        className="text_22_Inter_Bold"
                        style={{
                            color: '#626466',
                        }}
                    >
                        7.{' '}
                    </span>
                    생각을 믿는 정도
                </div>
                <div className={styles.percent_input_container}>
                    <input
                        className={`${styles.percent_input} text_18_NotoSansKR_Regular`}
                        readOnly={readOnly}
                        onChange={(e) => onChangeTrustlnThoughts(0, e.target.value)}
                        value={fields.trustInThoughts[0].patient}
                    />
                    <span className="text_18_Inter_Regular" style={{ position: 'absolute', right: '1.25rem' }}>
                        %
                    </span>
                </div>
            </div>
        </div>
    );
};

// CBT 퀴즈
export const Quiz = () => {
    const memberInfo = getMemberInfo();
    const readOnly = memberInfo?.memberRole !== 'user' && true;
    const dispatch = useDispatch();
    const { fieldIds, fields } = useSelector((state: RootState) => state.session);

    const [quizList, setQuizList] = useState([
        {
            url: Img_quiz_1,
            answerList: [
                {
                    title: '독심술',
                    select: false,
                },
                {
                    title: '정진적 여과',
                    select: false,
                },
                {
                    title: '명명하기',
                    select: false,
                },
                {
                    title: '당위진술',
                    select: false,
                },
            ],
        },
        {
            url: Img_quiz_2,
            answerList: [
                {
                    title: '독심술',
                    select: false,
                },
                {
                    title: '정진적 여과',
                    select: false,
                },
                {
                    title: '명명하기',
                    select: false,
                },
                {
                    title: '당위진술',
                    select: false,
                },
            ],
        },
        {
            url: Img_quiz_3,
            answerList: [
                {
                    title: '독심술',
                    select: false,
                },
                {
                    title: '정진적 여과',
                    select: false,
                },
                {
                    title: '명명하기',
                    select: false,
                },
                {
                    title: '당위진술',
                    select: false,
                },
            ],
        },
        {
            url: Img_quiz_4,
            answerList: [
                {
                    title: '독심술',
                    select: false,
                },
                {
                    title: '정진적 여과',
                    select: false,
                },
                {
                    title: '명명하기',
                    select: false,
                },
                {
                    title: '당위진술',
                    select: false,
                },
            ],
        },
    ]);

    useEffect(() => {
        getQuiz();
    }, [fields]);

    const getQuiz = () => {
        const quiz = [fields.quiz1, fields.quiz2, fields.quiz3, fields.quiz4];

        const updateData = quizList.map((item, index) => {
            // 현재 quiz[index]의 patient 목록을 가져옴
            const patientList = quiz[index].map((q) => q.patient);

            // item.answerList를 업데이트하여 새로운 배열을 생성
            const updatedAnswerList = item.answerList.map((value, idx) => {
                // idx가 patientList에 포함되어 있으면 select를 true로 설정
                if (patientList.includes(idx.toString())) {
                    return {
                        ...value,
                        select: true, // select 값을 true로 변경
                    };
                }
                return value; // 변경이 없으면 기존 값 반환
            });

            // item의 answerList를 업데이트하여 새 객체 반환
            return {
                ...item,
                answerList: updatedAnswerList,
            };
        });
        setQuizList(updateData);
    };

    const selectQuizAnswer = (url: string, title: string, index: number, itemIndex: number) => {
        const updatedData = quizList.map((item) => {
            if (item.url === url) {
                return {
                    ...item,
                    answerList: item.answerList.map((value) => {
                        if (value.title === title) {
                            return { ...value, select: !value.select }; // hobby 업데이트
                        }
                        return value;
                    }),
                };
            }
            return item;
        });

        let result = updatedData.flatMap(
            (quiz, quizIndex) =>
                quiz.answerList
                    .map((answer, answerIndex) => {
                        // select가 true인 경우에만 객체를 반환
                        if (answer.select) {
                            return {
                                index: quizIndex,
                                patient: answerIndex.toString(),
                                feedback: '',
                                visible: false,
                                feedbackCheck: false,
                            };
                        }
                        return null; // select가 false인 경우 null 반환
                    })
                    .filter(
                        (
                            item
                        ): item is {
                            index: number;
                            patient: string;
                            feedback: string;
                            visible: boolean;
                            feedbackCheck: boolean;
                        } => item !== null
                    ) // null 값 제거
        );
        let groupedResult = result.reduce((acc, item) => {
            // 해당 인덱스가 존재하지 않으면 새 배열 생성
            if (!acc[item.index]) {
                acc[item.index] = [];
            }
            // 해당 인덱스 배열에 객체 추가
            acc[item.index].push(item);
            return acc;
        }, [] as { index: number; patient: string; feedback: string; visible: boolean; feedbackCheck: boolean }[][]); // 타입을 명시적으로 지정

        if (groupedResult.length !== 0) {
            onChangeQuiz(groupedResult[index]);
        } else {
            onChangeQuiz([{ index: index, patient: '', feedback: '', visible: false, feedbackCheck: false }]);
        }
        setQuizList(updatedData);
    };

    const onChangeQuiz = (
        list: { index: number; patient: string; feedback: string; visible: boolean; feedbackCheck: boolean }[]
    ) => {
        dispatch(updateQuiz(list));
    };

    return (
        <>
            {quizList.map((item, index) => (
                <div key={index.toString()} className={`${styles.quiz_container} ${readOnly && styles.therapist}`}>
                    <img alt={index.toString()} src={item.url} className={styles.img} />
                    <div className={`${styles.quiz_list} ${readOnly && styles.therapist}`}>
                        {item.answerList.map((value, idx) => (
                            <button
                                key={value.title.toString()}
                                onClick={() => {
                                    if (!readOnly) selectQuizAnswer(item.url, value.title, index, idx);
                                }}
                                className={`${styles.quiz_button} ${readOnly && styles.therapist} ${
                                    value.select ? styles.activate : ''
                                }`}
                            >
                                <CircleCheckBox bool={value.select} />
                                <span
                                    className="text_24_NotoSansKR_Medium"
                                    style={{
                                        color: value.select ? '#41465A' : '#9D9FA2',
                                    }}
                                >
                                    {value.title}
                                </span>
                            </button>
                        ))}
                    </div>
                </div>
            ))}
        </>
    );
};

/* 인지오류 */
/*  session4, session5, session6, session7, session8, session9, session10  */
export const CognitiveError = () => {
    const dispatch = useDispatch();
    const { fieldIds, fields } = useSelector((state: RootState) => state.session);
    const memberInfo = getMemberInfo();
    const readOnly = memberInfo?.memberRole !== 'user' && true;

    const [cognitiveList, setCognitiveList] = useState([
        {
            title: '흑백논리의 오류',
            content:
                "전부 혹은 전문의 사고 (All of nothing) 라고 불리는 흑백논리는 선택지 사이에 다수의 연속적 개념이 있다고 생각하기보다 흑과 백처럼 ‘이것' 아니면 ‘저것', 두 개의 범주로 나누어 상황을 본다.",
            select: false,
        },
        {
            title: '재앙화',
            content: '미래에 대한 예상을 현실적 고려 없이 파국적이고 부정적으로 생각하는 오류이다.',
            select: false,
        },
        {
            title: '명명하기',
            content:
                '라벨링(Labeling)이라고도 하는 명명하기는 자신이나 다른 사람 혹은 어떤 상황에 고정적이며 부정적인 이름을 붙인다.',
            select: false,
        },
        {
            title: '과대평가/과소평가',
            content: '어떤 상황이나 자신을 평가할 때 부정적인 측면을 강조하고 긍정적인 측면을 최소화한다.',
            select: false,
        },
        {
            title: '감정적 추론',
            content: '근거를 고려하지 않거나 무시하고 자신의 감정의 느낌에 따라 결론을 내린다.',
            select: false,
        },
        {
            title: '정신적 여과',
            content:
                "‘선택적 추상', ‘필터링'이라고 하며 전체 상황을 보는 대신에 자신이 집중하는 세세한 것에 지나치게 관심을 가진다.",
            select: false,
        },
        {
            title: '독심술의 오류',
            content: '다른사람들이 생각하는 것을 자신이 알 수 있다고 생각하는 오류이다.',
            select: false,
        },
        { title: '지나친 일반화', content: '우연히 일어난 사건을 일반화시켜 부정적인 결론을 내린다.', select: false },
        {
            title: '자기 탓',
            content:
                '다른 사람의 부정적인 행동의 이유에 대해 타당한 근거를 고려하지 않고 자신 때문에 타인이 부정적인 행동을 하는 것이라 믿는다.',
            select: false,
        },
        {
            title: '당위진술',
            content: '행동에 대한 확실하고 고정된 사고를 가지고 있으며, 충족되지 못할 때 얼마나 나쁜지 과대평가한다.',
            select: false,
        },
        { title: '터널시야', content: '어떤 상황의 부정적인 측면만을 본다.', select: false },
    ]);

    const selectCognitive = (title: string, index: number) => {
        const updateData = cognitiveList.map((item) => {
            if (item.title === title) {
                return {
                    ...item,
                    select: !item.select,
                };
            }
            return item;
        });
        onChangeCognitiveError(updateData, index);
        setCognitiveList(updateData);
    };

    useEffect(() => {
        getCognitiveError();
    }, [fields]);

    const getCognitiveError = () => {
        const filter = fields.cognitiveError.map((e) => e.patient);
        const updateData = cognitiveList.map((item, index) => {
            if (filter.includes(index.toString())) {
                return {
                    ...item,
                    select: true,
                };
            }
            return item;
        });

        setCognitiveList(updateData);
    };

    const onChangeCognitiveError = (
        list: {
            title: string;
            content: string;
            select: boolean;
        }[],
        index: number
    ) => {
        const selectCognitiveError = list
            .map((item, index) => {
                if (item.select) {
                    return {
                        index: index,
                        patient: index.toString(),
                        feedback: '',
                        visible: false,
                        feedbackCheck: false,
                    };
                }
                return null;
            })
            .filter(
                (
                    item
                ): item is {
                    index: number;
                    patient: string;
                    feedback: string;
                    visible: boolean;
                    feedbackCheck: boolean;
                } => item !== null
            ); // null 값 제거

        dispatch(updateCognitiveError(selectCognitiveError));
    };

    return (
        <div className={styles.cognitive_error}>
            <div
                className="text_22_NotoSansKR_Bold"
                style={{
                    color: '#626466',
                }}
            >
                <span
                    className="text_22_Inter_Bold"
                    style={{
                        color: '#626466',
                    }}
                >
                    8.{' '}
                </span>
                인지오류{' '}
                <span
                    className="text_18_NotoSansKR_Medium"
                    style={{
                        color: '#D60000',
                    }}
                >
                    (TIP: 감정, 사고를 찾은 뒤 인지모델을 다시 확인해 주세요.)
                </span>
            </div>
            <div className={styles.cognitive_content_container}>
                {cognitiveList.map((item, index) => (
                    <button
                        key={item.title.toString()}
                        onClick={() => {
                            !readOnly && selectCognitive(item.title, index);
                        }}
                        className={`${styles.cognitive_button} ${item.select ? styles.activate : ''}`}
                    >
                        <div>
                            <CircleCheckBox bool={item.select} />
                        </div>
                        <div className={styles.cognitive_text_container}>
                            <span
                                className="text_24_NotoSansKR_Medium"
                                style={{
                                    color: item.select ? '#41465A' : '#9D9FA2',
                                }}
                            >
                                {item.title}
                            </span>
                            <span
                                className="text_24_NotoSansKR_Medium"
                                style={{
                                    color: item.select ? '#41465A' : '#9D9FA2',
                                }}
                            >
                                {item.content}
                            </span>
                        </div>
                    </button>
                ))}
            </div>
        </div>
    );
};

/* 소크라테스 문답법 */
/*  session5, session6, session8, session9   */
export const SocraticQuestionAnswer = () => {
    const dispatch = useDispatch();
    const { fieldIds, fields } = useSelector((state: RootState) => state.session);
    const memberInfo = getMemberInfo();
    const readOnly = memberInfo?.memberRole !== 'user' && true;

    const openFeedback = (type: string) => {
        if (memberInfo?.memberRole === 'therapist') {
            switch (type) {
                case 'socraticAutoThinking':
                    dispatch(
                        uppdateSocraticAutoThinking({
                            patient: fields.socraticAutoThinking[0].patient,
                            feedback: fields.socraticAutoThinking[0].feedback,
                            visible: !fields.socraticAutoThinking[0].visible,
                            feedbackCheck: fields.socraticAutoThinking[0].feedbackCheck,
                        })
                    );
                    break;
                case 'evidenceCorrectThinking':
                    dispatch(
                        updateEvidenceCorrectThinking({
                            patient: fields.evidenceCorrectThinking[0].patient,
                            feedback: fields.evidenceCorrectThinking[0].feedback,
                            visible: !fields.evidenceCorrectThinking[0].visible,
                            feedbackCheck: fields.evidenceCorrectThinking[0].feedbackCheck,
                        })
                    );
                    break;
                case 'evidenceIncorrectThinking':
                    dispatch(
                        updateEvidenceIncorrectThinking({
                            patient: fields.evidenceIncorrectThinking[0].patient,
                            feedback: fields.evidenceIncorrectThinking[0].feedback,
                            visible: !fields.evidenceIncorrectThinking[0].visible,
                            feedbackCheck: fields.evidenceIncorrectThinking[0].feedbackCheck,
                        })
                    );
                    break;
            }
        }
    };

    const onChangeSocraticAutoThinking = (text: string) => {
        const result = fields.socraticAutoThinking[0];
        switch (memberInfo?.memberRole) {
            case 'therapist':
                dispatch(
                    uppdateSocraticAutoThinking({
                        patient: result.patient,
                        feedback: text,
                        visible: result.visible,
                        feedbackCheck: result.feedbackCheck,
                    })
                );
                break;
            default:
                dispatch(
                    uppdateSocraticAutoThinking({
                        patient: text,
                        feedback: result.feedback,
                        visible: result.visible,
                        feedbackCheck: result.feedbackCheck,
                    })
                );
                break;
        }
    };

    const onChangeEvidenceCorrectThinking = (text: string) => {
        const result = fields.evidenceCorrectThinking[0];
        switch (memberInfo?.memberRole) {
            case 'therapist':
                dispatch(
                    updateEvidenceCorrectThinking({
                        patient: result.patient,
                        feedback: text,
                        visible: result.visible,
                        feedbackCheck: result.feedbackCheck,
                    })
                );
                break;
            default:
                dispatch(
                    updateEvidenceCorrectThinking({
                        patient: text,
                        feedback: result.feedback,
                        visible: result.visible,
                        feedbackCheck: result.feedbackCheck,
                    })
                );
                break;
        }
    };

    const onChangeEvidenceIncorrectThinking = (text: string) => {
        const result = fields.evidenceIncorrectThinking[0];
        switch (memberInfo?.memberRole) {
            case 'therapist':
                dispatch(
                    updateEvidenceIncorrectThinking({
                        patient: result.patient,
                        feedback: text,
                        visible: result.visible,
                        feedbackCheck: result.feedbackCheck,
                    })
                );
                break;
            default:
                dispatch(
                    updateEvidenceIncorrectThinking({
                        patient: text,
                        feedback: result.feedback,
                        visible: result.visible,
                        feedbackCheck: result.feedbackCheck,
                    })
                );
                break;
        }
    };

    const saveFeedback = (fieldId: number, field: any, key: string, index: number) => {
        if (field[index].feedbackCheck) {
            dispatch(changeFeedbackState({ index: index, key: key, value: field }));
        } else {
            const extractDataByKey = () => {
                return {
                    [key]: field,
                };
            };
            saveSessionFeedback(fieldId, extractDataByKey()).then((response) => {
                if (response.result) {
                    dispatch(updateSessionField({ index: index, key: key, value: field }));
                }
            });
        }
    };

    const cancel = (type: string) => {
        if (memberInfo?.memberRole === 'therapist') {
            openFeedback(type);
        }
    };

    return (
        <div className={styles.socratic_question_answer}>
            <SpanText className="text_32_NotoSansKR_Bold" color="#41465A" text="소크라테스 문답법 : 생각 바꾸기" />
            <div className={styles.content}>
                <div className={styles.automatic_accident}>
                    <SpanText className="text_18_NotoSansKR_Bold" color="#3A5074" text="자동사고:" />
                    <textarea
                        className={`${styles.input} text_18_NotoSansKR_Regular`}
                        readOnly={readOnly}
                        onDoubleClick={() => openFeedback('socraticAutoThinking')}
                        onChange={(e) => onChangeSocraticAutoThinking(e.target.value)}
                        value={fields.socraticAutoThinking[0].patient}
                    />
                    {fields.socraticAutoThinking[0].visible && readOnly && (
                        <WriteFeedbackTextarea
                            onChange={(text: string) => onChangeSocraticAutoThinking(text)}
                            save={() =>
                                saveFeedback(
                                    fieldIds.socraticAutoThinking,
                                    fields.socraticAutoThinking,
                                    'socraticAutoThinking',
                                    0
                                )
                            }
                            cancel={() => cancel('socraticAutoThinking')}
                            height="19.06rem"
                            edit={fields.socraticAutoThinking[0].feedbackCheck}
                            value={fields.socraticAutoThinking[0].feedback}
                        />
                    )}
                </div>
                <div className={styles.evidence}>
                    <div
                        style={{
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-start',
                        }}
                    >
                        <div className={`${styles.step} text_18_Inter_Bold`}>STEP 1</div>
                    </div>
                    <SpanText
                        className="text_22_NotoSansKR_Bold"
                        color="#626466"
                        text='"최대한 많은 증거들을 찾아보세요"'
                    />
                    <div className={styles.evidence_container}>
                        <div className={styles.evidence_content}>
                            <div className={styles.postit_container}>
                                <img alt="postit1" src={Postit} className={styles.postit} />
                                <span className={`${styles.postit_text} text_22_NotoSansKR_Bold`}>
                                    내 생각이 맞다는 증거
                                </span>
                            </div>
                            <div className={styles.evidence_input_conatiner}>
                                <textarea
                                    className={`${styles.evidence_input} text_18_NotoSansKR_Regular`}
                                    readOnly={readOnly}
                                    onDoubleClick={() => openFeedback('evidenceCorrectThinking')}
                                    onChange={(e) => onChangeEvidenceCorrectThinking(e.target.value)}
                                    value={fields.evidenceCorrectThinking[0].patient}
                                />
                                {fields.evidenceCorrectThinking[0].visible && readOnly && (
                                    <WriteFeedbackTextarea
                                        onChange={(text: string) => onChangeEvidenceCorrectThinking(text)}
                                        save={() =>
                                            saveFeedback(
                                                fieldIds.evidenceCorrectThinking,
                                                fields.evidenceCorrectThinking,
                                                'evidenceCorrectThinking',
                                                0
                                            )
                                        }
                                        cancel={() => cancel('evidenceCorrectThinking')}
                                        height="19.06rem"
                                        edit={fields.evidenceCorrectThinking[0].feedbackCheck}
                                        value={fields.evidenceCorrectThinking[0].feedback}
                                    />
                                )}
                            </div>
                        </div>
                        <div className={styles.evidence_content}>
                            <div className={styles.postit_container}>
                                <img alt="postit1" src={Postit} className={styles.postit} />
                                <span className={`${styles.postit_text} text_22_NotoSansKR_Bold`}>
                                    내 생각이 틀리다는 증거
                                </span>
                            </div>
                            <div className={styles.evidence_input_conatiner}>
                                <textarea
                                    className={`${styles.evidence_input} text_18_NotoSansKR_Regular`}
                                    readOnly={readOnly}
                                    onDoubleClick={() => openFeedback('evidenceIncorrectThinking')}
                                    onChange={(e) => onChangeEvidenceIncorrectThinking(e.target.value)}
                                    value={fields.evidenceIncorrectThinking[0].patient}
                                />
                                {fields.evidenceIncorrectThinking[0].visible && readOnly && (
                                    <WriteFeedbackTextarea
                                        onChange={(text: string) => onChangeEvidenceIncorrectThinking(text)}
                                        save={() =>
                                            saveFeedback(
                                                fieldIds.evidenceIncorrectThinking,
                                                fields.evidenceIncorrectThinking,
                                                'evidenceIncorrectThinking',
                                                0
                                            )
                                        }
                                        cancel={() => cancel('evidenceIncorrectThinking')}
                                        height="19.06rem"
                                        edit={fields.evidenceIncorrectThinking[0].feedbackCheck}
                                        value={fields.evidenceIncorrectThinking[0].feedback}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.socratic_question_answer_explanation}>
                    <SpanText
                        className="text_22_NotoSansKR_Bold"
                        color="#41465A"
                        text="[ 증거로 활용해 볼만한 것들 ] 과거의 경험,타인의경험,인터넷 증거,직접 확인해 보았는지,내 생각과 다른 방식"
                    />
                    <SpanText
                        className="text_18_NotoSansKR_Medium"
                        color="#626466"
                        text="(사소한 증거라도 무시하거나 확대 / 축소하는지 확인해보기)"
                    />
                </div>
            </div>
        </div>
    );
};
/* 소크라테스 문답법 step */
/*  session5, session6, session8, session9   */
export const SocraticQuestionAnswerStep = () => {
    const location = useLocation();
    const currentSession = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);
    const dispatch = useDispatch();
    const { fieldIds, fields } = useSelector((state: RootState) => state.session);
    const memberInfo = getMemberInfo();
    const readOnly = memberInfo?.memberRole !== 'user' && true;

    const openFeedback = (type: string) => {
        if (memberInfo?.memberRole === 'therapist') {
            switch (type) {
                case 'evidenceCorrectThinking':
                    dispatch(
                        updateEvidenceCorrectThinking({
                            patient: fields.evidenceCorrectThinking[0].patient,
                            feedback: fields.evidenceCorrectThinking[0].feedback,
                            visible: !fields.evidenceCorrectThinking[0].visible,
                            feedbackCheck: fields.evidenceCorrectThinking[0].feedbackCheck,
                        })
                    );
                    break;
                case 'evidenceIncorrectThinking':
                    dispatch(
                        updateEvidenceIncorrectThinking({
                            patient: fields.evidenceIncorrectThinking[0].patient,
                            feedback: fields.evidenceIncorrectThinking[0].feedback,
                            visible: !fields.evidenceIncorrectThinking[0].visible,
                            feedbackCheck: fields.evidenceIncorrectThinking[0].feedbackCheck,
                        })
                    );
                    break;
            }
        }
    };

    const onChangeEvidenceCorrectThinking = (text: string) => {
        const result = fields.evidenceCorrectThinking[0];
        switch (memberInfo?.memberRole) {
            case 'therapist':
                dispatch(
                    updateEvidenceCorrectThinking({
                        patient: result.patient,
                        feedback: text,
                        visible: result.visible,
                        feedbackCheck: result.feedbackCheck,
                    })
                );
                break;
            default:
                dispatch(
                    updateEvidenceCorrectThinking({
                        patient: text,
                        feedback: result.feedback,
                        visible: result.visible,
                        feedbackCheck: result.feedbackCheck,
                    })
                );
                break;
        }
    };

    const onChangeEvidenceIncorrectThinking = (text: string) => {
        const result = fields.evidenceIncorrectThinking[0];
        switch (memberInfo?.memberRole) {
            case 'therapist':
                dispatch(
                    updateEvidenceIncorrectThinking({
                        patient: result.patient,
                        feedback: text,
                        visible: result.visible,
                        feedbackCheck: result.feedbackCheck,
                    })
                );
                break;
            default:
                dispatch(
                    updateEvidenceIncorrectThinking({
                        patient: text,
                        feedback: result.feedback,
                        visible: result.visible,
                        feedbackCheck: result.feedbackCheck,
                    })
                );
                break;
        }
    };

    const saveFeedback = (fieldId: number, field: any, key: string, index: number) => {
        if (field[index].feedbackCheck) {
            dispatch(changeFeedbackState({ index: index, key: key, value: field }));
        } else {
            const extractDataByKey = () => {
                return {
                    [key]: field,
                };
            };
            saveSessionFeedback(fieldId, extractDataByKey()).then((response) => {
                if (response.result) {
                    dispatch(updateSessionField({ index: index, key: key, value: field }));
                }
            });
        }
    };

    const cancel = (type: string) => {
        if (memberInfo?.memberRole === 'therapist') {
            openFeedback(type);
        }
    };

    return (
        <div className={styles.socratic_question_answer_step}>
            <SpanText className="text_32_NotoSansKR_Bold" color="#41465A" text="소크라테스 문답법 : 생각 바꾸기" />
            <div className={styles.content}>
                <div className={styles.automatic_accident}>
                    <SpanText className="text_18_NotoSansKR_Bold" color="#3A5074" text="자동사고:" />
                    <textarea
                        className={`${styles.input} text_18_NotoSansKR_Regular`}
                        readOnly
                        style={{
                            backgroundColor: '#F6F7F9',
                        }}
                        value={fields.socraticAutoThinking[0].patient}
                    />
                </div>
                <div className={styles.step_container}>
                    <div className={`${styles.step} text_18_Inter_Bold`}>STEP 1</div>
                    <div className={styles.mult_textarea_container}>
                        <div className={styles.mult_textarea}>
                            <div
                                className="text_18_NotoSansKR_Bold"
                                style={{
                                    color: '#3A5074',
                                }}
                            >
                                내 생각이 맞다는 증거:
                            </div>
                            <textarea
                                className={`${styles.textarea} text_18_NotoSansKR_Regular`}
                                readOnly={readOnly}
                                onDoubleClick={() => openFeedback('evidenceCorrectThinking')}
                                onChange={(e) => onChangeEvidenceCorrectThinking(e.target.value)}
                                value={fields.evidenceCorrectThinking[0].patient}
                            />
                            {fields.evidenceCorrectThinking[0].visible && readOnly && (
                                <WriteFeedbackTextarea
                                    onChange={(text: string) => onChangeEvidenceCorrectThinking(text)}
                                    save={() =>
                                        saveFeedback(
                                            fieldIds.evidenceCorrectThinking,
                                            fields.evidenceCorrectThinking,
                                            'evidenceCorrectThinking',
                                            0
                                        )
                                    }
                                    cancel={() => cancel('evidenceCorrectThinking')}
                                    height="19.06rem"
                                    edit={fields.evidenceCorrectThinking[0].feedbackCheck}
                                    value={fields.evidenceCorrectThinking[0].feedback}
                                />
                            )}
                        </div>
                        <div className={styles.mult_textarea}>
                            <div
                                className="text_18_NotoSansKR_Bold"
                                style={{
                                    color: '#3A5074',
                                }}
                            >
                                내 생각이 틀리다는 증거:
                            </div>
                            <textarea
                                className={`${styles.textarea} text_18_NotoSansKR_Regular`}
                                readOnly={readOnly}
                                onDoubleClick={() => openFeedback('evidenceIncorrectThinking')}
                                onChange={(e) => onChangeEvidenceIncorrectThinking(e.target.value)}
                                value={fields.evidenceIncorrectThinking[0].patient}
                            />
                            {fields.evidenceIncorrectThinking[0].visible && readOnly && (
                                <WriteFeedbackTextarea
                                    onChange={(text: string) => onChangeEvidenceIncorrectThinking(text)}
                                    save={() =>
                                        saveFeedback(
                                            fieldIds.evidenceIncorrectThinking,
                                            fields.evidenceIncorrectThinking,
                                            'evidenceIncorrectThinking',
                                            0
                                        )
                                    }
                                    cancel={() => cancel('evidenceIncorrectThinking')}
                                    height="19.06rem"
                                    edit={fields.evidenceIncorrectThinking[0].feedbackCheck}
                                    value={fields.evidenceIncorrectThinking[0].feedback}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

/* 소크라테스 문답법 step */
/*  session5, session6, session8, session9   */
export const SocraticQuestionAnswerNonFace = () => {
    const location = useLocation();
    const currentSession = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);
    const dispatch = useDispatch();
    const { fieldIds, fields } = useSelector((state: RootState) => state.session);
    const memberInfo = getMemberInfo();
    const readOnly = memberInfo?.memberRole !== 'user' && true;

    const openFeedback = (type: string) => {
        if (memberInfo?.memberRole === 'therapist') {
            switch (type) {
                case 'evidenceCorrectThinking':
                    dispatch(
                        updateEvidenceCorrectThinking({
                            patient: fields.evidenceCorrectThinking[0].patient,
                            feedback: fields.evidenceCorrectThinking[0].feedback,
                            visible: !fields.evidenceCorrectThinking[0].visible,
                            feedbackCheck: fields.evidenceCorrectThinking[0].feedbackCheck,
                        })
                    );
                    break;
                case 'evidenceIncorrectThinking':
                    dispatch(
                        updateEvidenceIncorrectThinking({
                            patient: fields.evidenceIncorrectThinking[0].patient,
                            feedback: fields.evidenceIncorrectThinking[0].feedback,
                            visible: !fields.evidenceIncorrectThinking[0].visible,
                            feedbackCheck: fields.evidenceIncorrectThinking[0].feedbackCheck,
                        })
                    );
                    break;
            }
        }
    };

    const onChangeEvidenceCorrectThinking = (text: string) => {
        const result = fields.evidenceCorrectThinking[0];
        switch (memberInfo?.memberRole) {
            case 'therapist':
                dispatch(
                    updateEvidenceCorrectThinking({
                        patient: result.patient,
                        feedback: text,
                        visible: result.visible,
                        feedbackCheck: result.feedbackCheck,
                    })
                );
                break;
            default:
                dispatch(
                    updateEvidenceCorrectThinking({
                        patient: text,
                        feedback: result.feedback,
                        visible: result.visible,
                        feedbackCheck: result.feedbackCheck,
                    })
                );
                break;
        }
    };

    const onChangeEvidenceIncorrectThinking = (text: string) => {
        const result = fields.evidenceIncorrectThinking[0];
        switch (memberInfo?.memberRole) {
            case 'therapist':
                dispatch(
                    updateEvidenceIncorrectThinking({
                        patient: result.patient,
                        feedback: text,
                        visible: result.visible,
                        feedbackCheck: result.feedbackCheck,
                    })
                );
                break;
            default:
                dispatch(
                    updateEvidenceIncorrectThinking({
                        patient: text,
                        feedback: result.feedback,
                        visible: result.visible,
                        feedbackCheck: result.feedbackCheck,
                    })
                );
                break;
        }
    };

    const saveFeedback = (fieldId: number, field: any, key: string, index: number) => {
        if (field[index].feedbackCheck) {
            dispatch(changeFeedbackState({ index: index, key: key, value: field }));
        } else {
            const extractDataByKey = () => {
                return {
                    [key]: field,
                };
            };
            saveSessionFeedback(fieldId, extractDataByKey()).then((response) => {
                if (response.result) {
                    dispatch(updateSessionField({ index: index, key: key, value: field }));
                }
            });
        }
    };

    const cancel = (type: string) => {
        if (memberInfo?.memberRole === 'therapist') {
            openFeedback(type);
        }
    };

    return (
        <div className={styles.socratic_question_answer_step}>
            <SpanText className="text_32_NotoSansKR_Bold" color="#41465A" text="소크라테스 문답법 : 생각 바꾸기" />
            <div className={styles.content}>
                <div className={styles.automatic_accident}>
                    <SpanText className="text_18_NotoSansKR_Bold" color="#3A5074" text="자동사고:" />
                    <textarea
                        className={`${styles.input} text_18_NotoSansKR_Regular`}
                        value={fields.socraticAutoThinking[0].patient}
                    />
                </div>
            </div>
        </div>
    );
};

/* 현실적 결과 */
/*  session8, session9   */
export const RealictResults = () => {
    const dispatch = useDispatch();
    const { fieldIds, fields } = useSelector((state: RootState) => state.session);
    const memberInfo = getMemberInfo();
    const readOnly = memberInfo?.memberRole !== 'user' && true;

    const openFeedback = (type: string) => {
        if (memberInfo?.memberRole === 'therapist') {
            switch (type) {
                case 'bestOutcome':
                    dispatch(
                        updateBestOutcome({
                            patient: fields.bestOutcome[0].patient,
                            feedback: fields.bestOutcome[0].feedback,
                            visible: !fields.bestOutcome[0].visible,
                            feedbackCheck: fields.bestOutcome[0].feedbackCheck,
                        })
                    );
                    break;
                case 'worstOutcome':
                    dispatch(
                        updateWorstOutcome({
                            patient: fields.worstOutcome[0].patient,
                            feedback: fields.worstOutcome[0].feedback,
                            visible: !fields.worstOutcome[0].visible,
                            feedbackCheck: fields.worstOutcome[0].feedbackCheck,
                        })
                    );
                    break;
                case 'realisticOutcome':
                    dispatch(
                        updateRealisticOutcome({
                            patient: fields.realisticOutcome[0].patient,
                            feedback: fields.realisticOutcome[0].feedback,
                            visible: !fields.realisticOutcome[0].visible,
                            feedbackCheck: fields.realisticOutcome[0].feedbackCheck,
                        })
                    );
                    break;
            }
        }
    };

    const onChangeBestOutcome = (text: string) => {
        const result = fields.bestOutcome[0];
        switch (memberInfo?.memberRole) {
            case 'therapist':
                dispatch(
                    updateBestOutcome({
                        patient: result.patient,
                        feedback: text,
                        visible: result.visible,
                        feedbackCheck: result.feedbackCheck,
                    })
                );
                break;
            default:
                dispatch(
                    updateBestOutcome({
                        patient: text,
                        feedback: result.feedback,
                        visible: result.visible,
                        feedbackCheck: result.feedbackCheck,
                    })
                );
                break;
        }
    };

    const onChangeWorstOutcome = (text: string) => {
        const result = fields.worstOutcome[0];
        switch (memberInfo?.memberRole) {
            case 'therapist':
                dispatch(
                    updateWorstOutcome({
                        patient: result.patient,
                        feedback: text,
                        visible: result.visible,
                        feedbackCheck: result.feedbackCheck,
                    })
                );
                break;
            default:
                dispatch(
                    updateWorstOutcome({
                        patient: text,
                        feedback: result.feedback,
                        visible: result.visible,
                        feedbackCheck: result.feedbackCheck,
                    })
                );
                break;
        }
    };
    const onChangeRealisticOutcome = (text: string) => {
        const result = fields.realisticOutcome[0];
        switch (memberInfo?.memberRole) {
            case 'therapist':
                dispatch(
                    updateRealisticOutcome({
                        patient: result.patient,
                        feedback: text,
                        visible: result.visible,
                        feedbackCheck: result.feedbackCheck,
                    })
                );
                break;
            default:
                dispatch(
                    updateRealisticOutcome({
                        patient: text,
                        feedback: result.feedback,
                        visible: result.visible,
                        feedbackCheck: result.feedbackCheck,
                    })
                );
                break;
        }
    };

    const saveFeedback = (fieldId: number, field: any, key: string, index: number) => {
        if (field[index].feedbackCheck) {
            dispatch(changeFeedbackState({ index: index, key: key, value: field }));
        } else {
            const extractDataByKey = () => {
                return {
                    [key]: field,
                };
            };
            saveSessionFeedback(fieldId, extractDataByKey()).then((response) => {
                if (response.result) {
                    dispatch(updateSessionField({ index: index, key: key, value: field }));
                }
            });
        }
    };

    const cancel = (type: string) => {
        if (memberInfo?.memberRole === 'therapist') {
            openFeedback(type);
        }
    };

    return (
        <div className={styles.realict_results}>
            <div className={`${styles.step} text_18_Inter_Bold`}>STEP 3</div>
            <img alt="Realict_results" src={Realict_results} className={styles.img} />
            <div className={styles.content_container}>
                <div className={styles.input_container}>
                    <div>
                        <SpanText className="text_18_NotoSansKR_Bold" color="#F00" text="최악의 " />
                        <span
                            className="text_18_NotoSansKR_Bold"
                            style={{
                                color: '#3A5074',
                            }}
                        >
                            결과:
                        </span>
                    </div>
                    <textarea
                        className={`${styles.input} text_18_NotoSansKR_Regular`}
                        readOnly={readOnly}
                        onDoubleClick={() => openFeedback('worstOutcome')}
                        onChange={(e) => onChangeWorstOutcome(e.target.value)}
                        value={fields.worstOutcome[0].patient}
                    />
                    {fields.worstOutcome[0].visible && readOnly && (
                        <WriteFeedbackTextarea
                            onChange={(text: string) => onChangeWorstOutcome(text)}
                            save={() => saveFeedback(fieldIds.worstOutcome, fields.worstOutcome, 'worstOutcome', 0)}
                            cancel={() => cancel('worstOutcome')}
                            height="19.06rem"
                            edit={fields.worstOutcome[0].feedbackCheck}
                            value={fields.worstOutcome[0].feedback}
                        />
                    )}
                </div>
                <div className={styles.input_container}>
                    <div>
                        <SpanText className="text_18_NotoSansKR_Bold" color="#0061FF" text="최상의 " />
                        <span
                            className="text_18_NotoSansKR_Bold"
                            style={{
                                color: '#3A5074',
                            }}
                        >
                            결과:
                        </span>
                    </div>
                    <textarea
                        className={`${styles.input} text_18_NotoSansKR_Regular`}
                        readOnly={readOnly}
                        onDoubleClick={() => openFeedback('bestOutcome')}
                        onChange={(e) => onChangeBestOutcome(e.target.value)}
                        value={fields.bestOutcome[0].patient}
                    />
                    {fields.bestOutcome[0].visible && readOnly && (
                        <WriteFeedbackTextarea
                            onChange={(text: string) => onChangeBestOutcome(text)}
                            save={() => saveFeedback(fieldIds.bestOutcome, fields.bestOutcome, 'bestOutcome', 0)}
                            cancel={() => cancel('bestOutcome')}
                            height="19.06rem"
                            edit={fields.bestOutcome[0].feedbackCheck}
                            value={fields.bestOutcome[0].feedback}
                        />
                    )}
                </div>
                <div className={styles.input_container}>
                    <div>
                        <SpanText className="text_18_NotoSansKR_Bold" color="#3A5074" text="현실적 " />
                        <span
                            className="text_18_NotoSansKR_Bold"
                            style={{
                                color: '#3A5074',
                            }}
                        >
                            결과:
                        </span>
                    </div>
                    <textarea
                        className={`${styles.input} text_18_NotoSansKR_Regular`}
                        readOnly={readOnly}
                        onDoubleClick={() => openFeedback('realisticOutcome')}
                        onChange={(e) => onChangeRealisticOutcome(e.target.value)}
                        value={fields.realisticOutcome[0].patient}
                    />
                    {fields.realisticOutcome[0].visible && (
                        <WriteFeedbackTextarea
                            onChange={(text: string) => onChangeRealisticOutcome(text)}
                            save={() =>
                                saveFeedback(fieldIds.realisticOutcome, fields.realisticOutcome, 'realisticOutcome', 0)
                            }
                            cancel={() => cancel('realisticOutcome')}
                            height="19.06rem"
                            edit={fields.realisticOutcome[0].feedbackCheck}
                            value={fields.realisticOutcome[0].feedback}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

/* 자동사고 장점 */
/*  session8, session9   */
export const AdvantageAutomaticAccidents = () => {
    const dispatch = useDispatch();
    const { fieldIds, fields } = useSelector((state: RootState) => state.session);
    const memberInfo = getMemberInfo();
    const readOnly = memberInfo?.memberRole !== 'user' && true;

    const openFeedback = (type: string) => {
        if (memberInfo?.memberRole === 'therapist') {
            switch (type) {
                case 'advantagesOfTrustingAutoThought':
                    dispatch(
                        updateAdvantagesOfTrustingAutoThought({
                            patient: fields.advantagesOfTrustingAutoThought[0].patient,
                            feedback: fields.advantagesOfTrustingAutoThought[0].feedback,
                            visible: !fields.advantagesOfTrustingAutoThought[0].visible,
                            feedbackCheck: fields.advantagesOfTrustingAutoThought[0].feedbackCheck,
                        })
                    );
                    break;
                case 'letter':
                    dispatch(
                        updateLetter({
                            patient: fields.letter[0].patient,
                            feedback: fields.letter[0].feedback,
                            visible: !fields.letter[0].visible,
                            feedbackCheck: fields.letter[0].feedbackCheck,
                        })
                    );
                    break;
            }
        }
    };

    const onChangeAdvantagesOfTrustingAutoThought = (text: string) => {
        const result = fields.advantagesOfTrustingAutoThought[0];
        switch (memberInfo?.memberRole) {
            case 'therapist':
                dispatch(
                    updateAdvantagesOfTrustingAutoThought({
                        patient: result.patient,
                        feedback: text,
                        visible: result.visible,
                        feedbackCheck: result.feedbackCheck,
                    })
                );
                break;
            default:
                dispatch(
                    updateAdvantagesOfTrustingAutoThought({
                        patient: text,
                        feedback: result.feedback,
                        visible: result.visible,
                        feedbackCheck: result.feedbackCheck,
                    })
                );
                break;
        }
    };

    const onChangeLetter = (text: string) => {
        const result = fields.letter[0];
        switch (memberInfo?.memberRole) {
            case 'therapist':
                dispatch(
                    updateLetter({
                        patient: result.patient,
                        feedback: text,
                        visible: result.visible,
                        feedbackCheck: result.feedbackCheck,
                    })
                );
                break;
            default:
                dispatch(
                    updateLetter({
                        patient: text,
                        feedback: result.feedback,
                        visible: result.visible,
                        feedbackCheck: result.feedbackCheck,
                    })
                );
                break;
        }
    };

    const saveFeedback = (fieldId: number, field: any, key: string, index: number) => {
        if (field[index].feedbackCheck) {
            dispatch(changeFeedbackState({ index: index, key: key, value: field }));
        } else {
            const extractDataByKey = () => {
                return {
                    [key]: field,
                };
            };
            saveSessionFeedback(fieldId, extractDataByKey()).then((response) => {
                if (response.result) {
                    dispatch(updateSessionField({ index: index, key: key, value: field }));
                }
            });
        }
    };

    const cancel = (type: string) => {
        if (memberInfo?.memberRole === 'therapist') {
            openFeedback(type);
        }
    };

    return (
        <div className={styles.advantage_automatic_accidents}>
            <div className={styles.step_container}>
                <div className={`${styles.step} text_18_Inter_Bold`}>STEP 4</div>
                <div
                    className="text_18_NotoSansKR_Bold"
                    style={{
                        color: '#3A5074',
                    }}
                >
                    이 자동사고를 믿을 때 얻을 수 있는 장점:
                </div>
                <textarea
                    className={`${styles.input} text_18_NotoSansKR_Regular`}
                    readOnly={readOnly}
                    onDoubleClick={() => openFeedback('advantagesOfTrustingAutoThought')}
                    onChange={(e) => onChangeAdvantagesOfTrustingAutoThought(e.target.value)}
                    value={fields.advantagesOfTrustingAutoThought[0].patient}
                />
            </div>
            <div className={styles.step_mult_container}>
                <div className={styles.step_container}>
                    <div className={`${styles.step} text_18_Inter_Bold`}>STEP 5</div>
                    <div
                        className="text_18_NotoSansKR_Bold"
                        style={{
                            color: '#3A5074',
                        }}
                    >
                        내 자동사고가 ‘친구의 고민'이라고 생각하고 조언해 보자.
                    </div>
                </div>
                <div className={styles.step_container}>
                    <div
                        className="text_18_NotoSansKR_Bold"
                        style={{
                            color: '#3A5074',
                        }}
                    >
                        자동사고:
                    </div>
                    <textarea className={styles.textarea} readOnly />
                </div>
            </div>
            <div className={styles.letter_container}>
                <img alt="Letter" src={Letter} className={styles.img} />
                <div className={styles.input_container}>
                    <textarea
                        className={`${styles.input} text_18_NotoSansKR_Regular`}
                        readOnly={readOnly}
                        onDoubleClick={() => openFeedback('letter')}
                        onChange={(e) => onChangeLetter(e.target.value)}
                        value={fields.letter[0].patient}
                    />
                </div>
            </div>
            <div className={styles.letter_feedback_container}>
                {fields.letter[0].visible && readOnly && (
                    <WriteFeedbackTextarea
                        onChange={(text: string) => onChangeLetter(text)}
                        save={() => saveFeedback(fieldIds.letter, fields.letter, 'letter', 0)}
                        cancel={() => cancel('letter')}
                        height="19.06rem"
                        edit={fields.letter[0].feedbackCheck}
                        value={fields.letter[0].feedback}
                    />
                )}
            </div>
        </div>
    );
};

/* 자동사고 점검 */
/*  session8, session9   */
export const AutomaticAccidentInspection = () => {
    const dispatch = useDispatch();
    const { fieldIds, fields } = useSelector((state: RootState) => state.session);
    const memberInfo = getMemberInfo();
    const readOnly = memberInfo?.memberRole !== 'user' && true;

    const [isSelectedEmotion, setIsSelectedEmotion] = useState<string>('');

    const [emotionLis, setEmotionList] = useState([
        { Icon: Icon_horror, name: '공포' },
        { Icon: Icon_anger, name: '분노' },
        { Icon: Icon_anxiety, name: '불안' },
        { Icon: Icon_disappointment, name: '실망' },
        { Icon: Icon_frustration, name: '좌절' },
        { Icon: Icon_depression, name: '우울' },
        { Icon: Icon_irritability, name: '짜증' },
        { Icon: Icon_etc, name: '기타' },
    ]);
    const emotionRatio = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

    const openFeedback = (type: string) => {
        if (memberInfo?.memberRole === 'therapist') {
            switch (type) {
                case 'rationalThinkingAutoCheck':
                    dispatch(
                        updateRationalThinkingAutoCheck({
                            patient: fields.rationalThinkingAutoCheck[0].patient,
                            feedback: fields.rationalThinkingAutoCheck[0].feedback,
                            visible: !fields.rationalThinkingAutoCheck[0].visible,
                            feedbackCheck: fields.rationalThinkingAutoCheck[0].feedbackCheck,
                        })
                    );
                    break;
                case 'emotionsAfterChange':
                    dispatch(
                        updateEmotionsAfterChange({
                            patient: fields.emotionsAfterChange[0].patient,
                            feedback: fields.emotionsAfterChange[0].feedback,
                            visible: !fields.emotionsAfterChange[0].visible,
                            feedbackCheck: fields.emotionsAfterChange[0].feedbackCheck,
                        })
                    );
                    break;
                case 'scoreAfterChange':
                    dispatch(
                        updateScoreAfterChange({
                            patient: fields.scoreAfterChange[0].patient,
                            feedback: fields.scoreAfterChange[0].feedback,
                            visible: !fields.scoreAfterChange[0].visible,
                            feedbackCheck: fields.scoreAfterChange[0].feedbackCheck,
                        })
                    );
                    break;
            }
        }
    };

    const onChangeRationalThinkingAutoCheck = (text: string) => {
        const result = fields.rationalThinkingAutoCheck[0];
        switch (memberInfo?.memberRole) {
            case 'therapist':
                dispatch(
                    updateRationalThinkingAutoCheck({
                        patient: result.patient,
                        feedback: text,
                        visible: result.visible,
                        feedbackCheck: result.feedbackCheck,
                    })
                );
                break;
            default:
                dispatch(
                    updateRationalThinkingAutoCheck({
                        patient: text,
                        feedback: result.feedback,
                        visible: result.visible,
                        feedbackCheck: result.feedbackCheck,
                    })
                );
                break;
        }
    };

    const onChangeEmotionsAfterChange = (text: string) => {
        const result = fields.emotionsAfterChange[0];
        switch (memberInfo?.memberRole) {
            case 'therapist':
                dispatch(
                    updateEmotionsAfterChange({
                        patient: result.patient,
                        feedback: text,
                        visible: result.visible,
                        feedbackCheck: result.feedbackCheck,
                    })
                );
                break;
            default:
                dispatch(
                    updateEmotionsAfterChange({
                        patient: text,
                        feedback: result.feedback,
                        visible: result.visible,
                        feedbackCheck: result.feedbackCheck,
                    })
                );
                break;
        }
    };
    const onChangeScoreAfterChange = (text: string) => {
        const result = fields.scoreAfterChange[0];
        switch (memberInfo?.memberRole) {
            case 'therapist':
                dispatch(
                    updateScoreAfterChange({
                        patient: result.patient,
                        feedback: text,
                        visible: result.visible,
                        feedbackCheck: result.feedbackCheck,
                    })
                );
                break;
            default:
                dispatch(
                    updateScoreAfterChange({
                        patient: text,
                        feedback: result.feedback,
                        visible: result.visible,
                        feedbackCheck: result.feedbackCheck,
                    })
                );
                break;
        }
    };

    const saveFeedback = (fieldId: number, field: any, key: string, index: number) => {
        if (field[index].feedbackCheck) {
            dispatch(changeFeedbackState({ index: index, key: key, value: field }));
        } else {
            const extractDataByKey = () => {
                return {
                    [key]: field,
                };
            };
            saveSessionFeedback(fieldId, extractDataByKey()).then((response) => {
                if (response.result) {
                    dispatch(updateSessionField({ index: index, key: key, value: field }));
                }
            });
        }
    };

    const cancel = (type: string) => {
        if (memberInfo?.memberRole === 'therapist') {
            openFeedback(type);
        }
    };

    return (
        <div className={styles.automatic_accident_inspection}>
            <SpanText className="text_32_NotoSansKR_Bold" color="#41465A" text="마지막으로 내 자동사고를 점검해보자" />
            <div className={styles.automatic_think_content}>
                <div
                    className="text_18_NotoSansKR_Bold"
                    style={{
                        color: '#3A5074',
                    }}
                >
                    자동사고:
                </div>
                <textarea className={`${styles.textarea} text_18_NotoSansKR_Regular`} readOnly />
            </div>
            <div className={styles.rational_think_content}>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '0.5rem',
                    }}
                >
                    <Icon_pencil className={styles.icon_pencil} />
                    <SpanText className="text_22_NotoSansKR_Bold" color="#626466" text="자동사고에 대한 합리적 사고" />
                </div>
                <textarea
                    className={`${styles.input} text_18_NotoSansKR_Regular`}
                    readOnly={readOnly}
                    onDoubleClick={() => openFeedback('rationalThinkingAutoCheck')}
                    onChange={(e) => onChangeRationalThinkingAutoCheck(e.target.value)}
                    value={fields.rationalThinkingAutoCheck[0].patient}
                />
                <Icon_change_automatic_accident_bubble className={styles.icon_change_automatic_accident_bubble} />
                {fields.rationalThinkingAutoCheck[0].visible && readOnly && (
                    <WriteFeedbackTextarea
                        onChange={(text: string) => onChangeRationalThinkingAutoCheck(text)}
                        save={() =>
                            saveFeedback(
                                fieldIds.rationalThinkingAutoCheck,
                                fields.rationalThinkingAutoCheck,
                                'rationalThinkingAutoCheck',
                                0
                            )
                        }
                        cancel={() => cancel('rationalThinkingAutoCheck')}
                        height="19.06rem"
                        edit={fields.rationalThinkingAutoCheck[0].feedbackCheck}
                        value={fields.rationalThinkingAutoCheck[0].feedback}
                    />
                )}
            </div>
            <div className={styles.emotional_score}>
                <div className={styles.emotional_score_content}>
                    <span
                        className="text_22_NotoSansKR_Bold"
                        style={{
                            color: '#626466',
                        }}
                    >
                        합리적 사고로 변경 후의 감정을{' '}
                        <span
                            className="text_22_Inter_Bold"
                            style={{
                                color: '#626466',
                            }}
                        >
                            1~10
                        </span>
                        으로 나타내보세요.
                    </span>
                </div>
                <div className={styles.emotion_icon_container_box}>
                    {emotionLis.map((item, index) => (
                        <button
                            key={index.toString()}
                            onClick={() => {
                                if (!readOnly) {
                                    if (fields.emotionsAfterChange[0].patient === item.name) {
                                        onChangeEmotionsAfterChange('');
                                    } else {
                                        onChangeEmotionsAfterChange(item.name);
                                    }
                                }
                            }}
                            className={`${styles.emotion_icon_container} ${
                                fields.emotionsAfterChange[0].patient === item.name
                                    ? styles.activate
                                    : styles.deactivate
                            }`}
                        >
                            <div className={styles.icon_container}>
                                <item.Icon
                                    style={{
                                        width: '100%', // 아이콘을 부모의 너비에 맞게
                                        height: 'auto', // 비율 유지
                                    }}
                                />
                            </div>
                            <span
                                className="text_22_NotoSansKR_Medium"
                                style={{
                                    color: '#626466',
                                }}
                            >
                                {item.name}
                            </span>
                        </button>
                    ))}
                </div>
                <div className={styles.select_score_radio_container}>
                    <div
                        className="text_22_NotoSansKR_Bold"
                        style={{
                            color: '#626466',
                        }}
                    >
                        선택한 감정의 정도를{' '}
                        <span
                            className="text_22_Inter_Bold"
                            style={{
                                color: '#626466',
                            }}
                        >
                            1~10
                        </span>
                        점 사이로 측정해 주세요.
                    </div>
                    <div className={styles.radio_item_container}>
                        {emotionRatio.map((item, index) => (
                            <button
                                key={index.toString()}
                                className={styles.radio_item}
                                onClick={() => onChangeScoreAfterChange(item.toString())}
                            >
                                <CircleCheckBox bool={fields.scoreAfterChange[0].patient === item.toString()} />
                                <div
                                    className="text_24_Inter_Medium"
                                    style={{
                                        color: '#9D9FA2',
                                    }}
                                >
                                    {item}
                                </div>
                            </button>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export const AuthmaticThinkingGraph = () => {
    const location = useLocation();
    const currentSession = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);
    const dispatch = useDispatch();
    const { fieldIds, fields } = useSelector((state: RootState) => state.session);
    const memberInfo = getMemberInfo();
    const readOnly = memberInfo?.memberRole !== 'user' && true;

    const [automaticAccident, setAutomaticAccident] = useState([
        {
            title: 'a.',
            therapist: '',
            patient: '',
        },
        {
            title: 'b.',
            therapist: '',
            patient: '',
        },
        {
            title: 'c.',
            therapist: '',
            patient: '',
        },
        {
            title: 'd.',
            therapist: '',
            patient: '',
        },
        {
            title: 'e.',
            therapist: '',
            patient: '',
        },
        {
            title: 'f.',
            therapist: '',
            patient: '',
        },
        {
            title: 'g.',
            therapist: '',
            patient: '',
        },
    ]);

    const openFeedback = (index: number) => {
        if (memberInfo?.memberRole === 'therapist') {
            dispatch(
                updateAuthmaticThinkingGraph({
                    index: index,
                    patient: fields.authmaticThinkingGraph[0].patient,
                    feedback: fields.authmaticThinkingGraph[0].feedback,
                    visible: !fields.authmaticThinkingGraph[0].visible,
                    feedbackCheck: fields.authmaticThinkingGraph[0].feedbackCheck,
                })
            );
        }
    };

    const onChangeAuthmaticThinkingGraph = (index: number, text: string) => {
        const result = fields.authmaticThinkingGraph[index];
        switch (memberInfo?.memberRole) {
            case 'therapist':
                dispatch(
                    updateAuthmaticThinkingGraph({
                        index: index,
                        patient: result.patient,
                        feedback: text,
                        visible: result.visible,
                        feedbackCheck: result.feedbackCheck,
                    })
                );
                break;
            default:
                dispatch(
                    updateAuthmaticThinkingGraph({
                        index: index,
                        patient: text,
                        feedback: result.feedback,
                        visible: result.visible,
                        feedbackCheck: result.feedbackCheck,
                    })
                );
                break;
        }
    };

    const saveFeedback = (fieldId: number, field: any, key: string, index: number) => {
        if (field[index].feedbackCheck) {
            dispatch(changeFeedbackState({ index: index, key: key, value: field }));
        } else {
            const extractDataByKey = () => {
                return {
                    [key]: field,
                };
            };
            saveSessionFeedback(fieldId, extractDataByKey()).then((response) => {
                if (response.result) {
                    dispatch(updateSessionField({ index: index, key: key, value: field }));
                }
            });
        }
    };

    const cancel = (index: number) => {
        if (memberInfo?.memberRole === 'therapist') {
            openFeedback(index);
        }
    };

    return (
        <div className={styles.possibilities_automatic_accident}>
            <div className={`${styles.step} text_18_Inter_Bold`}>STEP 2</div>
            <span className="text_22_NotoSansKR_Bold">
                나의 자동사고에 대한 여러가지 가능성을 생각해 봅시다.{' '}
                <span className="text_18_NotoSansKR_Medium" style={{ color: '#9D9FA2' }}>
                    (최대한 많이 적을 수록 좋아요.)
                </span>
            </span>
            <div className={styles.content_container}>
                <div>
                    <img alt="img1" src={AutomaticGraph} className={styles.img} />
                </div>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        gap: '0.75rem',
                    }}
                >
                    {automaticAccident.map((item, index) => (
                        <div
                            key={index.toString()}
                            style={{
                                display: 'flex',
                                alignItems: 'flex-start',
                                gap: '0.75rem',
                            }}
                        >
                            <div key={index.toString()} className={styles.input_container}>
                                <div className={`${styles.input_title} text_22_Inter_Bold`}>{item.title}</div>
                            </div>
                            <div
                                className={`${styles.input_feedback_container} ${
                                    fields.authmaticThinkingGraph[index].visible ? styles.padding_bottom : ''
                                }`}
                            >
                                <textarea
                                    className={`${styles.input} text_18_NotoSansKR_Regular`}
                                    readOnly={readOnly}
                                    onDoubleClick={() => openFeedback(index)}
                                    onChange={(e) => onChangeAuthmaticThinkingGraph(index, e.target.value)}
                                    value={fields.authmaticThinkingGraph[index].patient}
                                />
                                {fields.authmaticThinkingGraph[index].visible && readOnly && (
                                    <WriteFeedbackTextarea
                                        onChange={(text: string) => onChangeAuthmaticThinkingGraph(index, text)}
                                        save={() =>
                                            saveFeedback(
                                                fieldIds.authmaticThinkingGraph,
                                                fields.authmaticThinkingGraph,
                                                'authmaticThinkingGraph',
                                                index
                                            )
                                        }
                                        cancel={() => cancel(index)}
                                        height="19.06rem"
                                        edit={fields.authmaticThinkingGraph[index].feedbackCheck}
                                        value={fields.authmaticThinkingGraph[index].feedback}
                                    />
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

/* 배운점/느낀점 */
/*  session8, session9   */
export const LearnedFelt = () => {
    const dispatch = useDispatch();
    const { fieldIds, fields } = useSelector((state: RootState) => state.session);
    const memberInfo = getMemberInfo();
    const readOnly = memberInfo?.memberRole !== 'user' && true;

    const openFeedback = () => {
        if (memberInfo?.memberRole === 'therapist') {
            dispatch(
                updateLessonsLearned({
                    patient: fields.lessonsLearned[0].patient,
                    feedback: fields.lessonsLearned[0].feedback,
                    visible: !fields.lessonsLearned[0].visible,
                    feedbackCheck: fields.lessonsLearned[0].feedbackCheck,
                })
            );
        }
    };

    const onChangeLessonsLearned = (text: string) => {
        const result = fields.lessonsLearned[0];
        switch (memberInfo?.memberRole) {
            case 'therapist':
                dispatch(
                    updateLessonsLearned({
                        patient: result.patient,
                        feedback: text,
                        visible: result.visible,
                        feedbackCheck: result.feedbackCheck,
                    })
                );
                break;
            default:
                dispatch(
                    updateLessonsLearned({
                        patient: text,
                        feedback: result.feedback,
                        visible: result.visible,
                        feedbackCheck: result.feedbackCheck,
                    })
                );
                break;
        }
    };

    const saveFeedback = (fieldId: number, field: any, key: string, index: number) => {
        if (field[index].feedbackCheck) {
            dispatch(changeFeedbackState({ index: index, key: key, value: field }));
        } else {
            const extractDataByKey = () => {
                return {
                    [key]: field,
                };
            };
            saveSessionFeedback(fieldId, extractDataByKey()).then((response) => {
                if (response.result) {
                    dispatch(updateSessionField({ index: index, key: key, value: field }));
                }
            });
        }
    };

    const cancel = () => {
        if (memberInfo?.memberRole === 'therapist') {
            openFeedback();
        }
    };
    return (
        <div className={styles.learned_felt}>
            <div className={styles.input_container}>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '0.5rem',
                    }}
                >
                    <Icon_pencil className={styles.icon_pencil} />
                    <span
                        className="text_22_NotoSansKR_Bold"
                        style={{
                            color: '#626466',
                        }}
                    >
                        배운점/느낀점
                    </span>
                </div>
                <textarea
                    className={`${styles.input} text_18_NotoSansKR_Regular`}
                    readOnly={readOnly}
                    onDoubleClick={() => openFeedback()}
                    onChange={(e) => onChangeLessonsLearned(e.target.value)}
                    value={fields.lessonsLearned[0].patient}
                />
                {fields.lessonsLearned[0].visible && readOnly && (
                    <WriteFeedbackTextarea
                        onChange={(text: string) => onChangeLessonsLearned(text)}
                        save={() => saveFeedback(fieldIds.lessonsLearned, fields.lessonsLearned, 'lessonsLearned', 0)}
                        cancel={() => cancel()}
                        height="19.06rem"
                        edit={fields.lessonsLearned[0].feedbackCheck}
                        value={fields.lessonsLearned[0].feedback}
                    />
                )}
            </div>
            <SpanText
                className="text_22_NotoSansKR_Bold"
                color="#41465A"
                text="그동안 생각의 악순환을 끊을 수 있는 방법을 함께 익혀보았습니다. 이제, 스스로 연습해 볼 차례입니다."
            />
        </div>
    );
};

/* 워크북 제출 */
export const SubmitWorkbook = ({ onClick }: { onClick: MouseEventHandler<HTMLButtonElement> }) => {
    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
                paddingLeft: '2rem',
                marginTop: '2.5rem',
            }}
        >
            <button onClick={onClick} className={`${styles.submit_button} text_18_NotoSansKR_Bold`}>
                워크북 제출
            </button>
        </div>
    );
};

export const CircleCheckBox = ({ bool }: { bool: boolean }) => {
    if (bool) {
        return (
            <Icon_circle_checkedBox
                style={{
                    width: '2rem',
                    height: '2rem',
                }}
            />
        );
    } else {
        return (
            <Icon_circle_checkbox
                style={{
                    width: '2rem',
                    height: '2rem',
                }}
            />
        );
    }
};

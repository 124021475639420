import { useSelector } from 'react-redux';
import styles from './css/ReservationSearch.module.css';
import { RootState } from '../../../../../store';
import moment from 'moment';
import { format } from 'date-fns';
import { ko } from 'date-fns/locale';

export const ReservationSearch = () => {
    const { events, searchVal } = useSelector((state: RootState) => state.events);

    const searchFilter = events.reserves.filter(
        (item) =>
            String(item.memberId).includes(searchVal) ||
            item.memberName.includes(searchVal) ||
            item.workBookNames.join(', ').includes(searchVal)
    );

    // 시간 표기 변환
    const formatTimeRange = (startTime: string, endTime: string) => {
        const formatTime = (time: any) => {
            const [hour, minute] = time.split(':').map(Number);
            const period = hour < 12 ? '오전' : '오후';
            const formattedHour = hour % 12 === 0 ? 12 : hour % 12; // 12시 포맷 조정
            return `${formattedHour}시${minute}분`;
        };

        const startHour = Number(startTime.split(':')[0]);
        const endHour = Number(endTime.split(':')[0]);

        const startFormatted = formatTime(startTime);
        const endFormatted = formatTime(endTime);

        if (startHour < 12 && endHour < 12) {
            // 모두 오전
            return `오전 ${startFormatted} ~ ${endFormatted}`;
        } else if (startHour < 12 && endHour >= 12) {
            // 시작은 오전, 끝은 오후
            return `오전 ${startFormatted} ~ 오후 ${endFormatted}`;
        } else if (startHour >= 12 && endHour >= 12) {
            // 모두 오후
            return `오후 ${startFormatted} ~ ${endFormatted}`;
        } else {
            // 시작은 오후, 끝은 오전 (이 경우는 일반적이지 않음)
            return `오후 ${startFormatted} ~ ${endFormatted}`;
        }
    };
    const SearchRender = () => {
        return (
            <div className={styles.reverse_container}>
                {searchFilter.map((item, index) => (
                    <div
                        key={index.toString()}
                        className={`${styles.reverse_item} ${
                            events.reserves.length - 1 === index ? '' : styles.border_bottom
                        }`}
                    >
                        <div
                            className="text_16_NotoSansKR_Medium"
                            style={{
                                width: '8.9375rem',
                            }}
                        >
                            {format(item.reservationDate, 'yyyy-MM-dd EEEE', { locale: ko })}
                        </div>
                        <div className="text_16_NotoSansKR_Medium">
                            {item.memberId +
                                '/' +
                                item.memberName +
                                '/' +
                                item.workBookNames.join(', ') +
                                '/' +
                                item.sessionProgresses}
                            회 /{formatTimeRange(item.startTime, item.endTime)}
                        </div>
                    </div>
                ))}
            </div>
        );
    };

    return (
        <div className={styles.content_container}>
            <div className={`${styles.search_result} text_18_NotoSansKR_Medium`}>
                총
                <span
                    className="text_18_Inter_Medium"
                    style={{
                        color: '#3A5074',
                    }}
                >
                    {events.reserves.filter((item) => String(item.memberId) === searchVal).length}
                </span>
                건의 검색 결과
            </div>
            <div className={styles.title_container}>
                <div className={`${styles.title_item} text_16_NotoSansKR_Medium`}>날짜</div>
                <div className={`${styles.title_item} text_16_NotoSansKR_Medium`}>내용</div>
            </div>
            <SearchRender />
        </div>
    );
};

import { useState } from 'react';
import styles from './css/SessionMap.module.css';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { getSessionMap, SessionMapApi } from './session/ts/SessionApi';
import { useLocation, useNavigate } from 'react-router-dom';
import { encryptData } from '../../../utils/croptojs';

export const SessionMap = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const { data, isLoading, error } = useQuery<SessionMapApi>({
        queryKey: ['sessionMap'],
        queryFn: () => getSessionMap(),
        placeholderData: keepPreviousData,
    });

    const moveToWorkbook = (num: number, sessionId: number, workBookType: string) => {
        queryParams.set('page', num.toString());
        queryParams.set('session', encryptData(sessionId));

        switch (workBookType) {
            case 'face':
                navigate(`/yd/patient/face/session?${queryParams}`);
                break;
            case 'non-face':
                navigate(`/yd/patient/nonface/session?${queryParams}`);
                break;
        }
    };

    const moveToAssignment = (num: number, sessionId: number, workBookType: string) => {
        queryParams.set('page', num.toString());
        queryParams.set('session', encryptData(sessionId));

        switch (workBookType) {
            case 'face':
                navigate(`/yd/patient/face/session/assigned?${queryParams}`);
                break;
            case 'non-face':
                navigate(`/yd/patient/nonface/session/assigned?${queryParams}`);
                break;
        }
    };

    const NumberBox = ({
        num,
        index,
        finish,
        finishCount,
        workBookType,
        sessionId,
        assignment,
    }: {
        num: number;
        index: number;
        finish: boolean;
        finishCount: number;
        workBookType: string;
        sessionId: number;
        assignment: boolean;
    }) => {
        const find = num <= finishCount;
        return (
            <button
                onClick={() => {
                    if (assignment) {
                        moveToAssignment(num, sessionId, workBookType);
                    } else {
                        moveToWorkbook(num, sessionId, workBookType);
                    }
                }}
                className={`${styles.progress_button} ${
                    finish
                        ? find
                            ? styles.finsh_activate
                            : styles.deactivate
                        : find
                        ? styles.activate
                        : styles.deactivate
                } text_28_Inter_Bold`}
            >
                {num}
            </button>
        );
    };

    const Session = ({ mapList, workBookType }: any) => {
        return (
            <div className={styles.progress_content}>
                <div>
                    <div className={styles.title_container}>
                        <div
                            className={`${styles.title} ${
                                mapList.totalSessionCnt === mapList.finishCnt ? styles.deactivate : styles.activate
                            } text_18_NotoSansKR_Bold`}
                        >
                            세션
                        </div>
                        <div
                            className="text_18_NotoSansKR_Medium"
                            style={{
                                color: mapList.totalSessionCnt === mapList.finishCnt ? '#626466' : '#020202',
                            }}
                        >
                            세션 {mapList.finishCnt}까지 진행완료
                        </div>
                    </div>
                    <div className={styles.progress_button_container}>
                        {mapList.registerSessionIds.map((item: number, index: number) => (
                            <NumberBox
                                num={index + 1}
                                index={index}
                                finish={mapList.totalSessionCnt === mapList.finishCnt}
                                finishCount={mapList.finishCnt}
                                workBookType={workBookType}
                                sessionId={item}
                                assignment={false}
                            />
                        ))}
                    </div>
                </div>
                {/* <button className={`${styles.progress_status_button} text_18_NotoSansKR_Bold`}>{sessionList.buttonTitle}</button> */}
            </div>
        );
    };

    const Feedback = ({ mapList, workBookType }: any) => {
        return (
            <div className={styles.progress_content}>
                <div>
                    <div className={styles.title_container}>
                        <div
                            className={`${styles.title} ${
                                mapList.totalSessionCnt === mapList.feedbackCnt ? styles.deactivate : styles.activate
                            } text_18_NotoSansKR_Bold`}
                        >
                            피드백
                        </div>
                        <div
                            className="text_18_NotoSansKR_Medium"
                            style={{
                                color: mapList.totalSessionCnt === mapList.feedbackCnt ? '#626466' : '#020202',
                            }}
                        >
                            세션 {mapList.feedbackCnt}까지 진행완료
                        </div>
                    </div>
                    <div className={styles.progress_button_container}>
                        {mapList.registerSessionIds.map((item: number, index: number) => (
                            <NumberBox
                                num={index + 1}
                                index={index}
                                finish={mapList.totalSessionCnt === mapList.feedbackCnt}
                                finishCount={mapList.feedbackCnt}
                                workBookType={workBookType}
                                sessionId={item}
                                assignment={false}
                            />
                        ))}
                    </div>
                </div>
                {/* <button className={`${styles.progress_status_button} text_18_NotoSansKR_Bold`}>{sessionList.buttonTitle}</button> */}
            </div>
        );
    };

    const Assignment = ({ mapList, workBookType }: any) => {
        return (
            <div className={styles.progress_content}>
                <div>
                    <div className={styles.title_container}>
                        <div
                            className={`${styles.title} ${
                                mapList.totalAssignmentCnt === mapList.finishCnt ? styles.deactivate : styles.activate
                            } text_18_NotoSansKR_Bold`}
                        >
                            과제
                        </div>
                        <div
                            className="text_18_NotoSansKR_Medium"
                            style={{
                                color: mapList.totalAssignmentCnt === mapList.finishCnt ? '#626466' : '#020202',
                            }}
                        >
                            세션 {mapList.finishCnt}까지 진행완료
                        </div>
                    </div>
                    <div className={styles.progress_button_container}>
                        {mapList.registerAssignmentIds &&
                            mapList.registerAssignmentIds.map((item: number, index: number) => (
                                <NumberBox
                                    num={index + 2}
                                    index={index}
                                    finish={mapList.totalAssignmentCnt === mapList.finishCnt}
                                    finishCount={mapList.finishCnt}
                                    workBookType={workBookType}
                                    sessionId={item}
                                    assignment={true}
                                />
                            ))}
                    </div>
                </div>
                {/* <button className={`${styles.progress_status_button} text_18_NotoSansKR_Bold`}>{sessionList.buttonTitle}</button> */}
            </div>
        );
    };

    return (
        <div className={styles.main_container}>
            <div className="text_32_NotoSansKR_Bold">지도</div>
            {data?.workBooks.map((item, index) => (
                <div key={index.toString()} className={styles.content_container}>
                    <div className={styles.program_status_container}>
                        <span className={'text_22_NotoSansKR_Bold'}>{item.workBookName}</span>
                        <div
                            className={`${styles.program_status} ${
                                item.sessionMap.totalSessionCnt === item.sessionMap.finishCnt
                                    ? styles.deactivate
                                    : styles.activate
                            }  text_16_NotoSansKR_Medium`}
                        >
                            <span>
                                {item.sessionMap.totalSessionCnt === item.sessionMap.finishCnt ? '종료' : '진행중'}
                            </span>
                        </div>
                    </div>
                    <div className={styles.progress_container}>
                        <Session mapList={item.sessionMap} workBookType={item.workBookType} />
                        <Feedback mapList={item.sessionMap} workBookType={item.workBookType} />
                        {item.assignmentMap !== null && (
                            <Assignment mapList={item.assignmentMap} workBookType={item.workBookType} />
                        )}
                    </div>
                </div>
            ))}
        </div>
    );
};

import { useDispatch, useSelector } from 'react-redux';
import { SquareCheckBox } from '../../../../../component/PublicComponent';
import { CategoryList } from '../ts/ProgramManagementApi';

import styles from './css/ProgramCategoryDropDown.module.css';
import { RootState } from '../../../../../store';
import { updateProgramCategoryVisible, updateWorkBooks } from '../../../../../features/programReducer';
import { useState } from 'react';

export const ProgramCategoryDropDown = ({ list }: { list: CategoryList }) => {
    const dispatch = useDispatch();
    const { programCategoryVisible, workBooks } = useSelector((state: RootState) => state.programModal);
    const [selectWorkBookList, setSelectWorkBookList] = useState<
        { workBookId: number; price: number | string; workBookName: string }[]
    >([]);
    const hasCommonId = (workBookName: string) =>
        selectWorkBookList.some((comparisonItem) => comparisonItem.workBookName === workBookName);

    const addWorkbook = (newWorkbook: { workBookId: number; price: number; workBookName: string }) => {
        const isDuplicate = selectWorkBookList.some((workBook) => workBook.workBookId === newWorkbook.workBookId);

        if (!isDuplicate) {
            setSelectWorkBookList([...selectWorkBookList, newWorkbook]);
        } else {
            const workBooksFilter = workBooks.filter((workBook) => workBook.workBookId !== newWorkbook.workBookId);
            if (workBooksFilter.length === 0) {
                setSelectWorkBookList([{ workBookId: 0, price: '', workBookName: '' }]);
            } else {
                setSelectWorkBookList(workBooksFilter);
            }
        }
    };

    const saveProgram = () => {
        dispatch(updateWorkBooks(selectWorkBookList));
        dispatch(updateProgramCategoryVisible(!programCategoryVisible));
    };

    return (
        <div className={styles.program_picker}>
            <div className={styles.item_container}>
                {list.categories.map((item, index) => (
                    <button key={index.toString()} className={styles.program_button} onClick={() => addWorkbook(item)}>
                        <SquareCheckBox bool={hasCommonId(item.workBookName)} size="1.5rem" />
                        <span className="text_16_NotoSansKR_Medium">{item.workBookName}</span>
                    </button>
                ))}
            </div>
            <div className={styles.button_container}>
                <button
                    onClick={() => dispatch(updateProgramCategoryVisible(!programCategoryVisible))}
                    className={`${styles.cancel_button} text_16_NotoSansKR_Bold`}
                >
                    취소
                </button>
                <button onClick={saveProgram} className={`${styles.save_button} text_16_NotoSansKR_Bold`}>
                    선택완료
                </button>
            </div>
        </div>
    );
};

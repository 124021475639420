import React from 'react';

import moment from 'moment';

import styles from './css/PaymentReceipt.module.css';
import { BasicHeader } from '../../component/HeaderComponent';
import { MainButton } from '../member/component/MainButton';
import { ReactComponent as Icon_payment_checkout } from '../../assets/payment/icon_payment_checkout.svg';

export const PaymentReceipt = () => {
    const today = new Date();

    return (
        <div>
            <BasicHeader />
            <div className={styles.main_container}>
                <Icon_payment_checkout
                    style={{
                        width: '5rem',
                        height: '5rem',
                        alignSelf: 'flex-start',
                    }}
                />
                <div className={styles.content_container}>
                    <div className={`text_32_NotoSansKR_Bold`}>결제가 완료되었습니다.</div>
                    <div className={styles.receipt_content_container}>
                        <div className={styles.receipt_content}>
                            <div className={`${styles.receipt_content_title} text_22_NotoSansKR_Bold`}>
                                참여 프로그램 정보
                            </div>
                            <div className={styles.item_container}>
                                <div className={styles.item}>
                                    <div className={`${styles.item_title} text_18_NotoSansKR_Medium`}>결제일자</div>
                                    <span className="text_18_Inter_Medium">
                                        {moment(today).format('YYYY-MM-DD HH:mm:ss')}
                                    </span>
                                </div>
                                <div className={styles.item}>
                                    <div className={`${styles.item_title} text_18_NotoSansKR_Medium`}>주문번호</div>
                                    <span className="text_18_Inter_Medium">2024014-1234</span>
                                </div>
                                <div className={styles.item}>
                                    <div className={`${styles.item_title} text_18_NotoSansKR_Medium`}>
                                        프로그램 유형
                                    </div>
                                    <div className={styles.item_content}>
                                        <span className="text_18_NotoSansKR_Medium">
                                            [비약물치료 프리미엄 패키지] 인지행동치료(대면형) + 이완치료 + TMS
                                        </span>
                                        {'\n'}
                                        <span
                                            className="text_18_NotoSansKR_Regular"
                                            style={{
                                                color: '#9D9FA2',
                                            }}
                                        >
                                            진행회기: 인지행동치료(대면형) 프로그램 10회 + 이완훈련 7회 + TMS 15회
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={styles.receipt_content}>
                            <div className={`${styles.receipt_content_title} text_22_NotoSansKR_Bold`}>선택 옵션</div>
                            <div className={styles.item_container}>
                                <div className={styles.item}>
                                    <div className={styles.item_title}>
                                        <span className="text_18_NotoSansKR_Medium">결제유형</span>
                                        {'\n'}
                                        <span
                                            className="text_18_NotoSansKR_Regular"
                                            style={{
                                                color: '#626466',
                                            }}
                                        >
                                            인지행동치료(대면형) 프로그램 가격 유형
                                        </span>
                                    </div>
                                    <span className="text_18_Inter_Medium">
                                        200<span className="text_18_NotoSansKR_Medium">만원</span>
                                    </span>
                                </div>
                                <div className={styles.item}>
                                    <div className={styles.item_title}>
                                        <span className="text_18_NotoSansKR_Medium">결제유형</span>
                                        {'\n'}
                                        <span
                                            className="text_18_NotoSansKR_Regular"
                                            style={{
                                                color: '#626466',
                                            }}
                                        >
                                            인지행동치료(대면형) 프로그램 가격 유형
                                        </span>
                                    </div>
                                    <span className="text_18_Inter_Medium">1회</span>
                                </div>
                            </div>
                        </div>
                        <div className={styles.receipt_content}>
                            <div className={`${styles.receipt_content_title} text_22_NotoSansKR_Bold`}>결제 정보</div>
                            <div className={styles.item_container}>
                                <div className={styles.item}>
                                    <div className={`${styles.item_title} text_18_NotoSansKR_Medium`}>결제자명</div>
                                    <span className="text_18_NotoSansKR_Medium">김환자</span>
                                </div>
                                <div className={styles.item}>
                                    <div className={`${styles.item_title} text_18_NotoSansKR_Medium`}>총 결제금액</div>
                                    <span className="text_18_Inter_Medium">
                                        3,130,000<span className="text_18_NotoSansKR_Medium">원</span>
                                    </span>
                                </div>
                                <div className={styles.item}>
                                    <div className={`${styles.item_title} text_18_NotoSansKR_Medium`}>결제수단</div>
                                    <div className={styles.item_content}>
                                        <span className="text_18_NotoSansKR_Medium">신용카드 (하나Pay)</span>
                                        {'\n'}
                                        <span
                                            className="text_18_NotoSansKR_Regular"
                                            style={{
                                                color: '#9D9FA2',
                                            }}
                                        >
                                            일시불
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    style={{
                        width: '16.875rem',
                    }}
                >
                    <MainButton title={'주호소 증상 선택하기'} onClick={() => {}} />
                </div>
            </div>
        </div>
    );
};

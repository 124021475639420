import React, { useContext, useEffect, useState } from 'react';

import styles from './css/ProgramPayment.module.scss';
import { BasicHeader } from '../../component/HeaderComponent';
import { useLocation, useNavigate } from 'react-router-dom';

import { ReactComponent as Icon_circle_check } from '../../assets/payment/icon_circle_checkbox.svg';
import { ReactComponent as Icon_circle_checkedBox } from '../../assets/payment/icon_circle_checkedBox.svg';
import styled from 'styled-components';
import axiosInstance from '../../utils/AxiosInstanceJava';
import { Product } from './@types/payment';
import CryptoJS from 'crypto-js'; // CryptoJS import 추가
import { ModalContext } from '../../context/ModalContext';
import { getMemberInfo } from '../../utils/GetMemberInfo';
import { PaymentModal } from './PaymentModal';

interface PaymentData {
    PayMethod: string;
    GoodsName: string;
    Amt: string;
    MID: string;
    Moid: string;
    BuyerName: string;
    BuyerEmail: string;
    BuyerTel: string;
    ReturnURL: string;
    VbankExpDate: string;
    NpLang: string;
    GoodsCl: string;
    TransType: string;
    CharSet: string;
    ReqReserved: string;
    EdiDate: string; // YYYYMMDDHHMISS 형식으로 설정 필요
    SignData: string; // 서명 데이터는 나중에 설정
    ConnWithIframe: string; // iframe 사용 설정
}

interface ItemButtonProps {
    $isChecked: Array<{ type: string; index: number }>;
    $type: string;
    $index: number;
    $lastIndex: number;
}

const getBorderAndBackground = (props: ItemButtonProps) => {
    const exists = props.$isChecked.some((item) => item.type === props.$type && item.index === props.$index);
    return {
        borderColor: exists ? '#3a5074' : '#E3E5EA',
        backgroundColor: exists ? '#f6f9ff' : '#FFF',
    };
};
// 기본 버튼 스타일
const ItemButton = styled.button<ItemButtonProps>`
    display: flex;
    align-items: center;
    box-sizing: border-box;
    width: 46rem;
    padding: 1.25rem 1.5rem;
    gap: 0.75rem;
    background: ${(props) => getBorderAndBackground(props).backgroundColor};
    border: 1px solid ${(props) => getBorderAndBackground(props).borderColor};
    border-radius: ${(props) =>
        props.$index === 0 ? '10px 10px 0px 0px;' : props.$index === props.$lastIndex ? '0px 0px 10px 10px;' : '0px'};
    cursor: pointer;
    transition: background 0.3s;
`;

export const ProgramPayment = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { paymentVisible, paymentOpenModal } = useContext(ModalContext);

    const queryParams = new URLSearchParams(location.search);
    const value: string | null = queryParams.get('selected'); // value는 string 또는 null일 수 있음
    const selectedItem = value ? JSON.parse(value) : '';
    const [isChecked, setIsChecked] = useState<{ type: string; index: number }[]>([
        { type: 'price', index: 0 },
        { type: 'count', index: 0 },
        { type: 'method', index: 0 },
        { type: 'organ', index: 0 },
    ]);

    const paymentMethod = ['신용카드', '계좌이체'];

    const [productData, setproductData] = useState<Product>();
    const installmentPayment =
        productData?.product.workBookList[
            productData?.product.workBookList.findIndex((item) => item.splitAllowed === true)!
        ];
    const discountPrice = productData?.product.discountPrice !== 0 ? true : false;

    const price = discountPrice ? productData?.product.discountPrice : installmentPayment!.price;
    useEffect(() => {
        getProgramDetail();
    }, []);

    const getProgramDetail = async () => {
        await axiosInstance.post(`/api/workBook/sale/${Number(selectedItem)}/v1`).then((response) => {
            if (response.data.result) {
                setproductData(response.data);
            }
        });
    };

    const arryIndex = (type: string) => {
        const findIndex = isChecked.findIndex((item) => item.type === type);
        return findIndex;
    };

    const selectItem = (type: string, index: number) => {
        // 새로운 항목
        const newItem = { type: type, index: index };

        // 기존 항목 중복 제거
        const filteredItems = isChecked.filter((item) => !(item.type === type));

        // 새로운 항목 추가
        setIsChecked([...filteredItems, newItem]);
    };

    const paymentSuccess = () => {
        navigate('/paymentResult');
    };

    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [paymentUrl, setPaymentUrl] = useState<string>('');

    const getCurrentDate = (): string => {
        const date = new Date();
        return date
            .toISOString()
            .replace(/[-T:.Z]/g, '')
            .slice(0, 14); // "yyyyMMddHHmmss" 형식
    };

    const merchantKey = '33F49GnCMS1mFYlGXisbUDzVf2ATWCl9k3R++d5hDd3Frmuos/XLx8XhXpe+LDYAbpGKZYSwtlyyLOtS/8aD7A=='; // 상점 키 입력

    // 서명 데이터 생성 함수
    const getSignData = (ediDate: string, MID: string, price: string): string => {
        return CryptoJS.SHA256(`${ediDate}${MID}${price}${merchantKey}`).toString();
    };

    const paymentData = {
        GoodsName: productData?.product.productName,
        Amt: '1000',
        MID: 'nictest00m',
        EdiDate: getCurrentDate(),
        Moid: `nice_api_test_${Date.now()}`,
        SignData: getSignData(getCurrentDate(), 'nictest00m', '1000'), // 서명 데이터는 나중에 설정
        PayMethod: '',
        BuyerName: getMemberInfo()!.memberName,
        BuyerEmail: getMemberInfo()!.memberEmail,
        BuyerTel: getMemberInfo()!.memberPhone,
        ReqReserved: '',
        VbankExpDate: '',
        CharSet: 'utf-8',
        GoodsCl: '0',
        ConnWithIframe: 'Y', // iframe 사용 설정
    };

    const handlePaymentRequest = async () => {
        // EdiDate 및 SignData 설정
        const memberInfo = getMemberInfo();
        if (productData && memberInfo) {
            const ediDate = getCurrentDate();
            const signData = getSignData(ediDate, paymentData.MID, paymentData.Amt); // 서명 데이터 생성

            // const existingForm = document.querySelector('form[name="payForm"]');
            // if (existingForm) {
            //     // 모든 input 요소의 값을 초기화
            //     const inputs = existingForm.querySelectorAll('input');
            //     inputs.forEach((input) => {
            //         input.value = '';
            //     });
            // }

            // 폼 데이터를 위한 URL 생성
            // const paymentUrl = `https://pg-web.nicepay.co.kr/v3/gwPayment.jsp?${new URLSearchParams(
            //     requestData
            // ).toString()}`;
            // paymentOpenModal(true, paymentUrl);

            // const payForm = document.createElement('form');
            // payForm.name = 'payForm';
            // payForm.method = 'POST';
            // payForm.action = 'https://yd-icbt.co.kr/payResult/v1';

            // // // paymentData의 각 항목을 input으로 추가
            // for (const key in requestData) {
            //     const input = document.createElement('input');
            //     if (key === 'EdiDate' || key === 'SignData') {
            //         input.type = 'hidden';
            //     } else {
            //         input.type = 'hidden';
            //     }
            //     input.name = key;
            //     input.value = requestData[key];
            //     payForm.appendChild(input);
            // }
            // console.log('Request Data:', payForm);
            // console.log('Form Action:', payForm.action);
            // console.log('Form Method:', payForm.method);
            // document.body.appendChild(payForm); // Form을 DOM에 추가
            nicepayStart();
        }
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const getyyyyMMddHHmmss = () => {
        const now = new Date();

        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0'); // 월은 0부터 시작
        const day = String(now.getDate()).padStart(2, '0');
        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');
        const seconds = String(now.getSeconds()).padStart(2, '0');

        return `${year}${month}${day}${hours}${minutes}${seconds}`;
    };

    // 현재 날짜를 YYYYMMDDHHMISS 형식으로 반환하는 함수

    const IconCircleCheck = ({ type, index }: { type: string; index: number }) => {
        const exists = isChecked.some((item) => item.type === type && item.index === index);
        if (exists) {
            return <Icon_circle_checkedBox width={'2rem'} height={'2rem'} />;
        } else {
            return <Icon_circle_check width={'2rem'} height={'2rem'} />;
        }
    };

    // 프로그램
    const Program = () => {
        return (
            <div className={styles.item_container}>
                <span className="text_22_NotoSansKR_Bold">프로그램</span>
                <div className={styles.program_content}>
                    <div className={styles.title}>
                        <span className="text_18_NotoSansKR_Medium">{productData?.product.productName}</span>
                        <span className="text_18_NotoSansKR_Regular" style={{ color: '#9D9FA2' }}>
                            진행회기: {productData?.product.productDescription}
                        </span>
                    </div>

                    <div className={styles.price}>
                        <span className="text_18_Inter_Medium">
                            {discountPrice
                                ? productData?.product.discountPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                : productData?.product.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            <span className="text_18_NotoSansKR_Medium">원</span>
                        </span>
                    </div>
                </div>
            </div>
        );
    };

    // 가격
    const Price = () => {
        if (productData?.type === 'face') {
            return (
                <div className={styles.item_container}>
                    <span className="text_22_NotoSansKR_Bold">가격</span>
                    <span
                        className="text_18_NotoSansKR_Regular"
                        style={{
                            color: '#626466',
                        }}
                    >
                        인지행동치료(대면형) 프로그램의 가격 유형을 선택해 주세요.
                    </span>
                    <div className={styles.item_button_container}>
                        <ItemButton
                            onClick={() => selectItem('price', 0)}
                            $isChecked={isChecked}
                            $type="price"
                            $index={0}
                            $lastIndex={1}
                        >
                            <IconCircleCheck type={'price'} index={0} />
                            <span
                                className={`${
                                    isChecked[arryIndex('price')].index === 0
                                        ? 'text_18_NotoSansKR_Bold'
                                        : 'text_18_NotoSansKR_Medium'
                                }`}
                                style={{
                                    color: isChecked[arryIndex('price')].index === 0 ? '#3A5074' : '#020202',
                                }}
                            >
                                {(productData.splitAllowed ? price! / 2 : price!) / 10000 + '만원'}
                            </span>
                        </ItemButton>
                        {productData.splitAllowed && (
                            <ItemButton
                                onClick={() => selectItem('price', 1)}
                                $isChecked={isChecked}
                                $type="price"
                                $index={1}
                                $lastIndex={1}
                            >
                                <IconCircleCheck type={'price'} index={1} />
                                <span
                                    className={`${
                                        isChecked[arryIndex('price')].index === 1
                                            ? 'text_18_NotoSansKR_Bold'
                                            : 'text_18_NotoSansKR_Medium'
                                    }`}
                                    style={{
                                        color: isChecked[arryIndex('price')].index === 1 ? '#3A5074' : '#020202',
                                    }}
                                >
                                    {price! / 2 / 10000 + '만원'}
                                </span>
                            </ItemButton>
                        )}
                    </div>
                </div>
            );
        } else {
            return null;
        }
    };

    // 결제 횟수
    const Count = () => {
        if (productData?.type === 'face') {
            return (
                <div className={styles.item_container}>
                    <span className="text_22_NotoSansKR_Bold">결제 횟수</span>
                    <span
                        className={`text_18_NotoSansKR_Regular`}
                        style={{
                            color: '#626466',
                        }}
                    >
                        인지행동치료(대면형) 프로그램의 결제 횟수를 선택해 주세요.
                    </span>
                    <div className={styles.item_button_container}>
                        <ItemButton
                            onClick={() => selectItem('count', 0)}
                            $isChecked={isChecked}
                            $type="count"
                            $index={0}
                            $lastIndex={0}
                        >
                            <IconCircleCheck type={'count'} index={0} />
                            <span
                                className={`${
                                    isChecked[arryIndex('count')].index === 0
                                        ? 'text_18_NotoSansKR_Bold'
                                        : 'text_18_NotoSansKR_Medium'
                                }`}
                                style={{
                                    color: isChecked[arryIndex('count')].index === 0 ? '#3A5074' : '#020202',
                                }}
                            >
                                1회 (한 번에 결제)
                            </span>
                        </ItemButton>
                        {productData.splitAllowed && (
                            <ItemButton
                                onClick={() => selectItem('count', 1)}
                                $isChecked={isChecked}
                                $type="count"
                                $index={1}
                                $lastIndex={1}
                            >
                                <IconCircleCheck type={'count'} index={1} />
                                <span
                                    className={`${
                                        isChecked[arryIndex('count')].index === 1
                                            ? 'text_18_NotoSansKR_Bold'
                                            : 'text_18_NotoSansKR_Medium'
                                    }`}
                                    style={{
                                        color: isChecked[arryIndex('count')].index === 1 ? '#3A5074' : '#020202',
                                    }}
                                >
                                    2회 (각 1회차, 6회차에 두 번 나눠서 결제)
                                </span>
                            </ItemButton>
                        )}
                    </div>
                </div>
            );
        } else {
            return null;
        }
    };

    // 결제 수단
    const Method = () => {
        return (
            <div className={styles.item_container}>
                <span className="text_22_NotoSansKR_Bold">결제 수단</span>
                <div className={styles.item_button_container}>
                    {paymentMethod.map((item, index) => (
                        <ItemButton
                            key={index.toString()}
                            onClick={() => selectItem('method', index)}
                            $isChecked={isChecked}
                            $type="method"
                            $index={index}
                            $lastIndex={paymentMethod.length - 1}
                        >
                            <IconCircleCheck type={'method'} index={index} />
                            <span
                                className={`${
                                    isChecked[arryIndex('method')].index === index
                                        ? 'text_18_NotoSansKR_Bold'
                                        : 'text_18_NotoSansKR_Medium'
                                }`}
                                style={{
                                    color: isChecked[arryIndex('method')].index === index ? '#3A5074' : '#020202',
                                }}
                            >
                                {item}
                            </span>
                        </ItemButton>
                    ))}
                </div>
            </div>
        );
    };

    // 치료기관
    const Organ = () => {
        if (productData?.type === 'face') {
            return (
                <div className={styles.item_container}>
                    <span className="text_22_NotoSansKR_Bold">치료기관</span>
                    <span>통원 중이신 치료 기관을 선택해 주세요.</span>
                    <div className={styles.item_button_container}>
                        <ItemButton
                            onClick={() => selectItem('organ', 0)}
                            $isChecked={isChecked}
                            $type="organ"
                            $index={0}
                            $lastIndex={2}
                        >
                            <IconCircleCheck type={'organ'} index={0} />
                            <span
                                className={`${
                                    isChecked[arryIndex('organ')].index === 0
                                        ? 'text_18_NotoSansKR_Bold'
                                        : 'text_18_NotoSansKR_Medium'
                                }`}
                                style={{
                                    color: isChecked[arryIndex('organ')].index === 0 ? '#3A5074' : '#020202',
                                }}
                            >
                                부산
                            </span>
                        </ItemButton>
                        <ItemButton
                            onClick={() => selectItem('organ', 1)}
                            $isChecked={isChecked}
                            $type="organ"
                            $index={1}
                            $lastIndex={2}
                        >
                            <IconCircleCheck type={'organ'} index={1} />
                            <span
                                className={`${
                                    isChecked[arryIndex('organ')].index === 1
                                        ? 'text_18_NotoSansKR_Bold'
                                        : 'text_18_NotoSansKR_Medium'
                                }`}
                                style={{
                                    color: isChecked[arryIndex('organ')].index === 0 ? '#3A5074' : '#020202',
                                }}
                            >
                                창원
                            </span>
                        </ItemButton>
                        <ItemButton
                            // onClick={() => selectItem('organ', 2)}
                            $isChecked={isChecked}
                            $type="organ"
                            $index={2}
                            $lastIndex={2}
                        >
                            <IconCircleCheck type={'organ'} index={2} />
                            <span
                                className={`${
                                    isChecked[arryIndex('organ')].index === 2
                                        ? 'text_18_NotoSansKR_Bold'
                                        : 'text_18_NotoSansKR_Medium'
                                }`}
                                style={{
                                    color: '#D2D5D9',
                                }}
                            >
                                예정
                            </span>
                        </ItemButton>
                    </div>
                </div>
            );
        } else {
            return null;
        }
    };

    return (
        <div className={styles.main_container}>
            <BasicHeader />
            <div className={styles.content_container}>
                <div className={styles.payment_content_container}>
                    <span className="text_32_NotoSansKR_Bold">결제</span>
                    <Program />
                    <Price />
                    <Count />
                    <Method />
                    <Organ />
                </div>
                <div className={styles.payment_container}>
                    <div>
                        <div className={styles.total_price}>
                            <span className="text_22_NotoSansKR_Bold">총 결제금액</span>
                            <span className="text_22_Inter_Bold">
                                {productData &&
                                    (productData.product.discountPrice / 2)
                                        .toString()
                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}

                                {/* {productData?.productData.discountPrice !== 0
                                        ? productData?.productData.discountPrice
                                              .toString()
                                              .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                        : productData.productData.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} */}
                                <span className="text_22_NotoSansKR_Bold">원</span>
                            </span>
                        </div>
                        <div className={`${styles.payment_teram} text_16_NotoSansKR_Regular`}>
                            회원 본인은 주문내용을 확인했으며,{' '}
                            <a
                                onClick={() => navigate('/yd/policy/performance')}
                                style={{ textDecoration: 'underline' }}
                            >
                                환불 규정 및 이용 약관
                            </a>
                            과 결제에 동의합니다.
                        </div>

                        <button
                            onClick={() =>
                                //  navigate('/yd/patient/payment/receipt')
                                {
                                    handlePaymentRequest();
                                }
                            }
                            className={`${styles.payment_button} text_18_NotoSansKR_Bold`}
                        >
                            결제하기
                        </button>
                    </div>
                    <button
                        onClick={() => navigate(-1)}
                        className={`${styles.payment_cancel_button} text_18_NotoSansKR_Bold`}
                    >
                        취소
                    </button>
                </div>
            </div>
            {/* {paymentVisible && <PaymentModal />} */}
            <form name="payForm" method="POST" action="https://yd-icbt.co.kr/api/common/payResult/v1 ">
                <input type="hidden" name="GoodsName" value={paymentData.GoodsName} />
                <input type="hidden" name="Amt" value={paymentData.Amt} />
                <input type="hidden" name="MID" value={paymentData.MID} />
                <input type="hidden" name="EdiDate" value={paymentData.EdiDate} />
                <input type="hidden" name="Moid" value={paymentData.Moid} />
                <input type="hidden" name="SignData" value={paymentData.SignData} />
                <input type="hidden" name="PayMethod" value={paymentData.PayMethod} />
                <input type="hidden" name="BuyerName" value={paymentData.BuyerName} />
                <input type="hidden" name="BuyerTel" value={paymentData.BuyerTel} />
                <input type="hidden" name="ReqReserved" value={paymentData.ReqReserved} />
                <input type="hidden" name="BuyerEmail" value={paymentData.BuyerEmail} />
                <input type="hidden" name="CharSet" value={paymentData.CharSet} />
                <input type="hidden" name="VbankExpDate" value={paymentData.VbankExpDate} />
                <input type="hidden" name="GoodsCl" value={paymentData.GoodsCl} />
                <input type="hidden" name="ConnWithIframe" value={paymentData.ConnWithIframe} />
            </form>
        </div>
    );
};

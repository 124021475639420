import styles from './css/policy.module.css';

export const UniqueIdentifierConsent = () => {
    const Title = ({ text }: { text: string }) => {
        return <span className="text_22_NotoSansKR_Bold">{text}</span>;
    };

    const Content = ({ text }: { text: string }) => {
        return <span className="text_16_NotoSansKR_Medium">{text}</span>;
    };

    return (
        <div className={styles.main_container}>
            <div className={styles.content_container}>
                <span className="text_32_NotoSansKR_Bold">고유식별정보 처리동의서</span>
                <div className={styles.content}>
                    <Content
                        text="
                        와이디 퍼포먼스(이하 '회사'라고 합니다)는 개인정보보호법 등 관련 법령상의 개인정보보호 규정을 준수하며 회원의 개인정보보호에 최선을 다하고 있습니다. 회사는 개인정보보호법 제24조에 근거하여 다음과 같이 고유식별정보 처리에 관한 동의를 받고자 합니다."
                    />
                    {'\n'}
                    <Content text="다음의 내용을 자세히 읽어보신 후 동의 여부를 결정하여 주십시오." />
                </div>
                <div className={styles.content}>
                    <Title text="1. 처리하는 고유식별정보" />
                    <Content text="주민등록법 제7조의2 제1항에 따른 생년월일" />
                </div>
                <div className={styles.content}>
                    <Title text="2. 고유식별정보의 처리 목적" />
                    <ul>
                        <Content text="1. 중복가입 방지를 위한 이용자 인적사항 확인" />
                    </ul>
                    <ul>
                        <Content text="2. 개인별 맞춤형 프로그램 제공" />
                    </ul>
                </div>
                <div className={styles.content}>
                    <Title text="3. 고유식별정보의 보유기간" />
                    <Content text="1. 정보주체의 회원 탈퇴일까지" />
                </div>
                <div className={styles.content}>
                    <Title text="4. 동의 거부 시 불이익 안내" />
                    <Content text="회원께서는 고유식별정보의 처리에 대한 동의를 거부할 권리가 있습니다. 다만, 동의를 거부할 경우 회원 가입 불가 등의 불이익이 있을 수 있습니다." />
                </div>
                <div className={styles.content}>
                    <Content text="5. 고유식별정보의 제3자 제공" />
                    <ul>
                        <Content text="1. 고유식별정보를 제공 받는 제3자 : (주) 제이엔제이테크 " />
                        <ol>
                            <Content text="a. 고유식별정보 제공 목적 : 본인확인, 결제정보 확인" />
                        </ol>
                        <ol>
                            <Content text="b. 고유식별정보 제공 항목 : 주민등록번호" />
                        </ol>
                        <ol>
                            <Content text="c. 고유식별정보 보유 및 이용기간 : 회원 탈퇴시 까지" />
                        </ol>
                        <ol>
                            <Content text="d. 고유식별정보 제공 거부 시 불이익 : 회원가입 불가 " />
                        </ol>
                    </ul>
                </div>
                <div className={styles.content} style={{
                    gap: 0
                }}>
                    <Content text="※ 고유식별정보 처리의 위탁" />
                    <ul>
                        <Content text="1. 고유식별정보 처리를 위탁 받는 자 : (주) 제이엔제이테크 " />
                        <ol>
                            <Content text="a. 고유식별정보 처리 위탁 목적 : 본인 확인, 결제정보" />
                        </ol>
                        <ol>
                            <Content text="b. 고유식별정보 처리 위탁 기간 : 회원 탈퇴시" />
                        </ol>
                    </ul>
                </div>
                <div className={styles.content}>
                    <Content
                        text="본인은 본 동의서의 내용을 모두 이해하였으며, 고유식별정보의 처리에 관한 회원 권리 안내문에 관하여 자세한 설명을 듣고 내용에 동의합니다. "
                    />
                </div>
                <div className={styles.content}>
                    <span className="text_16_NotoSansKR_Bold">2024년 10월 03일</span>
                </div>
            </div>
        </div>
    );
};

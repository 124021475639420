import axios from 'axios';
import { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateEmailAddress, updateMemberAccount, updatememberEmail } from '../../../features/signupReducer';
import { updateLoginMemberAccount, updateLoginMemberPassword } from '../../../features/loginReducer';
import { useNavigate } from 'react-router-dom';
import { normalLoginFn } from './types/LoginFn';
import { RootState } from '../../../store';
import axiosInstance from '../../../utils/AxiosInstanceJava';
import { ModalContext } from '../../../context/ModalContext';

export const KakaoLogin = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { oneButtonOpenModal } = useContext(ModalContext);
    const memberType = useSelector((state: RootState) => state.memberType);
    const login = useSelector((state: RootState) => state.login);

    const params = new URLSearchParams(window.location.search);
    const code = params.get('code'); // 카카오에서 받은 인증 코드

    useEffect(() => {
        const getKakaoToken = async () => {
            if (code) {
                try {
                    await axios
                        .get('/api/auth/callback/kakao', {
                            params: {
                                code: code,
                            },
                        })
                        .then((response) => {
                            if (response.data.result) {
                                const res = response.data;
                                const emailParts = res.email.split('@');
                                console.log(response.data);
                                if (res.type === 'login') {
                                    const loginData = {
                                        memberAccount: res.kakaoId,
                                        kakaoEmail: res.email,
                                        memberPassword: '',
                                        memberOrgan: login.memberOrgan,
                                    };
                                    normalLoginFn(
                                        memberType.type === 0 ? 'user' : 'employee',
                                        loginData,
                                        false,
                                        axiosInstance,
                                        navigate,
                                        'kakao',
                                        oneButtonOpenModal
                                    );
                                } else {
                                    dispatch(updatememberEmail(emailParts[0]));
                                    dispatch(updateEmailAddress(emailParts[1]));
                                    dispatch(updateMemberAccount(res.kakaoId));
                                    navigate('/public/signup');
                                }
                            }
                        });
                    // 서버로부터 받은 사용자 정보 처리
                } catch (error) {
                    console.error('로그인 실패:', error);
                }
            }
        };

        getKakaoToken();
    }, [navigate]);

    return (
        <div>
            <span>카카오 로그인중</span>
        </div>
    );
};

import React, { useContext, useEffect, useState } from 'react';
import { PatientHeader } from '../../../../../component/HeaderComponent';
import { PatientLeftMenu } from '../../../../../component/MenuComponent';
import { ModalContext } from '../../../../../context/ModalContext';
import { Route, Routes, useLocation } from 'react-router-dom';
import styles from '../css/Main.module.css';
import { SessionMap } from '../../SessionMap';
import { MemberInfo } from '../../../../public/MemberInfo';
import { NotificationSetting } from '../../../../public/NotificationSetting';

import { SecondSession } from './nonFaceSession/SecondSession';
import { ThirdSession } from './nonFaceSession/ThirdSession';
import { FourthSession } from './nonFaceSession/FourthSession';
import { FifthSession } from './nonFaceSession/FifthSession';
import { SixthSession } from './nonFaceSession/SixthSession';
import { SeventhSession } from './nonFaceSession/SeventhSession';
import { EighthSession } from './nonFaceSession/EighthSession';
import { FirstSession } from './nonFaceSession/FirstSession';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { getSessionMenu } from '../ts/SessionApi';
import { updateSessionMenu } from '../../../../../features/sessionMenuReducer';
import { decryptData } from '../../../../../utils/croptojs';

export const NonFaceMain = () => {
    const dispatch = useDispatch();
    const visible = useSelector((state: RootState) => state.navVisible);
    const location = useLocation();
    const lastSegment = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);
    const queryParams = new URLSearchParams(location.search);
    const encryptedData = queryParams.get('page');
    const programId = queryParams.get('program');

    const { data, isLoading, error } = useQuery({
        queryKey: ['sessionMenu', programId],
        queryFn: () => getSessionMenu(programId!), // TypeScript에서 null이 아님을 보장
        placeholderData: keepPreviousData,
        enabled: programId !== null,
    });

    useEffect(() => {
        if (data) {
            dispatch(updateSessionMenu(data));
        }
    }, [data, dispatch]);

    if (isLoading) return <div>Loading...</div>;
    if (error) return <div>Error: {error.message}</div>;

    const renderSessionPage = (isAssigned: boolean) => {
        if (!encryptedData) {
            return <div>No encrypted data provided.</div>; // encryptedData 없을 때 처리
        }

        const sessionNumber = Number(encryptedData!);
        const SessionComponent = SessionComponents[sessionNumber];

        return SessionComponent ? <SessionComponent /> : null; // 해당 세션 컴포넌트가 존재할 경우 렌더링
    };

    const SessionComponents: any = {
        1: FirstSession,
        2: SecondSession,
        3: ThirdSession,
        4: FourthSession,
        5: FifthSession,
        6: SixthSession,
        7: SeventhSession,
        8: EighthSession,
    };

    // const AssignedSessionComponents: any = {
    //     1: AssignedFirstSession,
    //     2: AssignedSecondSession,
    //     3: AssignedThirdSession,
    //     4: AssignedFourthSession,
    //     5: AssignedFifthSession,
    //     6: AssignedSixthSession,
    //     7: AssignedSeventhSession,
    //     8: AssignedEighthSession,
    // };

    const headerTitle = () => {
        switch (lastSegment) {
            case 'firstSession':
                return '세션1: ';
            case 'secondSession':
                return '세션2: ';
            case 'thirdSession':
                return '세션3: ';
            case 'fourthSession':
                return '세션4: ';
            case 'fifthSession':
                return '세션5: ';
            case 'sixthSession':
                return '세션6: ';
            case 'seventhSession':
                return '세션7: ';
            case 'eighthSession':
                return '세션8: ';
            case 'ninthSession':
                return '세션9: ';
            case 'tenthSession':
                return '세션10: ';
            case 'sessionMap':
                return '';
            default:
                return '';
        }
    };

    return (
        <div className={styles.main_container}>
            <PatientHeader title={headerTitle() + '생각의 습관에서 자유로워지다'} />
            <PatientLeftMenu />
            <div className={`${styles.content_container} ${visible.navVisible ? styles.activate : styles.deactivate}`}>
                <Routes>
                    <Route path="session" element={renderSessionPage(false)} />

                    <Route path="sessionMap" element={<SessionMap />} />
                    <Route path="memberInfo" element={<MemberInfo />} />
                    <Route path="notificationSetting" element={<NotificationSetting />} />
                </Routes>
            </div>
        </div>
    );
};

// src/PieChart.js
import React from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, Title, Tooltip, Legend, ArcElement } from 'chart.js';

import { Doughnut, Pie } from 'react-chartjs-2';
ChartJS.register(CategoryScale, LinearScale, ArcElement, Title, Tooltip, Legend);

export const PieChart = () => {
    const data = {
        labels: ['빨강', '파랑', '노랑', '연두'],
        datasets: [
            {
                label: '# of Votes',
                data: [12, 19, 3, 5],
                backgroundColor: [
                    'rgba(255, 99, 132, 0.6)',
                    'rgba(54, 162, 235, 0.6)',
                    'rgba(255, 206, 86, 0.6)',
                    'rgba(75, 192, 192, 0.6)',
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                ],
                borderWidth: 1,
            },
        ],
    };

    const options = {
        onHover: (event: any, chartElement: any) => {
            const target = event.native ? event.native.target : event.target;
            target.style.cursor = chartElement[0] ? 'pointer' : 'default';
        },
    };

    return (
        <div>
            <h2>원형 통계 그래프</h2>
            <Doughnut data={data} options={options} />
        </div>
    );
};

import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface Blackboard {
    blackboardVisible: boolean;
    colorPickerVisible: boolean;
    lineWidth: number;
    lineColor: string;
}

const initialState: Blackboard = {
    blackboardVisible: false,
    colorPickerVisible: false,
    lineWidth: 1.5,
    lineColor: '#000000',
};

const blackboardReducer = createSlice({
    name: 'blackboard',
    initialState,
    reducers: {
        updateBlackboardVisible: (state, action: PayloadAction<boolean>) => {
            state.blackboardVisible = action.payload;
        },
        updateColorPickerVisible: (state, action: PayloadAction<boolean>) => {
            state.colorPickerVisible = action.payload;
        },
        updateLineWidth: (state, action: PayloadAction<number>) => {
            state.lineWidth = action.payload;
        },
        updateLineColor: (state, action: PayloadAction<string>) => {
            state.lineColor = action.payload;
        },
    },
});

export const { updateBlackboardVisible, updateColorPickerVisible, updateLineWidth, updateLineColor } =
    blackboardReducer.actions;
export default blackboardReducer.reducer;

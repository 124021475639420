import styles from '../../css/FourthSession.module.css';
import '../../../css/PublicSessionStyle.css';

import { useRef, useState } from 'react';
import {
    BottomFiveSeven,
    CognitiveError,
    Quiz,
    SituationEmotion,
    ThoughtSiuation,
} from '../../component/PublicSessionComponent';
import { useLocation } from 'react-router-dom';

export const TherapistFourthSession = () => {
    const location = useLocation();
    const currentSession = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);
    return (
        <div className={'therapist_main_container'}>
            <Quiz />
            <SituationEmotion currentSession={currentSession} />

            <ThoughtSiuation />
            <BottomFiveSeven />
            <CognitiveError />
        </div>
    );
};

import { useState } from 'react';
import styles from './css/NotificationComponent.module.css';

export const Notification = () => {
    const [isSelected, setIsSelected] = useState<string>('전체');
    const [categoryList, setCategoryList] = useState(['전체', '세션']);
    const [contentList, setContentList] = useState([
        {
            title: '가입을 환영합니다. oo님의 변화와 성장을 위해 YD가 늘 함께 하겠습니다.',
            createDate: '1초 전',
        },
        {
            title: '가입을 환영합니다. oo님의 변화와 성장을 위해 YD가 늘 함께 하겠습니다.',
            createDate: '1초 전',
        },
        {
            title: '가입을 환영합니다. oo님의 변화와 성장을 위해 YD가 늘 함께 하겠습니다.',
            createDate: '1초 전',
        },
    ]);

    const CategoryMenu = () => {
        return (
            <div className={styles.menu_container}>
                {categoryList.map((item, index) => (
                    <button
                        key={index.toString()}
                        onClick={() => setIsSelected(item)}
                        className={`${styles.menu_button} ${
                            isSelected === item ? styles.activate : styles.deactivate
                        } ${isSelected === item ? 'text_18_NotoSansKR_Bold' : 'text_18_NotoSansKR_Medium'}`}
                    >
                        <span>{item}</span>
                    </button>
                ))}
            </div>
        );
    };

    const NotificationContent = () => {
        return (
            <div className={styles.content}>
                {contentList.map((item, index) => (
                    <button key={index.toString()} className={styles.content_item}>
                        <span className="text_18_NotoSansKR_Medium">{item.title}</span>
                        <span
                            className="text_18_NotoSansKR_Regular"
                            style={{
                                color: '#9D9FA2',
                            }}
                        >
                            {item.createDate}
                        </span>
                    </button>
                ))}
            </div>
        );
    };

    return (
        <div className={styles.modal_main_container}>
            <div className={styles.title_container}>
                <span className="text_28_NotoSansKR_Bold">알림</span>
                <span
                    className="text_18_NotoSansKR_Bold"
                    style={{
                        color: '#3A5074',
                    }}
                >
                    모두 읽음 표시
                </span>
            </div>
            <div className={styles.content_container}>
                <CategoryMenu />
                <NotificationContent />
                <div className={`${styles.exp_date} text_18_NotoSansKR_Regular`}>
                    알림은 최대{' '}
                    <span
                        className="text_18_Inter_Regular"
                        style={{
                            color: '#626466',
                        }}
                    >
                        30
                    </span>
                    일간 보관됩니다.
                </div>
            </div>
        </div>
    );
};

import { useRef, useState } from 'react';
import styles from '../../css/SixthSession.module.css';
import '../../../css/PublicSessionStyle.css';

import {
    AuthmaticThinkingGraph,
    SocraticQuestionAnswer,
    SocraticQuestionAnswerStep,
    SubmitWorkbook,
} from '../../component/PublicSessionComponent';

import Img1 from '../../../../../../assets/face/session6/session6_automatic_accident.png';
import { useLocation } from 'react-router-dom';

export const TherapistSixthSession = () => {
    const location = useLocation();
    const currentSession = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);
    const [automaticAccident, setAutomaticAccident] = useState([
        {
            title: 'a.',
            therapist: '',
            patient: '',
        },
        {
            title: 'b.',
            therapist: '',
            patient: '',
        },
        {
            title: 'c.',
            therapist: '',
            patient: '',
        },
        {
            title: 'd.',
            therapist: '',
            patient: '',
        },
        {
            title: 'e.',
            therapist: '',
            patient: '',
        },
        {
            title: 'f.',
            therapist: '',
            patient: '',
        },
        {
            title: 'g.',
            therapist: '',
            patient: '',
        },
    ]);

    return (
        <div className={'therapist_main_container'}>
            <SocraticQuestionAnswerStep />
            <AuthmaticThinkingGraph />
        </div>
    );
};

import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import styled from 'styled-components';
import { useMemo } from 'react';

interface IdAndNameInputProps {
    isFocused: number;
    memberName: string;
    memberId: string;
    inputFocused: Function;
    resetFocuse: Function;
    onChange: Function;
}

interface InputProps {
    $index: number;
    $isFocused: number;
}

const InputContentContainer = styled.div`
    display: flex;
    align-items: flex-start;
    width: 100%;
    flex-direction: column;
    gap: 1rem;
`;

const InputPossible = styled.input<InputProps>`
    box-sizing: border-box;
    width: 100%;
    height: 4rem;
    padding: 1.18rem 1.25rem;
    border-radius: 10px;
    outline: ${(props) => (props.$isFocused === props.$index ? '0.1875rem solid #3A5074' : '1px solid #e3e5ea')};
    background: #fff;
`;

export const IdAndNameInput = ({
    isFocused,
    memberName,
    memberId,
    inputFocused,
    resetFocuse,
    onChange,
}: IdAndNameInputProps) => {
    const memberType = useSelector((state: RootState) => state.memberType);

    const InputRender = useMemo(() => {
        return (
            <InputContentContainer>
                <span className="text_18_NotoSansKR_Bold" style={{ color: '#626466' }}>
                    {memberType.type === 0 ? '이름' : '아이디'}
                </span>
                <InputPossible
                    $isFocused={isFocused}
                    $index={memberType.type === 0 ? 0 : 1}
                    value={memberType.type === 0 ? memberName : memberId}
                    onChange={(e) =>
                        memberType.type === 0 ? onChange('name', e.target.value) : onChange('id', e.target.value)
                    }
                    onFocus={() => inputFocused(memberType.type === 0 ? 0 : 1)}
                    onBlur={() => resetFocuse()} // 필요 시 이 로직을 수정
                    className="text_18_NotoSansKR_Regular"
                    placeholder={memberType.type === 0 ? '이름 입력' : '아이디 입력'}
                />
            </InputContentContainer>
        );
    }, [memberType.type, memberName, memberId, inputFocused, resetFocuse]);

    return InputRender;
};

// export const FindIdSuccess = () => {
//     return(

//     )
// }

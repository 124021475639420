import styles from '../../css/EighthSession.module.css';
import '../../../css/PublicSessionStyle.css';

import {
    AdvantageAutomaticAccidents,
    AutomaticAccidentInspection,
    LearnedFelt,
    SubmitWorkbook,
} from '../../component/PublicSessionComponent';
import { ThoughtFeel, ThoughtFeelAnswer } from '../../component/TenthSessionComponent';
import { useLocation } from 'react-router-dom';

export const TherapistEighthSession = () => {
    const location = useLocation();
    const currentSession = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);
    return (
        <div className={'therapist_main_container'}>
            <AdvantageAutomaticAccidents />
            <AutomaticAccidentInspection />
            <LearnedFelt />
            <ThoughtFeelAnswer />
            <ThoughtFeel />
        </div>
    );
};

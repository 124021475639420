import React, { useContext, useEffect, useState } from 'react';
import { PatientHeader } from '../../../../../component/HeaderComponent';
import { PatientLeftMenu } from '../../../../../component/MenuComponent';
import { SymptomSelectModal } from '../../component/SymptomSelectModal';
import { ModalContext } from '../../../../../context/ModalContext';
import { Route, Routes, useLocation } from 'react-router-dom';
import { FirstSession } from './faceSession/FirstSession';
import styles from '../css/Main.module.css';
import { SecondSession } from './faceSession/SecondSession';
import { SessionMap } from '../../SessionMap';
import { MemberInfo } from '../../../../public/MemberInfo';
import { NotificationSetting } from '../../../../public/NotificationSetting';
import { ThirdSession } from './faceSession/ThirdSession';
import { FourthSession } from './faceSession/FourthSession';
import { FifthSession } from './faceSession/FifthSession';
import { SixthSession } from './faceSession/SixthSession';
import { SeventhSession } from './faceSession/SeventhSession';
import { EighthSession } from './faceSession/EighthSession';
import { NinthSession } from './faceSession/NinthSession';
import { TenthSession } from './faceSession/TenthSession';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import { AssignedFirstSession } from '../sessionAssigned/assigned/AssignedFirstSession';
import { AssignedSecondSession } from '../sessionAssigned/assigned/AssignedSecondSession';
import { AssignedThirdSession } from '../sessionAssigned/assigned/AssignedThirdSession';
import { AssignedFourthSession } from '../sessionAssigned/assigned/AssignedFourthSession';
import { AssignedFifthSession } from '../sessionAssigned/assigned/AssignedFifthSession';
import { AssignedSixthSession } from '../sessionAssigned/assigned/AssignedSixthSession';
import { AssignedSeventhSession } from '../sessionAssigned/assigned/AssignedSeventhSession';
import { AssignedEighthSession } from '../sessionAssigned/assigned/AssignedEighthSession';
import { AssignedNinthSession } from '../sessionAssigned/assigned/AssignedNinthSession';
import { AssignedTenthSession } from '../sessionAssigned/assigned/AssignedTenthSession';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { getSessionMenu } from '../ts/SessionApi';
import { updateSessionMenu } from '../../../../../features/sessionMenuReducer';
import { decryptData } from '../../../../../utils/croptojs';

export const FaceMain = () => {
    const dispatch = useDispatch();
    const visible = useSelector((state: RootState) => state.navVisible);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const encryptedData = queryParams.get('page');
    const programId = queryParams.get('program');

    const { data, isLoading, error } = useQuery({
        queryKey: ['sessionMenu', programId],
        queryFn: () => getSessionMenu(programId!), // TypeScript에서 null이 아님을 보장
        placeholderData: keepPreviousData,
        enabled: programId !== null,
    });

    useEffect(() => {
        if (data) {
            dispatch(updateSessionMenu(data));
        }
    }, [data, dispatch]);

    if (isLoading) return <div>Loading...</div>;
    if (error) return <div>Error: {error.message}</div>;

    const renderSessionPage = (isAssigned: boolean) => {
        if (!encryptedData) {
            return <div>No encrypted data provided.</div>; // encryptedData 없을 때 처리
        }

        const sessionNumber = Number(encryptedData!);
        const SessionComponent = isAssigned
            ? AssignedSessionComponents[sessionNumber]
            : SessionComponents[sessionNumber];

        return SessionComponent ? <SessionComponent /> : null; // 해당 세션 컴포넌트가 존재할 경우 렌더링
    };

    const SessionComponents: any = {
        1: FirstSession,
        2: SecondSession,
        3: ThirdSession,
        4: FourthSession,
        5: FifthSession,
        6: SixthSession,
        7: SeventhSession,
        8: EighthSession,
        9: NinthSession,
        10: TenthSession,
    };

    const AssignedSessionComponents: any = {
        1: AssignedFirstSession,
        2: AssignedSecondSession,
        3: AssignedThirdSession,
        4: AssignedFourthSession,
        5: AssignedFifthSession,
        6: AssignedSixthSession,
        7: AssignedSeventhSession,
        8: AssignedEighthSession,
        9: AssignedNinthSession,
        10: AssignedTenthSession,
    };

    const headerTitle = () => {
        switch (encryptedData) {
            case '1':
                return '세션1: ';
            case '2':
                return '세션2: ';
            case '3':
                return '세션3: ';
            case '4':
                return '세션4: ';
            case '5':
                return '세션5: ';
            case '6':
                return '세션6: ';
            case '7':
                return '세션7: ';
            case '8':
                return '세션8: ';
            case '9':
                return '세션9: ';
            case '10':
                return '세션10: ';
            default:
                return '';
        }
    };

    return (
        <div className={styles.main_container}>
            <PatientHeader title={headerTitle() + (headerTitle() !== '' ? '생각의 습관에서 자유로워지다' : '')} />
            <PatientLeftMenu />
            <div className={`${styles.content_container} ${visible.navVisible ? styles.activate : styles.deactivate}`}>
                <Routes>
                    <Route path="session" element={renderSessionPage(false)} />
                    <Route path="session/assigned" element={renderSessionPage(true)} />
                    <Route path="sessionMap" element={<SessionMap />} />
                    <Route path="memberInfo" element={<MemberInfo />} />
                    <Route path="notificationSetting" element={<NotificationSetting />} />
                </Routes>
            </div>
        </div>
    );
};

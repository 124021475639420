import { useDispatch, useSelector } from 'react-redux';
import { SquareCheckBox } from '../../../../../component/PublicComponent';
import { CategoryList } from '../ts/ProgramManagementApi';

import styles from './css/ProgramStatusDropDown.module.css';
import { RootState } from '../../../../../store';
import {
    updateProgramCategoryVisible,
    updateProgramStatus,
    updateProgramStatusVisible,
    updateWorkBooks,
} from '../../../../../features/programReducer';

export const ProgramStatusDropDown = () => {
    const dispatch = useDispatch();
    const { programStatusVisible, statusList } = useSelector((state: RootState) => state.programModal);

    const onChangeStatus = (item: string) => {
        dispatch(updateProgramStatus(item === '노출' ? true : false));
        dispatch(updateProgramStatusVisible(!programStatusVisible));
    };

    return (
        <div className={styles.program_picker}>
            <div className={styles.item_container}>
                {statusList.map((item, index) => (
                    <button
                        key={index.toString()}
                        className={styles.program_button}
                        onClick={() => onChangeStatus(item)}
                    >
                        <span className="text_16_NotoSansKR_Medium">{item}</span>
                    </button>
                ))}
            </div>
        </div>
    );
};

import React from 'react';

import styles from './css/policy.module.css';
import { BasicHeader } from '../../component/HeaderComponent';

export const PerformancePolicy = () => {
    const policyList = [
        {
            title: '비대면 CBT',
            middleTitle:
                '본 프로그램은 와이디 퍼포먼스(YD퍼포먼스 인지행동치료 센터)에서 진행되며, 의료비 공제에 해당되지 않습니다.',
            content: [
                '1. 프로그램 종료 이후, 치료 자료(영상, 워크북 등)에 접근이 제한됩니다.',
                '2. YD 인지 교정 프로그램의 유형 변경(대면형, 비대면형)은 1회에 한 해 가능합니다.',
                '3. 사업자의 귀책 또는 자연재해로 인한 수강 불능 :전액 환급',
                '4. 소비자의 귀책사유로 인한 계약 해제 및 해지',
                '- 프로그램 시작 (네이버 카페 회원 등급 조정 이후) 99,000원 차감 후 환불 -프로그램 시작 후 피드백(1차 피드백 후) 총금액의 50%',
                '- 프로그램 시작 후 피드백(2차 피드백 후) 환불 불가',
            ],
        },
        {
            title: '대면형CBT + 이완',
            middleTitle:
                '본 프로그램은 와이디 퍼포먼스(YD퍼포먼스 인지행동치료 센터)에서 진행되며, 의료비 공제에 해당되지 않습니다.',
            content: [
                '1. 인지행동치료 프로그램의 유형 변경(대면형, 비대면형)은 1회에 한 해 가능합니다.',
                '2. 프로그램 종료 이후, 치료 자료(영상, 워크북 등)에 접근이 제한됩니다.',
                '3. 환불 규정 (관련근거: 소비자 분쟁 해결기준(kca.go.kr)',
                '- 사업자의 귀책 또는 자연재해로 인한 수강 불능: 전액 환급',
                '- 소비자의 귀책 사유로 인한 계약 해제 및 해지',
                'a. 비용 결제 후, 90일 이후 취소/환불 불가',
                'b. 예약된 회기 시작 24시간 이내 환불 요청 시 보증금 환불 불가',
                'c. 이완치료의 경우, 패키지 결제에 따른 할인 금액으로 중도 환불 시 할인 혜택이 미적용됩니다.',
            ],
        },

        {
            title: '비대면형CBT+이완',
            middleTitle:
                '본 프로그램은 와이디 퍼포먼스(YD퍼포먼스 인지행동치료 센터)에서 진행되며, 의료비 공제에 해당되지 않습니다.',
            content: [
                '1. 비대면형, 대면형 유형 변경은 1회만 가능합니다.',
                '2. 패키지 결제 시 적용된 할인금액은 중도 환불 시 할인 혜택 미적용하여 차감됩니다.',
                '3. 프로그램 종료 이후, 치료자료(영상, 워크북 등)에 접근이 제한됩니다.',
                '4. 환불 규정 (관련근거: 소비자 분쟁 해결기준(kca.go.kr)',
                '- 사업자의 귀책 또는 자연재해로 인한 수강 불능 :전액 환급',
                '- 소비자의 귀책사유로 인한 계약 해제 및 해지',
                'a. 프로그램 시작 (네이버 카페 회원 등급 조정 이후) 99,000원 차감 후 환불',
                'b. 프로그램 시작 후 피드백(1차 피드백 후) 총 금액의 50%',
                'c. 프로그램 시작 후 피드백(2차 피드백 후) 환불 불가',
                'd. (이완프로그램) 비용 결제 후, 90일 이후 취소/환불 불가',
            ],
        },
        {
            title: '온라인형 (화상)',
            middleTitle:
                '본 프로그램은 와이디 퍼포먼스(YD퍼포먼스 인지행동치료 센터)에서 진행되며, 의료비 공제에 해당되지 않습니다.',
            content: [
                '1. 환불 규정 (관련 근거: 소비자 분쟁 해결기준(kca.go.kr)',
                '- 사업자의 귀책 또는 자연재해로 인한 수강 불능 :전액 환급',
                '- 소비자의 귀책사유로 인한 계약 해제 및 해지',
                'a. 예약된 회기 시작 24시간 이내 환불 요청 시 보증금 환불 불가',
                'b. 비용 결제 후, 90일 이후 취소/환불 불가',
            ],
        },
        {
            title: '이완 (바이오피드백)',
            middleTitle:
                '본 프로그램은 와이디 퍼포먼스(YD퍼포먼스 인지행동치료 센터)에서 진행되며, 의료비 공제에 해당되지 않습니다.',
            content: [
                '1. 프로그램 종료 이후, 치료 자료(영상)에 접근이 제한됩니다.',
                '2. 환불 규정 (관련근거: 소비자 분쟁 해결기준(kca.go.kr)',
                '- 사업자의 귀책 또는 자연재해로 인한 수강 불능 :전액 환급',
                '- 소비자의 귀책사유로 인한 계약 해제 및 해지',
                'a. 해지일까지 진행한 치료 횟수에 해당하는 금액을 차감한 나머지 금액 환불 (단, 패키지 결제 시 적용된 할인 혜택은 미적용됨)',
                'b. 비용 결제 후, 90일 이후 취소/환불 불가',
            ],
        },

        {
            title: 'TMS',
            middleTitle:
                '본 프로그램은 와이디 퍼포먼스(YD퍼포먼스 인지행동치료 센터)에서 진행되며, 의료비 공제에 해당되지 않습니다.',
            content: [
                '1. 환불 규정 (관련 근거: 소비자 분쟁 해결기준(kca.go.kr)',
                '- 사업자의 귀책 또는 자연재해로 인한 수강 불능 :전액 환급 소비자의 귀책사유로 인한 계약 해제 및 해지',
                '- 해지일까지 진행한 치료 횟수에 해당하는 금액을 차감한 나머지 금액 환불 (단, 패키지 결제 시 적용된 할인 혜택은 미적용됨)',
                '- 비용 결제 후, 90일 이후 취소/환불 불가',
            ],
        },

        {
            title: '사회기술훈련 지지상담',
            middleTitle:
                '본 프로그램은 와이디 퍼포먼스(YD퍼포먼스 인지행동치료 센터)에서 진행되며, 의료비 공제에 해당되지 않습니다.',
            content: [
                '1. 환불 규정 (관련 근거: 소비자 분쟁 해결기준(kca.go.kr)',
                '- 사업자의 귀책 또는 자연재해로 인한 수강 불능 : 전액 환급',
                '- 소비자의 귀책사유로 인한 계약 해제 및 해지',
                'a. 예약된 회기 시작 24시간 이내 환불 요청 시 보증금 환불 불가',
            ],
        },
    ];

    const PolicyRender = () => {
        return (
            <>
                {policyList.map((item, index) => (
                    <div>
                        <span className="text_22_NotoSansKR_Bold">
                            {/* 비대면 <span className="text_22_Inter_Bold">CBT</span> */}
                            {item.title}
                        </span>
                        <div className={styles.policy_content}>
                            <span className="text_16_NotoSansKR_Medium">
                                {item.middleTitle}
                                {'\n'}
                                {'\n'}
                            </span>
                            <ul>
                                {item.content.map((content, index) => (
                                    <li className={`${styles.li} text_16_NotoSansKR_Medium`}>{content}</li>
                                ))}
                            </ul>
                        </div>
                    </div>
                ))}
            </>
        );
    };

    return (
        <div>
            <BasicHeader />
            <div className={styles.main_container}>
                <div className={styles.content_container}>
                    <div className="text_32_NotoSansKR_Bold">YD Performance 이용약관</div>
                    <div className={styles.policy_content_container}>
                        <PolicyRender />
                    </div>
                </div>
            </div>
        </div>
    );
};

import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateEvent } from '../../../../../features/reservationReducer';
import { RootState } from '../../../../../store';

import { ReactComponent as Icon_arrowleft } from '../../../../../assets/public/icon_arrowleft.svg';
import { ReactComponent as Icon_arrowright } from '../../../../../assets/member/signup/icon_arrowright.svg';

import styles from './css/Calendar.module.css';
import axiosInstance from '../../../../../utils/AxiosInstanceJava';
import moment from 'moment';
import { ModalContext } from '../../../../../context/ModalContext';
import {
    updateReservationDate,
    updateReservationStatus,
    updateReserveTime,
    updateScheduleMoreVisible,
    updateSelectPatient,
    updateSelectProgarm,
} from '../../../../../features/scheduleModalReducer';
import { ScheduleMore } from '../../../../../component/ScheduleMore';
import { getMemberInfo } from '../../../../../utils/GetMemberInfo';

interface Schedule {
    date: string;
    time: string;
    description: string;
}

export const Calendar = () => {
    const dispatch = useDispatch();
    const reservation = useSelector((state: RootState) => state.events);
    const { scheduleMoreVisible } = useSelector((state: RootState) => state.scheduleModal);
    const { scheduleOpenModal } = useContext(ModalContext);
    const memberInfo = getMemberInfo();
    const [currentDate, setCurrentDate] = useState(new Date());
    const [isSelectedTimeframe, setIsSelectedTimeframe] = useState('월');
    const weekdays = ['일', '월', '화', '수', '목', '금', '토'];
    const timeframe = ['일', '주', '월'];

    const today = new Date();
    const isToday = (day: Date) => day && day.toDateString() === today.toDateString();

    useEffect(() => {
        getReserveStatus();
    }, []);

    const getReserveStatus = async () => {
        await axiosInstance
            .post(`/api/${memberInfo?.memberRole}/reserve/members/v1`, {
                yearMonth: '2024-12',
                companyId: 1,
            })
            .then((response) => {
                dispatch(updateEvent(response.data));
            });
    };

    const daysInMonth = (date: Date) => {
        const year = date.getFullYear();
        const month = date.getMonth();
        return new Date(year, month + 1, 0).getDate(); // 해당 월의 마지막 날
    };
    const firstDayOfMonth = (date: Date) => {
        return new Date(date.getFullYear(), date.getMonth(), 1).getDay(); // 해당 월의 첫 번째 날의 요일
    };

    const generateCalendar = () => {
        const days: any = [];
        const totalDays = daysInMonth(currentDate);
        const firstDay = firstDayOfMonth(currentDate);

        // 첫 번째 날 이전의 빈 공간 추가
        for (let i = 0; i < firstDay; i++) {
            days.push(null); // 빈 공간
        }

        for (let i = 1; i <= totalDays; i++) {
            days.push(new Date(currentDate.getFullYear(), currentDate.getMonth(), i));
        }
        return days;
    };

    const generateWeek = () => {
        const days = [];
        const startOfWeek = new Date(currentDate);
        startOfWeek.setDate(currentDate.getDate() - currentDate.getDay()); // 주의 시작일(일요일)

        for (let i = 0; i < 7; i++) {
            days.push(new Date(startOfWeek.getFullYear(), startOfWeek.getMonth(), startOfWeek.getDate() + i));
        }
        return days;
    };

    const calendarDays = isSelectedTimeframe === '월' ? generateCalendar() : generateWeek();

    const resetMonth = () => {
        setCurrentDate(new Date());
    };

    const goToPreviousMonth = () => {
        setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() - 1));
    };

    const goToNextMonth = () => {
        setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1));
    };

    const goToPreviousWeek = () => {
        setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - 7));
    };

    const goToNextWeek = () => {
        setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + 7));
    };
    const [selectedDate, setSelectedDate] = useState<Date>(new Date());
    const [schedules, setSchedules] = useState<Schedule[]>([]);

    // const hexToRgba = (hex, alpha) => {
    //     let r = 0, g = 0, b = 0;

    //     // 3자리 헥사코드 (#RGB)
    //     if (hex.length === 4) {
    //         r = parseInt(hex[1] + hex[1], 16);
    //         g = parseInt(hex[2] + hex[2], 16);
    //         b = parseInt(hex[3] + hex[3], 16);
    //     }
    //     // 6자리 헥사코드 (#RRGGBB)
    //     else if (hex.length === 7) {
    //         r = parseInt(hex[1] + hex[2], 16);
    //         g = parseInt(hex[3] + hex[4], 16);
    //         b = parseInt(hex[5] + hex[6], 16);
    //     }

    //     return `rgba(${r}, ${g}, ${b}, ${alpha})`; // alpha 값을 추가
    // };

    // const colorFilter = (title: string) => {
    //     switch (title) {
    //         case '대면 CBT':
    //             return hexToRgba('#78BF79', 0.5); // 50% 투명도
    //         case '이완치료':
    //             return hexToRgba('#DCBB67', 0.5); // 50% 투명도
    //         case 'TMS':
    //             return hexToRgba('#7D6ADE', 0.5); // 50% 투명도
    //         case '심리상담':
    //             return hexToRgba('#DC63C2', 0.5); // 50% 투명도
    //         default:
    //             return 'transparent'; // 기본값 설정
    //     }
    // };

    const colorFilter = (title: string) => {
        switch (title) {
            case '대면 CBT':
                return '#78BF79';
            case '이완치료':
                return '#DCBB67';
            case 'TMS':
                return '#7D6ADE';
            case '심리상담':
                return '#DC63C2';
        }
    };

    const reserveDetail = (item: {
        memberId: number;
        memberName: string;
        registerSessionIds: number[];
        workBookNames: string[];
        therapistNames: string[][];
        sessionProgresses: number[];
        calendarId: number;
        reservationStatus: number;
        startTime: string;
        endTime: string;
    }) => {
        // 프로그램 관련정보
        dispatch(
            updateSelectProgarm({
                registerSessionIds: item.registerSessionIds,
                workBookNames: item.workBookNames,
                therapistNames: item.therapistNames,
                sessionProgresses: item.sessionProgresses,
                calendarId: item.calendarId,
            })
        );
        // 내담자 정보
        dispatch(updateSelectPatient({ memberId: item.memberId, memberName: item.memberName }));
        dispatch(updateReservationStatus(item.reservationStatus));
        dispatch(
            updateReserveTime({
                startTime: item.startTime,
                originStartTime: convertTo12HourFormat(item.startTime),
                endTime: item.endTime,
                originEndTime: convertTo12HourFormat(item.endTime),
            })
        );
        scheduleOpenModal(true);
    };

    // 시간 표기 변환
    const convertTo12HourFormat = (time24: string) => {
        // 입력된 시간 문자열을 ":"로 분리
        const [hour24, minutes] = time24.split(':');

        // 24시간 형식을 정수로 변환
        let hour = parseInt(hour24, 10);
        let period = '오전'; // 기본적으로 오전으로 설정

        // 12시간 형식으로 변환
        if (hour >= 12) {
            period = '오후';
            if (hour > 12) {
                hour -= 12; // 12보다 큰 경우 12를 빼줌
            }
        } else if (hour === 0) {
            hour = 12; // 0시를 12시로 변환
        }

        // HH:mm 형식으로 변환
        const formattedHour = hour.toString(); // 시간은 2자리로 표현할 필요 없음
        const formattedMinutes = minutes.padStart(2, '0'); // 분은 항상 2자리로 표현

        // 변환된 시간 반환
        return `${period} ${formattedHour}:${formattedMinutes}`;
    };

    // 월간 뷰로 스케줄 표시
    const RenderMonthSchedule = () => {
        return (
            <>
                <div className={styles.month_weekdays}>
                    {weekdays.map((day, index) => (
                        <div key={index} className={`${styles.weekday} text_16_NotoSansKR_Medium`}>
                            {day}
                        </div>
                    ))}
                </div>
                <div className={styles.days}>
                    {calendarDays.map((day: any, index: number) => {
                        const filterReservationList =
                            day &&
                            reservation.events.reserves.filter(
                                (event) => new Date(event.reservationDate).toDateString() === day.toDateString()
                            );
                        return (
                            <div
                                key={index}
                                className={styles.day}
                                style={{
                                    borderWidth:
                                        index + 1 !== calendarDays.length
                                            ? (index + 1) % 7 === 0 && index + 1 !== calendarDays.length
                                                ? '0px 0px 1px 0px'
                                                : index > 7 && index < 28
                                                ? '0px 1px 1px 0px'
                                                : index + 1 > 28
                                                ? '0px 1px 0px 0px'
                                                : '0px 1px 1px 0px'
                                            : '0px',
                                }}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    dispatch(updateReservationDate(moment(day).format('YYYY-MM-DD')));
                                    scheduleOpenModal(true);
                                }}
                            >
                                <div
                                    className={`${
                                        isToday(day) ? styles.today_child : styles.day_child
                                    } text_12_Inter_Medium`}
                                    style={{
                                        color: isToday(day) ? '#FFF' : '#020202',
                                    }}
                                >
                                    {day ? day.getDate() : ''}
                                </div>

                                {day &&
                                    filterReservationList.slice(0, 2).map((event: any, index: number) => {
                                        return event.registerSessionIds.map((item: number, idx: number) => {
                                            return (
                                                <button
                                                    key={idx}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        dispatch(
                                                            updateReservationDate(moment(day).format('YYYY-MM-DD'))
                                                        );
                                                        reserveDetail(event);
                                                    }}
                                                    className={`${styles.event} text_12_NotoSansKR_Medium`}
                                                    style={{
                                                        backgroundColor: colorFilter(event.workBookNames[0]),
                                                    }}
                                                >
                                                    <span className={`${styles.title} text_12_NotoSansKR_Medium`}>
                                                        {event.memberId +
                                                            '/' +
                                                            event.memberName +
                                                            '/' +
                                                            event.workBookNames.join(', ') +
                                                            '/' +
                                                            event.sessionProgresses}
                                                    </span>
                                                </button>
                                            );
                                        });
                                    })}

                                {day && filterReservationList.length > 3 && (
                                    <div className={styles.schedule_button_container}>
                                        <button
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                dispatch(
                                                    updateScheduleMoreVisible({
                                                        day: day.toDateString(),
                                                        bool: true,
                                                    })
                                                );
                                            }}
                                            className={styles.more_schedule_button}
                                        >
                                            <span className="text_12_Inter_Medium" style={{ color: '#3A5074' }}>
                                                +{filterReservationList.length - 3}
                                                <span
                                                    className="text_12_NotoSansKR_Medium"
                                                    style={{ color: '#3A5074' }}
                                                >
                                                    건 더보기
                                                </span>
                                            </span>
                                            <Icon_arrowright className={styles.icon_arrowright} />
                                        </button>
                                    </div>
                                )}
                                {day && scheduleMoreVisible.bool && scheduleMoreVisible.day === day.toDateString() && (
                                    <ScheduleMore
                                        reservationList={filterReservationList}
                                        reserveDetail={reserveDetail}
                                        colorFilter={colorFilter}
                                    />
                                )}
                            </div>
                        );
                    })}
                </div>
            </>
        );
    };

    // 주간 뷰로 스케줄 표시
    const RenderWeeklySchedule = () => {
        const weekStart = new Date(selectedDate);
        weekStart.setDate(weekStart.getDate() - weekStart.getDay()); // 주의 시작일 (일요일)

        // 시간대 설정
        const timeSlots = Array.from({ length: 24 }, (_, index) => {
            const hour = (index + 1) % 12 === 0 ? 12 : (index + 1) % 12; // 1부터 시작하고 12시 처리
            const period = index < 11 ? '오전' : '오후'; // 오전/오후 결정
            return index === 23 ? '' : `${period}${hour}시`; // 오후 12시는 빈 문자열로 처리
        });

        // 15분 단위의 시작 시간 생성 (24시간 형식)
        const startTimes = [...Array(96)].map((_, index) => {
            const hour = Math.floor(index / 4); // 0~23시
            const minutes = (index % 4) * 15; // 0, 15, 30, 45 분
            return `${hour < 10 ? '0' : ''}${hour}:${minutes < 10 ? '0' : ''}${minutes}`; // "HH:mm" 형식
        });

        const heightAdjustment = (schedule: { startTime: string; endTime: string }) => {
            const toMinutes = (time: string) => {
                const [hours, minutes] = time.split(':').map(Number);
                return hours * 60 + minutes;
            };

            // 시작 및 종료 시간을 분으로 변환
            const startMinutes = toMinutes(schedule.startTime); // 예: "10:30" -> 630
            const endMinutes = toMinutes(schedule.endTime); // 예: "11:30" -> 690

            // 15분 슬롯으로 나누기
            const startSlotIndex = Math.floor(startMinutes / 15);
            const endSlotIndex = Math.floor(endMinutes / 15);

            // 두 슬롯 인덱스의 차이 계산
            const difference = endSlotIndex - startSlotIndex; // 슬롯 수 차이
            return difference * (3.75 / 4); // 15분 슬롯 수에 따라 높이 조정
        };

        const toMinutes = (time: any) => {
            const [hours, minutes] = time.split(':').map(Number);
            return hours * 60 + minutes;
        };

        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                }}
            >
                <div className={styles.weekdays}>
                    {weekdays.map((day, index) => (
                        <div key={index} className={styles.weekday}>
                            <span className="text_16_NotoSansKR_Medium">{day}</span>
                            <span className="text_24_Inter_Medium">{calendarDays[index].getDate()}</span>
                        </div>
                    ))}
                </div>
                {/* 시간 표시 */}
                <div
                    style={{
                        display: 'flex',
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            height: '100%',
                            paddingTop: '0.5%',
                            width: '4.7%',
                        }}
                    >
                        {timeSlots.map((time, index) => (
                            <div
                                key={index.toString()}
                                style={{
                                    display: 'flex',
                                    alignItems: 'flex-end',
                                    justifyContent: 'center',
                                    whiteSpace: 'nowrap',
                                    height: '3.75rem',
                                }}
                            >
                                <span
                                    className="text_12_NotoSansKR_Medium"
                                    style={{
                                        color: '#626466',
                                    }}
                                >
                                    {time}
                                </span>
                            </div>
                        ))}
                    </div>
                    {/* 주간 스케줄 */}
                    {calendarDays.map((date: any, index: number) => (
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                width: '13.8%', // 부모 div의 너비
                            }}
                            key={index}
                        >
                            {timeSlots.map((_, timeIndex) => {
                                const startTime = startTimes[timeIndex * 4]; // 60분 박스의 시작 시간
                                const endTime = startTimes[timeIndex * 4 + 4]; // 60분 박스의 종료 시간

                                return (
                                    <button
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            dispatch(updateReservationDate(moment(date).format('YYYY-MM-DD')));
                                            scheduleOpenModal(true);
                                        }}
                                        key={timeIndex.toString()}
                                        style={{
                                            position: 'relative',
                                            height: '3.75rem',
                                            border: '1px solid #eee',
                                            boxSizing: 'border-box',
                                            display: 'flex',
                                            flexDirection: 'column',
                                        }}
                                    >
                                        {Array.from({ length: 4 }).map((_, quarterIndex) => {
                                            const quarterStartTime = startTimes[timeIndex * 4 + quarterIndex]; // 15분 슬롯의 시작 시간
                                            const quarterEndTime = startTimes[timeIndex * 4 + quarterIndex + 1]; // 15분 슬롯의 종료 시간

                                            const currentSchedules = reservation.events.reserves.filter((schedule) => {
                                                const isSameDate =
                                                    new Date(schedule.reservationDate).toDateString() ===
                                                    date.toDateString();
                                                return (
                                                    isSameDate &&
                                                    quarterStartTime < schedule.endTime && // 겹치는 조건
                                                    quarterEndTime > schedule.startTime // 겹치는 조건
                                                );
                                            });

                                            const heightAdjustment = (schedule: {
                                                startTime: string;
                                                endTime: string;
                                            }) => {
                                                const toMinutes = (time: string) => {
                                                    const [hours, minutes] = time.split(':').map(Number);
                                                    return hours * 60 + minutes;
                                                };

                                                const startMinutes = toMinutes(schedule.startTime);
                                                const endMinutes = toMinutes(schedule.endTime);
                                                const startSlotIndex = Math.floor(startMinutes / 15);
                                                const endSlotIndex = Math.floor(endMinutes / 15);
                                                const difference = endSlotIndex - startSlotIndex;

                                                return difference * (3.75 / 4); // 15분 슬롯 수에 따라 높이 조정
                                            };

                                            return (
                                                <div
                                                    key={quarterIndex.toString()}
                                                    style={{
                                                        flex: 1,
                                                        display: 'flex',
                                                        position: 'relative',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    {currentSchedules.map((event: any, idx) => {
                                                        const uniqueId = event.registerSessionIds.join('-'); // 유니크한 ID 생성
                                                        const scheduleHeight = heightAdjustment(event); // 각 예약의 높이 계산

                                                        // 예약이 시작하는 시점에만 표시
                                                        if (quarterStartTime === event.startTime) {
                                                            // 같은 시간에 있는 예약의 수
                                                            const eventCount = currentSchedules.length;

                                                            // 예약의 너비 및 간격 계산
                                                            const totalWidth = 13; // 부모 div의 너비 (rem 단위)
                                                            const eventWidth = totalWidth / eventCount - 0.2; // 간격을 고려하여 너비 계산
                                                            const leftPosition = `${
                                                                idx * (eventWidth + 0.2) + idx * 0.2
                                                            }rem`; // 각 예약의 left 위치를 오른쪽으로 밀기

                                                            return (
                                                                <button
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        dispatch(
                                                                            updateReservationDate(
                                                                                moment(date).format('YYYY-MM-DD')
                                                                            )
                                                                        );
                                                                        reserveDetail(event);
                                                                    }}
                                                                    key={uniqueId}
                                                                    className={`${styles.event}`}
                                                                    style={{
                                                                        position: 'absolute',
                                                                        display: 'flex',
                                                                        width: `${(100 * eventCount).toFixed(2)}%`, // 계산된 너비를 퍼센트로 설정
                                                                        height: `${scheduleHeight}rem`, // 예약 수에 따른 높이 적용
                                                                        flexDirection: 'column',
                                                                        padding: '0.5rem',
                                                                        top: '0',
                                                                        left: leftPosition, // 동적으로 계산된 left 위치
                                                                        zIndex: eventCount - idx, // 지속 시간이 짧을수록 z-index를 높게 설정
                                                                        alignItems: 'center',
                                                                        backgroundColor:
                                                                            currentSchedules.length > 0
                                                                                ? colorFilter(event.workBookNames[0])
                                                                                : 'transparent', // 배경색 설정
                                                                        border: '1px solid #fff',
                                                                    }}
                                                                >
                                                                    {event.registerSessionIds.map(
                                                                        (item: any, id: number) => (
                                                                            <div
                                                                                key={id}
                                                                                className={`${styles.title} text_12_NotoSansKR_Medium`}
                                                                                style={{
                                                                                    width: '90%',
                                                                                    overflow: 'hidden',
                                                                                    whiteSpace: 'normal',
                                                                                    textOverflow: 'ellipsis',
                                                                                }}
                                                                            >
                                                                                <span
                                                                                    style={{
                                                                                        width: '90%',
                                                                                        overflow: 'hidden',
                                                                                        whiteSpace: 'nowrap',
                                                                                        textOverflow: 'ellipsis',
                                                                                    }}
                                                                                >
                                                                                    {event.memberId +
                                                                                        '/' +
                                                                                        event.memberName +
                                                                                        '/' +
                                                                                        event.workBookNames.join(', ') +
                                                                                        '/' +
                                                                                        event.sessionProgresses}
                                                                                </span>
                                                                                <span style={{ display: 'block' }}>
                                                                                    {event.startTime} {event.endTime}
                                                                                </span>
                                                                            </div>
                                                                        )
                                                                    )}
                                                                </button>
                                                            );
                                                        }

                                                        return null; // 조건을 만족하지 않으면 아무것도 출력하지 않음
                                                    })}
                                                </div>
                                            );
                                        })}
                                    </button>
                                );
                            })}
                        </div>
                    ))}
                </div>
            </div>
        );
    };

    return (
        reservation.events && (
            <div className={styles.calendar_container}>
                <div className={styles.calendar}>
                    <div className={styles.calendar_header}>
                        <div className={styles.navigation}>
                            <div className={styles.left_menu_container}>
                                <button
                                    className={`${styles.today_button} text_16_NotoSansKR_Bold `}
                                    onClick={resetMonth}
                                >
                                    오늘
                                </button>
                                <div className={styles.button_container}>
                                    <button
                                        className={styles.prev_button}
                                        onClick={() => {
                                            console.log(isSelectedTimeframe);
                                            isSelectedTimeframe === '월' ? goToPreviousMonth() : goToPreviousWeek();
                                        }}
                                    >
                                        <Icon_arrowleft className={styles.icon_arrowleft} />
                                    </button>
                                    <button
                                        className={styles.next_button}
                                        onClick={() =>
                                            isSelectedTimeframe === '월' ? goToNextMonth() : goToNextWeek()
                                        }
                                    >
                                        <Icon_arrowright className={styles.icon_arrowright} />
                                    </button>
                                </div>
                                <div className="text_24_NotoSansKR_Bold">
                                    <span className="text_24_Inter_Bold">{currentDate.getFullYear()}</span>년{' '}
                                    <span className="text_24_Inter_Bold">{currentDate.getMonth() + 1}</span>월
                                </div>
                            </div>

                            <div className={styles.timeframe}>
                                {timeframe.map((item, index) => (
                                    <button
                                        key={index.toString()}
                                        onClick={() => setIsSelectedTimeframe(item)}
                                        className={`${styles.timefram_button} ${
                                            isSelectedTimeframe === item ? styles.activate : styles.deactivate
                                        } ${
                                            isSelectedTimeframe === item
                                                ? 'text_16_NotoSansKR_Bold'
                                                : 'text_16_NotoSansKR_Medium'
                                        } `}
                                    >
                                        {item}
                                    </button>
                                ))}
                            </div>
                        </div>
                    </div>
                    {isSelectedTimeframe === '월' ? <RenderMonthSchedule /> : <RenderWeeklySchedule />}
                </div>
            </div>
        )
    );
};

import axiosInstance from '../../../../../utils/AxiosInstanceJava';
import { getMemberInfo } from '../../../../../utils/GetMemberInfo';

export interface EmployeeList {
    members: {
        content: {
            memberId: number;
            memberName: string;
            memberRole: string;
            branches: {
                companyId: number;
                branchName: string;
            }[];
            modDate: string;
        }[];
        pageable: {
            pageNumber: number;
            pageSize: number;
            sort: {
                sorted: boolean;
                empty: boolean;
                unsorted: boolean;
            };
            offset: number;
            paged: boolean;
            unpaged: boolean;
        };
        totalElements: number;
        totalPages: number;
        last: boolean;
        size: number;
        number: number;
        sort: {
            sorted: boolean;
            empty: boolean;
            unsorted: boolean;
        };
        numberOfElements: number;
        first: boolean;
        empty: boolean;
    };
    branchesCategories: {
        companyId: number;
        branchName: string;
        count: number;
    }[];
    message: string;
    result: boolean;
}

export const getEmployeeList = async (companyId: number, pageNum: number): Promise<EmployeeList> => {
    const memberInfo = getMemberInfo();
    return await axiosInstance
        .post(`/api/${memberInfo?.memberRole}/employee/list/v1`, {
            companyId: memberInfo?.memberRole === 'representative' ? 0 : companyId,
            pageNum: pageNum,
            pageable: {
                page: 0,
                size: 1,
                sort: ['string'],
            },
        })
        .then((response) => response.data);
};

import styled from 'styled-components';
import { ReactComponent as Icon_checkbox } from '../assets/member/icon_checkbox.svg';
import { ReactComponent as Icon_checkedbox } from '../assets/member/icon_checkedbox.svg';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateMemberMarketing } from '../features/editProfileReducer';
import { RootState } from '../store';

interface SpanTextProps {
    className: string;
    color: string;
    text: string;
}

export const SpanText = ({ className, color, text }: SpanTextProps) => {
    return (
        <span className={className} style={{ color: color }}>
            {text}
        </span>
    );
};

const ProgressContainer = styled.div`
    min-width: 9.375rem;
    background-color: #eef0f3;
    border-radius: 100px;
    overflow: hidden;
`;

const Progress = styled.div`
    height: 1.25rem;
    background-color: #39d71d;
    border-radius: 100px;
    transition: width 0.5s ease-in-out;
`;

export const ProgressBar = ({ progress, max }: { progress: number; max: number }) => {
    return (
        <ProgressContainer className="progress-container">
            <Progress
                className="progress-bar"
                style={{ width: `${(progress / max) * 100}%` }} // 백분율 계산
            />
        </ProgressContainer>
    );
};

export const SquareCheckBox = ({ bool, size }: { bool: boolean; size: string }) => {
    if (bool) {
        return <Icon_checkedbox width={size} height={size} />;
    } else {
        return <Icon_checkbox width={size} height={size} />;
    }
};

const ToggleContainer = styled.div`
    position: relative;
    cursor: pointer;

    > .toggle-container {
        width: 5.0625rem;
        height: 3rem;
        box-sizing: border-box;
        padding: 0.3125rem;
        border-radius: 100px;
        background-color: rgb(233, 233, 234);
    }
    > .toggle--checked {
        background-color: #3a5074;
        transition: 0.3s;
    }

    > .toggle-circle {
        position: absolute;
        top: 0.3125rem;
        left: 0.3125rem;
        width: 2.375rem;
        height: 2.375rem;
        border-radius: 100px;
        background-color: rgb(255, 254, 255);
        transition: 0.3s;
        //.toggle--checked 클래스가 활성화 되었을 경우의 CSS를 구현
    }
    > .toggle--checked {
        left: 2.375rem;
        transition: 0.3s;
    }
`;

export const Toggle = ({ fn }: { fn: Function }) => {
    const dispatch = useDispatch();
    const { marketing } = useSelector((state: RootState) => state.editProfile);

    const toggleHandler = () => {
        // isOn의 상태를 변경하는 메소드를 구현
        dispatch(updateMemberMarketing(!marketing));
        fn('push');
    };

    return (
        <ToggleContainer onClick={toggleHandler}>
            <div className={`toggle-container ${marketing ? 'toggle--checked' : null}`} />
            <div className={`toggle-circle ${marketing ? 'toggle--checked' : null}`} />
        </ToggleContainer>
    );
};

import { useEffect, useState } from 'react';
import axiosInstance from '../../../../../utils/AxiosInstanceJava';
import { SquareCheckBox } from '../../../../../component/PublicComponent';
import styles from './css/ProgramPicker.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import { updateProgramPickerVisible, updateSelectProgarm } from '../../../../../features/scheduleModalReducer';

interface WorkBookInfos {
    registerId: number;
    registerSessionId: number;
    workBookName: string;
    therapistNames: string[];
    sessionNo: number;
    check: boolean;
}

export const ProgramPicker = () => {
    const dispatch = useDispatch();
    const { programPickerVisible, registerSessionIds, calendarId, memberInfo } = useSelector(
        (state: RootState) => state.scheduleModal
    );
    const [pageLoading, setPageLoading] = useState<boolean>(false);

    const [workBookList, setWorkBookList] = useState<WorkBookInfos[]>([]);

    useEffect(() => {
        getProgramList();
    }, []);

    const getProgramList = async () => {
        await axiosInstance
            .post(`/api/therapist/reserve/list/workBook/${memberInfo.memberId}/v1`)
            .then((response) => {
                if (response.data.result) {
                    response.data.workBookInfos.map((item: WorkBookInfos, index: number) => {
                        if (registerSessionIds.includes(item.registerSessionId)) {
                            item.check = true;
                        } else {
                            item.check = false;
                        }
                    });
                    setWorkBookList(response.data.workBookInfos);
                }
            })
            .finally(() => {
                setPageLoading(true);
            });
    };

    const selectProgram = (index: number) => {
        const updateAry = [...workBookList];
        updateAry[index].check = !updateAry[index].check;
        setWorkBookList(updateAry);
    };

    const saveProgram = () => {
        const filterAry: any = workBookList.filter((item) => item.check === true);
        const mergedData = filterAry.reduce((acc: any, obj: any) => {
            for (let key in obj) {
                if (!acc[key]) {
                    acc[key] = []; // 키가 없으면 새로운 배열 생성
                }
                acc[key].push(obj[key]); // 해당 키의 배열에 값 추가
            }
            return acc;
        }, {});

        dispatch(
            updateSelectProgarm({
                registerSessionIds: mergedData.registerSessionId,
                workBookNames: mergedData.workBookName,
                therapistNames: mergedData.therapistNames,
                sessionProgresses: mergedData.sessionNo,
                calendarId: calendarId,
            })
        );
        dispatch(updateProgramPickerVisible(false));
    };

    return pageLoading ? (
        <div className={styles.program_picker}>
            <div className={styles.item_container}>
                {workBookList.map((item, index) => (
                    <button
                        key={index.toString()}
                        className={styles.program_button}
                        onClick={() => selectProgram(index)}
                    >
                        <SquareCheckBox bool={item.check} size="1.5rem" />
                        <span className="text_16_NotoSansKR_Medium">{item.workBookName}</span>
                    </button>
                ))}
            </div>
            <div className={styles.button_container}>
                <button
                    onClick={() => dispatch(updateProgramPickerVisible(false))}
                    className={`${styles.cancel_button} text_16_NotoSansKR_Bold`}
                >
                    취소
                </button>
                <button onClick={saveProgram} className={`${styles.save_button} text_16_NotoSansKR_Bold`}>
                    선택완료
                </button>
            </div>
        </div>
    ) : null;
};

import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { OneButtonModal, TwoButtonModal } from '../component/ModalComponent';
import { SymptomSelectModal } from '../pages/home/cbt/component/SymptomSelectModal';
import { ScheduleModal } from '../pages/home/official/reservationStatus/component/ScheduleModal';
import { ProgramModal } from '../pages/home/official/program/component/ProgramModal';
import { AdditionalInfoModal } from '../pages/home/official/assignedDetail/component/AdditionalInfoModal';
import { PaymentModal } from '../pages/payment/PaymentModal';

interface ModalContextType {
    icon: any;
    title: string;
    message: string;
    firstButton: string;
    secondButton: string;
    paymentUrl: string;

    oneButtonVisible: boolean;
    oneButtonIconVisible: boolean;
    twoButtonVisible: boolean;
    symptomVisible: boolean;
    additionalInfoVisible: boolean;
    selectTherapistVisible: boolean;
    scheduleVisible: boolean;
    programVisible: boolean;
    paymentVisible: boolean;

    oneButtonOpenModal: (callback: any, title: string, message: string, button: string) => void;
    oneButtonIconOpenModal: (callback: any, icon: any, title: string, message: string, button: string) => void;
    twoButtonOpenModal: (
        firstcallback: any,
        secondcallback: any,
        title: string,
        message: string,
        firstButton: string,
        secondButton: string
    ) => void;
    symptomOpenModal: (visible: boolean) => void;
    additionalInfoOpenModal: (visible: boolean) => void;
    selectTherapistOpenModal: (visible: boolean) => void;
    scheduleOpenModal: (visible: boolean) => void;
    programOpenModal: (visible: boolean) => void;
    paymentOpenModal: (visible: boolean, payment: string) => void;

    closeModal: () => void;
    onCancel: () => void;
    onConfirm: () => void;
}

type OnCloseCallback = () => void;

export const ModalContext = createContext<ModalContextType>({
    icon: '',
    title: '',
    message: '',
    firstButton: '',
    secondButton: '',
    paymentUrl: '',

    oneButtonVisible: false,
    oneButtonIconVisible: false,
    twoButtonVisible: false,
    symptomVisible: false,
    additionalInfoVisible: false,
    selectTherapistVisible: false,
    scheduleVisible: false,
    programVisible: false,
    paymentVisible: false,

    oneButtonOpenModal: (callback: any, title: string, message: string, button: string) => {},
    oneButtonIconOpenModal: (callback: any, icon: any, title: string, message: string, button: string) => {},
    twoButtonOpenModal: (
        firstcallback: any,
        secondcallback: any,
        title: string,
        message: string,
        firstButton: string,
        secondButton: string
    ) => {},
    symptomOpenModal: (visible: boolean) => {},
    additionalInfoOpenModal: (visible: boolean) => {},
    selectTherapistOpenModal: (visible: boolean) => {},
    scheduleOpenModal: (visible: boolean) => {},
    programOpenModal: (visible: boolean) => {},
    paymentOpenModal: (visible: boolean, payment: string) => {},

    closeModal: () => {},
    onCancel: () => {},
    onConfirm: () => {},
});

export const ModalProvider = ({ children }: { children: React.ReactNode }) => {
    const [icon, setIcon] = useState<any>();
    const [title, setTitle] = useState<string>('');
    const [message, setMessage] = useState<string>('');
    const [firstButton, setFirstButton] = useState<string>('');
    const [secondButton, setSecondButton] = useState<string>('');
    const [paymentUrl, setPaymentUrl] = useState<string>('');

    const [oneButtonVisible, setOneButtonVisible] = useState<boolean>(false);
    const [oneButtonIconVisible, setOneButtonIconVisible] = useState<boolean>(false);
    const [twoButtonVisible, setTwoButtonVisible] = useState<boolean>(false);
    const [symptomVisible, setSymptomVisible] = useState<boolean>(false);
    const [additionalInfoVisible, setAdditionalInfoVisible] = useState<boolean>(false);
    const [selectTherapistVisible, setSelectTherapistVisible] = useState<boolean>(false);
    const [scheduleVisible, setScheduleVisible] = useState<boolean>(false);
    const [programVisible, setProgramVisible] = useState<boolean>(false);
    const [paymentVisible, setPaymentVisible] = useState<boolean>(false);

    const [firstOnCloseCallback, setFirstOnCloseCallback] = useState<OnCloseCallback | null>(null);
    const [secondOnCloseCallback, setSecondOnCloseCallback] = useState<OnCloseCallback | null>(null);

    const oneButtonOpenModal = (callback: any, title: string, message: string, button: string) => {
        setTitle(title);
        setMessage(message);
        setFirstButton(button);
        setFirstOnCloseCallback(() => callback);
        setOneButtonVisible(true);
    };

    const oneButtonIconOpenModal = (callback: any, icon: any, title: string, message: string, button: string) => {
        setIcon(() => icon);
        setTitle(title);
        setMessage(message);
        setFirstButton(button);
        setFirstOnCloseCallback(() => callback);
        setOneButtonVisible(true);
    };

    const twoButtonOpenModal = (
        secondcallback: () => void,
        firstcallback: () => void,
        title: string,
        message: string,
        firstButton: string,
        secondButton: string
    ) => {
        setTitle(title);
        setMessage(message);
        setFirstButton(firstButton);
        setSecondButton(secondButton);
        setFirstOnCloseCallback(() => firstcallback);
        setSecondOnCloseCallback(() => secondcallback);
        setTwoButtonVisible(true);
    };

    const symptomOpenModal = (visible: boolean) => {
        setSymptomVisible(visible);
    };

    const additionalInfoOpenModal = (visible: boolean) => {
        setAdditionalInfoVisible(visible);
    };

    const selectTherapistOpenModal = (visible: boolean) => {
        setSelectTherapistVisible(visible);
    };

    const scheduleOpenModal = (visible: boolean) => {
        setScheduleVisible(visible);
    };

    const programOpenModal = (visible: boolean) => {
        setProgramVisible(visible);
    };

    const paymentOpenModal = (visible: boolean, payment: string) => {
        setPaymentUrl(payment);
        setPaymentVisible(visible);
    };

    const onCancel = () => {
        if (firstOnCloseCallback) {
            firstOnCloseCallback();
        }
    };

    const onConfirm = () => {
        if (secondOnCloseCallback) {
            secondOnCloseCallback();
        }
    };

    const closeModal = () => {
        setOneButtonVisible(false);
        setOneButtonIconVisible(false);
        setTwoButtonVisible(false);
    };

    return (
        <ModalContext.Provider
            value={{
                icon,
                title,
                message,
                firstButton,
                secondButton,
                paymentUrl,
                oneButtonVisible,
                oneButtonIconVisible,
                twoButtonVisible,
                symptomVisible,
                additionalInfoVisible,
                selectTherapistVisible,
                scheduleVisible,
                programVisible,
                paymentVisible,
                oneButtonOpenModal,
                oneButtonIconOpenModal,
                twoButtonOpenModal,
                symptomOpenModal,
                additionalInfoOpenModal,
                selectTherapistOpenModal,
                scheduleOpenModal,
                programOpenModal,
                paymentOpenModal,
                closeModal,
                onCancel,
                onConfirm,
            }}
        >
            {children}
            {oneButtonVisible && <OneButtonModal />}
            {twoButtonVisible && <TwoButtonModal />}
            {symptomVisible && <SymptomSelectModal />}
            {additionalInfoVisible && <AdditionalInfoModal />}
            {/* {selectTherapistVisible && } */}
            {scheduleVisible && <ScheduleModal />}
            {programVisible && <ProgramModal />}
        </ModalContext.Provider>
    );
};

export const useModal = () => {
    const context = useContext(ModalContext);
    if (!context) {
        throw new Error('useModal must be used within a ModalProvider');
    }
    return context;
};

import React, { useContext, useEffect, useState } from 'react';

import styled from 'styled-components';
import styles from './css/ProgramManagement.module.css';
import { ReactComponent as Icon_plus } from '../../../../assets/public/icon_plus.svg';

import { useNavigate } from 'react-router-dom';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { ProgramList, getProgramList } from './ts/ProgramManagementApi';
import { ModalContext } from '../../../../context/ModalContext';
import { useDispatch } from 'react-redux';
import { updateProductId } from '../../../../features/programReducer';

export const ProgramManagement = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { programOpenModal, programVisible } = useContext(ModalContext);
    const { data, isLoading, error, refetch } = useQuery<ProgramList>({
        queryKey: ['programProduct'],
        queryFn: () => getProgramList(),
        placeholderData: keepPreviousData,
    });

    const [isSelectedTitle, setIsSelectedTitle] = useState<{ title: string }>({ title: '모든 기관' });

    const statusList = ['전체', '노출', '비노출'];
    const [isSelectedOfficial, setIsSelectedOfficial] = useState<string>('전체');

    // 가격 단위 변경
    const formatNumber = (number: number) => {
        return new Intl.NumberFormat('ko-KR').format(number);
    };

    // 로딩 중일 때
    if (isLoading) {
        return <div>Loading...</div>;
    }

    // 오류 발생 시
    if (error) {
        return <div>Error: {error.message}</div>;
    }

    // data가 존재하지 않거나 비어있을 경우
    if (!data || !data?.products) {
        return <div>No employees found.</div>;
    }

    const officialMemberListFilter = () => {
        let filterList = data?.products;
        let onSale = isSelectedOfficial === '노출' ? true : false;
        if (isSelectedOfficial !== '전체') {
            filterList = data?.products.filter((item) => item.onSale === onSale);
        }
        return filterList;
    };

    const programDetail = (productId: number) => {
        dispatch(updateProductId(productId));
        programOpenModal(!programVisible);
    };

    const FilterContainer = () => {
        return (
            <div className={styles.search_container}>
                <button
                    onClick={() => programDetail(0)}
                    className={`${styles.program_add_button} text_16_NotoSansKR_Bold`}
                >
                    <Icon_plus />
                    프로그램 추가
                </button>
                <div className={styles.filter_button_container}>
                    {statusList.map((item, index) => (
                        <button
                            key={index.toString()}
                            onClick={() => setIsSelectedOfficial(item)}
                            className={`${styles.filter_button} ${
                                isSelectedOfficial === item ? styles.activate : styles.deactivate
                            } ${
                                isSelectedOfficial === item ? 'text_16_NotoSansKR_Bold' : 'text_16_NotoSansKR_Medium'
                            } `}
                        >
                            {item}
                        </button>
                    ))}
                </div>
            </div>
        );
    };

    const OfficialRender = () => {
        return (
            <>
                <div className={styles.chart_title_container}>
                    <div className={styles.no_program_group}>
                        <div className={styles.item_no}>
                            <span
                                className="text_16_Inter_Medium"
                                style={{
                                    color: '#626466',
                                }}
                            >
                                No.
                            </span>
                        </div>
                        <div className={styles.item_program_name}>
                            <span
                                className="text_16_NotoSansKR_Medium"
                                style={{
                                    color: '#626466',
                                }}
                            >
                                프로그램명
                            </span>
                        </div>
                    </div>
                    <div className={styles.status_origin_price_group}>
                        <div className={styles.item_status}>
                            <span
                                className="text_16_NotoSansKR_Medium"
                                style={{
                                    color: '#626466',
                                }}
                            >
                                상태
                            </span>
                        </div>
                        <div className={styles.item_origin_price}>
                            <span
                                className="text_16_NotoSansKR_Medium"
                                style={{
                                    color: '#626466',
                                }}
                            >
                                기존가
                            </span>
                        </div>
                    </div>
                    <div className={styles.item_price}>
                        <span
                            className="text_16_NotoSansKR_Medium"
                            style={{
                                color: '#626466',
                            }}
                        >
                            최종가
                        </span>
                    </div>
                    <div className={styles.item_category}>
                        <span
                            className="text_16_NotoSansKR_Medium"
                            style={{
                                color: '#626466',
                            }}
                        >
                            카테고리
                        </span>
                    </div>
                    <div className={styles.item_detail}>
                        <div className={styles.item_detail}></div>
                    </div>
                </div>
                {officialMemberListFilter().map((item, index) => (
                    <div
                        key={index.toString()}
                        className={`${styles.chart_content_container} ${
                            index === officialMemberListFilter().length - 1 ? null : styles.border_bottom
                        }`}
                    >
                        <div className={styles.no_program_group}>
                            <div className={styles.item_no}>
                                <span className="text_16_Inter_Medium">{item.workBookProductId}</span>
                            </div>
                            <div className={styles.item_program_name}>
                                <span className="text_16_NotoSansKR_Medium">{item.productName}</span>
                            </div>
                        </div>
                        <div className={styles.status_origin_price_group}>
                            <div className={styles.item_status}>
                                <span className="text_16_NotoSansKR_Medium">{item.onSale ? '노출' : '미노출'}</span>
                            </div>
                            <div className={styles.item_origin_price}>
                                <span className="text_16_Inter_Medium">{formatNumber(item.price)}</span>
                            </div>
                        </div>
                        <div className={styles.item_price}>
                            <span className="text_16_Inter_Medium">{formatNumber(item.discountPrice)}</span>
                        </div>
                        <div className={styles.item_category}>
                            <span className="text_16_NotoSansKR_Medium">{item.category}</span>
                        </div>
                        <div className={styles.item_detail}>
                            <button
                                onClick={() => programDetail(item.workBookProductId)}
                                className={`${styles.program_detail_button} text_16_NotoSansKR_Bold`}
                            >
                                프로그램 상세정보
                            </button>
                        </div>
                    </div>
                ))}
            </>
        );
    };
    return (
        <div className={styles.main_container}>
            <div className="text_32_NotoSansKR_Bold">프로그램</div>
            <div className={styles.content_container}>
                <FilterContainer />
                <OfficialRender />
            </div>
        </div>
    );
};

import ReactModal from 'react-modal';

import styles from './css/AdditionalInfo.module.css';
import { useContext } from 'react';
import { ModalContext } from '../../../../../context/ModalContext';
import { ReactComponent as Icon_x } from '../../../../../assets/public/icon_x.svg';
import { EssentialMark } from '../../../../member/component/EssentialMark';

export const AdditionalInfoModal = () => {
    const customModalStyles: ReactModal.Styles = {
        overlay: {
            position: 'fixed',
            top: '0',
            left: '0',
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: 'transparent',
            zIndex: 200,
        },
        content: {
            border: 'none',
            position: 'fixed',
            width: '100%',
            height: '100vh',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            borderRadius: '10px',
            background: 'rgba(0, 0, 0, 0.6)',
        },
    };

    const { onConfirm, additionalInfoVisible, additionalInfoOpenModal } = useContext(ModalContext);

    return (
        <ReactModal
            style={customModalStyles}
            isOpen={additionalInfoVisible}
            shouldCloseOnOverlayClick
            appElement={document.getElementById('root') as HTMLElement}
            onRequestClose={() => {
                console.log('hihihi');
            }}
        >
            <div className={styles.modal_content_container}>
                <div className={styles.title_header_container}>
                    <span className="text_22_NotoSansKR_Bold">부가정보</span>
                    <button onClick={() => additionalInfoOpenModal(false)}>
                        <Icon_x />
                    </button>
                </div>
                <div className={styles.input_content_container}>
                    <div className={styles.input_container}>
                        <span
                            className="text_16_NotoSansKR_Bold"
                            style={{
                                color: '#626466',
                            }}
                        >
                            동의서 작성 날짜
                            <EssentialMark />
                        </span>
                        <input className={styles.input} />
                    </div>
                    <div className={styles.input_container}>
                        <span
                            className="text_16_NotoSansKR_Bold"
                            style={{
                                color: '#626466',
                            }}
                        >
                            프로그램 권고 유형
                        </span>
                        <input className={styles.input} />
                    </div>
                    <div className={styles.input_container}>
                        <span
                            className="text_16_NotoSansKR_Bold"
                            style={{
                                color: '#626466',
                            }}
                        >
                            권고 횟수
                        </span>
                        <input className={styles.input} />
                    </div>
                    <div className={styles.input_container}>
                        <span
                            className="text_16_NotoSansKR_Bold"
                            style={{
                                color: '#626466',
                            }}
                        >
                            권고 날짜
                        </span>
                        <input className={styles.input} />
                    </div>
                    <div className={styles.input_container}>
                        <span
                            className="text_16_NotoSansKR_Bold"
                            style={{
                                color: '#626466',
                            }}
                        >
                            미참여 사유
                        </span>
                        <input className={styles.input} />
                    </div>
                    <div className={styles.input_container}>
                        <span
                            className="text_16_NotoSansKR_Bold"
                            style={{
                                color: '#626466',
                            }}
                        >
                            미참여 상세사유
                        </span>
                        <input className={styles.input} />
                    </div>
                </div>
                <div className={styles.button_container}>
                    <button
                        onClick={() => additionalInfoOpenModal(false)}
                        className={`${styles.cancel_button} text_16_NotoSansKR_Bold`}
                    >
                        취소
                    </button>
                    <button className={`${styles.save_button} text_16_NotoSansKR_Bold`}>입력완료</button>
                </div>
            </div>
        </ReactModal>
    );
};

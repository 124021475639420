import React, { useCallback, useEffect, useRef, useState } from 'react';
import styles from '../../css/FirstSession.module.css';
import '../../../css/PublicSessionStyle.css';
import ReactPlayer from 'react-player';
import Img1 from '../../../../../../assets/face/session1/session1_1.png';
import Img2 from '../../../../../../assets/face/session1/session1_2.png';
import Img3 from '../../../../../../assets/face/session1/session1_3.png';
import Img_brain from '../../../../../../assets/face/session1/session1_brain.png';
import Img4 from '../../../../../../assets/face/session1/session1_4.png';

import {
    AdvantagesAndGoals,
    DesiredSymptomsAndReasons,
    TherapistThoughtFeelInput,
    ThoughtFeelInput,
    VideoPopup,
} from '../../component/FirstSessionComponent';
import { SubmitWorkbook } from '../../component/PublicSessionComponent';
import { useLocation } from 'react-router-dom';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { updateSessionPage } from '../../../../../../features/session/sessionReducer';
import { getSessionData } from '../../ts/SessionApi';

export const TherapistFirstSession = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const currentSession = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);

    const queryParams = new URLSearchParams(location.search);
    const encryptedData = queryParams.get('session');
    const { data, isLoading, error, refetch } = useQuery({
        queryKey: ['getFirstSessionData', encryptedData],
        queryFn: () => getSessionData(encryptedData!),
        placeholderData: keepPreviousData,
    });

    const playerRef = useRef<ReactPlayer | null>(null);
    const playerPopUpRef = useRef<ReactPlayer | null>(null);
    const [playing, setPlaying] = useState(false);
    const [volume, setVolume] = useState(0.8); // 초기 볼륨
    const [playbackTime, setPlaybackTime] = useState(0);
    const [popUpVisible, setPopUpVisible] = useState<boolean>(true);

    const targetRef: any = useRef(null);
    const [showPopup, setShowPopup] = useState(false);
    const lastScrollY = useRef(0); // useRef로 상태를 관리

    const handleScroll = () => {
        if (targetRef.current) {
            const rect = targetRef.current.getBoundingClientRect();
            const currentScrollY = window.scrollY;

            // 아래로 스크롤할 때
            if (currentScrollY > lastScrollY.current) {
                // 컴포넌트가 화면에 보이지 않으면 팝업을 켭니다.
                if (rect.bottom < 600 || rect.top > window.innerHeight) {
                    // 현재 팝업이 보이지 않을 때만 상태 업데이트
                    setShowPopup(true);
                }
            }
            // 위로 스크롤할 때
            else {
                // 컴포넌트가 화면에 보이면 팝업을 끕니다.
                if (rect.top < window.innerHeight && rect.bottom > 0) {
                    // 현재 팝업이 보일 때만 상태 업데이트
                    setShowPopup(false);
                }
            }

            // 마지막 스크롤 위치 업데이트
            lastScrollY.current = currentScrollY;
        }
    };

    // 동영상의 재생 시간 업데이트
    const handleProgress = (progress: { playedSeconds: number }) => {
        setPlaybackTime(progress.playedSeconds);
    };

    useEffect(() => {
        // 스크롤 이벤트 리스너 추가
        window.addEventListener('scroll', handleScroll);
        return () => {
            // 컴포넌트 언마운트 시 이벤트 리스너 제거
            window.removeEventListener('scroll', handleScroll);
        };
    }, []); // 빈 배열을 사용하여 한 번만 실행

    useEffect(() => {
        if (showPopup && playerPopUpRef.current) {
            playerPopUpRef.current.seekTo(playbackTime, 'seconds');
        }
    }, [showPopup]); // showPopup이 true일 때만 seekTo 호출

    useEffect(() => {
        window.scrollTo(0, 0);
        if (data) {
            dispatch(updateSessionPage(data));
        }
    }, [data]);

    const handlePlay = () => {
        setPlaying(!playing); // 재생 중
    };

    return (
        <div className={'therapist_main_container'}>
            <div ref={targetRef}>
                <ReactPlayer
                    ref={playerRef}
                    url="https://exqibabapqbf17923502.cdn.ntruss.com/ydTest/CBT10.mp4"
                    height="auto" // 비디오 높이
                    width="100%"
                    playing={playing}
                    onPlay={handlePlay}
                    onPause={handlePlay}
                    volume={volume}
                    onProgress={handleProgress}
                    pip={false}
                    controls // 기본 컨트롤 비활성화
                    stopOnUnmount={true}
                />
            </div>
            <img alt="1" src={Img1} className={styles.img1} />
            <img alt="2" src={Img2} className={styles.img} />
            <img alt="3" src={Img3} className={styles.img} />
            <div className={styles.thought_feel}>
                <div className={styles.thought_feel_container}>
                    <div>
                        <img alt="brain" src={Img_brain} className={styles.img} />
                    </div>
                    <TherapistThoughtFeelInput currentSession={currentSession} />
                </div>
            </div>
            <DesiredSymptomsAndReasons currentSession={currentSession} />
            <AdvantagesAndGoals />
            <img alt="4" src={Img4} className={styles.img} />
        </div>
    );
};

import styled from 'styled-components';
import styles from '../css/Login.module.scss';
import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import loginReducer, { updateLoginMemberAccount, updateLoginMemberPassword } from '../../../../features/loginReducer';
import { RootState } from '../../../../store';

interface CustomInputProps {
    $isFocused: boolean;
}

const CustomInput = styled.input<CustomInputProps>`
    box-sizing: border-box;
    width: 100%;
    height: 4rem;
    padding: 1.18rem 1.25rem;
    border-radius: 10px;
    outline: ${(props) => (props.$isFocused ? '0.1875rem solid #3A5074' : '1px solid #e3e5ea')};
    background: #fff;
`;

export const LoginInput = ({ loginRule }: { loginRule: Function }) => {
    const dispatch = useDispatch();
    const login = useSelector((state: RootState) => state.login);

    const [isFocused, setIsFocused] = useState<{ bool: boolean; index: number }>({ bool: false, index: 0 });

    const loginAry = [
        {
            title: '아이디',
            placeholder: '아이디 입력',
            value: login.memberAccount,
            fn: (value: string) => {
                dispatch(updateLoginMemberAccount(value));
            },
        },
        {
            title: '비밀번호',
            placeholder: '비밀번호 입력',
            value: login.memberPassword,
            fn: (value: string) => {
                dispatch(updateLoginMemberPassword(value));
            },
        },
    ];

    return (
        <form>
            <div className={styles.input_container}>
                {loginAry.map((item, index) => (
                    <div className={styles.input_content_container} key={index.toString()}>
                        <span className="text_18_NotoSansKR_Bold" style={{ color: '#626466' }}>
                            {item.title}
                        </span>
                        <CustomInput
                            $isFocused={isFocused?.bool && isFocused.index === index} // 포커스 상태 전달
                            className={'text_18_NotoSansKR_Regular'}
                            placeholder={item.placeholder}
                            value={item.value}
                            autoComplete={'off'}
                            type={index === 1 ? 'password' : 'email'}
                            onChange={(e) => item.fn(e.target.value)} // 로컬 상태와 Redux 상태 업데이트
                            onFocus={() => setIsFocused({ bool: true, index: index })}
                            onBlur={() => setIsFocused({ bool: false, index: index })}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    loginRule();
                                }
                            }}
                        />
                    </div>
                ))}
            </div>
        </form>
    );
};

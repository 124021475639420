import styles from '../../css/SeventhSession.module.css';
import '../../../css/PublicSessionStyle.css';

import {
    RealictResults,
    SocraticQuestionAnswer,
    SocraticQuestionAnswerStep,
    SubmitWorkbook,
} from '../../component/PublicSessionComponent';
import { useLocation } from 'react-router-dom';

export const TherapistSeventhSession = () => {
    const location = useLocation();
    const currentSession = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);
    return (
        <div className={'therapist_main_container'}>
            <SocraticQuestionAnswerStep />
            <RealictResults />
        </div>
    );
};

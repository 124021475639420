import React, { useContext, useEffect, useState } from 'react';

import styles from './css/Signup.module.css';

import { Illustration } from '../component/Illustration';
import { SignupInput } from './component/SignupInput';
import logoPng from '../../../assets/member/login/logo.png';
import { ReactComponent as Icon_checkbox } from '../../../assets/member/icon_checkbox.svg';
import { ReactComponent as Icon_checkedbox } from '../../../assets/member/icon_checkedbox.svg';
import { ReactComponent as Icon_check } from '../../../assets/member/signup/icon_check.svg';
import { ReactComponent as Icon_arrowright } from '../../../assets/member/signup/icon_arrowright.svg';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { ModalContext } from '../../../context/ModalContext';
import { MemberTypeMenu } from '../login/component/MemberTypeMenu';
import { MainButton } from '../component/MainButton';
import { useDispatch, useSelector } from 'react-redux';
import { updateUserIdentityInfo } from '../../../features/signupReducer';
import { RootState } from '../../../store';
import axiosInstance from '../../../utils/AxiosInstanceJava';

interface MemberTypeButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    $policyAllAgreeCheck: boolean; // 추가적인 prop 정의
}

export const Signup = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const signup = useSelector((state: RootState) => state.signup);
    const memberType = useSelector((state: RootState) => state.memberType);
    const { oneButtonOpenModal } = useContext(ModalContext);

    const policyAry = [
        { title: '개인정보처리방침 동의', url: '/public/privacyPolicy' },
        { title: '민감정보 처리 동의', url: '/public/sensitivenInfo' },
        { title: '고유식별정보 처리 동의', url: '/public/uniqueIdentifier' },
    ];

    const [policyCheckedAry, setPolicyCheckedAry] = useState<{ id: number; agree: boolean }[]>([
        { id: 0, agree: false },
        { id: 1, agree: false },
        { id: 2, agree: false },
    ]);

    const policyAllAgreeCheck = policyCheckedAry.every((item) => item.agree);

    const policyCheck = (id: number) => {
        setPolicyCheckedAry((prevAry) =>
            prevAry.map((item) => (item.id === id ? { ...item, agree: !item.agree } : item))
        );
    };

    const policyAllAgree = () => {
        if (policyAllAgreeCheck) {
            setPolicyCheckedAry([
                { id: 0, agree: false },
                { id: 1, agree: false },
                { id: 2, agree: false },
            ]);
        } else {
            setPolicyCheckedAry([
                { id: 0, agree: true },
                { id: 1, agree: true },
                { id: 2, agree: true },
            ]);
        }
    };

    const identityVeification = () => {
        dispatch(updateUserIdentityInfo(['PYSOYA', '010-2222-3333', 970123, 0]));
    };

    const dupliAccountCheck = async () => {
        await axiosInstance
            .post('/api/auth/check/account/v1', {
                memberAccount: signup.memberAccount,
            })
            .then((response) => {
                if (response.data.result) {
                    signUp();
                } else {
                    oneButtonOpenModal(() => {}, '', '이미 가입된 계정입니다.', '확인');
                }
            });
    };

    const signUpRule = () => {
        let message = '';
        const accountRegex = /^(?=.*[a-z])(?=.*\d)[a-z\d]{4,}$/;
        const passwordRegex =
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>])[a-zA-Z\d!@#$%^&*(),.?":{}|<>]{4,}$/;
        const emailAddressRegex = /^(?!-)([a-zA-Z0-9-]{1,63}(?<!-)\.)+[a-zA-Z]{2,}$/;

        const falseCount = policyCheckedAry.filter((item) => item.agree === false).length;
        if (signup.snsType !== 'normal') {
            signUp();
        } else {
            if (signup.identityInfo[0] === '') {
                message = '본인인증을 진행해 주세요.';
            } else if (signup.emailAddress === '' || signup.memberEmail === '') {
                message = '이메일 주소를 입력해 주세요.';
            } else if (!emailAddressRegex.test(signup.emailAddress.replace('@', ''))) {
                message = '올바르지 않은 이메일 형식입니다.';
            } else if (!accountRegex.test(signup.memberAccount)) {
                message = '올바르지 않은 아이디 형식입니다. (특수문자, 띄어쓰기, 한글 불가)';
            } else if (!passwordRegex.test(signup.memberPassword)) {
                // message = '올바르지 않은 비밀번호 형식입니다. (띄어쓰기 미포함 최소 4자리 이상 입력 가능)';
                message =
                    '올바르지 않은 비밀번호 형식입니다. (띄어쓰기 미포함 최소 4자리 이상,영문 소문자, 영문 대문자, 숫자, 특수문자 포함)';
            } else if (falseCount >= 2) {
                message = '필수 약관에 동의해 주세요.';
            } else if (memberType.type === 1 && signup.selectedOrgan.length === 0) {
                message = '소속기관을 선택해 주세요. (복수선택 가능)';
            } else {
                dupliAccountCheck();
            }

            if (message !== '') {
                return oneButtonOpenModal(
                    () => {
                        message = '';
                    },
                    '',
                    message,
                    '확인'
                );
            } else {
                signUp();
            }
        }
    };

    const signUp = async () => {
        let message = '';
        message = '14세 미만 회원가입 불가';
        const userData = {
            memberAccount: signup.memberAccount,
            memberEmail: signup.memberEmail + signup.emailAddress,
            memberPassword: signup.snsType === 'kakao' ? '' : signup.memberPassword,
            memberName: signup.identityInfo[0],
            memberPhone: signup.identityInfo[1],
            memberBirth: signup.identityInfo[2],
            memberGender: signup.identityInfo[3],
            snsType: signup.snsType === 'kakao' ? 'kakao' : 'normal',
            memberRole: memberType.type === 0 ? 'user' : 'employee',
            companyList: signup.selectedOrgan,
            termsOfUse: policyCheckedAry[0].agree,
            privacyConsent: policyCheckedAry[1].agree,
            infoThird: policyCheckedAry[2].agree,
        };
        console.log(userData);
        await axiosInstance
            .post('/api/auth/signUp/v1', userData)
            .then((response) => {
                oneButtonOpenModal(
                    () => {},
                    '',
                    '회원가입 신청이 정상적으로 완료되었습니다. 관리자가 확인 후 승인까지 영업시간 내에 완료될 수 있으며 승인 완료 후 본 서비스를 이용하실 수 있습니다.',
                    '확인'
                );
            })
            .catch((error) => {
                console.log(error);
            });
        // oneButtonOpenModal(() => {}, '', message, '확인');
    };

    const PolicyContent = () => {
        return (
            <div className={styles.policy_checkbox_container}>
                {policyAry.map((item, index) => (
                    <div className={styles.policy_content_container} key={index.toString()}>
                        <div className={styles.policy_content}>
                            <button
                                onClick={(e) => {
                                    e.preventDefault();
                                    policyCheck(index);
                                }}
                            >
                                {policyCheckedAry[index].agree ? (
                                    <Icon_checkedbox width={'1.5rem'} height={'1.5rem'} />
                                ) : (
                                    <Icon_checkbox width={'1.5rem'} height={'1.5rem'} />
                                )}
                            </button>
                            <button
                                onClick={() => navigate(item.url)}
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <span className="text_18_NotoSansKR_Regular">{item.title}</span>
                                <Icon_arrowright className={styles.icon_arrowright} />
                            </button>
                        </div>
                    </div>
                ))}
            </div>
        );
    };

    return (
        <div className={styles.main_container}>
            <div className={styles.view_container}>
                <div className={styles.content_container}>
                    <img className={styles.logo} src={logoPng} />
                    <div>
                        <div className={styles.login_title}>
                            <span className="text_40_Inter_Bold">YD iCBT </span>
                            <span className="text_40_NotoSansKR_Bold">회원가입</span>
                        </div>
                        <div className={`${styles.signup_title} text_18_NotoSansKR_Regular`}>
                            <span
                                style={{
                                    color: '#626466',
                                }}
                            >
                                계정이 있으신가요?
                            </span>
                            <button className={'text_18_NotoSansKR_Regular'} onClick={() => navigate('/public/login')}>
                                <span
                                    style={{
                                        color: '#3A5074',
                                        textDecorationLine: 'underline',
                                    }}
                                >
                                    로그인 하기
                                </span>
                            </button>
                        </div>
                    </div>
                    <MemberTypeMenu menuList={['일반회원', '직원']} selectedIndex={0} reset={() => {}} />
                    <div className={styles.identity_container}>
                        <span className="text_18_NotoSansKR_Bold">본인인증</span>
                        <span
                            className="text_18_NotoSansKR_Regular"
                            style={{
                                color: '#626466',
                            }}
                        >
                            더 안전한 서비스 이용을 위해 최초 1회 휴대폰 본인인증을 진행해 주세요.
                        </span>
                        <button
                            onClick={identityVeification}
                            className={`${styles.identity_button} text_18_NotoSansKR_Bold`}
                        >
                            휴대폰 본인인증
                        </button>
                    </div>
                    <SignupInput />
                    <PolicyButton $policyAllAgreeCheck={policyAllAgreeCheck} onClick={policyAllAgree}>
                        <Icon_check width={'1.25rem'} height={'1.25rem'} color="" />
                        <span
                            className="text_18_NotoSansKR_Bold"
                            style={{
                                color: policyAllAgreeCheck ? '#3A5074' : '#D2D5D9',
                            }}
                        >
                            아래 내용에 모두 동의합니다.
                        </span>
                    </PolicyButton>
                    <PolicyContent />
                    <MainButton title={'회원가입'} onClick={signUpRule} />
                </div>
            </div>
            <Illustration />
        </div>
    );
};

const PolicyButton = styled.button<MemberTypeButtonProps>`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 2.5rem;
    gap: 0.5rem;
    padding: 1.18rem 0 1.18rem 0;
    border-radius: 0.625rem;
    border: ${(props) => (props.$policyAllAgreeCheck ? '1px solid #3A5074' : '1px solid #e3e5ea')};
    color: ${(props) => (props.$policyAllAgreeCheck ? '#3A5074' : '#D2D5D9')};
    background: #fff;
`;

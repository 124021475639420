import styled from 'styled-components';
import illustrationPng from '../../../assets/main_illustration.png';

export const Illustration = () => {
    return (
        <IllustrationView>
            <img
                src={illustrationPng}
                style={{
                    width: '100%',
                    backgroundSize: 'contain',
                }}
            />
        </IllustrationView>
    );
};

const IllustrationView = styled.div`
    display: flex;
    position: fixed;
    top: 0;
    right: 0;
    justify-content: center;
    box-sizing: border-box;
    height: 100%;
    width: 36.04%;
    padding: 0 3.38%;
    box-sizing: border-box;
    align-items: center;
    background: linear-gradient(
            230deg,
            rgba(227, 243, 255, 0.9) 13.52%,
            rgba(244, 250, 255, 0.9) 28.64%,
            rgba(249, 251, 255, 0.9) 43.67%,
            rgba(236, 244, 255, 0.9) 63.81%,
            rgba(217, 233, 254, 0.9) 83.62%
        ),
        linear-gradient(158deg, rgba(255, 212, 0, 0.5) 0%, rgba(255, 255, 255, 0.5) 100%);
`;

const IllustrationContent = styled.div`
    width: 32.75rem;
    height: 19.375rem;
    background-color: #d9d9d9;
    border-radius: 2.18rem;
`;

import ReactModal from 'react-modal';
import styles from './css/ModalComponent.module.css';
import { useContext } from 'react';
import { ModalContext } from '../context/ModalContext';

export const OneButtonModal = () => {
    const { oneButtonVisible, title, message, firstButton, closeModal, onCancel } = useContext(ModalContext);

    const customModalStyles: ReactModal.Styles = {
        overlay: {
            position: 'fixed',
            top: '0',
            left: '0',
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: 'transparent',
            zIndex: 300,
        },
        content: {
            border: 'none',
            position: 'fixed',
            width: '100%',
            height: '100vh',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            borderRadius: '10px',
            background: 'rgba(0, 0, 0, 0.6)',

            // backgroundColor: 'white',
            //     display: 'flex',
            //     flexDirection: 'column',
            //     height: '100px',
            // padding: padding(20, 20, 20, 20),
            //     boxSizing: 'border-box',
            //     borderRadius: '10px',
            //     boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.25)',
            //     backgroundColor: '#FFF',
        },
    };

    return (
        <ReactModal
            style={customModalStyles}
            isOpen={oneButtonVisible}
            shouldCloseOnOverlayClick
            appElement={document.getElementById('root') as HTMLElement}
            onRequestClose={() => {
                closeModal();
                onCancel();
            }}
        >
            <div className={styles.modal_content_container}>
                {title !== '' && (
                    <div>
                        <span className="text_22_NotoSansKR_Bold">{title}</span>
                    </div>
                )}
                <div>
                    <span
                        className="text_18_NotoSansKR_Medium"
                        style={{
                            color: '#020202',
                        }}
                    >
                        {message}
                    </span>
                </div>
                <div className={styles.button_container}>
                    <button
                        onClick={() => {
                            onCancel();
                            closeModal();
                        }}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                onCancel();
                                closeModal();
                            }
                        }}
                        className={`${styles.first_button} text_18_NotoSansKR_Bold`}
                        style={{
                            color: '#FFF',
                        }}
                    >
                        {firstButton}
                    </button>
                </div>
            </div>
        </ReactModal>
    );
};

export const TwoButtonModal = () => {
    const { twoButtonVisible, title, message, firstButton, secondButton, closeModal, onCancel, onConfirm } =
        useContext(ModalContext);

    const customModalStyles: ReactModal.Styles = {
        overlay: {
            position: 'fixed',
            top: '0',
            left: '0',
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: 'transparent',
            zIndex: 300,
        },
        content: {
            border: 'none',
            position: 'fixed',
            width: '100%',
            height: '100vh',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            borderRadius: '10px',
            background: 'rgba(0, 0, 0, 0.6)',

            // backgroundColor: 'white',
            //     display: 'flex',
            //     flexDirection: 'column',
            //     height: '100px',
            // padding: padding(20, 20, 20, 20),
            //     boxSizing: 'border-box',
            //     borderRadius: '10px',
            //     boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.25)',
            //     backgroundColor: '#FFF',
        },
    };

    return (
        <ReactModal
            style={customModalStyles}
            isOpen={twoButtonVisible}
            shouldCloseOnOverlayClick
            appElement={document.getElementById('root') as HTMLElement}
            onRequestClose={() => {
                closeModal();
                onCancel();
            }}
        >
            <div className={styles.modal_content_container}>
                {title !== '' && (
                    <div>
                        <span className="text_22_NotoSansKR_Bold">{title}</span>
                    </div>
                )}
                <div>
                    <span
                        className="text_18_NotoSansKR_Medium"
                        style={{
                            color: '#020202',
                        }}
                    >
                        {message}
                    </span>
                </div>
                <div className={styles.button_container}>
                    <button
                        onClick={() => {
                            onConfirm();
                            closeModal();
                        }}
                        className={`${styles.second_button} text_18_NotoSansKR_Bold`}
                    >
                        {firstButton}
                    </button>
                    <button
                        onClick={() => {
                            closeModal();
                            onCancel();
                        }}
                        className={`${styles.first_button} text_18_NotoSansKR_Bold`}
                    >
                        {secondButton}
                    </button>
                </div>
            </div>
        </ReactModal>
    );
};

import { useContext, useEffect, useRef, useState } from 'react';
import styles from '../../css/NinthSession.module.css';
import ReactPlayer from 'react-player';
import {
    AdvantageAutomaticAccidents,
    AuthmaticThinkingGraph,
    AutomaticAccidentInspection,
    BottomFiveSeven,
    CognitiveError,
    LearnedFelt,
    RealictResults,
    SituationEmotion,
    SocraticQuestionAnswer,
    SocraticQuestionAnswerStep,
    SubmitWorkbook,
    ThoughtSiuation,
} from '../../component/PublicSessionComponent';
import { useLocation } from 'react-router-dom';
import axiosInstance from '../../../../../../utils/AxiosInstanceJava';
import { RootState } from '../../../../../../store';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { getSessionData } from '../../ts/SessionApi';
import { VideoPopup } from '../../component/FirstSessionComponent';
import { decryptData } from '../../../../../../utils/croptojs';
import { useDispatch, useSelector } from 'react-redux';
import { Fields, updateSessionPage } from '../../../../../../features/session/sessionReducer';
import { ModalContext } from '../../../../../../context/ModalContext';

export const NinthSession = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const encryptedData = queryParams.get('session');
    const currentSession = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);

    const { registerSessionId, fields } = useSelector((state: RootState) => state.session);
    const { oneButtonOpenModal, twoButtonOpenModal } = useContext(ModalContext);

    const { data, isLoading, error, refetch } = useQuery({
        queryKey: ['getSessionData', encryptedData],
        queryFn: () => getSessionData(encryptedData!),
        enabled: encryptedData !== null,
        placeholderData: keepPreviousData,
    });

    const playerRef = useRef<ReactPlayer | null>(null);
    const playerPopUpRef = useRef<ReactPlayer | null>(null);
    const [playing, setPlaying] = useState(false);
    const [volume, setVolume] = useState(0.8); // 초기 볼륨
    const [playbackTime, setPlaybackTime] = useState(0);
    const [popUpVisible, setPopUpVisible] = useState<boolean>(true);

    const targetRef: any = useRef(null);
    const [showPopup, setShowPopup] = useState(false);
    const lastScrollY = useRef(0); // useRef로 상태를 관리

    const [videoUrl, setVideoUrl] = useState('https://exqibabapqbf17923502.cdn.ntruss.com/ydTest/CBT10.mp4');

    const [sessionVideoList, setSessionVideoList] = useState([
        {
            title: '세션1 영상',
            url: 'https://exqibabapqbf17923502.cdn.ntruss.com/ydTest/CBT10.mp4',
        },
        {
            title: '세션2 영상',
            url: '2',
        },
        {
            title: '세션3 영상',
            url: '3',
        },
        {
            title: '세션4 영상',
            url: '4',
        },
        {
            title: '세션5 영상',
            url: '5',
        },
        {
            title: '세션6 영상',
            url: '6',
        },
        {
            title: '세션7 영상',
            url: '7',
        },
        {
            title: '세션8 영상',
            url: '8',
        },
    ]);

    const rootFontSize = parseFloat(getComputedStyle(document.documentElement).fontSize); // 현재 루트 폰트 사이즈

    const [popupStyle, setPopupStyle] = useState<{ top: number; right: number; width: number; height: number }>({
        top: 120 / rootFontSize, // px를 rem으로 변환
        right: 32 / rootFontSize, // px를 rem으로 변환
        width: 661 / rootFontSize, // px를 rem으로 변환
        height: 372 / rootFontSize, // px를 rem으로 변환
    });

    const sessionRole = () => {
        const fieldData: any = {
            // (sessionNo, 2,3,4,5,6,7,8,9) 자동사고 기록지 / 감정
            emotionIcon: fields.emotionIcon,

            // (sessionNo, 2,3,4,5,6,7,8,9) 자동사고 기록지 / 감정 점수
            emotionScore: fields.emotionScore,

            // (sessionNo, 3,4,5,6,7,8,9) 어떤 생각이 떠올랐나요?
            thought: fields.thought,

            // (sessionNo, 3,4,5,6,7,8,9) 최악의 경우, 어떤 일이 일어날 것이라는 생각이 들었나요?
            worstCaseScenario: fields.worstCaseScenario,

            // (sessionNo, 3,4,5,6,7,8,9) 그때 떠오른 이미지는
            image: fields.image,

            // (sessionNo, 3,4,5,6,7,8,9) 내가 어떤 사람으로 보여질까?
            selfPerception: fields.selfPerception,

            // (sessionNo, 3,4,5,6,7,8,9) 상황에 대한 생각 + 생각으로 인한 결과
            situationThoughts: fields.situationThoughts,

            // (sessionNo, 3,4,5,6,7,8,9) 생각을 믿는 정도
            trustInThoughts: fields.trustInThoughts,

            // (sessionNo, 4,5,6,7,8,9,10) 인지오류
            cognitiveError: fields.cognitiveError,

            // (sessionNo, 5) 소크라테스 문답법 / 내 생각이 맞다는 증거
            evidenceCorrectThinking: fields.evidenceCorrectThinking,

            // (sessionNo, 5) 소크라테스 문답법 / 내 생각이 틀리다는 증거
            evidenceIncorrectThinking: fields.evidenceIncorrectThinking,

            // (sessionNo, 6) 소크라테스 문답법 / 나의 자동사고에 대한 여러가지 가능성
            authmaticThinkingGraph: fields.authmaticThinkingGraph,

            // (sessionNo, 7,8) 소크라테스 문답법 / 자동사고의 대안적 설명
            altExpAutoThought: fields.altExpAutoThought,

            // (sessionNo, 7) 소크라테스 문답법 / 최악의 결과
            worstOutcome: fields.worstOutcome,

            // (sessionNo, 7) 소크라테스 문답법 / 최상의 결과
            bestOutcome: fields.bestOutcome,

            // (sessionNo, 7) 소크라테스 문답법 / 현실적 결과
            realisticOutcome: fields.realisticOutcome,

            // (sessionNo, 8) 이 자동사고를 믿을 때 얻을 수 있는 장점
            advantagesOfTrustingAutoThought: fields.advantagesOfTrustingAutoThought,

            // (sessionNo, 8) 내 자동사고가 '친구의 고민'이라고 생각하고 조언해 보자.
            letter: fields.letter,

            // (sessionNo, 8) 자동사고 점검 / 자동사고에 대한 합리적 사고
            rationalThinkingAutoCheck: fields.rationalThinkingAutoCheck,

            // (sessionNo, 8) 자동사고 점검 / 감정
            emotionsAfterChange: fields.emotionsAfterChange,

            // (sessionNo, 8) 자동사고 점검 / 감정 점수
            scoreAfterChange: fields.scoreAfterChange,

            // (sessionNo, 8) 배운점/느낀점
            lessonsLearned: fields.lessonsLearned,
        };

        let hasWhitespace = false;

        // 각 key에 대해 patient가 공백인지 확인
        Object.keys(fieldData).forEach((key) => {
            const values = fieldData[key as keyof Fields]; // key를 keyof Fields로 캐스팅

            values.forEach((item: any) => {
                if (item.patient.trim() === '' || item.patient.includes(' ')) {
                    // 공백이 있으면 모달 창 띄우기
                    hasWhitespace = true; // 공백이 있음을 표시
                }
            });
        });

        if (hasWhitespace) {
            oneButtonOpenModal(() => {}, '', '워크북내 모든 입력란에 작성해 주세요.', '확인');
        } else {
            twoButtonOpenModal(
                () => {},
                () => {
                    saveSessionData(fieldData);
                },
                '',
                '입력하신 모든 내용은 제출 완료 이후 수정이 불가합니다.\n정말 이대로 제출하시겠습니까?',
                '취소',
                '확인'
            );
        }
    };

    // 워크북 제출
    const saveSessionData = async (fieldData: any) => {
        const requestData = {
            registerSessionId: decryptData(encryptedData!),
            fields: fieldData,
        };
        await axiosInstance.post(`/api/workBook/field/v1`, requestData).then((response) => {
            const res = response.data;
            if (res.result) {
                switch (res.message) {
                    case 'SESSION_FIELD_SAVE_SUCCESS':
                        break;
                }
            }
        });
    };

    const handleScroll = () => {
        if (targetRef.current) {
            const rect = targetRef.current.getBoundingClientRect();
            const currentScrollY = window.scrollY;

            // 아래로 스크롤할 때
            if (currentScrollY > lastScrollY.current) {
                // 컴포넌트가 화면에 보이지 않으면 팝업을 켭니다.
                if (rect.bottom < 600 || rect.top > window.innerHeight) {
                    // 현재 팝업이 보이지 않을 때만 상태 업데이트
                    // showPopup이 false일 때만 PIP 활성화
                    if (!showPopup) {
                        setShowPopup(true);
                    }
                    // activatePIP();
                }
            }
            // 위로 스크롤할 때
            else {
                // 컴포넌트가 화면에 보이면 팝업을 끕니다.
                if (rect.top < window.innerHeight && rect.bottom > 0) {
                    if (showPopup) {
                        // showPopup이 true일 때만 PIP 비활성화
                        // setPlaying(false);
                        setShowPopup(false);
                        // deactivatePIP();
                    }
                }
            }

            // 마지막 스크롤 위치 업데이트
            lastScrollY.current = currentScrollY;
        }
    };

    const handleMouseDown = (e: React.MouseEvent) => {
        const startX = e.clientX;
        const startY = e.clientY;

        const handleMouseMove = (moveEvent: MouseEvent) => {
            const root = document.documentElement;
            const computedStyle = getComputedStyle(root);
            const rootSize = computedStyle.fontSize;
            const padding = parseFloat(rootSize) * 2;

            const newTop = popupStyle.top + (moveEvent.clientY - startY);
            const newRight = popupStyle.right - (moveEvent.clientX - startX); // 오른쪽 기준으로 변경

            const maxTop = window.innerHeight - (popupStyle.height + padding); // 비디오 높이 (예: 100px)
            const maxRight = window.innerWidth - (popupStyle.width + padding); // 비디오 너비 (예: 300px)

            const constrainedTop = Math.min(Math.max(padding, newTop), maxTop);
            const constrainedRight = Math.min(Math.max(padding, newRight), maxRight);
            setPopupStyle((prev) => ({
                ...prev,
                top: constrainedTop,
                right: constrainedRight,
            }));
        };

        const handleMouseUp = () => {
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('mouseup', handleMouseUp);
        };

        window.addEventListener('mousemove', handleMouseMove);
        window.addEventListener('mouseup', handleMouseUp);
    };

    const handleResizeMouseDown = (e: React.MouseEvent) => {
        e.stopPropagation(); // 비디오 드래그 이벤트와 겹치지 않도록 함

        const startWidth = popupStyle.width;
        const startHeight = popupStyle.height;
        const startX = e.clientX;
        const startY = e.clientY;

        const handleMouseMove = (moveEvent: MouseEvent) => {
            const newWidth = startWidth - (moveEvent.clientX - startX);
            const newHeight = newWidth / (16 / 9); // 비율 유지
            setPopupStyle((prev) => ({
                ...prev,
                width: newWidth > 100 ? newWidth : 100, // 최소 너비 설정
                height: newHeight > 100 ? newHeight : 372, // 최소 높이 설정
            }));
        };

        const handleMouseUp = () => {
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('mouseup', handleMouseUp);
        };

        window.addEventListener('mousemove', handleMouseMove);
        window.addEventListener('mouseup', handleMouseUp);
    };
    useEffect(() => {
        // 스크롤 이벤트 리스너 추가
        window.addEventListener('scroll', handleScroll);
        return () => {
            // 컴포넌트 언마운트 시 이벤트 리스너 제거
            window.removeEventListener('scroll', handleScroll);
        };
    }, [showPopup]); // 빈 배열을 사용하여 한 번만 실행

    useEffect(() => {
        window.scrollTo(0, 0);
        if (data) {
            dispatch(updateSessionPage(data));
        }
    }, [data]);

    const handlePlay = () => {
        setPlaying(!playing); // 재생 중
    };

    // 동영상의 재생 시간 업데이트
    const handleProgress = (progress: { playedSeconds: number }) => {
        if (!showPopup) {
            setPlaybackTime(progress.playedSeconds);
        }
    };

    const SelectVideo = () => {
        return (
            <div className={styles.video_list_container}>
                {sessionVideoList.map((item, index) => (
                    <button
                        key={index.toString()}
                        onClick={() => {
                            setVideoUrl(item.url);
                        }}
                        className={`${styles.vidoe_list_button} ${
                            videoUrl === item.url ? styles.activate : styles.deactivate
                        }`}
                    >
                        <span
                            className={`${
                                videoUrl === item.url ? 'text_18_NotoSansKR_Bold' : 'text_18_NotoSansKR_Medium'
                            }`}
                            style={{
                                color: videoUrl === item.url ? '#3A5074' : '#9D9FA2',
                            }}
                        >
                            {item.title}
                        </span>
                    </button>
                ))}
            </div>
        );
    };

    return (
        <div className={styles.main_container}>
            <div
                ref={targetRef}
                style={{
                    marginBottom: '2.25rem',
                }}
            >
                <SelectVideo />
                <ReactPlayer
                    ref={playerRef}
                    url={videoUrl}
                    height="auto" // 비디오 높이
                    width="100%"
                    playing={playing}
                    onPlay={handlePlay}
                    onPause={handlePlay}
                    volume={volume}
                    onProgress={handleProgress}
                    pip={false}
                    controls // 기본 컨트롤 비활성화
                    stopOnUnmount={true}
                />
            </div>
            {showPopup && popUpVisible && (
                <VideoPopup
                    showPopup={playing}
                    playerPopUpRef={playerPopUpRef}
                    onProgress={handleProgress}
                    currentTime={playbackTime}
                    popupStyle={popupStyle}
                    onMouseDown={handleMouseDown}
                    onResizeMouseDown={handleResizeMouseDown}
                    updatePopUpVisible={(visible: boolean) => setPopUpVisible(visible)}
                />
            )}
            <SituationEmotion currentSession={currentSession} />

            <ThoughtSiuation />
            <BottomFiveSeven />
            <CognitiveError />
            <SocraticQuestionAnswerStep />
            <AuthmaticThinkingGraph />
            <RealictResults />
            <AdvantageAutomaticAccidents />
            <AutomaticAccidentInspection />
            <LearnedFelt />
            <SubmitWorkbook onClick={sessionRole} />
        </div>
    );
};

import React from 'react';
import styled from 'styled-components';

export const MainButton = ({
    title,
    onClick,
}: {
    title: string;
    onClick: React.MouseEventHandler<HTMLButtonElement>;
}) => {
    return (
        <CustomButton onClick={onClick} className={`text_18_NotoSansKR_Bold`}>
            {title}
        </CustomButton>
    );
};

const CustomButton = styled.button`
    width: 100%;
    height: 4rem;
    margin-top: 2.5rem;
    border-radius: 0.625rem;
    color: #fff;
    background: #3a5074;
}
`;

import { Route, Routes } from 'react-router-dom';
import { ManagerAdministratorHeader } from '../../../../component/HeaderComponent';
import { AdministratorLeftMenu } from '../../../../component/MenuComponent';
import styles from './css/Administrator.module.css';
import { AssignedDetail } from '../assignedDetail/AssignedDetail';
import { MemberInfo } from '../../../public/MemberInfo';
import { NotificationSetting } from '../../../public/NotificationSetting';
import { AdministratorAssignedMember } from '../assignedMember/AdministratorAssignedMember';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { ReservationStatus } from '../reservationStatus/ReservationStatus';
import { Authority } from '../authority/Authority';

export const Administrator = () => {
    const visible = useSelector((state: RootState) => state.navVisible);

    return (
        <div className={styles.main_container}>
            <ManagerAdministratorHeader title={''} />
            <AdministratorLeftMenu />
            <div className={`${styles.content_container} ${visible.navVisible ? styles.activate : styles.deactivate}`}>
                <Routes>
                    <Route path="assignedMember" element={<AdministratorAssignedMember />} />
                    <Route path="assignedMember/detail/*" element={<AssignedDetail />} />
                    <Route path="reservationStatus" element={<ReservationStatus />} />
                    <Route path="authority" element={<Authority />} />
                    <Route path="memberInfo" element={<MemberInfo />} />
                    <Route path="notificationSetting" element={<NotificationSetting />} />
                </Routes>
            </div>
        </div>
    );
};

import React, { createContext, useContext, useEffect, useState } from 'react';

const WindowWidthContext = createContext<number | undefined>(undefined);

export const WindowWidthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const designWidth = 1920;

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth); // 윈도우 너비 업데이트
        };

        window.addEventListener('resize', handleResize); // resize 이벤트 리스너 등록

        return () => {
            window.removeEventListener('resize', handleResize); // 컴포넌트 언마운트 시 리스너 제거
        };
    }, []);

    // 비율 계산 및 CSS 변수 설정
    useEffect(() => {
        const ratio = windowWidth / designWidth;
        document.documentElement.style.setProperty('--window-ratio', ratio.toFixed(2).toString());
    }, [windowWidth]); // windowWidth가 변경될 때마다 업데이트

    return <WindowWidthContext.Provider value={windowWidth}>{children}</WindowWidthContext.Provider>;
};

export const useWindowWidth = () => {
    const context = useContext(WindowWidthContext);
    if (context === undefined) {
        throw new Error('useWindowWidth must be used within a WindowWidthProvider');
    }
    return context;
};

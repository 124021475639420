import styles from '../../css/ThirdSession.module.css';
import '../../../css/PublicSessionStyle.css';

import {
    BottomFiveSeven,
    SituationEmotion,
    SubmitWorkbook,
    ThoughtSiuation,
} from '../../component/PublicSessionComponent';
import { useLocation } from 'react-router-dom';

export const TherapistThirdSession = () => {
    const location = useLocation();
    const currentSession = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);
    return (
        <div className={'therapist_main_container'}>
            <SituationEmotion currentSession={currentSession} />

            <ThoughtSiuation />
            <BottomFiveSeven />
        </div>
    );
};

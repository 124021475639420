import { useState } from 'react';
import styles from '../css/SecondSession.module.css';

import Postit from '../../../../../assets/face/session2/session2_postit.png';
import { getMemberInfo } from '../../../../../utils/GetMemberInfo';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import {
    changeFeedbackState,
    updateEmotionalReactions,
    updateSessionField,
} from '../../../../../features/session/sessionReducer';
import { WriteFeedbackTextarea } from './FeedbackComponent';
import { saveSessionFeedback } from '../ts/SessionApi';

export const ExperiencesEmotion = () => {
    const dispatch = useDispatch();
    const { fieldIds, fields } = useSelector((state: RootState) => state.session);

    const memberInfo = getMemberInfo();
    const readOnly = memberInfo?.memberRole !== 'user' && true;

    const openFeedback = (index: number) => {
        if (memberInfo?.memberRole === 'therapist') {
            const emotional = fields.emotionalReactions[index];
            dispatch(
                updateEmotionalReactions({
                    index: index,
                    patient: emotional.patient,
                    feedback: emotional.feedback,
                    visible: !emotional.visible,
                    feedbackCheck: emotional.feedbackCheck,
                })
            );
        }
    };

    const onChangeEmotionalReactions = (index: number, text: string) => {
        const result = fields.emotionalReactions[index];

        switch (memberInfo?.memberRole) {
            case 'therapist':
                dispatch(
                    updateEmotionalReactions({
                        index: index,
                        patient: result.patient,
                        feedback: text,
                        visible: result.visible,
                        feedbackCheck: result.feedbackCheck,
                    })
                );
                break;
            default:
                dispatch(
                    updateEmotionalReactions({
                        index: index,
                        patient: text,
                        feedback: result.feedback,
                        visible: result.visible,
                        feedbackCheck: result.feedbackCheck,
                    })
                );
                break;
        }
    };

    const saveFeedback = (fieldId: number, field: any, key: string, index: number) => {
        if (field[index].feedbackCheck) {
            dispatch(changeFeedbackState({ index: index, key: key, value: field }));
        } else {
            const extractDataByKey = () => {
                return {
                    [key]: field,
                };
            };
            saveSessionFeedback(fieldId, extractDataByKey()).then((response) => {
                if (response.result) {
                    dispatch(updateSessionField({ index: index, key: key, value: field }));
                }
            });
        }
    };
    const cancel = (index: number) => {
        if (memberInfo?.memberRole === 'therapist') {
            openFeedback(index);
        }
    };

    return (
        <div className={styles.experiences_emotion_container}>
            <div
                className="text_32_NotoSansKR_Bold"
                style={{
                    color: '#41465A',
                }}
            >
                내가 경험하는 감정의 반응
            </div>
            <div className={styles.experiences_content_container}>
                <div className={styles.experiences_content}>
                    <div className={styles.postit_container}>
                        <img alt="postit" src={Postit} className={styles.postit} />
                        <div className={`${styles.postit_title} text_22_NotoSansKR_Bold`}>감정의 신체적 반응</div>
                    </div>
                    <div className={styles.experiences_input_container}>
                        <textarea
                            className={styles.experiences_input}
                            readOnly={readOnly}
                            onDoubleClick={() => openFeedback(0)}
                        />
                        {fields.emotionalReactions[0].visible && readOnly && (
                            <WriteFeedbackTextarea
                                onChange={(text: string) => onChangeEmotionalReactions(0, text)}
                                save={() =>
                                    saveFeedback(
                                        fieldIds.emotionalReactions,
                                        fields.emotionalReactions,
                                        'emotionalReactions',
                                        0
                                    )
                                }
                                cancel={() => cancel(0)}
                                height="19.06rem"
                                edit={fields.emotionalReactions[0].feedbackCheck}
                                value={fields.emotionalReactions[0].feedback}
                            />
                        )}
                    </div>
                </div>
                <div className={styles.experiences_content}>
                    <div className={styles.postit_container}>
                        <img alt="postit" src={Postit} className={styles.postit} />
                        <div className={`${styles.postit_title} text_22_NotoSansKR_Bold`}>감정의 행동적 반응</div>
                    </div>
                    <div className={styles.experiences_input_container}>
                        <textarea
                            className={styles.experiences_input}
                            readOnly={readOnly}
                            onDoubleClick={() => openFeedback(1)}
                        />
                        {fields.emotionalReactions[1].visible && readOnly && (
                            <WriteFeedbackTextarea
                                onChange={(text: string) => onChangeEmotionalReactions(10, text)}
                                save={() =>
                                    saveFeedback(
                                        fieldIds.emotionalReactions,
                                        fields.emotionalReactions,
                                        'emotionalReactions',
                                        1
                                    )
                                }
                                cancel={() => cancel(1)}
                                height="19.06rem"
                                edit={fields.emotionalReactions[1].feedbackCheck}
                                value={fields.emotionalReactions[1].feedback}
                            />
                        )}
                    </div>
                </div>
                <div className={styles.experiences_content}>
                    <div className={styles.postit_container}>
                        <img alt="postit" src={Postit} className={styles.postit} />
                        <div className={`${styles.postit_title} text_22_NotoSansKR_Bold`}>감정의 인지적 반응</div>
                    </div>
                    <div className={styles.experiences_input_container}>
                        <textarea
                            className={styles.experiences_input}
                            readOnly={readOnly}
                            onDoubleClick={() => openFeedback(2)}
                        />
                        {fields.emotionalReactions[2].visible && readOnly && (
                            <WriteFeedbackTextarea
                                onChange={(text: string) => onChangeEmotionalReactions(2, text)}
                                save={() =>
                                    saveFeedback(
                                        fieldIds.emotionalReactions,
                                        fields.emotionalReactions,
                                        'emotionalReactions',
                                        2
                                    )
                                }
                                cancel={() => cancel(2)}
                                height="19.06rem"
                                edit={fields.emotionalReactions[2].feedbackCheck}
                                value={fields.emotionalReactions[2].feedback}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

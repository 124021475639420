import moment from 'moment';
import { ProgressBar } from '../../../../../component/PublicComponent';
import { ReactComponent as Icon_search } from '../../../../../assets/therapist/icon_search.svg';
import { ReactComponent as Icon_checkbox } from '../../../../../assets/member/icon_checkbox.svg';
import { ReactComponent as Icon_arrowright } from '../../../../../assets/member/signup/icon_arrowright.svg';
import styles from './css/AssignedMemberComponent.module.css';
import { encryptData } from '../../../../../utils/croptojs';
import { useNavigate } from 'react-router-dom';

// 차트 타이틀
export const ChartTitle = ({ title }: { title: string }) => {
    return (
        <div
            className={styles.chart_title}
            style={{
                width:
                    title === '세션 진행도'
                        ? '12rem'
                        : title === ''
                        ? '8.75rem'
                        : title === '담당 상담사'
                        ? '8.31rem'
                        : '',
            }}
        >
            <span
                className="text_16_NotoSansKR_Medium"
                style={{
                    color: '#626466',
                }}
            >
                {title}
            </span>
        </div>
    );
};

// 이름(차트번호)
export const NameChartNumber = ({ name, chartNumber }: { name: string; chartNumber: number }) => {
    return (
        <div
            className={styles.patient_name}
            style={{
                width: '7.5rem',
            }}
        >
            <span className="text_16_NotoSansKR_Bold">
                {name}{' '}
                <span
                    className="text_16_Inter_Medium"
                    style={{
                        color: '#626466',
                    }}
                >
                    ({chartNumber})
                </span>
            </span>
        </div>
    );
};

// 프로그램 유형
export const ProgramType = ({ programType }: { programType: string }) => {
    let background: string = '';
    let color: string = '';

    switch (programType) {
        case '대면 CBT':
            color = '#66A467';
            background = '#EDF9EE';
            break;
        case '비대면 CBT':
            color = '#647FDC';
            background = '#EDF2FE';
            break;
        case '심리상담':
            color = '#DC63C2';
            background = '#FCEBF8';
            break;
        case 'TMS':
            color = '#7D6ADE';
            background = '#F2EEFE';
            break;
        case '이완치료':
            color = '#E2A11F';
            background = '#FFF7E6';
            break;
    }

    return (
        <div
            className={styles.program_type_container}
            style={{
                width: '7.5rem',
            }}
        >
            <div
                className={styles.program_type}
                style={{
                    background: background,
                }}
            >
                <span className="text_16_NotoSansKR_Medium" style={{ color: color }}>
                    {programType}
                </span>
            </div>
        </div>
    );
};

// 세션 진행도
export const SessionProgress = ({
    sessionProgress,
    maxProgress,
    moveToPatientCBT,
}: {
    sessionProgress: number;
    maxProgress: number;
    moveToPatientCBT: any;
}) => {
    return (
        <div className={styles.progress_container}>
            <ProgressBar progress={sessionProgress} max={maxProgress} />
            <button className={styles.progress_button} onClick={moveToPatientCBT}>
                <div
                    className={`${styles.progress_size} ${
                        sessionProgress > 0 ? styles.underline : ''
                    } text_16_Inter_Bold`}
                >
                    {sessionProgress}/{maxProgress}
                </div>
                <Icon_arrowright className={styles.progress_icon} />
            </button>
        </div>
    );
};

// 세션 완료일
export const SessionCompleteDate = ({ completeDate }: { completeDate: string }) => {
    return (
        <div
            className={`${styles.session_complete_date} text_16_Inter_Medium`}
            style={{
                width: '7.5rem',
            }}
        >
            {moment(completeDate).format('YYYY-MM-DD')}
        </div>
    );
};

// 최근 완료한 피드백
export const RecentCompleteFeedback = ({ recentCompleteSession }: { recentCompleteSession: number }) => {
    return (
        <div
            className={`${styles.recent_complete_session} text_16_Inter_Medium`}
            style={{
                width: '7.5rem',
            }}
        >
            {recentCompleteSession === 0 ? '' : '세션' + String(recentCompleteSession)}
        </div>
    );
};

// 담당 상담사
export const AssignedTherapist = ({ therapist }: { therapist: string[] }) => {
    return (
        <div
            className={styles.chart_title}
            style={{
                width: '8.31rem',
            }}
        >
            <span className="text_16_NotoSansKR_Medium">{therapist.join(', ')}</span>
        </div>
    );
};

// 담당 상담사
export const SelectAssignedTherapist = () => {
    return (
        <button
            className={`${styles.chart_title}`}
            style={{
                width: '8.31rem',
            }}
        >
            <span
                className="text_16_NotoSansKR_Medium"
                style={{
                    textDecoration: 'underline',
                    color: '#1100FF',
                }}
            >
                담당 상담사 선택
            </span>
            <Icon_arrowright className={styles.icon_arrowright} />
        </button>
    );
};

export const MemberInfoButton = ({ memberId }: { memberId: number }) => {
    const navigate = useNavigate();
    const memberDetail = () => {
        const queryString = `data=${encodeURIComponent(encryptData(memberId))}`;
        navigate(`detail?${queryString}`);
    };

    return (
        <button onClick={memberDetail} className={`${styles.user_info_button} text_16_NotoSansKR_Bold`}>
            회원 상세정보
        </button>
    );
};

export const SearchContainer = () => {
    return (
        <div className={styles.filter_container}>
            <div className={styles.filter_input_container}>
                <input className={`${styles.filter_input} text_16_NotoSansKR_Regular`} placeholder="검색어 입력" />
                <Icon_search className={styles.icon_search} />
            </div>
            <div className={styles.filter_checkbox_container}>
                <Icon_checkbox width={'1.5rem'} height={'1.5rem'} />
                <span className="text_18_NotoSansKR_Medium">피드백 미완료</span>
            </div>
        </div>
    );
};

import { useDispatch } from 'react-redux';
import { getMemberInfo } from '../../../../utils/GetMemberInfo';
import { updateSelectOrgan } from '../../../../features/headerReducer';
import { encryptData } from '../../../../utils/croptojs';
import { useContext } from 'react';
import { ModalContext } from '../../../../context/ModalContext';

export const normalLoginFn = async (
    loginType: string,
    user: any,
    saveIdChecked: boolean,
    axiosInstance: any,
    naviate: Function,
    snsType: string,
    oneButtonOpenModal: Function
) => {
    const firebase = localStorage.getItem('firebaseToken');
    let firebaseData: { firebase_token: string } = { firebase_token: '' };
    if (firebase !== null) {
        firebaseData = JSON.parse(firebase);
    }

    await axiosInstance
        .post(`/api/auth/login/${loginType}/v1`, {
            memberAccount: user.memberAccount,
            memberPassword: user.memberPassword,
            kakaoEmail: user.kakaoEmail,
            snsType: snsType,
            companyBranch: user.memberOrgan,
            firebaseToken: firebase ? firebaseData.firebase_token : '',
        })
        .then(async (response: any) => {
            if (response.data.result) {
                const token = response.data.tokenDTO;
                localStorage.setItem(
                    'userToken',
                    JSON.stringify({
                        accessToken: token.accessToken,
                        accessTokenExp: token.accessTokenExp,
                        refreshToken: token.refreshToken,
                        refreshTokenExp: token.refreshTokenExp,
                    })
                );
                if (saveIdChecked) {
                    localStorage.setItem(
                        'saveMemberAccount',
                        JSON.stringify({
                            memberAccount: user.memberAccount,
                        })
                    );
                } else {
                    localStorage.removeItem('saveMemberAccount');
                }
                const info = getMemberInfo();
                const lastVisitedPage = localStorage.getItem('lastVisitedPage');
                if (lastVisitedPage) {
                    naviate(lastVisitedPage);
                    return localStorage.removeItem('lastVisitedPage');
                }

                if (info) {
                    switch (info?.memberRole) {
                        case 'user':
                            if (response.data.workBooks) {
                                if (response.data.workBooks.length > 0) {
                                    response.data.workBooks.map(
                                        (item: { registerId: number; workBookName: string }) => {
                                            const params = new URLSearchParams();
                                            params.append('page', response.data.sessionNo);
                                            params.append('session', encryptData(response.data.registerSessionId));
                                            params.append('program', encryptData(item.registerId));
                                            switch (item.workBookName) {
                                                case '대면 CBT':
                                                    naviate(`/yd/patient/face/session?${params}`);
                                                    break;
                                                case '비대면 CBT':
                                                    naviate(`/yd/patient/nonface/session?${params}`);
                                                    break;
                                            }
                                        }
                                    );
                                }
                            } else {
                                naviate('/yd/patient/program/select');
                            }
                            break;
                        case 'employee':
                            // naviate('/yd/patient/face');
                            naviate('/yd/patient/program/select ');
                            break;
                        case 'therapist':
                        case 'manager':
                        case 'administrator':
                        case 'representative':
                            naviate('/yd/official');
                            break;
                    }
                }
            } else {
                loginErrorHandle(response.data.message, oneButtonOpenModal);
            }
        });
};

export const kakaoLogin = async () => {
    const rest_api_key = process.env.REACT_APP_KAKAO_REST_API_KEY;
    // const redirect_uri = 'https://192.168.0.8:3000/public/auth/kakaologin';
    const redirect_uri = 'https://yd-icbt.co.kr/public/auth/kakaologin';
    // const redirect_uri = 'http://223.130.147.159:8025/auth/kakaologin';
    const kakaoURL = `https://kauth.kakao.com/oauth/authorize?client_id=${rest_api_key}&redirect_uri=${redirect_uri}&response_type=code`;
    window.location.href = kakaoURL;
};

export const loginErrorHandle = (message: string, oneButtonOpenModal: Function) => {
    switch (message) {
        // 비밀번호 불일치
        case 'PASSWORD_NOT_MATCH':
            oneButtonOpenModal(() => {}, '', '아이디 또는 비밀번호가 잘못되었습니다.', '확인');
            break;
        // 계정이 존재하지 않을 때
        case 'THIS_ACCOUNT_IS_NOT_EXIST':
            oneButtonOpenModal(() => {}, '', '존재하는 계정 정보가 없습니다. 회원가입 후 다시 진행해 주세요.', '확인');
            break;
        // 카카오 소셜검증 실패
        case 'SOCIAL_VERIFICATION_FAILED':
            oneButtonOpenModal(() => {}, '', '존재하는 계정 정보가 없습니다. 회원가입 후 다시 진행해 주세요.', '확인');
            break;
        // 지점 소속이 아닐 경우
        case 'INTERNAL_SERVER_ERROR':
            oneButtonOpenModal(
                () => {},
                '',
                '해당 기관에 소속된 계정 정보가 없습니다. 확인 후 로그인을 다시 진행해 주세요.',
                '확인'
            );
            break;
        // 직원이 회원으로 로그인 할 경우
        case 'EMPLOYEE_LOGIN_PLEASE':
            oneButtonOpenModal(() => {}, '', '로그인하실 소속기관을 선택해 주세요.', '확인');
            break;
        // 회원이 직원으로 로그인 할 경우
        case 'USER_LOGIN_PLEASE':
            oneButtonOpenModal(
                () => {},
                '',
                '해당 기관에 소속된 계정 정보가 없습니다. 확인 후 로그인을 다시 진행해 주세요.',
                '확인'
            );
            break;
        // 카카오 토큰 없을 때
        case 'KAKAO_EMAIL_IS_NOT_EXIST':
            oneButtonOpenModal(() => {}, '', '아이디 또는 비밀번호가 잘못되었습니다.', '확인');
            break;
        // 승인 대기중
        case 'WAITING_FOR_APPROVAL':
            oneButtonOpenModal(
                () => {},
                '',
                '아직 회원님의 계정이 회원가입 진행 중입니다. 회원가입 승인까지 주말, 공휴일 포함 1~2일 정도 소요될 수 있습니다.',
                '확인'
            );
            break;

        // 거절 됨
        case 'REGISTER_REJECTED':
            oneButtonOpenModal(
                () => {},
                '',
                '회원가입 신청이 거절된 계정입니다. 다시 한번 확인 후 가입을 진행해 주세요. (*일반 회원일 경우, 상단 일반회원 버튼을 눌러 회원가입 진행)',
                '확인'
            );
            break;

        // 에러가 발생할 경우
        case 'LOGIN_FAILED_ERROR':
            oneButtonOpenModal(() => {}, '', '아이디 또는 비밀번호가 잘못되었습니다.', '확인');
            break;
    }
};

import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface LeftNavState {
    navVisible: boolean;
}

const initialState: LeftNavState = {
    navVisible: true,
};

export const leftNavSlice = createSlice({
    name: 'leftNavVisible',
    initialState: initialState,
    reducers: {
        navVisible: (state, action: PayloadAction<boolean>) => {
            state.navVisible = action.payload; // 새로운 이벤트 추가
        },
    },
});

export const { navVisible } = leftNavSlice.actions;
export default leftNavSlice.reducer;

import { MouseEventHandler } from 'react';
import '../css/Feedback.css';

export const WriteFeedbackTextarea = ({
    onChange,
    save,
    cancel,
    height,
    edit,
    value,
}: {
    onChange: Function;
    save: MouseEventHandler<HTMLButtonElement>;
    cancel: MouseEventHandler<HTMLButtonElement>;
    height: string;
    edit: boolean;
    value: string;
}) => {
    return (
        <div className={'feedback_container'}>
            <div
                className={'feedback_content_container'}
                style={{
                    height: height,
                }}
            >
                {edit ? (
                    <div className={`feedback_title text_18_NotoSansKR_Bold`}>{'치료자명'}님의 피드백</div>
                ) : (
                    <div className={`feedback_title text_18_NotoSansKR_Bold`}>피드백 작성</div>
                )}
                <textarea
                    className={`feedback_textarea ${edit ? 'edit' : ''} text_18_NotoSansKR_Bold`}
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                    readOnly={edit}
                />
            </div>
            {edit ? (
                <div className="button_container">
                    <button onClick={cancel} className={`cancel_button text_16_NotoSansKR_Bold`}>
                        삭제
                    </button>
                    <button onClick={save} className={`save_button text_16_NotoSansKR_Bold`}>
                        수정
                    </button>
                </div>
            ) : (
                <div className="button_container">
                    <button onClick={cancel} className={`cancel_button text_16_NotoSansKR_Bold`}>
                        취소
                    </button>
                    <button onClick={save} className={`save_button text_16_NotoSansKR_Bold`}>
                        저장
                    </button>
                </div>
            )}
        </div>
    );
};

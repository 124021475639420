import styled from 'styled-components';

import { ReactComponent as Icon_arrowbotom } from '../../../../assets/member/icon_arrowbottom.svg';
import { ReactComponent as Icon_check } from '../../../../assets/member/signup/icon_check.svg';
import { ReactComponent as Icon_checkbox } from '../../../../assets/member/icon_checkbox.svg';
import { ReactComponent as Icon_checkedbox } from '../../../../assets/member/icon_checkedbox.svg';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { useState } from 'react';

import styles from '../component/css/SignupInput.module.css';
import { EssentialMark } from '../../component/EssentialMark';
import axiosInstance from '../../../../utils/AxiosInstanceJava';
import { updateSelectedOrgan } from '../../../../features/signupReducer';

interface DropDownButtonProps {
    $isSelected: boolean;
    $length: number;
}

const DropDown = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    position: relative;
`;

const DropDownButton = styled.button<DropDownButtonProps>`
    display: flex;
    height: 4rem;
    width: 100%;
    box-sizing: border-box;
    padding: 0 1.25rem;
    align-items: center;
    justify-content: space-between;
    border-radius: 10px;
    color: ${(props) => (props.$length === 0 ? '#9d9fa2' : '#020202')};
    outline: ${(props) => (props.$isSelected ? '0.1875rem solid #3A5074' : '1px solid #e3e5ea')};
    background: #fff;
`;

const CustomUl = styled.ul`
    position: absolute;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    align-items: flex-start;
    width: 100%;
    padding: 1.25rem 0;
    gap: 1.5rem;
    top: 4.5rem;
    border-radius: 15px;
    border: 1px solid #e3e5ea;
    background: #fff;
    box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
`;

const CustomLi = styled.li`
    display: flex;
    align-items: center;
    width: 100%;
    text-align: left;
    padding: 0 1.5rem;
    justify-content: space-between;
`;

const CustomItemButton = styled.button`
    display: flex;
    alin-items: center;
    gap: 0.75rem;
`;

const EndLine = styled.div`
    display: flex;
    width: 100%;
    justify-content: flex-end;
    box-sizing: border-box;
    border-top: 1px solid #e3e5ea;
    gap: 0.75rem;
    padding: 0.75rem 1.5rem 0 0;
`;

const CancelButton = styled.button`
    width: 5.875rem;
    height: 2.5rem;
    color: #020202;
`;

const OkButton = styled.button`
    width: 5.875rem;
    height: 2.5rem;
    border-radius: 5px;
    background: #3a5074;
    color: #fff;
`;

interface OrganList {
    companyId: number;
    branchName: string;
}

export const SelectOrganizationSignup = () => {
    const dispath = useDispatch();
    const memberType = useSelector((state: RootState) => state.memberType);
    const signup = useSelector((state: RootState) => state.signup);
    const [organList, setOrganList] = useState<string[]>(['부산점', '창원점', '예정']);
    const [dropdownVisible, setDropdownVisible] = useState<boolean>(false);
    const [isSelected, setIsSelected] = useState<boolean>(false);

    const [selectedOrgan, setSelectedOrgan] = useState<OrganList[]>([]);
    const [originCheckAry, setOriginCheckAry] = useState<number[]>([]);
    const [checkAry, setCheckAry] = useState<OrganList[]>([]);

    if (memberType.type === 0) return null;

    const DrowDownItem = () => {
        const dupliValue = (item: OrganList) => {
            const index = checkAry.findIndex((existingItem) => existingItem.branchName === item.branchName);

            if (index !== -1) {
                // 중복이 있으면 해당 값을 제거
                return checkAry.filter((_, i) => i !== index);
            } else {
                // 중복이 없으면 추가
                return [...checkAry, item];
            }
        };
        const checkStorage = (item: OrganList) => {
            setCheckAry((prevValues) => dupliValue(item));
        };

        const handleToggleValue = () => {
            const commonValues = signup.organList.filter((organ) =>
                checkAry.some((check) => check.branchName === organ.branchName)
            );
            setSelectedOrgan(commonValues);
            dispath(updateSelectedOrgan(commonValues));
            setIsSelected(!isSelected);
            setDropdownVisible(!dropdownVisible);
        };

        const cancel = () => {
            setCheckAry([]);
            setIsSelected(!isSelected);
            setDropdownVisible(!dropdownVisible);
        };

        const CheckBoxRender = ({ item }: { item: string }) => {
            const findItem = checkAry.some((value) => value.branchName === item); // true

            return findItem ? (
                <Icon_checkedbox width={'1.5rem'} height={'1.5rem'} />
            ) : (
                <Icon_checkbox width={'1.5rem'} height={'1.5rem'} />
            );
        };

        return (
            <CustomUl>
                {signup.organList.map((item, index) => (
                    <CustomLi key={index.toString()}>
                        <CustomItemButton onClick={() => checkStorage(item)} className={`text_16_NotoSansKR_Medium`}>
                            <CheckBoxRender item={item.branchName} />
                            {item.branchName}
                        </CustomItemButton>
                    </CustomLi>
                ))}
                <EndLine>
                    <CancelButton onClick={cancel} className="text_16_NotoSansKR_Bold">
                        취소
                    </CancelButton>
                    <OkButton onClick={() => handleToggleValue()} className="text_16_NotoSansKR_Bold">
                        선택완료
                    </OkButton>
                </EndLine>
            </CustomUl>
        );
    };

    const visible = () => {
        setIsSelected(!isSelected);
        setDropdownVisible(!dropdownVisible);
        setCheckAry(selectedOrgan);
    };

    return (
        <div className={styles.input_content_container}>
            <span className="text_18_NotoSansKR_Bold" style={{ color: '#626466' }}>
                소속기관
                <EssentialMark />
                <span
                    className="text_18_NotoSansKR_Regular"
                    style={{
                        color: '#FF5833',
                    }}
                >
                    (복수선택 가능)
                </span>
            </span>
            <DropDown style={{}}>
                <DropDownButton
                    $length={selectedOrgan.length}
                    $isSelected={isSelected}
                    className="text_18_NotoSansKR_Regular"
                    onClick={visible}
                >
                    {selectedOrgan.length === 0
                        ? '소속기관 선택'
                        : selectedOrgan.map((item) => item.branchName).join(', ')}
                    <Icon_arrowbotom width={'1rem'} height={'1rem'} />
                </DropDownButton>
                {dropdownVisible && <DrowDownItem />}
            </DropDown>
        </div>
    );
};

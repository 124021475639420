import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface SessionMenu {
    menus: [
        {
            registerId: number;
            registerSessionId: number;
            sessionNo: number;
            registerAssignmentId: number;
            assignmentNo: number;
            assignAccess: boolean;
            assignFinish: boolean;
        }
    ];
}

const initialState: SessionMenu = {
    menus: [
        {
            registerId: 0,
            registerSessionId: 0,
            sessionNo: 0,
            registerAssignmentId: 0,
            assignmentNo: 0,
            assignAccess: false,
            assignFinish: false,
        },
    ],
};

export const sessionMenuReducer = createSlice({
    name: 'sessionMenu',
    initialState,
    reducers: {
        updateSessionMenu: (state, action: PayloadAction<SessionMenu>) => {
            state.menus = action.payload.menus;
        },
    },
});

export const { updateSessionMenu } = sessionMenuReducer.actions;
export default sessionMenuReducer.reducer;

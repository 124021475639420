import { useContext, useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import { ModalContext } from '../../../../../context/ModalContext';
import { ReactComponent as Icon_x } from '../../../../../assets/public/icon_x.svg';
import { ReactComponent as Icon_schedule_time } from '../../../../../assets/public/icon_schedule_time.svg';
import { ReactComponent as Icon_dash } from '../../../../../assets/public/icon_dash.svg';
import { ReactComponent as Icon_arrowbottom } from '../../../../../assets/member/icon_arrowbottom.svg';
import { ReactComponent as Icon_reserve_cancel } from '../../../../../assets/public/icon_reserve_cancel.svg';
import styles from './css/ScheduleModal.module.css';
import { EssentialMark } from '../../../../member/component/EssentialMark';
import { SquareCheckBox } from '../../../../../component/PublicComponent';
import { ScheduleDatePicker } from './ScheduleDatePicker';
import axiosInstance from '../../../../../utils/AxiosInstanceJava';
import { TimePicker } from './TimePicker';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import {
    resetScheduleData,
    updateMemo,
    updatePatientPickerVisible,
    updateProgramPickerVisible,
    updateTimePickerVisible,
} from '../../../../../features/scheduleModalReducer';
import { ProgramPicker } from './ProgramPicker';
import { PatientPicker } from './PatientPicker';
import { getMemberInfo } from '../../../../../utils/GetMemberInfo';

export const ScheduleModal = () => {
    const customModalStyles: ReactModal.Styles = {
        overlay: {
            position: 'fixed',
            top: '0',
            left: '0',
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: 'transparent',
            zIndex: 200,
        },
        content: {
            border: 'none',
            position: 'fixed',
            width: '100%',
            height: '97%',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            borderRadius: '10px',
            background: 'rgba(0, 0, 0, 0.6)',
        },
    };

    const { scheduleVisible, scheduleOpenModal, oneButtonOpenModal } = useContext(ModalContext);
    const loginMemberInfo = getMemberInfo();
    const dispatch = useDispatch();
    const {
        timePickerVisible,
        programPickerVisible,
        patientPickerVisible,
        originStartTime,
        originEndTime,
        memberInfo,
        workBookNames,
        therapistNames,
        registerSessionIds,
        sessionProgresses,
        reservationStatus,
        memo,
        reservationDate,
        calendarId,
        startTime,
        endTime,
    } = useSelector((state: RootState) => state.scheduleModal);

    useEffect(() => {
        if (calendarId) {
            getReverseInfo();
        }
    }, []);

    const getReverseInfo = async () => {
        await axiosInstance.post(`/api/therapist/reserve/${calendarId}/v1`).then((response) => {
            if (response.data.result) {
                dispatch(updateMemo(response.data.reserveInfo.memo));
            }
        });
    };

    const patientName = () => {
        if (memberInfo.memberName) {
            return memberInfo.memberName.replace(/\s*\([^)]*\)/, '').trim();
        } else {
            return '';
        }
    };

    const cancelButton = () => {
        dispatch(resetScheduleData());
        scheduleOpenModal(!scheduleVisible);
    };

    const headerTitle = () => {
        if (calendarId) {
            return '예약 상세정보';
        } else {
            return '일정 추가';
        }
    };

    // 예약 일정 추가
    const addReserve = async () => {
        const requestData = {
            memberId: memberInfo.memberId,
            therapistNames: therapistNames,
            registerSessionIds: registerSessionIds,
            workBookNames: workBookNames,
            sessionProgresses: sessionProgresses,
            memo: memo,
            reservationDate: reservationDate,
            startTime: startTime,
            endTime: endTime,
        };
        await axiosInstance.post(`/api/${loginMemberInfo?.memberRole}/reserve/v1`, requestData).then((response) => {
            if (response.data.result) {
                oneButtonOpenModal(
                    () => {
                        scheduleOpenModal(false);
                    },
                    '',
                    '예약 내역이 저장되었습니다.',
                    '확인'
                );
            }
        });
    };

    const openTimePicker = (type: string) => {
        dispatch(updateTimePickerVisible({ visible: !timePickerVisible, type: type }));
    };

    // 예약 일정 취소
    const cancelReverse = async (reservationStatus: number) => {
        await axiosInstance
            .put(`/api/${loginMemberInfo?.memberRole}/${calendarId}/${reservationStatus}/v1`)
            .then((response) => {});
    };

    const ReserveCancel = () => {
        if (reservationStatus === 1) {
            return (
                <div className={styles.reserve_cancel}>
                    <Icon_reserve_cancel />
                    <span
                        className="text_16_NotoSansKR_Medium"
                        style={{
                            color: '#fff',
                        }}
                    >
                        취소된 예약
                    </span>
                </div>
            );
        } else {
            return null;
        }
    };

    return (
        <ReactModal
            style={customModalStyles}
            isOpen={scheduleVisible}
            shouldCloseOnOverlayClick
            appElement={document.getElementById('root') as HTMLElement}
            onRequestClose={() => {
                console.log('hihihi');
            }}
        >
            <div className={styles.modal_content_container}>
                <div>
                    <div className={styles.title}>
                        <div className={`${styles.reserve_cancel_container} text_24_NotoSansKR_Bold`}>
                            {headerTitle()}
                            <ReserveCancel />
                        </div>
                        <button onClick={cancelButton}>
                            <Icon_x />
                        </button>
                    </div>

                    <div className={styles.content_container}>
                        <div className={styles.content}>
                            <div className={styles.input_container}>
                                <div
                                    className="text_16_NotoSansKR_Bold"
                                    style={{
                                        color: '#626466',
                                    }}
                                >
                                    예약일
                                    <EssentialMark />
                                </div>
                                <ScheduleDatePicker />
                            </div>
                            <div className={styles.input_container}>
                                <div
                                    className="text_16_NotoSansKR_Bold"
                                    style={{
                                        color: '#626466',
                                    }}
                                >
                                    내담자 이름
                                    <EssentialMark />
                                </div>
                                <div
                                    style={{
                                        position: 'relative',
                                    }}
                                >
                                    <button
                                        onClick={() => dispatch(updatePatientPickerVisible(!patientPickerVisible))}
                                        className={styles.drop_down_button}
                                    >
                                        <span className="text_16_NotoSansKR_Regular">{patientName()}</span>
                                        <Icon_arrowbottom />
                                    </button>
                                    {patientPickerVisible && <PatientPicker />}
                                </div>
                            </div>
                            <div className={styles.input_container}>
                                <div
                                    className="text_16_NotoSansKR_Bold"
                                    style={{
                                        color: '#626466',
                                    }}
                                >
                                    프로그램
                                    <span
                                        className="text_16_NotoSansKR_Medium"
                                        style={{
                                            color: '#FF5833',
                                        }}
                                    >
                                        (복수선택 가능)
                                        <EssentialMark />
                                    </span>
                                </div>
                                <div style={{ position: 'relative' }}>
                                    <button
                                        onClick={() => dispatch(updateProgramPickerVisible(!programPickerVisible))}
                                        className={styles.drop_down_button}
                                    >
                                        <span className="text_16_NotoSansKR_Regular">{workBookNames?.join(', ')}</span>
                                        <Icon_arrowbottom />
                                    </button>
                                    {programPickerVisible && <ProgramPicker />}
                                </div>
                            </div>
                            <div className={styles.input_container}>
                                <div
                                    className="text_16_NotoSansKR_Bold"
                                    style={{
                                        color: '#626466',
                                    }}
                                >
                                    담당 상담사
                                    <EssentialMark />
                                </div>
                                <input
                                    className={`${styles.input_readonly} text_16_NotoSansKR_Regular`}
                                    readOnly
                                    defaultValue={therapistNames.join(',')}
                                />
                            </div>
                        </div>
                        <div className={styles.content}>
                            <div className={styles.input_container}>
                                <div
                                    className="text_16_NotoSansKR_Bold"
                                    style={{
                                        color: '#626466',
                                    }}
                                >
                                    예약 시간
                                    <EssentialMark />
                                </div>
                                <div className={styles.schedule_time_container}>
                                    <Icon_schedule_time className={styles.icon} />
                                    <div className={styles.button_container}>
                                        <button
                                            onClick={() => openTimePicker('left')}
                                            className={`${styles.time_button} text_16_NotoSansKR_Regular`}
                                        >
                                            {originStartTime}
                                        </button>
                                        <Icon_dash />
                                        <button
                                            onClick={() => openTimePicker('right')}
                                            className={`${styles.time_button} text_16_NotoSansKR_Regular`}
                                        >
                                            {originEndTime}
                                        </button>
                                    </div>
                                    {timePickerVisible && <TimePicker />}
                                </div>
                            </div>
                            <div className={styles.input_container}>
                                <div
                                    className="text_16_NotoSansKR_Bold"
                                    style={{
                                        color: '#626466',
                                    }}
                                >
                                    차트번호
                                    <EssentialMark />
                                </div>
                                <input
                                    className={`${styles.input_readonly} text_16_NotoSansKR_Regular`}
                                    readOnly
                                    defaultValue={memberInfo.memberId !== 0 ? memberInfo.memberId : ''}
                                />
                            </div>
                            <div className={styles.input_container}>
                                <div
                                    className="text_16_NotoSansKR_Bold"
                                    style={{
                                        color: '#626466',
                                    }}
                                >
                                    진행 회기
                                    <EssentialMark />
                                </div>
                                <input
                                    className={`${styles.input_readonly} text_16_NotoSansKR_Regular`}
                                    readOnly
                                    defaultValue={sessionProgresses
                                        .map((item, index) => `${workBookNames[index]}: ${item}회`)
                                        .join(', ')}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={styles.memo_container}>
                        <div
                            className="text_16_NotoSansKR_Bold"
                            style={{
                                color: '#626466',
                            }}
                        >
                            메모
                        </div>
                        <textarea
                            className={`${styles.memo} text_16_NotoSansKR_Regular`}
                            onChange={(e) => dispatch(updateMemo(e.target.value))}
                            value={memo}
                        />
                    </div>
                    <div className={styles.no_show}>
                        <span
                            className="text_16_NotoSansKR_Bold"
                            style={{
                                color: '#626466',
                            }}
                        >
                            노쇼여부
                        </span>
                        <div className={styles.content}>
                            <SquareCheckBox bool={reservationStatus === 2 ? true : false} size="1.5rem" />
                            <span
                                className="text_16_NotoSansKR_Bold"
                                style={{
                                    color: '#626466',
                                }}
                            >
                                불참
                            </span>
                        </div>
                    </div>
                </div>
                <div className={styles.button_container}>
                    <button
                        onClick={() => cancelReverse(2)}
                        className={`${styles.schedule_cancel_button} text_18_NotoSansKR_Bold`}
                    >
                        예약 취소
                    </button>
                    <div>
                        <button onClick={cancelButton} className={`${styles.cancel_button} text_18_NotoSansKR_Bold`}>
                            취소
                        </button>
                        <button onClick={addReserve} className={`${styles.save_button} text_18_NotoSansKR_Bold`}>
                            저장
                        </button>
                    </div>
                </div>
            </div>
        </ReactModal>
    );
};

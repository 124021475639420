import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface DisallowDuplicateSelection {
    patient: string;
    feedback: string;
    visible: boolean;
    feedbackCheck: boolean;
}

export interface Fields {
    brain: DisallowDuplicateSelection[]; // (sessionNo, 1,10) 요즘 나의 주된 생각과 감정
    desiredSymptomChange: DisallowDuplicateSelection[]; // (sessionNo, 1) 인지행동치료 후에 가장 변화하고 싶은 증상과 그 이유
    psychologicalBarriers: DisallowDuplicateSelection[]; // (sessionNo, 1) 치료에 방해되는 심리적 장애물
    myStrengths: DisallowDuplicateSelection[]; // (sessionNo, 1) 나의 장점
    therapyGoals: DisallowDuplicateSelection[]; // (sessionNo, 1) 치료를 위한 단계별 목표
    physicalSymptoms: DisallowDuplicateSelection[]; // (sessionNo, 2) 내가 불편해하는 신체증상
    emotionalReactions: DisallowDuplicateSelection[]; // (sessionNo, 2) 내가 경험하는 감정의 반응
    quiz1: DisallowDuplicateSelection[]; // (sessionNo, 4) quiz
    quiz2: DisallowDuplicateSelection[]; // (sessionNo, 4) quiz
    quiz3: DisallowDuplicateSelection[]; // (sessionNo, 4) quiz
    quiz4: DisallowDuplicateSelection[]; // (sessionNo, 4) quiz
    socraticAutoThinking: DisallowDuplicateSelection[]; // (sessionNo, 5) 소크라테스 문답법 / 자동사고
    evidenceCorrectThinking: DisallowDuplicateSelection[]; // (sessionNo, 5) 소크라테스 문답법 / 내 생각이 맞다는 증거
    evidenceIncorrectThinking: DisallowDuplicateSelection[]; // (sessionNo, 5) 소크라테스 문답법 / 내 생각이 틀리다는 증거
    authmaticThinkingGraph: DisallowDuplicateSelection[]; // (sessionNo, 6) 소크라테스 문답법 / 나의 자동사고에 대한 여러가지 가능성
    worstOutcome: DisallowDuplicateSelection[]; // (sessionNo, 7) 소크라테스 문답법 / 최악의 결과
    bestOutcome: DisallowDuplicateSelection[]; // (sessionNo, 7) 소크라테스 문답법 / 최상의 결과
    realisticOutcome: DisallowDuplicateSelection[]; // (sessionNo, 7) 소크라테스 문답법 / 현실적 결과
    advantagesOfTrustingAutoThought: DisallowDuplicateSelection[]; // (sessionNo, 8) 이 자동사고를 믿을 때 얻을 수 있는 장점
    letter: DisallowDuplicateSelection[]; // (sessionNo, 8) 내 자동사고가 '친구의 고민'이라고 생각하고 조언해 보자.
    rationalThinkingAutoCheck: DisallowDuplicateSelection[]; // (sessionNo, 8) 자동사고 점검 / 자동사고에 대한 합리적 사고
    emotionsAfterChange: DisallowDuplicateSelection[]; // (sessionNo, 8) 자동사고 점검 / 감정
    scoreAfterChange: DisallowDuplicateSelection[]; // (sessionNo, 8) 자동사고 점검 / 감정 점수
    lessonsLearned: DisallowDuplicateSelection[]; // (sessionNo, 8) 배운점/느낀점
    physicalResponseOfEmotion: DisallowDuplicateSelection[]; // (sessionNo, 10) 감정의 신체적 반응
    behavioralResponseOfEmotion: DisallowDuplicateSelection[]; // (sessionNo, 10) 감정의 행동적 반응
    cognitiveResponseOfEmotion: DisallowDuplicateSelection[]; // (sessionNo, 10) 감정의 인지적 반응
    top3CognitiveErrors: DisallowDuplicateSelection[]; // (sessionNo, 10) 상위 3개의 선택된 인지오류
    beforeBrain: DisallowDuplicateSelection[]; // (sessionNo, 10) 이전 생각과 감정
    situation: DisallowDuplicateSelection[]; // (sessionNo, 2,3,4,5,6,7,8,9) 자동사고 기록지 / 상황
    emotionIcon: DisallowDuplicateSelection[]; // (sessionNo, 2,3,4,5,6,7,8,9) 자동사고 기록지 / 감정
    emotionScore: DisallowDuplicateSelection[]; // (sessionNo, 2,3,4,5,6,7,8,9) 자동사고 기록지 / 감정 점수
    thought: DisallowDuplicateSelection[]; // (sessionNo, 3,4,5,6,7,8,9) 어떤 생각이 떠올랐나요?
    worstCaseScenario: DisallowDuplicateSelection[]; // (sessionNo, 3,4,5,6,7,8,9) 최악의 경우, 어떤 일이 일어날 것이라는 생각이 들었나요?
    image: DisallowDuplicateSelection[]; // (sessionNo, 3,4,5,6,7,8,9) 그때 떠오른 이미지는
    selfPerception: DisallowDuplicateSelection[]; // (sessionNo, 3,4,5,6,7,8,9) 내가 어떤 사람으로 보여질까?
    situationThoughts: DisallowDuplicateSelection[]; // (sessionNo, 3,4,5,6,7,8,9) 상황에 대한 생각 + 생각으로 인한 결과
    trustInThoughts: DisallowDuplicateSelection[]; // (sessionNo, 3,4,5,6,7,8,9) 생각을 믿는 정도
    cognitiveError: DisallowDuplicateSelection[]; // (sessionNo, 4,5,6,7,8,9,10) 인지오류
    altExpAutoThought: DisallowDuplicateSelection[]; // (sessionNo, 7,8) 소크라테스 문답법 / 자동사고의 대안적 설명
}

interface Session {
    registerSessionId: number;
    fieldIds: any;
    fields: Fields;
    finished: boolean;
}

const initialState: Session = {
    registerSessionId: 0,
    fieldIds: {},
    fields: {
        brain: [
            { patient: '', feedback: '', visible: false, feedbackCheck: false },
            { patient: '', feedback: '', visible: false, feedbackCheck: false },
            { patient: '', feedback: '', visible: false, feedbackCheck: false },
            { patient: '', feedback: '', visible: false, feedbackCheck: false },
            { patient: '', feedback: '', visible: false, feedbackCheck: false },
            { patient: '', feedback: '', visible: false, feedbackCheck: false },
            { patient: '', feedback: '', visible: false, feedbackCheck: false },
        ],
        // (sessionNo, 1,10) 요즘 나의 주된 생각과 감정
        desiredSymptomChange: [{ patient: '', feedback: '', visible: false, feedbackCheck: false }], // (sessionNo, 1) 인지행동치료 후에 가장 변화하고 싶은 증상과 그 이유
        psychologicalBarriers: [
            { patient: '', feedback: '', visible: false, feedbackCheck: false },
            { patient: '', feedback: '', visible: false, feedbackCheck: false },
            { patient: '', feedback: '', visible: false, feedbackCheck: false },
        ], // (sessionNo, 1) 치료에 방해되는 심리적 장애물
        myStrengths: [{ patient: '', feedback: '', visible: false, feedbackCheck: false }], // (sessionNo, 1) 나의 장점
        therapyGoals: [
            { patient: '', feedback: '', visible: false, feedbackCheck: false },
            { patient: '', feedback: '', visible: false, feedbackCheck: false },
            { patient: '', feedback: '', visible: false, feedbackCheck: false },
        ], // (sessionNo, 1) 치료를 위한 단계별 목표
        physicalSymptoms: [
            { patient: '', feedback: '', visible: false, feedbackCheck: false },
            { patient: '', feedback: '', visible: false, feedbackCheck: false },
            { patient: '', feedback: '', visible: false, feedbackCheck: false },
            { patient: '', feedback: '', visible: false, feedbackCheck: false },
            { patient: '', feedback: '', visible: false, feedbackCheck: false },
            { patient: '', feedback: '', visible: false, feedbackCheck: false },
            { patient: '', feedback: '', visible: false, feedbackCheck: false },
            { patient: '', feedback: '', visible: false, feedbackCheck: false },
            { patient: '', feedback: '', visible: false, feedbackCheck: false },
        ], // (sessionNo, 2) 내가 불편해하는 신체증상
        emotionalReactions: [
            { patient: '', feedback: '', visible: false, feedbackCheck: false },
            { patient: '', feedback: '', visible: false, feedbackCheck: false },
            { patient: '', feedback: '', visible: false, feedbackCheck: false },
        ], // (sessionNo, 2) 내가 경험하는 감정의 반응
        quiz1: [
            { patient: '', feedback: '', visible: false, feedbackCheck: false },
            { patient: '', feedback: '', visible: false, feedbackCheck: false },
            { patient: '', feedback: '', visible: false, feedbackCheck: false },
            { patient: '', feedback: '', visible: false, feedbackCheck: false },
        ], // (sessionNo, 4) quiz
        quiz2: [
            { patient: '', feedback: '', visible: false, feedbackCheck: false },
            { patient: '', feedback: '', visible: false, feedbackCheck: false },
            { patient: '', feedback: '', visible: false, feedbackCheck: false },
            { patient: '', feedback: '', visible: false, feedbackCheck: false },
        ], // (sessionNo, 4) quiz
        quiz3: [
            { patient: '', feedback: '', visible: false, feedbackCheck: false },
            { patient: '', feedback: '', visible: false, feedbackCheck: false },
            { patient: '', feedback: '', visible: false, feedbackCheck: false },
            { patient: '', feedback: '', visible: false, feedbackCheck: false },
        ], // (sessionNo, 4) quiz
        quiz4: [
            { patient: '', feedback: '', visible: false, feedbackCheck: false },
            { patient: '', feedback: '', visible: false, feedbackCheck: false },
            { patient: '', feedback: '', visible: false, feedbackCheck: false },
            { patient: '', feedback: '', visible: false, feedbackCheck: false },
        ], // (sessionNo, 4) quiz
        socraticAutoThinking: [{ patient: '', feedback: '', visible: false, feedbackCheck: false }], // (sessionNo, 5) 소크라테스 문답법 / 자동사고
        evidenceCorrectThinking: [{ patient: '', feedback: '', visible: false, feedbackCheck: false }], // (sessionNo, 5) 소크라테스 문답법 / 내 생각이 맞다는 증거
        evidenceIncorrectThinking: [{ patient: '', feedback: '', visible: false, feedbackCheck: false }], // (sessionNo, 5) 소크라테스 문답법 / 내 생각이 틀리다는 증거
        authmaticThinkingGraph: [
            { patient: '', feedback: '', visible: false, feedbackCheck: false },
            { patient: '', feedback: '', visible: false, feedbackCheck: false },
            { patient: '', feedback: '', visible: false, feedbackCheck: false },
            { patient: '', feedback: '', visible: false, feedbackCheck: false },
            { patient: '', feedback: '', visible: false, feedbackCheck: false },
            { patient: '', feedback: '', visible: false, feedbackCheck: false },
            { patient: '', feedback: '', visible: false, feedbackCheck: false },
        ], // (sessionNo, 6) 소크라테스 문답법 / 나의 자동사고에 대한 여러가지 가능성
        worstOutcome: [{ patient: '', feedback: '', visible: false, feedbackCheck: false }], // (sessionNo, 7) 소크라테스 문답법 / 최악의 결과
        bestOutcome: [{ patient: '', feedback: '', visible: false, feedbackCheck: false }], // (sessionNo, 7) 소크라테스 문답법 / 최상의 결과
        realisticOutcome: [{ patient: '', feedback: '', visible: false, feedbackCheck: false }], // (sessionNo, 7) 소크라테스 문답법 / 현실적 결과
        advantagesOfTrustingAutoThought: [{ patient: '', feedback: '', visible: false, feedbackCheck: false }], // (sessionNo, 8) 이 자동사고를 믿을 때 얻을 수 있는 장점
        letter: [{ patient: '', feedback: '', visible: false, feedbackCheck: false }], // (sessionNo, 8) 내 자동사고가 '친구의 고민'이라고 생각하고 조언해 보자.
        rationalThinkingAutoCheck: [{ patient: '', feedback: '', visible: false, feedbackCheck: false }], // (sessionNo, 8) 자동사고 점검 / 자동사고에 대한 합리적 사고
        emotionsAfterChange: [{ patient: '', feedback: '', visible: false, feedbackCheck: false }], // (sessionNo, 8) 자동사고 점검 / 감정
        scoreAfterChange: [{ patient: '', feedback: '', visible: false, feedbackCheck: false }], // (sessionNo, 8) 자동사고 점검 / 감정 점수
        lessonsLearned: [{ patient: '', feedback: '', visible: false, feedbackCheck: false }], // (sessionNo, 8) 배운점/느낀점
        physicalResponseOfEmotion: [{ patient: '', feedback: '', visible: false, feedbackCheck: false }], // (sessionNo, 10) 감정의 신체적 반응
        behavioralResponseOfEmotion: [{ patient: '', feedback: '', visible: false, feedbackCheck: false }], // (sessionNo, 10) 감정의 행동적 반응
        cognitiveResponseOfEmotion: [{ patient: '', feedback: '', visible: false, feedbackCheck: false }], // (sessionNo, 10) 감정의 인지적 반응
        top3CognitiveErrors: [
            { patient: '', feedback: '', visible: false, feedbackCheck: false },
            { patient: '', feedback: '', visible: false, feedbackCheck: false },
            { patient: '', feedback: '', visible: false, feedbackCheck: false },
        ], // (sessionNo, 10) 상위 3개의 선택된 인지오류
        beforeBrain: [
            { patient: '', feedback: '', visible: false, feedbackCheck: false },
            { patient: '', feedback: '', visible: false, feedbackCheck: false },
            { patient: '', feedback: '', visible: false, feedbackCheck: false },
            { patient: '', feedback: '', visible: false, feedbackCheck: false },
            { patient: '', feedback: '', visible: false, feedbackCheck: false },
            { patient: '', feedback: '', visible: false, feedbackCheck: false },
            { patient: '', feedback: '', visible: false, feedbackCheck: false },
        ], // (sessionNo, 10) 이전 생각과 감정
        situation: [
            { patient: '', feedback: '', visible: false, feedbackCheck: false },
            { patient: '', feedback: '', visible: false, feedbackCheck: false },
            { patient: '', feedback: '', visible: false, feedbackCheck: false },
        ], // (sessionNo, 2,3,4,5,6,7,8,9) 자동사고 기록지 / 상황
        emotionIcon: [{ patient: '', feedback: '', visible: false, feedbackCheck: false }], // (sessionNo, 2,3,4,5,6,7,8,9) 자동사고 기록지 / 감정
        emotionScore: [{ patient: '', feedback: '', visible: false, feedbackCheck: false }], // (sessionNo, 2,3,4,5,6,7,8,9) 자동사고 기록지 / 감정 점수
        thought: [{ patient: '', feedback: '', visible: false, feedbackCheck: false }], // (sessionNo, 3,4,5,6,7,8,9) 어떤 생각이 떠올랐나요?
        worstCaseScenario: [{ patient: '', feedback: '', visible: false, feedbackCheck: false }], // (sessionNo, 3,4,5,6,7,8,9) 최악의 경우, 어떤 일이 일어날 것이라는 생각이 들었나요?
        image: [{ patient: '', feedback: '', visible: false, feedbackCheck: false }], // (sessionNo, 3,4,5,6,7,8,9) 그때 떠오른 이미지는
        selfPerception: [{ patient: '', feedback: '', visible: false, feedbackCheck: false }], // (sessionNo, 3,4,5,6,7,8,9) 내가 어떤 사람으로 보여질까?
        situationThoughts: [{ patient: '', feedback: '', visible: false, feedbackCheck: false }], // (sessionNo, 3,4,5,6,7,8,9) 상황에 대한 생각 + 생각으로 인한 결과
        trustInThoughts: [{ patient: '', feedback: '', visible: false, feedbackCheck: false }], // (sessionNo, 3,4,5,6,7,8,9) 생각을 빋는 정도
        cognitiveError: [{ patient: '', feedback: '', visible: false, feedbackCheck: false }], // (sessionNo, 4,5,6,7,8,9,10) 인지오류
        altExpAutoThought: [{ patient: '', feedback: '', visible: false, feedbackCheck: false }], // (sessionNo, 7,8) 소크라테스 문답법 / 자동사고의 대안적 설명
    },
    finished: false,
};

export const sessionReducer = createSlice({
    name: 'session',
    initialState,
    reducers: {
        // (sessionNo, 1,10) 요즘 나의 주된 생각과 감정
        updateBrain: (
            state,
            action: PayloadAction<{
                index: number;
                patient: string;
                feedback: string;
                visible: boolean;
                feedbackCheck: boolean;
            }>
        ) => {
            const payload = action.payload;
            const index = payload.index;
            delete (payload as any).index;
            state.fields.brain[index] = payload;
        },

        // (sessionNo, 1) 인지행동치료 후에 가장 변화하고 싶은 증상과 그 이유
        updateDesiredSymptomChange: (
            state,
            action: PayloadAction<{ patient: string; feedback: string; visible: boolean; feedbackCheck: boolean }>
        ) => {
            const payload = action.payload;
            state.fields.desiredSymptomChange = [payload];
        },

        // (sessionNo, 1) 치료에 방해되는 심리적 장애물
        updatePsychologicalBarriers: (
            state,
            action: PayloadAction<{
                index: number;
                patient: string;
                feedback: string;
                visible: boolean;
                feedbackCheck: boolean;
            }>
        ) => {
            const payload = action.payload;
            const index = payload.index;
            delete (payload as any).index;
            state.fields.psychologicalBarriers[index] = payload;
        },

        // (sessionNo, 1) 나의 장점
        updateMyStrengths: (
            state,
            action: PayloadAction<{ patient: string; feedback: string; visible: boolean; feedbackCheck: boolean }>
        ) => {
            const payload = action.payload;
            state.fields.myStrengths = [payload];
        },

        // (sessionNo, 1) 치료를 위한 단계별 목표
        updateTherapyGoals: (
            state,
            action: PayloadAction<{
                index: number;
                patient: string;
                feedback: string;
                visible: boolean;
                feedbackCheck: boolean;
            }>
        ) => {
            const payload = action.payload;
            const index = payload.index;
            delete (payload as any).index;
            state.fields.therapyGoals[index] = payload;
        },

        // (sessionNo, 2) 내가 불편해하는 신체증상
        updatePhysicalSymptoms: (
            state,
            action: PayloadAction<{
                index: number;
                patient: string;
                feedback: string;
                visible: boolean;
                feedbackCheck: boolean;
            }>
        ) => {
            const payload = action.payload;
            const index = payload.index;
            delete (payload as any).index;
            state.fields.physicalSymptoms[index] = payload;
        },

        // (sessionNo, 2) 내가 경험하는 감정의 반응
        updateEmotionalReactions: (
            state,
            action: PayloadAction<{
                index: number;
                patient: string;
                feedback: string;
                visible: boolean;
                feedbackCheck: boolean;
            }>
        ) => {
            const payload = action.payload;
            const index = payload.index;
            delete (payload as any).index;
            state.fields.emotionalReactions[index] = payload;
        },

        // (sessionNo, 4) quiz
        updateQuiz: (
            state,
            action: PayloadAction<
                { index: number; patient: string; feedback: string; visible: boolean; feedbackCheck: boolean }[]
            >
        ) => {
            const payload = action.payload;

            payload.map((item, index) => {
                switch (item.index) {
                    case 0:
                        delete (payload[index] as any).index;
                        state.fields.quiz1 = payload;
                        break;
                    case 1:
                        delete (payload[index] as any).index;
                        state.fields.quiz2 = payload;
                        break;

                    case 2:
                        delete (payload[index] as any).index;
                        state.fields.quiz3 = payload;
                        break;

                    case 3:
                        delete (payload[index] as any).index;
                        state.fields.quiz4 = payload;
                        break;
                }
            });
        },
        // (sessionNo, 5) 소크라테스 문답법 / 자동사고
        uppdateSocraticAutoThinking: (
            state,
            action: PayloadAction<{ patient: string; feedback: string; visible: boolean; feedbackCheck: boolean }>
        ) => {
            const payload = action.payload;
            state.fields.socraticAutoThinking = [payload];
        },
        // (sessionNo, 5) 소크라테스 문답법 / 내 생각이 맞다는 증거
        updateEvidenceCorrectThinking: (
            state,
            action: PayloadAction<{ patient: string; feedback: string; visible: boolean; feedbackCheck: boolean }>
        ) => {
            const payload = action.payload;
            state.fields.evidenceCorrectThinking = [payload];
        },
        // (sessionNo, 5) 소크라테스 문답법 / 내 생각이 틀리다는 증거
        updateEvidenceIncorrectThinking: (
            state,
            action: PayloadAction<{ patient: string; feedback: string; visible: boolean; feedbackCheck: boolean }>
        ) => {
            const payload = action.payload;
            state.fields.evidenceIncorrectThinking = [payload];
        },
        // (sessionNo, 6) 소크라테스 문답법 / 나의 자동사고에 대한 여러가지 가능성
        updateAuthmaticThinkingGraph: (
            state,
            action: PayloadAction<{
                index: number;
                patient: string;
                feedback: string;
                visible: boolean;
                feedbackCheck: boolean;
            }>
        ) => {
            const payload = action.payload;
            const index = payload.index;
            delete (payload as any).index;
            state.fields.authmaticThinkingGraph[index] = payload;
        },
        // (sessionNo, 7) 소크라테스 문답법 / 최악의 결과
        updateWorstOutcome: (
            state,
            action: PayloadAction<{ patient: string; feedback: string; visible: boolean; feedbackCheck: boolean }>
        ) => {
            const payload = action.payload;
            state.fields.worstOutcome = [payload];
        },
        // (sessionNo, 7) 소크라테스 문답법 / 최상의 결과
        updateBestOutcome: (
            state,
            action: PayloadAction<{ patient: string; feedback: string; visible: boolean; feedbackCheck: boolean }>
        ) => {
            const payload = action.payload;
            state.fields.bestOutcome = [payload];
        },
        // (sessionNo, 7) 소크라테스 문답법 / 현실적 결과
        updateRealisticOutcome: (
            state,
            action: PayloadAction<{ patient: string; feedback: string; visible: boolean; feedbackCheck: boolean }>
        ) => {
            const payload = action.payload;
            state.fields.realisticOutcome = [payload];
        },
        // (sessionNo, 8) 이 자동사고를 믿을 때 얻을 수 있는 장점
        updateAdvantagesOfTrustingAutoThought: (
            state,
            action: PayloadAction<{ patient: string; feedback: string; visible: boolean; feedbackCheck: boolean }>
        ) => {
            const payload = action.payload;
            state.fields.advantagesOfTrustingAutoThought = [payload];
        },
        // (sessionNo, 8) 내 자동사고가 '친구의 고민'이라고 생각하고 조언해 보자.
        updateLetter: (
            state,
            action: PayloadAction<{ patient: string; feedback: string; visible: boolean; feedbackCheck: boolean }>
        ) => {
            const payload = action.payload;
            state.fields.letter = [payload];
        },

        // (sessionNo, 8) 자동사고 점검 / 자동사고에 대한 합리적 사고
        updateRationalThinkingAutoCheck: (
            state,
            action: PayloadAction<{ patient: string; feedback: string; visible: boolean; feedbackCheck: boolean }>
        ) => {
            const payload = action.payload;
            state.fields.rationalThinkingAutoCheck = [payload];
        },

        // (sessionNo, 8) 자동사고 점검 / 감정
        updateEmotionsAfterChange: (
            state,
            action: PayloadAction<{ patient: string; feedback: string; visible: boolean; feedbackCheck: boolean }>
        ) => {
            const payload = action.payload;
            state.fields.emotionsAfterChange = [payload];
        },

        // (sessionNo, 8) 자동사고 점검 / 감정 점수
        updateScoreAfterChange: (
            state,
            action: PayloadAction<{ patient: string; feedback: string; visible: boolean; feedbackCheck: boolean }>
        ) => {
            const payload = action.payload;
            state.fields.scoreAfterChange = [payload];
        },

        // (sessionNo, 8) 배운점/느낀점
        updateLessonsLearned: (
            state,
            action: PayloadAction<{ patient: string; feedback: string; visible: boolean; feedbackCheck: boolean }>
        ) => {
            const payload = action.payload;
            state.fields.lessonsLearned = [payload];
        },

        // (sessionNo, 10) 감정의 신체적 반응
        updatePhysicalResponseOfEmotion: (
            state,
            action: PayloadAction<{ patient: string; feedback: string; visible: boolean; feedbackCheck: boolean }>
        ) => {
            const payload = action.payload;
            state.fields.physicalResponseOfEmotion = [payload];
        },

        // (sessionNo, 10) 감정의 행동적 반응
        updateBehavioralResponseOfEmotion: (
            state,
            action: PayloadAction<{ patient: string; feedback: string; visible: boolean; feedbackCheck: boolean }>
        ) => {
            const payload = action.payload;
            state.fields.behavioralResponseOfEmotion = [payload];
        },

        // (sessionNo, 10) 감정의 인지적 반응
        updateCognitiveResponseOfEmotion: (
            state,
            action: PayloadAction<{ patient: string; feedback: string; visible: boolean; feedbackCheck: boolean }>
        ) => {
            const payload = action.payload;
            state.fields.cognitiveResponseOfEmotion = [payload];
        },

        // (sessionNo, 10) 상위 3개의 선택된 인지오류
        updateTop3CognitiveErrors: (
            state,
            action: PayloadAction<{
                index: number;
                patient: string;
                feedback: string;
                visible: boolean;
                feedbackCheck: boolean;
            }>
        ) => {
            const payload = action.payload;
            const index = payload.index;
            delete (payload as any).index;
            state.fields.top3CognitiveErrors[index] = payload;
        },

        // (sessionNo, 10) 이전 생각과 감정
        updateBeforeBrain: (
            state,
            action: PayloadAction<{
                index: number;
                patient: string;
                feedback: string;
                visible: boolean;
                feedbackCheck: boolean;
            }>
        ) => {
            const payload = action.payload;
            const index = payload.index;
            delete (payload as any).index;
            state.fields.beforeBrain[index] = payload;
        },

        // (sessionNo, 2,3,4,5,6,7,8,9) 자동사고 기록지 / 상황
        updateSituation: (
            state,
            action: PayloadAction<{
                index: number;
                patient: string;
                feedback: string;
                visible: boolean;
                feedbackCheck: boolean;
            }>
        ) => {
            const payload = action.payload;
            const index = payload.index;
            delete (payload as any).index;
            state.fields.situation[index] = payload;
        },
        // (sessionNo, 2,3,4,5,6,7,8,9) 자동사고 기록지 / 감정
        updateEmotionIcon: (
            state,
            action: PayloadAction<{ patient: string; feedback: string; visible: boolean; feedbackCheck: boolean }>
        ) => {
            const payload = action.payload;
            state.fields.emotionIcon[0] = payload;
        },
        // (sessionNo, 2,3,4,5,6,7,8,9) 자동사고 기록지 / 감정 점수
        updateEmotionScore: (
            state,
            action: PayloadAction<{ patient: string; feedback: string; visible: boolean; feedbackCheck: boolean }>
        ) => {
            const payload = action.payload;
            state.fields.emotionScore[0] = payload;
        },
        // (sessionNo, 3,4,5,6,7,8,9) 어떤 생각이 떠올랐나요?
        updateThought: (
            state,
            action: PayloadAction<{ patient: string; feedback: string; visible: boolean; feedbackCheck: boolean }>
        ) => {
            const payload = action.payload;
            state.fields.thought[0] = payload;
        },

        // (sessionNo, 3,4,5,6,7,8,9) 최악의 경우, 어떤 일이 일어날 것이라는 생각이 들었나요?
        updateWorstCaseScenario: (
            state,
            action: PayloadAction<{ patient: string; feedback: string; visible: boolean; feedbackCheck: boolean }>
        ) => {
            const payload = action.payload;
            state.fields.worstCaseScenario[0] = payload;
        },

        // (sessionNo, 3,4,5,6,7,8,9) 그때 떠오른 이미지는
        updateImage: (
            state,
            action: PayloadAction<{ patient: string; feedback: string; visible: boolean; feedbackCheck: boolean }>
        ) => {
            const payload = action.payload;
            state.fields.image[0] = payload;
        },

        // (sessionNo, 3,4,5,6,7,8,9) 내가 어떤 사람으로 보여질까?
        updateSelfPerception: (
            state,
            action: PayloadAction<{ patient: string; feedback: string; visible: boolean; feedbackCheck: boolean }>
        ) => {
            const payload = action.payload;
            state.fields.selfPerception[0] = payload;
        },

        // (sessionNo, 3,4,5,6,7,8,9) 상황에 대한 생각 + 생각으로 인한 결과
        updateSituationThoughts: (
            state,
            action: PayloadAction<{ patient: string; feedback: string; visible: boolean; feedbackCheck: boolean }>
        ) => {
            const payload = action.payload;
            state.fields.situationThoughts[0] = payload;
        },

        // (sessionNo, 3,4,5,6,7,8,9) 생각을 믿는 정도
        updateTrustInThoughts: (
            state,
            action: PayloadAction<{ patient: string; feedback: string; visible: boolean; feedbackCheck: boolean }>
        ) => {
            const payload = action.payload;
            state.fields.trustInThoughts[0] = payload;
        },
        // (sessionNo, 4,5,6,7,8,9,10) 인지오류
        updateCognitiveError: (
            state,
            action: PayloadAction<
                {
                    index: number;
                    patient: string;
                    feedback: string;
                    visible: boolean;
                    feedbackCheck: boolean;
                }[]
            >
        ) => {
            const payload = action.payload;
            state.fields.cognitiveError = payload;
        },

        // (sessionNo, 7,8) 소크라테스 문답법 / 자동사고의 대안적 설명
        updateAltExpAutoThought: (
            state,
            action: PayloadAction<{ patient: string; feedback: string; visible: boolean; feedbackCheck: boolean }>
        ) => {
            const payload = action.payload;
            state.fields.altExpAutoThought[0] = payload;
        },
        updateSessionPage: (state, action: PayloadAction<any>) => {
            const payload = action.payload;
            state.registerSessionId = payload.registerSessionId ?? state.registerSessionId;
            state.fieldIds = payload.fieldIds ?? state.fieldIds;
            state.finished = payload.finished;
            state.fields = {
                ...state.fields, // 기존 fields 복사
                ...payload.fields, // 새로운 fields로 업데이트
            };
        },

        updateSessionField: (state, action: PayloadAction<{ index: number; key: string; value: any }>) => {
            const { index, key, value } = action.payload;

            const currentField = state.fields[key as keyof Session['fields']];

            if (currentField && currentField[index]) {
                // 새로운 상태 객체 반환
                return {
                    ...state,
                    fields: {
                        ...state.fields,
                        [key]: currentField.map((item, idx) =>
                            idx === index ? { ...value, feedbackCheck: true, visible: true } : item
                        ),
                    },
                };
            }

            return state; // 변경이 없으면 기존 상태를 반환
        },
        changeFeedbackState: (state, action: PayloadAction<{ index: number; key: string; value: any }>) => {
            const { index, key, value } = action.payload;

            const currentField = state.fields[key as keyof Session['fields']];

            if (currentField && currentField[index]) {
                // 새로운 상태 객체 반환
                return {
                    ...state,
                    fields: {
                        ...state.fields,
                        [key]: currentField.map((item, idx) =>
                            idx === index ? { ...value, feedbackCheck: false, visible: true } : item
                        ),
                    },
                };
            }

            return state; // 변경이 없으면 기존 상태를 반환
        },
        deleteFeedback: (state, action: PayloadAction<{ index: number; key: string; value: any }>) => {
            const { index, key, value } = action.payload;

            const currentField = state.fields[key as keyof Session['fields']];

            if (currentField) {
                // 새로운 상태 객체 반환
                return {
                    ...state,
                    fields: {
                        ...state.fields,
                        [key]: [{ patient: '', feedback: '', visible: false, feedbackCheck: false }],
                    },
                };
            }

            return state; // 변경이 없으면 기존 상태를 반환
        },
        resetData: () => initialState,
    },
});

export const {
    // (sessionNo, 1,10) 요즘 나의 주된 생각과 감정
    updateBrain,

    // (sessionNo, 1) 인지행동치료 후에 가장 변화하고 싶은 증상과 그 이유
    updateDesiredSymptomChange,

    // (sessionNo, 1) 치료에 방해되는 심리적 장애물
    updatePsychologicalBarriers,

    // (sessionNo, 1) 나의 장점
    updateMyStrengths,

    // (sessionNo, 1) 치료를 위한 단계별 목표
    updateTherapyGoals,

    // (sessionNo, 2) 내가 불편해하는 신체증상
    updatePhysicalSymptoms,

    // (sessionNo, 2) 내가 경험하는 감정의 반응
    updateEmotionalReactions,

    // (sessionNo, 4) quiz
    updateQuiz,

    // (sessionNo, 5) 소크라테스 문답법 / 자동사고
    uppdateSocraticAutoThinking,

    // (sessionNo, 5) 소크라테스 문답법 / 내 생각이 맞다는 증거
    updateEvidenceCorrectThinking,

    // (sessionNo, 5) 소크라테스 문답법 / 내 생각이 틀리다는 증거
    updateEvidenceIncorrectThinking,

    // (sessionNo, 6) 소크라테스 문답법 / 나의 자동사고에 대한 여러가지 가능성
    updateAuthmaticThinkingGraph,

    // (sessionNo, 7) 소크라테스 문답법 / 최악의 결과
    updateWorstOutcome,

    // (sessionNo, 7) 소크라테스 문답법 / 최상의 결과
    updateBestOutcome,

    // (sessionNo, 7) 소크라테스 문답법 / 현실적 결과
    updateRealisticOutcome,

    // (sessionNo, 8) 이 자동사고를 믿을 때 얻을 수 있는 장점
    updateAdvantagesOfTrustingAutoThought,

    // (sessionNo, 8) 내 자동사고가 '친구의 고민'이라고 생각하고 조언해 보자.
    updateLetter,

    // (sessionNo, 8) 자동사고 점검 / 자동사고에 대한 합리적 사고
    updateRationalThinkingAutoCheck,

    // (sessionNo, 8) 자동사고 점검 / 감정
    updateEmotionsAfterChange,

    // (sessionNo, 8) 자동사고 점검 / 감정 점수
    updateScoreAfterChange,

    // (sessionNo, 8) 배운점/느낀점
    updateLessonsLearned,

    // (sessionNo, 10) 감정의 신체적 반응
    updatePhysicalResponseOfEmotion,

    // (sessionNo, 10) 감정의 행동적 반응
    updateBehavioralResponseOfEmotion,

    // (sessionNo, 10) 감정의 인지적 반응
    updateCognitiveResponseOfEmotion,

    // (sessionNo, 10) 상위 3개의 선택된 인지오류
    updateTop3CognitiveErrors,

    // (sessionNo, 10) 이전 생각과 감정
    updateBeforeBrain,

    // (sessionNo, 2,3,4,5,6,7,8,9) 자동사고 기록지 / 상황
    updateSituation,

    // (sessionNo, 2,3,4,5,6,7,8,9) 자동사고 기록지 / 감정
    updateEmotionIcon,

    // (sessionNo, 2,3,4,5,6,7,8,9) 자동사고 기록지 / 감정 점수
    updateEmotionScore,

    // (sessionNo, 3,4,5,6,7,8,9) 어떤 생각이 떠올랐나요?
    updateThought,

    // (sessionNo, 3,4,5,6,7,8,9) 최악의 경우, 어떤 일이 일어날 것이라는 생각이 들었나요?
    updateWorstCaseScenario,

    // (sessionNo, 3,4,5,6,7,8,9) 그때 떠오른 이미지는
    updateImage,

    // (sessionNo, 3,4,5,6,7,8,9) 내가 어떤 사람으로 보여질까?
    updateSelfPerception,

    // (sessionNo, 3,4,5,6,7,8,9) 상황에 대한 생각 + 생각으로 인한 결과
    updateSituationThoughts,

    // (sessionNo, 3,4,5,6,7,8,9) 생각을 믿는 정도
    updateTrustInThoughts,

    // (sessionNo, 4,5,6,7,8,9,10) 인지오류
    updateCognitiveError,

    // (sessionNo, 7,8) 소크라테스 문답법 / 자동사고의 대안적 설명
    updateAltExpAutoThought,

    updateSessionPage,
    updateSessionField,
    deleteFeedback,
    changeFeedbackState,
    resetData,
} = sessionReducer.actions;
export default sessionReducer.reducer;

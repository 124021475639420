import React, { useEffect, useState } from 'react';

import styles from './css/AssignedMember.module.css';
import styled from 'styled-components';

import { useNavigate } from 'react-router-dom';
import {
    AssignedTherapist,
    ChartTitle,
    MemberInfoButton,
    NameChartNumber,
    ProgramType,
    RecentCompleteFeedback,
    SearchContainer,
    SessionCompleteDate,
    SessionProgress,
} from './AssignedMemberComponent.tsx/AssignedMemberComponent';
import { getMemberInfo } from '../../../../utils/GetMemberInfo';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { getTherapistMemberList } from './ts/AssignedMemberApi';
import { decryptData, encryptData } from '../../../../utils/croptojs';

const ChartTitleItem = styled.li<{ $index: number; $lastIndex: number }>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    height: 3.3125rem;
    padding: 0.75rem 2.1875rem;
    ${(props) => (props.$index === props.$lastIndex ? '' : 'border-bottom: 1px solid #e3e5ea;')}
`;

const chartTitleList = ['이름(차트번호)', '프로그램유형', '세션 진행도', '세션 완료일', '최근 완료된 피드백', ''];
const officialChartTitleList = [
    '이름(차트번호)',
    '프로그램유형',
    '세션 진행도',
    '세션 완료일',
    '최근 완료된 피드백',
    '담당 상담사',
    '',
];

export const TherapistAssignedMember = () => {
    const navigate = useNavigate();

    const [pageNum, setPageNum] = useState<number>(0);
    const memberInfo = getMemberInfo();
    const companyId = memberInfo?.loginBranch.companyId!;

    const { data, isLoading, error, refetch } = useQuery({
        queryKey: ['therapistMemberList', { companyId, pageNum }],
        queryFn: () => getTherapistMemberList(companyId, pageNum),
        placeholderData: keepPreviousData,
    });

    const moveToPatientCBT = (programType: string, sessionPage: number, sessionId: number, registerId: number) => {
        const params = new URLSearchParams();
        params.append('page', sessionPage.toString());
        params.append('session', encryptData(sessionId));
        params.append('program', encryptData(registerId));

        if (programType === 'non-face') {
            navigate(`patientCBT/nonface/session?${params}`);
        } else if (programType === 'face') {
            navigate(`patientCBT/face/session?${params}`);
        }
    };

    const memberDetail = (memberId: number) => {
        const queryString = `data=${encodeURIComponent(encryptData(memberId))}`;
        navigate(`detail?${queryString}`);
    };

    const AssignedRender = () => {
        return (
            <div>
                <div className={`${styles.chart_title_container} text_16_NotoSansKR_Medium`}>
                    {chartTitleList.map((item, index) => (
                        <ChartTitle key={index.toString()} title={item} />
                    ))}
                </div>
                {data?.members.content ? (
                    <ul className={styles.chart_content_container}>
                        {data?.members.content.map((item, index) => (
                            <ChartTitleItem
                                key={index.toString()}
                                $index={index}
                                $lastIndex={data?.members.content.length - 1}
                            >
                                <NameChartNumber name={item.memberName} chartNumber={item.memberId} />
                                <ProgramType programType={item.sessionInfos[0].workBookName} />
                                <SessionProgress
                                    sessionProgress={item.sessionInfos[0].finishedCnt}
                                    maxProgress={item.sessionInfos[0].totalCnt}
                                    moveToPatientCBT={() =>
                                        moveToPatientCBT(
                                            item.sessionInfos[0].workBookType,
                                            item.sessionInfos[0].finishedCnt,
                                            item.sessionInfos[0].registerSessionId,
                                            item.sessionInfos[0].registerId
                                        )
                                    }
                                />
                                <SessionCompleteDate completeDate={item.sessionInfos[0].finishedDate} />
                                <RecentCompleteFeedback recentCompleteSession={item.sessionInfos[0].recentFeed} />
                                <MemberInfoButton memberId={item.memberId} />
                            </ChartTitleItem>
                        ))}
                    </ul>
                ) : (
                    <div className={styles.chart_nondata_container}>
                        <span
                            className="text_18_NotoSansKR_Medium"
                            style={{
                                color: '#9D9FA2',
                            }}
                        >
                            아직 담당 중인 회원이 없습니다.
                        </span>
                    </div>
                )}
            </div>
        );
    };

    return (
        <div className={styles.main_container}>
            <div className="text_32_NotoSansKR_Bold">회원</div>
            <div className={styles.middle_title_container}>
                <div
                    className={`${styles.middle_title} ${styles.activate} text_18_NotoSansKR_Bold`}
                    style={{
                        color: '#3A5074',
                    }}
                >
                    담당 중인 회원
                    <span>({data?.members.content.length})</span>
                </div>
            </div>
            <div className={styles.content_container}>
                <SearchContainer />
                <AssignedRender />
            </div>
        </div>
    );
};

export const OfficialAssignedMember = () => {
    const navigate = useNavigate();
    // const role = memberInfo.memberRole !== 'user' && memberInfo.memberRole !== 'employee';

    const [pageNum, setPageNum] = useState<number>(0);
    const memberInfo = getMemberInfo();
    const companyId = memberInfo?.loginBranch.companyId!;

    const { data, isLoading, error, refetch } = useQuery({
        queryKey: ['therapistMemberList', { companyId, pageNum }],
        queryFn: () => getTherapistMemberList(companyId, pageNum),
    });
    const [assignedMemberOfficialList, setAssignedMemberOfficialList] = useState([
        {
            memberId: 1,
            name: '김회원',
            chartNumber: 1145,
            programType: '비대면 CBT',
            background: '#EDF2FE',
            sessionProgress: 2,
            maxProgress: 8,
            completeDate: '2024-11-11',
            recentCompleteSession: '세션3',
            assignedTherapist: '치료자명',
        },
    ]);

    // const getInfo = async () => {
    //     const info = getMemberInfo();
    //     if (info) {
    //         setMemberInfo(info);
    //     }
    // };

    // useEffect(() => {
    //     getInfo();
    // }, []);

    const moveToPatientCBT = (programType: string) => {
        if (programType === 'non-face') {
            navigate('patientCBT/nonface/firstSession');
        } else if (programType === 'face') {
            navigate('patientCBT/face/firstSession');
        }
    };

    const AssignedRender = () => {
        return (
            <div>
                <div className={`${styles.chart_title_container} text_16_NotoSansKR_Medium`}>
                    {officialChartTitleList.map((item, index) => (
                        <ChartTitle title={item} />
                    ))}
                </div>
                <ul className={styles.chart_content_container}>
                    {data?.members.content.map((item, index) => (
                        <ChartTitleItem
                            key={index.toString()}
                            $index={index}
                            $lastIndex={assignedMemberOfficialList.length - 1}
                        >
                            <NameChartNumber name={item.memberName} chartNumber={item.memberId} />
                            <ProgramType programType={item.sessionInfos[0].workBookName} />
                            <SessionProgress
                                sessionProgress={item.sessionInfos[0].finishedCnt}
                                maxProgress={item.sessionInfos[0].totalCnt}
                                moveToPatientCBT={() => moveToPatientCBT(item.sessionInfos[0].workBookName)}
                            />
                            <SessionCompleteDate completeDate={item.sessionInfos[0].finishedDate} />
                            {/* <RecentCompleteFeedback recentCompleteSession={item.recentCompleteSession} /> */}
                            {/* <AssignedTherapist /> */}
                            <MemberInfoButton memberId={item.memberId} />
                        </ChartTitleItem>
                    ))}
                </ul>
            </div>
        );
    };

    return (
        <div className={styles.main_container}>
            <div className="text_32_NotoSansKR_Bold">회원</div>
            <div className={styles.middle_title_container}>
                <div
                    className={`${styles.middle_title} text_18_NotoSansKR_Bold`}
                    style={{
                        color: '#3A5074',
                    }}
                >
                    담당 중인 회원
                    <span>({assignedMemberOfficialList.length})</span>
                </div>
            </div>
            <div className={styles.content_container}>
                <SearchContainer />
                <AssignedRender />
            </div>
        </div>
    );
};

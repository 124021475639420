import { useContext, useEffect, useMemo, useRef } from 'react';

import { TEST_SERVER } from './BasicUrl';

import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

// Axios 인스턴스 생성
const axiosInstance: AxiosInstance = axios.create({
    timeout: 5000,
});

// Set을 사용하여 처리된 에러 코드를 저장
const errorHandledRef = new Set<string>();

// 요청 인터셉터 설정
axiosInstance.interceptors.request.use(
    (config) => {
        const userData = localStorage.getItem('userData');
        if (userData) {
            const result = JSON.parse(userData);
            config.headers['Authorization'] = result.Authorization;
            config.headers['Refreshtoken'] = result.RefreshToken;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// 응답 인터셉터 설정
axiosInstance.interceptors.response.use(
    (response: AxiosResponse) => {
        console.log(
            `Response url: ${response.config.url} with ResponseData: ${JSON.stringify(response.data, null, 2)}`
        );
        return response;
    },
    async (error) => {
        const originalRequest = error.config;
        const errorCode = error.response?.data?.errorCode;

        if (error.response?.status !== undefined) {
            if (!errorHandledRef.has(errorCode)) {
                errorHandledRef.add(errorCode); // 에러 코드 추가

                switch (error.response.status) {
                    case 401: // Unauthorized
                        await getNewToken(); // 토큰 갱신 함수 호출
                        return axiosInstance(originalRequest); // 원래 요청 재전송
                    case 403: // Forbidden
                        return Promise.reject(error.response.status);
                    default:
                        break;
                }
            }
        }

        return Promise.reject(error); // 기본 에러 처리
    }
);

// Axios 인스턴스 내보내기
export default axiosInstance;

// 토큰 재발급
const getNewToken = async () => {
    const userData = await localStorage.getItem('userData');
    if (userData !== null) {
        const result = JSON.parse(userData);
        const res = await axios({
            method: 'GET',
            url: `${TEST_SERVER}/api/auth/token/refresh`,
            timeout: 5000,
            headers: {
                Accept: 'application/json',
                refreshToken: result.RefreshToken,
            },
        })
            .then(function (response) {
                if (response.status == 200) {
                    if (response.data.RefreshToken == null) {
                        localStorage.setItem(
                            'userData',
                            JSON.stringify({
                                Authorization: response.data.Authorization,
                                Authorization_Exp: response.data.Authorization_Exp,
                                RefreshToken: result.RefreshToken,
                                RefreshToken_Exp: result.RefreshToken_Exp,
                            })
                        );
                    } else {
                        localStorage.setItem(
                            'userData',
                            JSON.stringify({
                                Authorization: response.data.Authorization,
                                Authorization_Exp: response.data.Authorization_Exp,
                                RefreshToken: response.data.RefreshToken,
                                RefreshToken_Exp: response.data.RefreshToken_Exp,
                            })
                        );
                    }
                    const token = response.data;
                    return token;
                } else {
                    return false;
                }
            })
            .catch((err) => {
                console.log('토큰 재발급' + err);
                localStorage.removeItem('userData');
                // navigate('LoginMain', '', '403');
                switch (err.response.status) {
                    case 401:
                        return Promise.reject(err.response.status);
                    case 403:
                        // Alert.alert('경고', '비정상적인 접근이 감지됐습니다 ' + '\n 다시 로그인 해주세요.', [
                        //     {
                        //         text: '확인',
                        //         onPress: () => null,
                        //     },
                        // ]);
                        return Promise.reject(err.response.status);
                }
            });
    }
};

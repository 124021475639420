import { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { ko } from 'date-fns/locale';
import './css/ScheduleDatePicker.css';
import moment from 'moment';
import { ReactComponent as Icon_arrowright } from '../../../../../assets/member/signup/icon_arrowright.svg';
import { ReactComponent as Icon_arrowleft } from '../../../../../assets/public/icon_arrowleft.svg';
import { ReactComponent as Icon_calendar } from '../../../../../assets/leftNavigation/icon_calendar.svg';
import { format } from 'date-fns';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store';

export const ScheduleDatePicker = () => {
    const { reservationDate } = useSelector((state: RootState) => state.scheduleModal);

    const [startDate, setStartDate] = useState(new Date(reservationDate));

    const dateFormat = (date: Date) => {
        return format(date, 'yyyy-MM-dd EEEE', { locale: ko }); // 한국어로 요일 표시
    };

    const changeMonth = (increment: number) => {
        const newDate = new Date(startDate);
        newDate.setMonth(startDate.getMonth() + increment);
        setStartDate(newDate);
    };

    return (
        <div className={'date_picker_container'}>
            <DatePicker
                shouldCloseOnSelect
                dateFormat={'yyyy-MM-dd EEEE'}
                onChange={(date: any) => setStartDate(date)}
                selected={startDate}
                minDate={new Date()}
                locale={ko}
                className=""
                customInput={
                    <div className={'schdule_button'}>
                        <Icon_calendar className={'icon'} />
                        <span>{dateFormat(startDate)}</span>
                    </div>
                }
                renderCustomHeader={({
                    date,
                    changeYear,
                    decreaseMonth,
                    increaseMonth,
                    prevMonthButtonDisabled,
                    nextMonthButtonDisabled,
                }) => (
                    <div className={'header_container'}>
                        <span className="text_16_NotoSansKR_Bold">{moment(startDate).format('YYYY년 MM월')}</span>
                        <div className={'button_container'}>
                            <button onClick={() => changeMonth(-1)} className={'arrow_button'}>
                                <Icon_arrowleft className={'icon_arrow'} />
                            </button>
                            <button onClick={() => changeMonth(+1)} className={'arrow_button'}>
                                <Icon_arrowright className={'icon_arrow'} />
                            </button>
                        </div>
                    </div>
                )}
            />
        </div>
    );
};

import { FocusEventHandler, MouseEventHandler, useMemo } from 'react';
import styles from '../css/FindAccount.module.css';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { MainButton } from '../../component/MainButton';
import styled from 'styled-components';

interface InputProps {
    $index: number;
    $isFocused: number;
}

const InputPossible = styled.input<InputProps>`
    box-sizing: border-box;
    width: 100%;
    height: 4rem;
    padding: 1.18rem 1.25rem;
    border-radius: 10px;
    outline: ${(props) => (props.$isFocused === props.$index ? '0.1875rem solid #3A5074' : '1px solid #e3e5ea')};
    background: #fff;
`;

export const FindSuccessRender = ({
    findSuccess,
    memberId,
    memberNewPw,
    memberNewRecheckPw,
    change,
    onFocus,
    isFocused,
    resetFocuse,
    changePasswordRole,
}: {
    findSuccess: number;
    memberId: string;
    memberNewPw: string;
    memberNewRecheckPw: string;
    change: Function;
    onFocus: Function;
    isFocused: number;
    resetFocuse: FocusEventHandler<HTMLInputElement>;
    changePasswordRole: MouseEventHandler<HTMLButtonElement>;
}) => {
    const memberType = useSelector((state: RootState) => state.memberType);

    const ChangePassswordInput = useMemo(() => {
        return (
            <>
                <div className={styles.input_container}>
                    <div className={styles.input_content_container}>
                        <span className="text_18_NotoSansKR_Bold" style={{ color: '#626466' }}>
                            새로운 비밀번호
                        </span>
                        <InputPossible
                            type="password"
                            $isFocused={isFocused}
                            $index={1}
                            value={memberNewPw}
                            onChange={(e) => change('newPw', e.target.value)}
                            onFocus={() => onFocus(1)}
                            onBlur={resetFocuse} // 필요 시 이 로직을 수정
                            className="text_18_NotoSansKR_Regular"
                            placeholder={'띄어쓰기 미포함 최소 4자리 이상 입력'}
                        />
                    </div>
                    <div className={styles.input_content_container}>
                        <span className="text_18_NotoSansKR_Bold" style={{ color: '#626466' }}>
                            새로운 비밀번호 확인
                        </span>
                        <InputPossible
                            type="password"
                            $isFocused={isFocused}
                            $index={2}
                            value={memberNewRecheckPw}
                            onChange={(e) => change('newRecheckPw', e.target.value)}
                            onFocus={() => onFocus(2)}
                            onBlur={resetFocuse} // 필요 시 이 로직을 수정
                            className="text_18_NotoSansKR_Regular"
                            placeholder={'새로운 비밀번호 재입력'}
                        />
                    </div>
                </div>
                <MainButton title={'변경하기'} onClick={changePasswordRole} />
            </>
        );
    }, [
        findSuccess,
        memberId,
        memberNewPw,
        memberNewRecheckPw,
        change,
        onFocus,
        isFocused,
        resetFocuse,
        changePasswordRole,
    ]);

    if (findSuccess === 0 && memberType.type === 0) {
        return (
            <div className={styles.find_success_id_container}>
                <span className="text_18_NotoSansKR_Regular">입력하신 정보와 일치하는 아이디입니다.</span>
                <div className={`${styles.find_success_id} text_18_Inter_Bold`}>{memberId}</div>
                <MainButton title={'로그인하러 가기'} onClick={() => {}} />
            </div>
        );
    } else if (findSuccess === 1 && memberType.type === 1) {
        return ChangePassswordInput;
    } else {
        return null;
    }
};

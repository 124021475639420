import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface Event {
    calendarId: number;
    memberId: number;
    memberName: string;
    registerSessionIds: number[];
    workBookNames: string[];
    sessionProgresses: string[];
    reservationDate: string;
    startTime: string;
    endTime: string;
}

interface Events {
    reserves: Event[];
    message: string;
    result: boolean;
}

interface AddReserve {
    searchVal: string;
    registerSessionIds: number[];
    workBookNames: string[];
    sessionProgresses: string[];
    memo: string;
    reservationDate: string; // 문자열로 변경
    startTime: string;
    endTime: string;
    events: Events; // 'evnets'를 'events'로 수정
}

const initialState: AddReserve = {
    searchVal: '',
    registerSessionIds: [],
    workBookNames: [],
    sessionProgresses: [],
    memo: '',
    reservationDate: '', // 초기 상태에서 공백으로 설정
    startTime: '',
    endTime: '',
    events: {
        reserves: [],
        message: '',
        result: false,
    },
};

export const reservationSlice = createSlice({
    name: 'reservation',
    initialState,
    reducers: {
        addEvent: (state, action: PayloadAction<{ list: Events }>) => {
            // state.events = list; // 새로운 이벤트 추가
        },
        updateEvent: (state, action: PayloadAction<Events>) => {
            state.events = action.payload;
        },
        removeEvent: (state, action: PayloadAction<{ id: number }>) => {
            // const { id } = action.payload;
            // state.events = state.events.filter((event) => event.id !== id); // 특정 이벤트 제거
        },
        updateSearchVal: (state, action: PayloadAction<string>) => {
            state.searchVal = action.payload;
        },
    },
});

export const { addEvent, updateEvent, removeEvent, updateSearchVal } = reservationSlice.actions;
export default reservationSlice.reducer;

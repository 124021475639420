import axiosInstance from '../../../../../utils/AxiosInstanceJava';
import { getMemberInfo } from '../../../../../utils/GetMemberInfo';

export interface ProgramList {
    products: {
        workBookProductId: number;
        productName: string;
        onSale: boolean;
        price: number;
        discountPrice: number;
        category: string;
    }[];
    message: string;
    result: boolean;
}

export interface Program {
    workBookProductId: number;
    productName: string;
    originalPrice: number;
    discount: number;
    workBooks: {
        workBookId: number;
        price: number;
        workBookName: string;
    }[];
    onSale: boolean;
    productDescription: string;
    message: string;
    result: boolean;
}

export interface CategoryList {
    categories: {
        workBookId: number;
        price: number;
        workBookName: string;
    }[];
    message: string;
    result: boolean;
}

// 프로그램 리스트
export const getProgramList = async (): Promise<ProgramList> => {
    const memberInfo = getMemberInfo();
    return await axiosInstance.get(`/api/${memberInfo?.memberRole}/product/v1`).then((response) => response.data);
};

// 프로그램 상세정보
export const getProgramDetail = async (productId: number): Promise<Program> => {
    const memberInfo = getMemberInfo();
    return await axiosInstance
        .post(`/api/${memberInfo?.memberRole}/product/${productId}/v1`)
        .then((response) => response.data);
};

// 프로그램 카테고리 정보
export const getProgramCategory = async (): Promise<CategoryList> => {
    const memberInfo = getMemberInfo();
    return await axiosInstance
        .get(`/api/${memberInfo?.memberRole}/product/category/v1`)
        .then((response) => response.data);
};

import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import styles from './css/FindAccount.module.css';
import logoPng from '../../../assets/member/login/logo.png';
import { MemberTypeMenu } from './component/MemberTypeMenu';
import { Illustration } from '../component/Illustration';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { EssentialMark } from '../component/EssentialMark';
import { MainButton } from '../component/MainButton';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { ModalContext } from '../../../context/ModalContext';
import { IdAndNameInput } from './component/FindAccountInput';
import { updateEmailAddress, updateEmailAddressVisible } from '../../../features/signupReducer';
import { EmailDropdown } from '../component/EmailDropdown';
import { ReactComponent as Icon_arrowbotton } from '../../../assets/member/icon_arrowbottom.svg';
import axiosInstance from '../../../utils/AxiosInstanceJava';
import { FindSuccessRender } from './component/\bChangePassword';
import { updateMemberType } from '../../../features/memberTypeMenuReducer';

interface InputProps {
    $index: number;
    $isFocused: number;
}

const InputEmail = styled.input<InputProps>`
    flex: 1;
    height: 4rem;
    box-sizing: border-box;
    padding: 1.18rem 1.25rem;
    border-radius: 10px;
    outline: ${(props) => (props.$isFocused === props.$index ? '0.1875rem solid #3A5074' : '1px solid #e3e5ea')};
    background: #fff;
`;

const EmailDropdownButton = styled.button<InputProps>`
    flex: 1;
    height: 4rem;
    position: relative;
    box-sizing: border-box;
    text-align: justify;
    padding: 1.18rem 1.25rem;
    border-radius: 10px;
    outline: ${(props) => (props.$isFocused === props.$index ? '0.1875rem solid #3A5074' : '1px solid #e3e5ea')};
    background: #fff;
`;

export const FindAccount = () => {
    const memberType = useSelector((state: RootState) => state.memberType);
    const userInfo = useSelector((state: RootState) => state.signup);
    const dispatch = useDispatch();
    const { oneButtonOpenModal } = useContext(ModalContext);
    const navigate = useNavigate();

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const value: string | null = queryParams.get('selectedIndex'); // value는 string 또는 null일 수 있음

    const [isFocused, setIsFocused] = useState<number>(-1);
    const [findSuccess, setFindSuccess] = useState<number>(-1);

    const [memberId, setMemberId] = useState<string>('');
    const [memberEmail, setMemberEmail] = useState<string>('');
    const [memberName, setMemberName] = useState<string>('');
    const [memberNewPw, setMemberNewPw] = useState<string>('');
    const [memberNewRecheckPw, setMemberNewRecheckPw] = useState<string>('');

    useEffect(() => {
        dispatch(updateMemberType(Number(value)!));
        dispatch(updateEmailAddress('선택해 주세요.'));
    }, []);

    // input에 포커스
    const inputFocused = (index: number) => {
        setIsFocused(index);
    };

    // input 포커스 초기화
    const resetFocuse = () => {
        setIsFocused(-1);
    };

    const resetData = () => {
        setMemberId('');
        setMemberEmail('');
        dispatch(updateEmailAddress('선택해 주세요.'));
        setMemberName('');
        setMemberNewPw('');
        setMemberNewRecheckPw('');
        setFindSuccess(-1);
    };

    const onChange = (type: string, text: string) => {
        switch (type) {
            case 'id':
                setMemberId(text);
                break;
            case 'email':
                setMemberEmail(text);
                break;
            case 'name':
                setMemberName(text);
                break;
            case 'newPw':
                setMemberNewPw(text);
                break;
            case 'newRecheckPw':
                setMemberNewRecheckPw(text);
                break;
        }
    };

    const findAccountRole = () => {
        const nameRegex = /^[A-Za-z가-힣]+$/;
        const emailAddressRegex = /^(?!-)([a-zA-Z0-9-]{1,63}(?<!-)\.)+[a-zA-Z]{2,}$/;

        if (memberName === '') {
            return oneButtonOpenModal(() => {}, '', '이름을 입력해 주세요.', '확인');
        } else if (memberEmail === '' || userInfo.emailAddress === '') {
            return oneButtonOpenModal(() => {}, '', '이메일 주소를 입력해 주세요.', '확인');
        } else if (!nameRegex.test(memberName)) {
            return oneButtonOpenModal(
                () => {},
                '',
                '올바르지 않은 이름 형식입니다. (특수문자, 숫자, 띄어쓰기 불가)',
                '확인'
            );
        } else if (!emailAddressRegex.test(userInfo.emailAddress.replace('@', ''))) {
            return oneButtonOpenModal(() => {}, '', '올바르지 않은 이메일 형식입니다.', '확인');
        }

        findAccount();
    };

    // 아이디 찾기
    const findAccount = async () => {
        await axiosInstance
            .post('/api/auth/find/account/v1', {
                findType: 'account',
                memberAccount: memberId,
                memberName: memberName,
                memberEmail: memberEmail + userInfo.emailAddress,
            })
            .then((response) => {
                if (response.data.result) {
                    setMemberId(response.data.memberAccount[0]);
                    setFindSuccess(memberType.type);
                } else {
                    oneButtonOpenModal(() => {}, '', '입력하신 정보에 일치하는 계정을 찾을 수 없습니다.', '확인');
                }
            });
    };

    // 비밀번호 찾기 인증
    const findPassword = async () => {
        await axiosInstance
            .post('/api/auth/find/account/v1', {
                findType: 'password',
                memberAccount: memberId,
                memberName: memberName,
                memberEmail: memberEmail + userInfo.emailAddress,
            })
            .then((response) => {
                if (response.data.result) {
                    setMemberId(response.data.memberAccount[0]);
                    setFindSuccess(memberType.type);
                } else {
                    oneButtonOpenModal(() => {}, '', '입력하신 정보에 일치하는 계정을 찾을 수 없습니다.', '확인');
                }
            });
    };

    // 비밀번호 변경
    const changePassword = async () => {
        await axiosInstance
            .put('/api/auth/new/password/v1', {
                memberAccount: memberId,
                memberEmail: memberEmail,
                newPassword: memberNewPw,
                confirmPassword: memberNewRecheckPw,
            })
            .then((response) => {
                if (response.data.result) {
                    oneButtonOpenModal(
                        () => {
                            navigate('/public/login');
                        },
                        '',
                        '비밀번호 변경이 완료되었습니다. 로그인을 다시 진행해 주세요.',
                        '확인'
                    );
                }
            });
    };

    const findPasswordRole = async () => {
        const accountRegex = /^(?=.*[a-z])(?=.*\d)[a-z\d]{4,}$/;
        const emailAddressRegex = /^(?!-)([a-zA-Z0-9-]{1,63}(?<!-)\.)+[a-zA-Z]{2,}$/;

        if (memberId === '') {
            return oneButtonOpenModal(() => {}, '', '아이디를 입력해 주세요.', '확인');
        } else if (!accountRegex.test(memberId)) {
            return oneButtonOpenModal(
                () => {},
                '',
                '올바르지 않은 아이디 형식입니다. (이모티콘, 띄어쓰기, 한글 불가)',
                '확인'
            );
        } else if (memberEmail === '' || userInfo.emailAddress === '') {
            return oneButtonOpenModal(() => {}, '', '이메일 주소를 입력해 주세요.', '확인');
        } else if (!emailAddressRegex.test(userInfo.emailAddress.replace('@', ''))) {
            return oneButtonOpenModal(() => {}, '', '올바르지 않은 이메일 형식입니다.', '확인');
        }
        findPassword();
    };

    const changePasswordRole = () => {
        const passwordRegex =
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>])[a-zA-Z\d!@#$%^&*(),.?":{}|<>]{4,}$/;
        if (memberNewPw === '') {
            return oneButtonOpenModal(() => {}, '', '새로운 비밀번호를 입력해 주세요.', '확인');
        } else if (memberNewRecheckPw === '') {
            return oneButtonOpenModal(() => {}, '', '비밀번호를 재입력해 주세요.', '확인');
        } else if (!passwordRegex.test(memberNewPw)) {
            // message = '올바르지 않은 비밀번호 형식입니다. (띄어쓰기 미포함 최소 4자리 이상 입력 가능)';
            return oneButtonOpenModal(
                () => {},
                '',
                '올바르지 않은 비밀번호 형식입니다. (띄어쓰기 미포함 최소 4자리 이상 입력 가능)',
                '확인'
            );
        } else if (memberNewPw !== memberNewRecheckPw) {
            return oneButtonOpenModal(() => {}, '', '비밀번호가 일치하지 않습니다.', '확인');
        }
        changePassword();
    };

    const EmailAddressSelect = () => {
        if (!userInfo.emailAddress.includes('직접')) {
            return (
                <EmailDropdownButton
                    $isFocused={userInfo.emailAddressVisible ? 2 : -1}
                    $index={2}
                    onClick={() => dispatch(updateEmailAddressVisible(!userInfo.emailAddressVisible))}
                    className="text_18_NotoSansKR_Regular"
                    style={{
                        color: userInfo.emailAddress.includes('선택') ? '#9D9FA2' : '#020202',
                    }}
                >
                    {userInfo.emailAddress.replace('@', '')}
                    <Icon_arrowbotton className={styles.icon_arrowbottm} />
                    {userInfo.emailAddressVisible && (
                        <EmailDropdown
                            updateAddress={(item: string) => {
                                dispatch(updateEmailAddress(item));
                                dispatch(updateEmailAddressVisible(!userInfo.emailAddressVisible));
                            }}
                        />
                    )}
                </EmailDropdownButton>
            );
        } else {
            return (
                <InputEmail
                    $isFocused={isFocused}
                    $index={2}
                    onFocus={() => inputFocused(2)}
                    onBlur={resetFocuse}
                    className="text_18_NotoSansKR_Regular"
                    placeholder="입력해 주세요."
                />
            );
        }
    };

    return (
        <div className={styles.main_container}>
            <div className={styles.view_container}>
                <div className={styles.content_container}>
                    <img className={styles.logo} src={logoPng} />
                    <div>
                        <div className={styles.login_title}>
                            <span className="text_40_NotoSansKR_Bold">아이디/비밀번호 찾기</span>
                        </div>
                    </div>
                    <MemberTypeMenu
                        menuList={['아이디 찾기', '비밀번호 찾기']}
                        selectedIndex={Number(value)!}
                        reset={resetData}
                    />
                    <FindSuccessRender
                        findSuccess={findSuccess}
                        memberId={memberId}
                        memberNewPw={memberNewPw}
                        memberNewRecheckPw={memberNewRecheckPw}
                        change={(type: string, text: string) => {
                            onChange(type, text);
                        }}
                        onFocus={(index: number) => inputFocused(index)}
                        isFocused={isFocused}
                        resetFocuse={resetFocuse}
                        changePasswordRole={changePasswordRole}
                    />
                    {findSuccess === -1 && (
                        <>
                            <div className={styles.input_container}>
                                <IdAndNameInput
                                    isFocused={isFocused}
                                    memberName={memberName}
                                    memberId={memberId}
                                    inputFocused={inputFocused}
                                    resetFocuse={resetFocuse}
                                    onChange={(type: string, text: string) => {
                                        onChange(type, text);
                                    }}
                                />
                                <div className={styles.input_content_container}>
                                    <span className="text_18_NotoSansKR_Bold" style={{ color: '#626466' }}>
                                        이메일 주소
                                    </span>
                                    <div className={styles.input_phone_number}>
                                        <InputEmail
                                            $isFocused={isFocused}
                                            $index={2}
                                            onChange={(e) => onChange('email', e.target.value)}
                                            onFocus={() => inputFocused(2)}
                                            onBlur={resetFocuse}
                                            value={memberEmail}
                                            className="text_18_NotoSansKR_Regular"
                                            placeholder="이메일 입력"
                                        />
                                        <span>@</span>
                                        <EmailAddressSelect />
                                    </div>
                                </div>
                            </div>
                            <MainButton
                                title={'확인'}
                                onClick={() => {
                                    if (memberType.type === 0) {
                                        findAccountRole();
                                    } else {
                                        findPasswordRole();
                                    }
                                }}
                            />
                        </>
                    )}
                </div>
            </div>
            <Illustration />
        </div>
    );
};

import React, { useEffect, useState } from 'react';

import styles from './css/AssignedMember.module.css';
import styled from 'styled-components';

import { useNavigate } from 'react-router-dom';
import {
    AssignedTherapist,
    ChartTitle,
    MemberInfoButton,
    NameChartNumber,
    ProgramType,
    RecentCompleteFeedback,
    SearchContainer,
    SelectAssignedTherapist,
    SessionCompleteDate,
    SessionProgress,
} from './AssignedMemberComponent.tsx/AssignedMemberComponent';
import { getMemberInfo } from '../../../../utils/GetMemberInfo';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { RepresentativeMemberList, getManagerMemberList } from './ts/AssignedMemberApi';
import { encryptData } from '../../../../utils/croptojs';

const ChartTitleItem = styled.li<{ $index: number; $lastIndex: number }>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    height: 3.3125rem;
    padding: 0.75rem 2.1875rem;
    ${(props) => (props.$index === props.$lastIndex ? '' : 'border-bottom: 1px solid #e3e5ea;')}
`;

const officialChartTitleList = [
    '이름(차트번호)',
    '프로그램유형',
    '세션 진행도',
    '세션 완료일',
    '최근 완료된 피드백',
    '담당 상담사',
    '',
];

export const ManagerAssignedMember = () => {
    const navigate = useNavigate();
    const [pageNum, setPageNum] = useState<number>(0);
    const [type, setType] = useState<string>('all');
    const { data, isLoading, error, refetch } = useQuery<RepresentativeMemberList>({
        queryKey: ['managerMemberList', { type, pageNum }],
        queryFn: () => getManagerMemberList(type, pageNum),
        placeholderData: keepPreviousData,
    });
    const menuList: { title: string; type: string }[] = [
        { title: '모든 회원', type: 'all' },
        { title: '담당 중인 회원', type: 'mine' },
    ];
    const [isSelectedMenu, setIsSelectedMenu] = useState<{ title: string; type: string }>({
        title: '모든 회원',
        type: 'all',
    });

    const [assignedMemberOfficialList, setAssignedMemberOfficialList] = useState([
        {
            memberId: 1,
            name: '김회원',
            chartNumber: 1145,
            programType: '비대면 CBT',
            background: '#EDF2FE',
            sessionProgress: 2,
            maxProgress: 8,
            completeDate: '2024-11-11',
            recentCompleteSession: '세션3',
            assignedTherapist: '치료자명',
        },
    ]);

    const moveToPatientCBT = () => {
        navigate('patientCBT/face/firstSession');
    };

    const memberDetail = (memberId: number) => {
        const queryString = `data=${encodeURIComponent(encryptData(memberId))}`;
        navigate(`detail?${queryString}`);
    };

    const MenuRender = () => {
        return (
            <div className={styles.middle_title_container}>
                {menuList.map((item, index) => (
                    <button
                        onClick={() => setIsSelectedMenu(item)}
                        className={`${styles.middle_title} ${
                            isSelectedMenu.title === item.title ? styles.activate : styles.deactivate
                        } ${
                            isSelectedMenu.title === item.title
                                ? 'text_18_NotoSansKR_Bold'
                                : 'text_18_NotoSansKR_Medium'
                        }`}
                    >
                        {item.title}({data?.patientList.content.length})
                    </button>
                ))}
            </div>
        );
    };

    const AssignedRender = () => {
        return (
            <div>
                <div className={`${styles.chart_title_container} text_16_NotoSansKR_Medium`}>
                    {officialChartTitleList.map((item, index) => (
                        <ChartTitle title={item} />
                    ))}
                </div>
                {data?.patientList.content ? (
                    <ul className={styles.chart_content_container}>
                        {data?.patientList.content.map((item, index) => (
                            <ChartTitleItem
                                key={index.toString()}
                                $index={index}
                                $lastIndex={data?.patientList.content.length - 1}
                            >
                                <NameChartNumber name={item.memberName} chartNumber={item.memberId} />
                                <ProgramType programType={item.workBookInfos[0].workBookName} />
                                <SessionProgress
                                    sessionProgress={item.workBookInfos[0].finishedCnt}
                                    maxProgress={item.workBookInfos[0].totalCnt}
                                    moveToPatientCBT={moveToPatientCBT}
                                />
                                <SessionCompleteDate completeDate={item.workBookInfos[0].finishedDate} />
                                <RecentCompleteFeedback recentCompleteSession={item.workBookInfos[0].recentFeed} />
                                {item.workBookInfos[0].therapistList.length === 0 ? (
                                    <SelectAssignedTherapist />
                                ) : (
                                    <AssignedTherapist therapist={item.workBookInfos[0].therapistList} />
                                )}
                                <MemberInfoButton memberId={item.memberId} />
                            </ChartTitleItem>
                        ))}
                    </ul>
                ) : (
                    <div className={styles.chart_nondata_container}>
                        <span
                            className="text_18_NotoSansKR_Medium"
                            style={{
                                color: '#9D9FA2',
                            }}
                        >
                            아직 담당 중인 회원이 없습니다.
                        </span>
                    </div>
                )}
            </div>
        );
    };

    return (
        <div className={styles.main_container}>
            <div className="text_32_NotoSansKR_Bold">회원</div>
            <MenuRender />
            <div className={styles.content_container}>
                <SearchContainer />
                <AssignedRender />
            </div>
        </div>
    );
};

import { useDispatch, useSelector } from 'react-redux';
import styles from './css/SelectOrganComponent.module.css';
import { RootState } from '../store';
import { updateSelectOrgan } from '../features/headerReducer';
import { ReactComponent as Icon_check } from '../assets/member/signup/icon_check.svg';
import { getMemberInfo } from '../utils/GetMemberInfo';
import axios from 'axios';
import { useEffect, useState } from 'react';

export const SelectOrgan = () => {
    const dispatch = useDispatch();
    const organList = [{ title: '부산점' }, { title: '창원점' }, { title: '지역3(예정)' }];
    const { settingVisible, selectOrgan } = useSelector((state: RootState) => state.header);
    const memberInfo = getMemberInfo();

    const changeOrgan = async (item: string) => {
        const info = getMemberInfo();
        const findBranch =
            info?.companyBranches.some((value) => {
                return value.branchName === item;
            }) || false;

        if (item !== info?.loginBranch.branchName && findBranch) {
            getNewToken(item);
        }
    };

    // 토큰 재발급
    const getNewToken = async (organ: string) => {
        const userData = localStorage.getItem('userToken');
        if (userData !== null) {
            const result = JSON.parse(userData);
            await axios
                .post(`/api/member/reIssue/${organ}/v1`, null, {
                    headers: {
                        Accept: 'application/json',
                        refreshToken: result.refreshToken,
                    },
                })
                .then(function (response) {
                    if (response.status == 200) {
                        const token = response.data.tokenDTO;
                        dispatch(updateSelectOrgan(organ));
                        if (response.data.refreshToken == null) {
                            localStorage.setItem(
                                'userToken',
                                JSON.stringify({
                                    accessToken: token.accessToken,
                                    accessTokenExp: token.accessTokenExp,
                                    refreshToken: result.refreshToken,
                                    refreshToken_Exp: result.refreshToken_Exp,
                                })
                            );
                        } else {
                            localStorage.setItem(
                                'userToken',
                                JSON.stringify({
                                    accessToken: token.accessToken,
                                    accessTokenExp: token.accessTokenExp,
                                    refreshToken: token.refreshToken,
                                    refreshToken_Exp: token.refreshToken_Exp,
                                })
                            );
                        }
                        window.location.reload();
                    } else {
                        return false;
                    }
                })
                .catch((err) => {
                    console.log('토큰 재발급' + err);
                    // localStorage.removeItem('userToken');
                });
        }
    };

    const OrganMenu = () => {
        return (
            <>
                {organList.map((item, index) => (
                    <button
                        key={index.toString()}
                        onClick={() => {
                            if (index !== 2) changeOrgan(item.title);
                        }}
                        className={`${styles.button} ${
                            memberInfo?.loginBranch.branchName === item.title ? styles.activate : styles.decativate
                        }`}
                    >
                        <div className={styles.button_content}>
                            <div
                                className={`${
                                    memberInfo?.loginBranch.branchName === item.title
                                        ? 'text_16_NotoSansKR_Bold'
                                        : 'text_16_NotoSansKR_Medium'
                                }`}
                                style={
                                    index === 2
                                        ? {
                                              color: '#D2D5D9',
                                          }
                                        : memberInfo?.loginBranch.branchName === item.title
                                        ? { color: '#3A5074' }
                                        : { color: '#020202' }
                                }
                            >
                                {item.title}
                            </div>
                        </div>
                        {memberInfo?.loginBranch.branchName === item.title && (
                            <Icon_check width={'1.5rem'} height={'1.5rem'} color="#3A5074" />
                        )}
                    </button>
                ))}
            </>
        );
    };

    return (
        <div className={styles.modal_main_container}>
            <OrganMenu />
        </div>
    );
};

import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface FaceSession {
    inputData: {
        brain: {
            title: string;
            therapist: string;
            patient: string;
            feedback: string;
        }[];
        desiredSymptom: {
            therapist: string;
            patient: string;
            feedback: string;
        };
        video: string;
        barrier: {
            therapist: string;
            patient: string;
            feedback: string;
        }[];
        advantage: {
            therapist: string;
            patient: string;
            feedback: string;
        };
        goal: {
            step: number;
            therapist: string;
            patient: string;
            feedback: string;
        }[];
    };
}

const initialState: FaceSession = {
    inputData: {
        brain: [
            {
                title: 'a.',
                therapist: '',
                patient: '',
                feedback: '',
            },
            {
                title: 'b.',
                therapist: '',
                patient: '',
                feedback: '',
            },
            {
                title: 'c.',
                therapist: '',
                patient: '',
                feedback: '',
            },
            {
                title: 'd.',
                therapist: '',
                patient: '',
                feedback: '',
            },
            {
                title: 'e.',
                therapist: '',
                patient: '',
                feedback: '',
            },
            {
                title: 'f.',
                therapist: '',
                patient: '',
                feedback: '',
            },
            {
                title: 'g.',
                therapist: '',
                patient: '',
                feedback: '',
            },
        ],
        desiredSymptom: {
            therapist: '',
            patient: '',
            feedback: '',
        },

        barrier: [
            {
                therapist: '',
                patient: '',
                feedback: '',
            },
            {
                therapist: '',
                patient: '',
                feedback: '',
            },
            {
                therapist: '',
                patient: '',
                feedback: '',
            },
        ],
        advantage: {
            therapist: '',
            patient: '',
            feedback: '',
        },
        goal: [
            {
                step: 1,
                therapist: '123123',
                patient: '',
                feedback: '',
            },
            {
                step: 1,
                therapist: '123123',
                patient: '',
                feedback: '',
            },
            {
                step: 1,
                therapist: '123123',
                patient: '',
                feedback: '',
            },
        ],
        video: '',
    },
};

export const faceSessionSlice = createSlice({
    name: 'faceSession',
    initialState,
    reducers: {
        updateBrain: (state, action: PayloadAction<{ index: number; patient: string; therapist: string }>) => {
            const { index, patient, therapist } = action.payload;
            if (state.inputData.brain[index]) {
                if (therapist !== undefined) {
                    state.inputData.brain[index].therapist = therapist;
                }
                if (patient !== undefined) {
                    state.inputData.brain[index].patient = patient;
                }
            }
        },
        updateDesiredSymptoms: (state, action: PayloadAction<{ patient: string; therapist: string }>) => {
            const { patient, therapist } = action.payload;
            if (state.inputData.desiredSymptom) {
                if (therapist !== undefined) {
                    state.inputData.desiredSymptom.therapist = therapist;
                }
                if (patient !== undefined) {
                    state.inputData.desiredSymptom.patient = patient;
                }
            }
        },
        updateBarrier: (state, action: PayloadAction<{ index: number; patient: string; therapist: string }>) => {
            const { index, patient, therapist } = action.payload;
            if (state.inputData.barrier[index]) {
                if (therapist !== undefined) {
                    state.inputData.barrier[index].therapist = therapist;
                }
                if (patient !== undefined) {
                    state.inputData.barrier[index].patient = patient;
                }
            }
        },
        updateAdvantage: (state, action: PayloadAction<{ patient: string; therapist: string }>) => {
            const { patient, therapist } = action.payload;
            if (state.inputData.advantage) {
                if (therapist !== undefined) {
                    state.inputData.advantage.therapist = therapist;
                }
                if (patient !== undefined) {
                    state.inputData.advantage.patient = patient;
                }
            }
        },
        updateGoal: (state, action: PayloadAction<{ index: number; patient: string; therapist: string }>) => {
            const { index, patient, therapist } = action.payload;
            if (state.inputData.goal[index]) {
                if (therapist !== undefined) {
                    state.inputData.goal[index].therapist = therapist;
                }
                if (patient !== undefined) {
                    state.inputData.goal[index].patient = patient;
                }
            }
        },
    },
});
export const { updateBrain, updateDesiredSymptoms, updateBarrier, updateAdvantage, updateGoal } =
    faceSessionSlice.actions;
export default faceSessionSlice.reducer;

import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { TherapistLeftMenu } from '../../../../component/MenuComponent';
import styles from './css/TherapistMain.module.css';
import { TherapistHeader } from '../../../../component/HeaderComponent';
import { TherapistAssignedMember } from '../assignedMember/TherapistAssignedMember';
import { AssignedDetail } from '../assignedDetail/AssignedDetail';
import { MemberInfo } from '../../../public/MemberInfo';
import { NotificationSetting } from '../../../public/NotificationSetting';
import { useEffect, useState } from 'react';
import { TherapistFaceCBT } from '../../cbt/session/therapist/TherapistFaceCBT';
import { TherapistNonFaceCBT } from '../../cbt/session/therapist/TherapistNonFaceCBT';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { ReservationStatus } from '../reservationStatus/ReservationStatus';
import { decryptData, encryptData } from '../../../../utils/croptojs';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { getSessionMenu } from '../../cbt/session/ts/SessionApi';
import { updateSessionMenu } from '../../../../features/sessionMenuReducer';

export const TherapistMain = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const encryptedData = queryParams.get('page');
    const [shouldRedirect, setShouldRedirect] = useState(false);

    const visible = useSelector((state: RootState) => state.navVisible);

    useEffect(() => {
        // 현재 URL을 확인하고 리디렉션 설정
        const currentPath = window.location.pathname;
        if (currentPath === '/yd/therapist/assignedMember/patientCBT/face') {
            setShouldRedirect(true);
        }
    }, []);

    const headerTitle = () => {
        if (encryptedData) {
            const session = encryptedData.toString();
            switch (session) {
                case '1':
                    return '세션1: ';

                case '2':
                    return '세션2: ';

                case '3':
                    return '세션3: ';

                case '4':
                    return '세션4: ';

                case '5':
                    return '세션5: ';

                case '6':
                    return '세션6: ';

                case '7':
                    return '세션7: ';

                case '8':
                    return '세션8: ';

                case '9':
                    return '세션9: ';

                case '10':
                    return '세션10: ';
            }
        }
    };

    return (
        <div className={styles.main_container}>
            <TherapistHeader title={headerTitle() ? headerTitle() + '생각의 습관에서 자유로워지다' : ''} />
            <TherapistLeftMenu />
            <div className={`${styles.content_container} ${visible.navVisible ? styles.activate : styles.deactivate}`}>
                <Routes>
                    <Route path="assignedMember" element={<TherapistAssignedMember />} />
                    <Route path="assignedMember/detail/*" element={<AssignedDetail />} />
                    <Route path="reservationStatus" element={<ReservationStatus />} />
                    <Route path="memberInfo" element={<MemberInfo />} />
                    <Route path="notificationSetting" element={<NotificationSetting />} />
                    <Route
                        path="assignedMember/patientCBT/face"
                        element={
                            shouldRedirect ? (
                                <Navigate
                                    to={`/yd/therapist/assignedMember/patientCBT/face/session?page=${encodeURIComponent(
                                        encryptData('1')
                                    )}`}
                                    replace
                                />
                            ) : (
                                <TherapistAssignedMember />
                            )
                        }
                    />
                    <Route path="assignedMember/patientCBT/face/*" element={<TherapistFaceCBT />} />
                    <Route
                        path="assignedMember/patientCBT/nonface"
                        element={
                            shouldRedirect ? (
                                <Navigate
                                    to={`/yd/therapist/assignedMember/patientCBT/nonface/session?page=${encodeURIComponent(
                                        encryptData('1')
                                    )}`}
                                    replace
                                />
                            ) : (
                                <TherapistAssignedMember />
                            )
                        }
                    />
                    <Route path="assignedMember/patientCBT/nonface/*" element={<TherapistNonFaceCBT />} />
                </Routes>
            </div>
        </div>
    );
};
